import type { ApplicationI, CampaignI, SectorI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { useAcl } from 'vue-simple-acl'
import CampaignService from '~/services/campaign.service'
import { useSearch } from '~/composables/useSearch'
import { ACL_ROLES, FiltersType } from '~/types'
import { useSort } from '~/composables/useSort'
import { useFetchPage } from '~/composables/useFetchPage'
import { useUserSession } from '~/stores/useUserSession'
import { useConfigStore } from '~/stores/useConfigStore'

export function useCampaign() {
  const service = CampaignService.getCampaigns.bind(CampaignService)
  const itemType = 'campaign'

  const { can } = useAcl()
  const { userSession } = useUserSession()
  const { defaultSectorCode } = useConfigStore()

  const { searchParams, filterCounts, filters, setFilter, search, onSearch } = useSearch(FiltersType.RAW)
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  function getDescription(item: ApplicationI) {
    return item?.name ?? ''
  }

  function canManageItems(sector: SectorI) {
    return !sector.isRootSector && can(ACL_ROLES.NO_CONTROL) && userSession?.sectors.map(sector => sector.code).includes(`${defaultSectorCode}`)
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map(item => item.id)
  }

  return {
    itemType,
    isLoading,
    results,
    error,
    filterCounts,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    getDescription,
    canManageItems,
    getItemIds,
    search,
    onSearch,
  }
}

export function useGetCampaignStat(campaign?: CampaignI | null): Map<string, any> {
  const stats: Map<string, any> = new Map()
  const zeroIfNaN = (value: number | undefined) => value === undefined || isNaN(value) ? 0 : value
  const sum = (values: Array<number | undefined>) => values.reduce((total: number | undefined, value: number | undefined) => zeroIfNaN(total) + zeroIfNaN(value), 0) || 0
  const countTotal = zeroIfNaN(campaign?.statistics?.total)
  const round = (value: number, decimal: number) => Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal)

  const countPending = sum([
    campaign?.statistics?.pending,
    campaign?.statistics?.opPending,
    campaign?.statistics?.paused,
  ])

  const countInProgress = sum([
    campaign?.statistics?.delivered,
    campaign?.statistics?.opRunning,
    campaign?.statistics?.retry,
    campaign?.statistics?.opRetry,
  ])

  const countSuccess = sum([
    campaign?.statistics?.done,
  ])

  const countFailed = sum([
    campaign?.statistics?.opCancelling,
    campaign?.statistics?.opCancelled,
    campaign?.statistics?.opError,
    campaign?.statistics?.cancelling,
    campaign?.statistics?.cancelled,
    campaign?.statistics?.error,
    campaign?.statistics?.expired,
  ])

  stats.set('countPending', countPending)
  stats.set('countInProgress', countInProgress)
  stats.set('countSuccess', countSuccess)
  stats.set('countFailed', countFailed)

  stats.set('percentPending', countTotal > 0 ? `${round(countPending / countTotal * 100, 2)}%` : '-')
  stats.set('percentInProgress', countTotal > 0 ? `${round(countInProgress / countTotal * 100, 2)}%` : '-')
  stats.set('percentSuccess', countTotal > 0 ? `${round(countSuccess / countTotal * 100, 2)}%` : '-')
  stats.set('percentFailed', countTotal > 0 ? `${round(countFailed / countTotal * 100, 2)}%` : '-')

  return stats
}
