import type { ApiListResult, CampaignOperationState, ObjectCampaignI } from '@lxc/app-device-types'
import { SearchMode, useSearch } from './useSearch'
import campaignService from '~/services/campaign.service'
import LxcError from '~/utils/LxcError'
import { Filters, FiltersType } from '~/types'

export function useCampaignProcessedObjects(campaignId: string) {
  const campaignProcessedObjects = ref<ApiListResult<ObjectCampaignI> | null>(null)
  const error = ref<LxcError | null>(null)
  const isLoading = ref<boolean>(false)

  const { searchParams, filterCounts, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.TWO_AMPERSAND_SEPARATOR,
    [
      Filters.NAME,
      Filters.MODEL_TYPE,
      Filters.STATE,
    ],
    SearchMode.FILTER_SEARCH,
  )

  const label: Ref<string> = ref('')
  const objectTypes: Ref<Array<string>|undefined> = ref()
  const states: Ref<Array<CampaignOperationState>|undefined> = ref()
  async function fetchData(page?: number, pageSize?: number, sort?: string) {
    isLoading.value = true
    error.value = null
    filters.forEach((filter) => {
      switch (filter.key) {
        case Filters.NAME:
          label.value = filter.value as string
          break
        case Filters.MODEL_TYPE:
          objectTypes.value = filter.value as Array<string>
          break
        case Filters.STATE:
          states.value = filter.value as Array<CampaignOperationState>
          break
      }
    })

    const response = await campaignService.getCampaignProcessedObjects(campaignId, page, pageSize, sort, label.value, objectTypes.value, states.value)

    if (LxcError.check(response)) {
      campaignProcessedObjects.value = null
      error.value = response
    } else {
      campaignProcessedObjects.value = response
    }

    isLoading.value = false
  }

  return {
    campaignProcessedObjects,
    error,
    isLoading,
    filterCounts,
    filters,
    searchParams,
    fetchData,
    onSearch,
    search,
    setFilter,
  }
}
