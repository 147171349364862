<script setup lang='ts'>
import { useSerialize } from '@lxc/app-device-common'
import type { FormInstance } from 'element-plus'
import { reactive } from 'vue'
import type { CertificateFormItemI, PkiConnectorI } from '@lxc/app-device-types'
import { CertificateType } from '@lxc/app-device-types'
import LxcCertificateSelectionModalVue from '../truststore/LxcCertificateSelectionModal.vue'
import ILxCloudQuestionMark from '~icons/lx/cloud-question-mark'

interface PkiFormRules {
  url: any
  login: any
  password?: any
  nominalTemplateName: any
  bootstrapTemplateName: any
  pemUpload: any
}

const serialize = useSerialize()

const emit = defineEmits([
  'cancel',
  'validate',
  'testConnection',
])
const props = defineProps<{
  pkiConfiguration: PkiConnectorI | undefined | null
}>()

const { t } = useI18n()
const [dialogVisible, toggleVisible] = useToggle()
const [passwordToUpdate, togglePasswordToUpdate] = useToggle()

const DEFAULT_TEMPLATE_DURATION_SEC = 3600
const MIN_TEMPLATE_DURATION_SEC = 60
const MAX_TEMPLATE_DURATION_SEC = 2147483647
const HTTPS_PREFIX = 'https://'
const DEFAULT_ROOT_FILE_NAME = LxcCertificateSelectionModalVue.DEFAULT_ROOT_FILE_NAME

const pkiFormRef = ref<FormInstance>()
const updateCertificateType = ref<CertificateFormItemI>()
const pkiConnectorForm = reactive({
  rootCaId: undefined,
  url: '',
  login: '',
  password: '',
  nominalTemplateName: '',
  bootstrapTemplateName: '',
  nominalIntervalTime: DEFAULT_TEMPLATE_DURATION_SEC,
  bootstrapIntervalTime: DEFAULT_TEMPLATE_DURATION_SEC,
  rootCa: '',
  lastKnowFileName: '',
})
const rules = computed(() => {
  const rulesConfig: PkiFormRules = {
    url: [
      {
        required: true,
        message: t('pki.validation.url'),
        whitespace: true,
        trigger: 'blur',
      },
      {
        type: 'url',
        message: t('pki.validation.urlFormat'),
        transform: (value: string) => HTTPS_PREFIX + value,
        trigger: 'blur',
      },
    ],
    login: [
      {
        required: true,
        message: t('pki.validation.login'),
        whitespace: true,
        trigger: 'blur',
      },
    ],
    nominalTemplateName: [
      {
        required: true,
        message: t('pki.validation.templateName'),
        trigger: 'blur',
      },
      {
        message: t('pki.validation.templateFormat'),
        whitespace: true,
        trigger: 'blur',
      },
    ],
    bootstrapTemplateName: [
      {
        required: false,
        message: t('pki.validation.templateFormat'),
        whitespace: true,
        trigger: 'blur',
      },
    ],
    pemUpload: [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (!pkiConnectorForm?.rootCa || pkiConnectorForm?.rootCa === '') {
            callback(new Error(t('pki.validation.rootCa')))
          } else {
            callback()
          }
        },
        trigger: 'change,  blur',
      },
    ],
  }

  if (passwordToUpdate.value) {
    rulesConfig.password = [
      {
        required: true,
        message: t('pki.validation.password'),
        whitespace: true,
        trigger: 'blur',
      },
    ]
  }

  return rulesConfig
})

const refreshPkiConfiguration: () => void = () => {
  Object.assign(pkiConnectorForm, props.pkiConfiguration)
  if (pkiConnectorForm.url) {
    pkiConnectorForm.url = pkiConnectorForm.url.replace(HTTPS_PREFIX, '')
  }
  if (!pkiConnectorForm.lastKnowFileName) {
    pkiConnectorForm.lastKnowFileName = DEFAULT_ROOT_FILE_NAME
  }
  if (!pkiConnectorForm.nominalIntervalTime) {
    pkiConnectorForm.nominalIntervalTime = DEFAULT_TEMPLATE_DURATION_SEC
  }
  if (!pkiConnectorForm.bootstrapIntervalTime) {
    pkiConnectorForm.bootstrapIntervalTime = DEFAULT_TEMPLATE_DURATION_SEC
  }
}

watch(() => props.pkiConfiguration, (newPkiConfiguration) => {
  if (!serialize(pkiConnectorForm) !== serialize(newPkiConfiguration)) {
    refreshPkiConfiguration()
  }
})

onMounted(async() => {
  refreshPkiConfiguration()
})

/**
 * Send data if form is valid
 */
async function onSave() {
  if (pkiFormRef.value) {
    pkiFormRef.value.clearValidate()
    await pkiFormRef.value.validate(valid => emitFormData(valid, 'validate'))
  }
}

/**
 * Send data if fields for test connection are valid
 */
async function onTestConnection() {
  if (pkiFormRef.value) {
    pkiFormRef.value.clearValidate()
    await pkiFormRef.value.validateField(['url', 'login', 'password', 'pemUpload'], valid => emitFormData(valid, 'testConnection'))
  }
}

/**
 * Send Data without password if passwordToUpdate is not
 * Note: new object is emitted, otherwise it causes issue with elemnt plus form validation
 * @param valid
 * @param name
 */
function emitFormData(valid: boolean, name: 'validate' | 'testConnection') {
  if (valid) {
    const { password, ...pkiConnectorFormWithoutPassword } = pkiConnectorForm
    emit(name, passwordToUpdate.value ? { ...pkiConnectorForm } : pkiConnectorFormWithoutPassword)
  }
}

/**
 * Apply modal configuration and opens it
 */
function onSelectCertificate() {
  updateCertificateType.value = {
    caCertChain: pkiConnectorForm.rootCa,
    type: CertificateType.CAPKISRVCERT,
    lastKnownFileName: pkiConnectorForm.lastKnowFileName,
  }

  dialogVisible.value = true
}

/**
 * Close modal and set form data based on modals user input
 * @param certificateDescription
 */
function onCertificateUpdated(certificateDescription: CertificateFormItemI) {
  dialogVisible.value = false

  pkiFormRef.value?.clearValidate()
  pkiConnectorForm.rootCa = certificateDescription.caCertChain
  pkiConnectorForm.lastKnowFileName = certificateDescription.lastKnownFileName ?? DEFAULT_ROOT_FILE_NAME
}
</script>

<template>
  <el-container direction="vertical">
    <lxc-container>
      <lxc-information-row :title="$t('pki.formGeneralDescription')" />
      <lxc-mandatory />
      <el-container direction="vertical">
        <el-form
          ref="pkiFormRef"
          :rules="rules"
          :model="pkiConnectorForm"
          label-position="left"
          label-width="210px"
        >
          <el-container direction="vertical">
            <h2 class="section-title">
              {{ $t('section.connectionParameters.title') }}
            </h2>

            <el-container
              direction="vertical"
              class="container"
            >
              <el-form-item
                :label="$t('input.url')"
                prop="url"
              >
                <el-input
                  v-model="pkiConnectorForm.url"
                  type="text"
                >
                  <template #prepend>
                    {{ HTTPS_PREFIX }}
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item
                :label="$t('input.login')"
                prop="login"
              >
                <el-input
                  v-model="pkiConnectorForm.login"
                  type="text"
                />
              </el-form-item>
              <el-form-item
                :label="$t('input.password')"
                prop="password"
              >
                <el-row
                  :gutter="10"
                  class="full-width"
                >
                  <el-col :span="12">
                    <el-input
                      v-if="passwordToUpdate"
                      v-model="pkiConnectorForm.password"
                      type="password"
                      show-password
                    />
                    <div v-else>
                      {{ $t('input.passwordPlaceholder') }}
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <el-button
                      plain
                      size="small"
                      class="lxc-plain"
                      @click="togglePasswordToUpdate"
                    >
                      {{ $t(passwordToUpdate ? 'button.cancel' : 'button.update') }}
                    </el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-container>
          </el-container>

          <lxc-information-row :title="$t('pki.formRootCaDescription')" />
          <el-row>
            <el-form-item
              label-width="0px"
              prop="pemUpload"
            >
              <el-button
                plain
                class="lxc-plain"
                @click="onSelectCertificate"
              >
                {{ pkiConnectorForm.rootCa ? $t('button.updateRootCa') : $t('button.create.rootCa') }}
              </el-button>
            </el-form-item>

            <el-button
              type="primary"
              class="test-button"
              :icon="ILxCloudQuestionMark"
              @click="onTestConnection"
            >
              {{ $t('button.test.pki') }}
            </el-button>
          </el-row>
          <el-divider />

          <el-container direction="vertical">
            <h2 class="section-title">
              {{ $t('section.specificParameters.title') }}
            </h2>

            <el-container direction="horizontal">
              <el-form-item
                class="form-half-row"
                :label="$t('input.nominalTemplateName')"
                prop="nominalTemplateName"
              >
                <el-input
                  v-model="pkiConnectorForm.nominalTemplateName"
                  maxlength="100"
                  show-word-limit
                  type="text"
                />
              </el-form-item>
              <el-form-item
                :label="$t('input.secondIntervalTime')"
                prop="nominalRetryAfter"
                label-width="220px"
              >
                <el-input-number
                  v-model="pkiConnectorForm.nominalIntervalTime"
                  :min="MIN_TEMPLATE_DURATION_SEC"
                  :max="MAX_TEMPLATE_DURATION_SEC"
                  controls-position="right"
                  class="mx-4 form-input-number"
                  :disabled="pkiConnectorForm.nominalTemplateName?.trim().length === 0"
                />
              </el-form-item>
            </el-container>
            <el-container direction="horizontal">
              <el-form-item
                prop="bootstrapTemplateName"
                class="form-half-row"
                :label="$t('input.bootstrapTemplateName')"
              >
                <el-input
                  v-model="pkiConnectorForm.bootstrapTemplateName"
                  maxlength="100"
                  show-word-limit
                  type="text"
                />
              </el-form-item>
              <el-form-item
                :label="$t('input.secondIntervalTime')"
                label-width="220px"
              >
                <el-input-number
                  v-model="pkiConnectorForm.bootstrapIntervalTime"
                  :min="MIN_TEMPLATE_DURATION_SEC"
                  :max="MAX_TEMPLATE_DURATION_SEC"
                  controls-position="right"
                  class="mx-4 form-input-number"
                  :disabled="pkiConnectorForm.bootstrapTemplateName?.trim().length === 0"
                />
              </el-form-item>
            </el-container>
          </el-container>
        </el-form>
      </el-container>

      <lxc-cancel-or-submit-buttons
        @cancel="$emit('cancel', $event)"
        @submit="onSave"
      />

      <lxc-certificate-selection-modal
        :already-added-certificate-type="[]"
        :update-certificate-type="updateCertificateType"
        :is-dialog-visible="dialogVisible"
        @update:toggle-dialog="toggleVisible"
        @validate="onCertificateUpdated"
      />
    </lxc-container>
  </el-container>
</template>

<style lang='scss' scoped>
//Overridden nota-bene-container to set custom margins
.nota-bene-container {
  margin-top: 5px;
  margin-bottom: 10px;
}

.container {
  width: 65vh;
  min-width: 150px;
}

.form-half-row {
  width: 65vh;
  min-width: 400px;
  padding-right: 4vh;
}

.form-upload {
  min-width: 25vh;
}

.form-input-number {
  width: 20vh;
  min-width: 100px;
}

.test-button {
  margin-left: 0.5rem;
}

.full-width {
  width: 100%;
}
</style>
