export function useVersion() {
  function getFunctionalVersion(version: string) {
    const found = version.match(/^\d+[a-z]?\.\d+[a-z]?|\d+[a-z]?/)
    if (found && found.length > 0) {
      return found[0]
    } else {
      return version
    }
  }

  return { getFunctionalVersion }
}
