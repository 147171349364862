<script setup lang="ts">
import type { Ref } from 'vue'
import { onMounted } from 'vue'
import type { DeviceI, SectorI } from '@lxc/app-device-types'
import { ElTableColumn } from 'element-plus'
import { useDevices } from '~/composables/useDevices'
import { PATHS } from '~/constants/paths'
import { Filters, SectorResource } from '~/types'
import { router } from '~/plugins/router'

const { t } = useI18n()
const props = defineProps<{
  sector: SectorI
}>()
const {
  isLoading,
  results: devices,
  error,
  fetchData,
  setFilter,
  canManageItems,
  getDescription,
} = useDevices()

const selectedItems: Ref<any[]> = ref([])
const displayActions = computed(() => canManageItems(props.sector))

onMounted(async() => {
  setFilter(Filters.SECTORS, [props.sector.code])
  await fetchData()
})

async function refresh() {
  await fetchData()
  selectedItems.value = []
}

function onRowClick(device: DeviceI) {
  router.push(`${PATHS.DEVICES_DVTM_ESOFT}/${device.id}`)
}
</script>

<template>
  <lxc-sector-toolbar
    ref="sectorToolbar"
    primary-key="id"
    :selected-items="selectedItems"
    :sector="sector"
    :resource="SectorResource.DEVICE"
    @change="refresh"
  >
    <el-table-column
      prop="name"
      :label="$t('device.name')"
    />
    <el-table-column
      prop="model.declination"
      :label="$t('device.model')"
    />
    <el-table-column :label="$t('device.state')">
      <template #default="scope">
        {{ scope.row.state ? $t(`device.states.${scope.row.state}`) : '' }}
      </template>
    </el-table-column>
  </lxc-sector-toolbar>

  <lxc-container
    :is-loading="isLoading && !devices"
    :error="error"
  >
    <lxc-sectors-details-table
      v-model="selectedItems"
      :is-loading="isLoading"
      :selection="displayActions"
      :items="devices"
      :display-actions="displayActions"
      :resource="SectorResource.DEVICE"
      :get-description="getDescription"
      @pagination:get-next-page="fetchData"
      @actions:detach="(item) => $refs.sectorToolbar.openDetachModal(item)"
      @row-click="onRowClick"
    >
      <el-table-column
        prop="name"
        :label="$t('device.name')"
      />
      <el-table-column
        prop="model.declination"
        :label="$t('device.model')"
      />
      <el-table-column
        prop="serialNumber"
        :label="$t('device.serialNumber')"
      />
      <el-table-column :label="$t('device.state')">
        <template #default="scope">
          {{ scope.row.state ? $t(`device.states.${scope.row.state}`) : '' }}
        </template>
      </el-table-column>
    </lxc-sectors-details-table>
  </lxc-container>
</template>
