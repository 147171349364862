<script lang='ts' setup>
import type LxcError from '~/utils/LxcError'

const { t } = useI18n()
const props = defineProps<{
  error?: LxcError
}>()
</script>

<template>
  <el-result
    :icon="t(`errors.${error.code}.type`)"
    :title="t(`errors.${error.code}.title`)"
    :sub-title="t(`errors.${error.code}.subtitle`)"
  >
    <slot />
  </el-result>
</template>
