export enum Granularity {
  MILLISECOND = 'millisecond',
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export interface Value {
  timestamp: number
  value: any
}

export interface Serie {
  name: string
  label: string
  unit: string
  unitSymbol?: string
  granularity?: Granularity
  isCalculated?: boolean
  precision?: number

  values: Array<Value>
  formatValue?: (value: number) => string

  // scale properties, cf. https://www.chartjs.org/docs/3.9.1/axes/styling.html
  scaleProperties?: any

  // dataset properties, cf. https://www.chartjs.org/docs/3.9.1/configuration/#dataset-configuration
  datasetProperties?: any
}
