<script lang='ts' setup>
import type { ApplicationModelConfigurationI, DeviceModelConfigurationI } from '@lxc/app-device-types'
import DeviceService from '~/services/device.service'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import ApplicationService from '~/services/application.service'
import { useCertificateConfiguration } from '~/composables/useCertificateConfiguration'
import LxcError from '~/utils/LxcError'
import { ConfigParam } from '~/types'
import { S4Declination, S4Types } from '~/types/certConfiguration'
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'

const { t } = useI18n()
const router = useRouter()

const {
  isLoading,
  error,
  appsConfig,
  devicesConfig,
  fetchAppsConfig,
  fetchDeviceConfig,
  resetAppsConfig,
  resetDevicesConfig,
  onAppsConfigSaved,
  onDeviceConfigSaved,
} = useCertificateConfiguration()

function setSameValuesForAllDevices(devices: DeviceModelConfigurationI[], configParam: ConfigParam, event: number) {
  return devices.map((device: DeviceModelConfigurationI) => ({
    type: device.type,
    declination: device.declination,
    polling: configParam === ConfigParam.POLLING ? event : device.polling,
    delay: configParam === ConfigParam.DELAY ? event : device.delay,
    autoActivation: configParam === ConfigParam.AUTO_ACTIVATION ? Boolean(event) : device.autoActivation,
  }))
}

function setValuesForApp(applications: ApplicationModelConfigurationI[], configParam: ConfigParam, event: number, type: S4Types) {
  return applications?.map((app: ApplicationModelConfigurationI) => app.type === type
    ? ({
      type: app.type,
      declination: app.declination,
      polling: configParam === ConfigParam.POLLING ? event : app.polling,
      delay: configParam === ConfigParam.DELAY ? event : app.delay,
      autoActivation: configParam === ConfigParam.AUTO_ACTIVATION ? Boolean(event) : app.autoActivation,
    })
    : app)
}

const device = computed<DeviceModelConfigurationI | null | undefined>(() => devicesConfig?.value?.find(device => device.declination === S4Declination.S4W))
const sg4000 = computed<ApplicationModelConfigurationI | null | undefined>(() => appsConfig?.value?.find(app => app.type === S4Types.SG4000))
const s4Tools = computed<ApplicationModelConfigurationI | null | undefined>(() => appsConfig?.value?.find(app => app.declination === S4Declination.S4W))
const s4Manager = computed<ApplicationModelConfigurationI | null | undefined>(() => appsConfig?.value?.find(app => app.type === S4Types.MANAGER))
const s4View = computed<ApplicationModelConfigurationI | null | undefined>(() => appsConfig?.value?.find(app => app.type === S4Types.VIEW))

/**
 * Update delay, polling or auto activation values
 */
function onValueChange(event: string | number | boolean, type: S4Types, configParam: ConfigParam) {
  const numberedEvent = Number(event)

  if (!appsConfig.value || !devicesConfig.value) {
    return
  }

  switch (type) {
    case S4Types.DVC:
      devicesConfig.value = setSameValuesForAllDevices(devicesConfig?.value, configParam, numberedEvent)
      break
    case S4Types.TOOLS:
    case S4Types.MANAGER:
    case S4Types.VIEW:
    case S4Types.SG4000:
      appsConfig.value = setValuesForApp(appsConfig?.value, configParam, numberedEvent, type)
      break
  }
}

/**
 * Update configurations
 */
async function updateConfig() {
  if (!devicesConfig.value || !appsConfig.value) {
    showNotificationError(t(NotificationKey.error))
    return
  }
  const result = await Promise.all([
    DeviceService.updateDeviceConfiguration(devicesConfig.value),
    ApplicationService.updateAppsConfiguration(appsConfig.value),
  ])

  if (result.some(updateAction => LxcError.check(updateAction))) {
    showNotificationError(t(NotificationKey.saveError))
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess))
    onAppsConfigSaved()
    onDeviceConfigSaved()
  }
}

function cancel() {
  resetAppsConfig()
  resetDevicesConfig()
  showNotificationSuccess(t(NotificationKey.cancel))
}

onMounted(() => {
  Promise.all([fetchDeviceConfig(), fetchAppsConfig()])
})

</script>

<template>
  <el-container direction="vertical">
    <lxc-breadcrumb
      :name="BreadcrumbConfig.DEVICE_CONFIGURATION.title"
      readonly
    />

    <lxc-container
      :is-loading="isLoading"
      :error="error"
    >
      <lxc-mandatory />

      <lxc-device-config
        v-if="devicesConfig"
        :s4-device="device"
        @change="onValueChange"
      />

      <el-divider />

      <lxc-apps-config
        v-if="appsConfig && devicesConfig"
        :s4-tool="s4Tools"
        :s4-manager="s4Manager"
        :s4-view="s4View"
        :sg4000="sg4000"
        @change="onValueChange"
      />

      <lxc-cancel-or-submit-buttons
        @cancel="cancel"
        @submit="updateConfig"
      />
    </lxc-container>
  </el-container>
</template>
