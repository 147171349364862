<script setup lang="ts">
import { menu } from './menu.config'
import { router } from '~/plugins/router'
import ILxLacroixLogo from '~icons/lx/lacroix-logo'
const { t } = useI18n()

let setIsMobileTimeout: any = null
// TODO : manage collapse mode
const collapse = ref<Boolean>(isMobile())

onMounted(() => window.addEventListener('resize', onResize))
onBeforeUnmount(() => window.removeEventListener('resize', onResize))
const backLabel = t('layout.menu.back')

function onResize() {
  clearTimeout(setIsMobileTimeout)
  setIsMobileTimeout = setTimeout(() => collapse.value = isMobile(), 100)
}

function isMobile() {
  return window.innerWidth <= 765
}

const globalIndex = (paramGroupIndex: number, paramItemIndex: number, paramSubGroupIndex?: number, paramSubItemIndex?: number, bottom?: boolean): string => {
  let index = 0
  for (let groupIndex = 0, groupLen = menu.value.items.length; groupIndex < groupLen; groupIndex++) {
    const group = menu.value.items[groupIndex]

    for (let itemIndex = 0, itemLen = group.items.length; itemIndex < itemLen; itemIndex++) {
      const item = group.items[itemIndex]
      let subIndex = 0

      if (paramSubGroupIndex !== undefined && paramSubItemIndex !== undefined && item.items !== undefined && item.items?.length !== 0) {
        for (let subGroupIndex = 0, subGroupLen = item.items.length; subGroupIndex < subGroupLen; subGroupIndex++) {
          const subGroup = item.items[groupIndex]

          for (let subItemIndex = 0, subItemLen = subGroup.items.length; subItemIndex < subItemLen; subItemIndex++) {
            if (!bottom && subGroupIndex === paramSubGroupIndex && subItemIndex === paramSubItemIndex) {
              return `${index}-${subIndex}`
            }
            subIndex++
          }
        }
      }

      if (!bottom && groupIndex === paramGroupIndex && paramItemIndex === itemIndex) {
        return index.toString()
      }

      index++
    }
  }

  if (bottom) {
    for (let groupIndex = 0, groupLen = menu.value.bottomItems.length; groupIndex < groupLen; groupIndex++) {
      const group = menu.value.bottomItems[groupIndex]

      for (let itemIndex = 0, itemLen = group.items.length; itemIndex < itemLen; itemIndex++) {
        const item = group.items[itemIndex]
        let subIndex = 0

        if (paramSubGroupIndex !== undefined && paramSubItemIndex !== undefined && item.items !== undefined && item.items?.length !== 0) {
          for (let subGroupIndex = 0, subGroupLen = item.items.length; subGroupIndex < subGroupLen; subGroupIndex++) {
            const subGroup = item.items[subGroupIndex]

            for (let subItemIndex = 0, subItemLen = subGroup.items.length; subItemIndex < subItemLen; subItemIndex++) {
              if (subGroupIndex === paramSubGroupIndex && subItemIndex === paramSubItemIndex) {
                return `${index}-${subIndex}`
              }
              subIndex++
            }
          }
        }

        if (groupIndex === paramGroupIndex && paramItemIndex === itemIndex) {
          return index.toString()
        }

        index++
      }
    }
  }

  return '0'
}
</script>

<template>
  <div class="min-h-[25rem] h-full">
    <lxc-menu
      :router="router"
      :default-active="menu.defaultActive?.toString()"
    >
      <lxc-menu-group
        v-for="(menuGroup, groupIndex) in menu.items"
        :key="groupIndex"
        :title="menuGroup.label ? $t(menuGroup.label) : undefined"
        :visible="menuGroup.visible"
      >
        <template
          v-for="(menuItem, itemIndex) in menuGroup.items"
          :key="itemIndex"
        >
          <lxc-menu-item
            v-if="!menuItem.items"
            :route="menuItem.to"
            :data-cy="menuItem.dataCy"
            :index="globalIndex(groupIndex, itemIndex)"
            :visible="menuItem.visible"
          >
            <component :is="menuItem.iconOrComponent" />
            <span
              v-if="menuItem.label !== undefined"
              class="whitespace-nowrap"
            >{{ t(menuItem.label) }}</span>
          </lxc-menu-item>
          <lxc-sub-menu
            v-if="menuItem.items !== undefined && menuItem.items?.length !== 0"
            :back-label="backLabel"
            :index="globalIndex(groupIndex, itemIndex)"
            :visible="menuItem.visible"
          >
            <template #title>
              <component :is="menuItem.iconOrComponent" />
              <span class="whitespace-nowrap">{{ menuItem.label }}</span>
            </template>
            <lxc-menu-group
              v-for="(subMenuGroup, subGroupIndex) in menuItem.items"
              :key="subGroupIndex"
              :title="subMenuGroup.label ? $t(subMenuGroup.label) : undefined"
              :visible="subMenuGroup.visible"
            >
              <lxc-menu-item
                v-for="(menuSubItem, subItemIndex) in subMenuGroup.items"
                :key="subItemIndex"
                :route="menuSubItem.to"
                :data-cy="menuSubItem.dataCy"
                :index="globalIndex(groupIndex, itemIndex, subGroupIndex, subItemIndex)"
                :visible="menuSubItem.visible"
              >
                <span
                  v-if="menuSubItem.label !== undefined"
                  class="whitespace-nowrap"
                >{{ t(menuSubItem.label) }}</span>
              </lxc-menu-item>
            </lxc-menu-group>
          </lxc-sub-menu>
        </template>
      </lxc-menu-group>

      <template
        v-if="menu.bottomItems.length !== 0"
        #menu-bottom
      >
        <lxc-menu-group
          v-for="(menuGroup, groupIndex) in menu.bottomItems"
          :key="groupIndex"
          :title="menuGroup.label ? $t(menuGroup.label) : undefined"
          :visible="menuGroup.visible"
        >
          <template
            v-for="(menuItem, itemIndex) in menuGroup.items"
            :key="itemIndex"
          >
            <lxc-menu-item
              v-if="!menuItem.items"
              :route="menuItem.to"
              :data-cy="menuItem.dataCy"
              :index="globalIndex(groupIndex, itemIndex, undefined, undefined, true)"
              :visible="menuItem.visible"
            >
              <component
                :is="menuItem.iconOrComponent"
                :title="menuItem.label !== undefined ? $t(menuItem.label) : ''"
              />
              <span
                v-if="menuItem.label !== undefined"
              >{{ t(menuItem.label) }}</span>
            </lxc-menu-item>
            <lxc-sub-menu
              v-if="menuItem.items !== undefined && menuItem.items?.length !== 0"
              :back-label="backLabel"
              :index="globalIndex(groupIndex, itemIndex, undefined, undefined, true)"
              :visible="menuItem.visible"
            >
              <template #title>
                <component
                  :is="menuItem.iconOrComponent"
                  :title="menuItem.label !== undefined ? $t(menuItem.label) : ''"
                />
                <span
                  v-if="menuItem.label !== undefined"
                  class="whitespace-nowrap"
                >{{ t(menuItem.label) }}</span>
              </template>
              <lxc-menu-group
                v-for="(subMenuGroup, subGroupIndex) in menuItem.items"
                :key="subGroupIndex"
                :title="subMenuGroup.label ? $t(subMenuGroup.label) : undefined"
                :visible="subMenuGroup.visible"
              >
                <lxc-menu-item
                  v-for="(menuSubItem, subItemIndex) in subMenuGroup.items"
                  :key="subItemIndex"
                  :route="menuSubItem.to"
                  :data-cy="menuSubItem.dataCy"
                  :index="globalIndex(groupIndex, itemIndex, subGroupIndex, subItemIndex, true)"
                  :visible="menuSubItem.visible"
                >
                  <span
                    v-if="menuSubItem.label !== undefined"
                    class="whitespace-nowrap"
                  >{{ t(menuSubItem.label) }}</span>
                </lxc-menu-item>
              </lxc-menu-group>
            </lxc-sub-menu>
          </template>
        </lxc-menu-group>
      </template>

      <template #footer>
        <div class="flex justify-items-center justify-center mt-[1.9375rem]">
          <i-lx-lacroix-logo class="w-[11.1875rem] h-[2.75rem]" />
        </div>
      </template>
    </lxc-menu>
  </div>
</template>
