<script setup lang="ts">
import ILxFilter from '~icons/lx/filter'
import type { FilterSelectionDefinition } from '~/types'
import { Filters, Option } from '~/types'
import { deviceModelOptions } from '~/components/devices/deviceList/deviceFilters.config'

const props = defineProps<{
  deviceType?: FilterSelectionDefinition
  dateAddedAfter?: FilterSelectionDefinition
  dateAddedBefore?: FilterSelectionDefinition
  dateUpdatedAfter?: FilterSelectionDefinition
  dateUpdatedBefore?: FilterSelectionDefinition
}>()

const filtersCount = computed(() => {
  return [props.deviceType, props.dateAddedAfter, props.dateUpdatedAfter]
    .filter(filter => filter && filter.value.length !== 0)
    .length ?? 0
})

const emit = defineEmits(['change', 'enter'])

const dropdown = ref()
/**
 * Emit enter event and close filter modal
 */
function closeModal() {
  emit('enter')
  dropdown.value.handleClose()
}

function onClearFilters() {
  if (props.deviceType) {
    emit('change', Filters.APPMGT_DEVICE_TYPES, [])
  }
  if (props.dateAddedAfter) {
    emit('change', Filters.APPMGT_DATE_ADDED_AFTER, '')
  }
  if (props.dateAddedBefore) {
    emit('change', Filters.APPMGT_DATE_ADDED_BEFORE, '')
  }
  if (props.dateUpdatedAfter) {
    emit('change', Filters.APPMGT_DATE_UPDATED_AFTER, '')
  }
  if (props.dateUpdatedBefore) {
    emit('change', Filters.APPMGT_DATE_UPDATED_BEFORE, '')
  }
}
</script>

<template>
  <el-dropdown
    ref="dropdown"
    trigger="click"
    :hide-on-click="false"
    popper-class="dropdown-overflow"
  >
    <el-button
      plain
      class="lxc-plain filter"
      size="large"
      :icon="ILxFilter"
    >
      {{ $t('page.filters') }}
      <span class="filters-count">{{ filtersCount }}</span>
    </el-button>

    <template #dropdown>
      <el-dropdown-menu
        class="filter-dd-menu"
        @keyup.enter="closeModal"
      >
        <div class="dd-content">
          <lxc-clear-filter-button
            @clear="onClearFilters"
          />
          <div
            v-if="deviceType"
            class="dd-item"
          >
            <h3>{{ $t(deviceModelOptions.label ?? '') }}</h3>
            <el-select
              :model-value="deviceType.value"
              multiple
              :placeholder="$t(deviceModelOptions.label ?? '')"
              :teleported="false"
              @change="$emit('change', Filters.APPMGT_DEVICE_TYPES, $event)"
            >
              <el-option
                v-for="item in deviceModelOptions.options"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value"
              />
            </el-select>
          </div>

          <lxc-date-range-filter
            v-if="dateAddedAfter && dateAddedBefore"
            :label="$t('applicationMgt.filters.creationDate')"
            :start="dateAddedAfter"
            :end="dateAddedBefore"
            @change:start="$emit('change', Filters.APPMGT_DATE_ADDED_AFTER, $event)"
            @change:end="$emit('change', Filters.APPMGT_DATE_ADDED_BEFORE, $event)"
          />
          <lxc-date-range-filter
            v-if="dateUpdatedAfter && dateUpdatedBefore"
            :label="$t('applicationMgt.filters.lastUpdated')"
            :start="dateUpdatedAfter"
            :end="dateUpdatedBefore"
            @change:start="$emit('change', Filters.APPMGT_DATE_UPDATED_AFTER, $event)"
            @change:end="$emit('change', Filters.APPMGT_DATE_UPDATED_BEFORE, $event)"
          />
        </div>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
