<script setup lang="ts">
import { type FirmwareI, FirmwareStatus } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import type LxcTable from '@lxc/app-device-common/src/components/LxcTable.vue'
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { displayColumns } from './LxcFirmwaresList.type'
import type { FilterOptions } from '~/types'
import { typeOptions as defaultRangeOptions } from '~/components/devices/deviceList/deviceFilters.config'
import { Filters } from '~/types'
import { useFirmware } from '~/composables/useFirmware'
import type { SearchMode } from '~/composables/useSearch'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'

const route = useRoute()
const { t } = useI18n()

const props = defineProps<{
  noAction?: boolean
  selectable?: boolean
  defaultFilters?: Map<Filters, any>
  searchMode?: SearchMode
  selectedFirmware?: FirmwareI | null
  firmwareDetail?: FirmwareI
  columns?: Array<displayColumns>
  triggerSearch?: boolean
  displayInfo?: boolean
  statusOptions?: FilterOptions
  rangeOptions?: FilterOptions
}>()

const tableRef = ref<InstanceType<typeof LxcTable>>()

const emit = defineEmits(['update:selectedFirmware', 'openFirmwareDetail'])

const {
  isLoading,
  results,
  filters,
  error,
  fetchData,
  setFilter,
  onSortChange,
  onSearch,
  search,
} = useFirmware(props.searchMode)

const searchQuery = ref<string>((filters.get(Filters.FIRMWARE_NAME_OR_VERSION) ?? '') as string)
const uuid = ref<string | undefined | null>()
const firmwareDetailShown = ref<boolean>(false)
/**
 * Retrieve selected firmware
 */
const setSelection = () => {
  if (tableRef.value?.data) {
    tableRef.value.setCurrentRow(tableRef.value.data.find(row => row.uuid === props.selectedFirmware?.uuid))
  }
}

watch(() => props.selectedFirmware, setSelection)

/**
 * Retrieve firmwares and set selected firmware if needed
 * @param params
 */
async function loadData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  await fetchData(page, pageSize)
  setTimeout(setSelection, 0)
}

const updateEditedFirmware = (paramFirmware: FirmwareI) => {
  if (results.value?.data) {
    const firmware = results.value.data.find((currentFirmware: FirmwareI) => currentFirmware.uuid === paramFirmware.uuid)
    if (firmware) {
      firmware.status = paramFirmware.status
    }
  }
}

function reloadWithContext() {
  loadData(results.value?.context?.page, results.value?.context?.pageSize)
}

const computedSelectedFirmware = computed({
  get(): FirmwareI | undefined | null {
    return props.selectedFirmware
  },
  set(selectedFirmware: FirmwareI | undefined | null) {
    emit('update:selectedFirmware', selectedFirmware)
  },
})

/**
 * Update the selected firmware or redirect to firmware details page
 */
function onSelectFirmware(firmware: FirmwareI) {
  if (props.selectable) {
    computedSelectedFirmware.value = firmware
  } else {
    uuid.value = firmware.uuid

    if (firmware?.uuid) {
      firmwareDetailShown.value = true
    }
  }
}

const onCloseFirmwareDetail = (event: Record<string, boolean>) => {
  uuid.value = null
  firmwareDetailShown.value = false

  if (event.edited) {
    search()
  }
}

const initDisplayFromRoute = () => {
  uuid.value = route?.params?.uuid as string | undefined
  if (uuid.value) {
    firmwareDetailShown.value = true
  }
}

const onDeleteFirmware = () => {
  uuid.value = null
  firmwareDetailShown.value = false
  search()
}

if (props.defaultFilters) {
  for (const [filter, value] of props.defaultFilters) {
    setFilter(filter, value || '')
  }
}

const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue
  setFilter(Filters.FIRMWARE_NAME_OR_VERSION, searchQuery.value)
}

const onClearSearch = () => {
  setFilter(Filters.FIRMWARE_NAME_OR_VERSION, '')
  search()
}

const defaultStatusOptions: FilterOptions = {
  label: t('firmware.filters.status.label'),
  options: Object.values(FirmwareStatus).map((value) => {
    const label = value ? t(`firmware.status.${value}`) : ''
    return {
      value,
      label,
    }
  }),
}

watch(() => props.triggerSearch, search)

onMounted(async() => {
  onSearch(loadData)
  initDisplayFromRoute()
})
</script>

<template>
  <lxc-search-bar
    :search-query="searchQuery"
    :search-placeholder="$t('firmware.filters.searchByNameOrVersion')"
    class="grow shrink basis-0"
    @clear="onClearSearch"
    @input-change="onSearchInputChanged"
    @search="search"
  />
  <lxc-firmware-filters
    :status="filters.get(Filters.STATUS)"
    :ranges="filters.get(Filters.RANGE)"
    :creation-start-date="defaultFilters && defaultFilters.get(Filters.DATE_ADDED_AFTER) ? undefined : filters.get(Filters.DATE_ADDED_AFTER)"
    :creation-end-date="defaultFilters && defaultFilters.get(Filters.DATE_ADDED_BEFORE) ? undefined : filters.get(Filters.DATE_ADDED_BEFORE)"
    :status-options="statusOptions ?? defaultStatusOptions"
    :range-options="rangeOptions ?? defaultRangeOptions"
    @change="setFilter"
    @enter="search"
  />

  <lxc-table
    ref="tableRef"
    v-loading="isLoading"
    :data="results?.data"
    :context="results?.context"
    :is-loading="isLoading && !results"
    :error="error"
    clickable
    :empty-text="$t('firmware.noFirmware')"
    @change-page-and-page-size="fetchData"
    @row-click="onSelectFirmware"
    @sort-change="onSortChange"
  >
    <lxc-table-column
      v-if="!columns || columns?.includes(displayColumns.NAME)"
      prop="name"
      :label="$t('firmware.column.versionName')"
    />
    <lxc-table-column
      v-if="!columns || columns?.includes(displayColumns.RANGE)"
      prop="range"
      :label="$t('firmware.column.type')"
    />
    <lxc-table-column
      v-if="!columns || columns?.includes(displayColumns.DECLINATION)"
      prop="declination"
      :label="$t('firmware.column.declination')"
    />
    <lxc-table-column
      v-if="!columns || columns?.includes(displayColumns.VERSION)"
      prop="version"
      :label="$t('firmware.column.version')"
    />
    <lxc-table-column
      v-if="!columns || columns?.includes(displayColumns.BUILD_DATE)"
      prop="creationDate"
      :label="$t('firmware.column.buildDate')"
      sortable="custom"
    >
      <template #default="scope">
        {{ (scope.row).buildDate ? dayjs((scope.row).buildDate).format(t('firmware.column.dateFormat')) : '' }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="!columns || columns?.includes(displayColumns.CREATION_DATE)"
      prop="creationDate"
      :label="$t('firmware.column.creationDate')"
      sortable="custom"
    >
      <template #default="scope">
        {{ (scope.row as FirmwareI).creationDate ? dayjs((scope.row as FirmwareI).creationDate).format(t('firmware.column.dateFormat')) : '' }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="!columns || columns?.includes(displayColumns.STATUS)"
      prop="status"
      :label="$t('firmware.column.status')"
    >
      <template #default="scope">
        <lxc-status-badge
          :status="scope.row.status"
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="!noAction"
      class="w-16"
    >
      <template #default="scoped">
        <div class="flex">
          <lxc-firmware-activate-action
            :firmware="scoped.row"
            @change="updateEditedFirmware"
          />
          <lxc-firmware-deactivate-action
            :firmware="scoped.row"
            @change="updateEditedFirmware"
          />
          <lxc-firmware-delete-action
            :key="scoped.row.id"
            :firmware="scoped.row"
            @change="reloadWithContext"
          />
        </div>
      </template>
    </lxc-table-column>
  </lxc-table>
  <lxc-firmware-detail
    :side-canvas-shown="firmwareDetailShown"
    :uuid="uuid"
    @close="onCloseFirmwareDetail"
    @delete="onDeleteFirmware"
  />

  <lxc-firmware-info
    v-if="displayInfo"
    v-model:firmware="computedSelectedFirmware"
  />
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
