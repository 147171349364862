<script setup lang="ts">
import { useTruststore } from '~/composables/useTruststore'
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'
import LxcBreadcrumb from '~/components/shared/breadcrumb/LxcBreadcrumb.vue'

const { t } = useI18n()
const {
  isLoading,
  error,
  certificates,
  fetchTruststoreConfiguration,
  addOrUpdateCertificateAndRefreshTruststoreConfiguration,
} = useTruststore()

onMounted(async() => await fetchTruststoreConfiguration())
</script>

<template>
  <el-container
    direction="vertical"
  >
    <lxc-breadcrumb
      :name="BreadcrumbConfig.TRUSTSTORE.title"
      readonly
    />
    <lxc-truststore-list
      :error="error"
      :is-loading="isLoading"
      :certificates="certificates"
      @on-certificate-updated="addOrUpdateCertificateAndRefreshTruststoreConfiguration"
    />
  </el-container>
</template>
