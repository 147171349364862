<script setup lang="ts">
import type { DeviceI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { filterEmptyValues } from '@lxc/app-device-common'
import filtersUtils from '~/utils/filters.utils'

const props = defineProps<{
  label: string
  modelValue: DeviceI[]
}>()

const emit = defineEmits(['update:modelValue'])
const deviceName = ref('')
const deviceTypes: Ref<string[]> = ref([])
const deviceDeclinations: Ref<string[]> = ref([])

function initFilterMap(paramDevicesDvtmEsoft?: DeviceI[]): Record<string, string | undefined> {
  const vFilterMap: Record<string, string | undefined> = {}

  if (paramDevicesDvtmEsoft && paramDevicesDvtmEsoft.length !== 0) {
    for (const deviceDvtmEsoft of paramDevicesDvtmEsoft) {
      const tag = filtersUtils.getDeviceDvtmEsoftTagText(deviceDvtmEsoft, props.label)
      if (tag) {
        vFilterMap[tag] = deviceDvtmEsoft.id
      }
    }
  }

  return vFilterMap
}

function deleleTagFromList(id: string, list?: DeviceI[]) {
  if (list) {
    const index = list.findIndex(deviceDvtmEsoft => deviceDvtmEsoft.id === id)

    if (index != null && index >= 0) {
      list.splice(index, 1)
    }
  }
}

const selectedDevicesDvtmEsoft = computed({
  get(): DeviceI[] {
    return props.modelValue ?? []
  },
  set(selected?: Array<DeviceI>) {
    emit('update:modelValue', selected)
  },
})
const isAnyAppliedFilter = computed(() => {
  return props.modelValue.length !== 0
})

const filterTags = computed((): string[] => {
  return filterEmptyValues<string>(props.modelValue.map(deviceDvtmEsoft => filtersUtils.getDeviceDvtmEsoftTagText(deviceDvtmEsoft, props.label)))
})

const filterMap = computed((): Record<string, string | undefined> => {
  return initFilterMap(props.modelValue)
})

const onTagDeleteClick = (tag: string) => {
  const deviceId = filterMap.value[tag]

  if (deviceId) {
    deleleTagFromList(deviceId, selectedDevicesDvtmEsoft.value)
  }
}
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>
  <div class="rounded-lg bg-white text-gray-900">
    <div class="flex p-6 gap-6 flex-nowrap">
      <div class="flex-1 items-start">
        <lxc-log-device-type-filter v-model="deviceTypes" />
      </div>
      <div class="flex-1 items-start">
        <lxc-log-device-declination-filter
          v-model="deviceDeclinations"
          :types="deviceTypes"
        />
      </div>
    </div>
    <lxc-log-device-name-filter v-model="deviceName" />
    <lxc-log-device-list-filter
      v-model="selectedDevicesDvtmEsoft"
      :declinations="deviceDeclinations"
      :name="deviceName"
      :types="deviceTypes"
    />
  </div>
</template>
