<script setup lang='ts'>
import type { CampaignI } from '@lxc/app-device-types'
import { Chart, registerables } from 'chart.js'
import type { ChartData, ChartOptions } from 'chart.js'
import { DoughnutChart, useDoughnutChart } from 'vue-chart-3'
import { useGetCampaignStat } from '~/composables/useCampaign'
import { DONNUT_FONT_FAMILY } from '~/constants/constants'
const { t } = useI18n()

const props = defineProps<{
  campaign?: CampaignI | null
}>()

Chart.register(...registerables)
// Ovveride here the tooltip to display % after the value
Chart.overrides.doughnut.plugins.tooltip = {
  enabled: true,
  callbacks: {
    label(context) {
      if (context.parsed && context.dataset?.data !== null) {
        const total = context.dataset.data.reduce((sum, val) => sum + val, 0)
        const percent = Math.round(context.parsed * 100 / total)

        return ` : ${percent}%`
      }

      return ''
    },
    title(context) {
      return context[0] !== null ? `${context[0].label || ''} (${context[0].parsed})` : ''
    },
  },
}

const statCampaign = computed(() => { return useGetCampaignStat(props.campaign) })

const chartData = computed<ChartData<'doughnut'>>(() => ({
  labels: [
    t('campaign.details.characteristics.equipments.status.pending', { percent: statCampaign.value.get('percentPending'), count: statCampaign.value.get('countPending') }),
    t('campaign.details.characteristics.equipments.status.running', { percent: statCampaign.value.get('percentInProgress'), count: statCampaign.value.get('countInProgress') }),
    t('campaign.details.characteristics.equipments.status.complete', { percent: statCampaign.value.get('percentSuccess'), count: statCampaign.value.get('countSuccess') }),
    t('campaign.details.characteristics.equipments.status.failed', { percent: statCampaign.value.get('percentFailed'), count: statCampaign.value.get('countFailed') }),
  ],
  datasets: [
    {
      backgroundColor: [
        '#BFCCDE',
        '#235A96',
        '#039855',
        '#F04438',
      ],
      data: [
        statCampaign.value.get('countPending'),
        statCampaign.value.get('countInProgress'),
        statCampaign.value.get('countSuccess'),
        statCampaign.value.get('countFailed'),
      ],
    },
  ],
}))

const options = computed<ChartOptions<'doughnut'>>(() => ({
  responsive: true,
  rotation: 180,
  plugins: {
    legend: {
      display: true,
      position: 'right',
      align: 'center',
      fullSize: false,
      labels: {
        boxWidth: 60,
        boxHeight: 14,
        font: {
          family: DONNUT_FONT_FAMILY,
          size: 14,
        },
        padding: 10,
        pointStyle: 'circle',
        textAlign: 'left',
        usePointStyle: true,
        pointStyleWidth: 20,
      },
    },
  },
}))

const { doughnutChartProps } = useDoughnutChart({
  chartData,
  options,
})
</script>

<template>
  <doughnut-chart v-bind="doughnutChartProps" />
</template>
