<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import dtwinsService from '~/services/dtwins.service'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationError } from '~/utils/notifications-tools'
import { useUserSession } from '~/stores/useUserSession'
import ILxcDrag from '~icons/lxc/drag'
import type { Serie, Value } from '~/types/chart'
import LxcErrorComponent from '~/components/shared/LxcError.vue'

const props = defineProps<{
  dtwin?: any
}>()

const { t } = useI18n()

const { userSession } = useUserSession()

interface Chart {
  uuid: uuidv4
  series: Array<Serie>
}

const series: Ref<Array<Serie>> = ref([])
const isLoading: Ref<boolean> = ref(false)
const error: Ref<LxcError|undefined> = ref()

/**
 * Fetch the list of series
 */
async function fetchSeries() {
  if (props.dtwin.uid) {
    isLoading.value = true

    const response = await dtwinsService.getDtwinTelemetries(props.dtwin.uid)

    if (LxcError.check(response)) {
      error.value = response
    } else {
      series.value = []

      if (response.information_List) {
        for (const telemetry of response.information_List) {
          if (telemetry.gts) {
            series.value.push({
              name: telemetry.gts,
              label: telemetry.label,
              unit: telemetry.unit,
              unitSymbol: telemetry.unitSymbol,
              precision: telemetry.precision,
              granularity: telemetry.granularity,
              isCalculated: telemetry.warpScriptProvided,
              values: [],
            })
          }
        }
      }

      error.value = undefined
    }

    isLoading.value = false
  }
}

const charts: Ref<Array<Chart>> = ref([])

/**
 * Update charts values
 */
const updateChartsValues = async(filterChart?: Chart, filterSerie?: Serie) => {
  for (const chart of charts.value) {
    if (!filterChart || chart.uuid === filterChart.uuid) {
      for (const serie of chart.series) {
        if (!filterSerie || serie.name === filterSerie.name) {
          setSerieValues(serie)
        }
      }
    }
  }
}

const interval = ref('day')

function updateInterval(value: string) {
  interval.value = value

  updateChartsValues()
}

/**
 * Fetch and set a serie values
 */
async function setSerieValues(serie: Serie) {
  let serieValues: Array<Value> = []

  if (props.dtwin.uid) {
    const endTimestamp = Date.now()

    let backInterval = 24 * 60 * 60 * 1000
    switch (interval.value) {
      case 'week':
        backInterval *= 7
        break
      case 'month':
        backInterval *= 31
        break
      case 'quarter':
        backInterval *= 31 * 4
        break
    }

    const startTimestamp = endTimestamp - backInterval

    const response = await dtwinsService.getDtwinTelemetryValues(
      props.dtwin.uid,
      serie.name,
      startTimestamp * 1000,
      endTimestamp * 1000,
      serie.isCalculated ?? false,
    )

    if (LxcError.check(response)) {
      showNotificationError(t(NotificationKey.error))
    } else {
      if (response.results.length > 0) {
        for (const label of response.results[0].labels) {
          // case when the values are boolean => set stepped serie
          if (label.key === 'format' && label.value === 'BOOLEAN') {
            if (!serie.datasetProperties) {
              serie.datasetProperties = {}
            }
            serie.datasetProperties.stepped = true
            if (!serie.scaleProperties) {
              serie.scaleProperties = {}
            }
          }
        }

        serieValues = response.results[0].values.map((value: any) => {
          return {
            timestamp: value.timestamp / 1000,
            value: value.value,
          }
        })
      }
    }
  }

  serie.values = serieValues
}

/**
 * Add a serie to a chart if it does not already exist and if limit of 3 is not exceeded
 * Update the serie values after being added
 * @param serie
 * @param chart
 */
const addSerieToChart = (serie: Serie, chart: Chart) => {
  if (chart.series.length < 3 && !chart.series.find(s => s.name === serie.name)) {
    chart.series.push(serie)
    updateChartsValues(chart, serie)
  }
}

/**
 * Start dragging a serie
 */
const startDragSerie = ($event: DragEvent, serie: Serie) => {
  $event.dataTransfer?.setData('serieName', serie.name)
}

/**
 * Drop a serie to a chart
 */
const onDropSerie = ($event: DragEvent, chartUuid?: uuidv4) => {
  const serieName = $event.dataTransfer?.getData('serieName')

  const serie = series.value?.find(serie => serie.name === serieName)

  if (serie) {
    let chart

    if (chartUuid) {
      chart = charts.value.find(chart => chart.uuid === chartUuid)

      if (chart) {
        addSerieToChart(serie, chart)
      }
    } else {
      chart = {
        uuid: uuidv4(),
        series: [serie],
      }

      charts.value.push(chart)
      updateChartsValues(chart)
    }
  }
}

onMounted(fetchSeries)
</script>

<template>
  <div
    v-if="isLoading"
    class="flex flex-1 items-center justify-center"
  >
    <lxc-loader size="28" />
  </div>
  <lxc-error-component
    v-else-if="error"
    :error="error"
  />
  <div
    v-else-if="series.length === 0"
    class="text-center"
  >
    {{ $t('dtwins.form.dataviz.noData') }}
  </div>
  <div
    v-else
    class="grid grid-cols-[max-content_auto] h-full"
  >
    <!-- serie list -->
    <div class="bg-gray-100">
      <div class="grid gap-2 p-4 sticky top-0">
        <div
          v-for="(serie, i) of series"
          :key="i"
          class="bg-white border border-gray-300 rounded-lg p-2 cursor-grab"
          draggable="true"
          @dragstart="$event => startDragSerie($event, serie)"
        >
          <div class="flex items-center">
            <div class="pr-2">
              <i-lxc-drag />
            </div>
            <div>
              <div>{{ serie.label }}<span v-if="serie.unit"> ({{ serie.unit }})</span></div>
              <div class="text-gray-700">
                {{ serie.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- right panel -->
    <div class="pl-4">
      <div>
        <!-- header-->
        <div>
          <div class="basis-1/3 inline-flex items-end justify-end">
            <lxc-button
              type="tertiary"
              class="rounded-tr-none rounded-br-none"
              :title="$t('dtwins.dataviz.day')"
              :active="interval === 'day'"
              @click="updateInterval('day')"
            >
              {{ $t('dtwins.form.dataviz.day') }}
            </lxc-button>
            <lxc-button
              type="tertiary"
              class="rounded-none"
              :title="$t('dtwins.dataviz.week')"
              :active="interval === 'week'"
              @click="updateInterval('week')"
            >
              {{ $t('dtwins.form.dataviz.week') }}
            </lxc-button>
            <lxc-button
              type="tertiary"
              class="rounded-none"
              :title="$t('dtwins.dataviz.month')"
              :active="interval === 'month'"
              @click="updateInterval('month')"
            >
              {{ $t('dtwins.form.dataviz.month') }}
            </lxc-button>
            <lxc-button
              type="tertiary"
              class="rounded-tl-none rounded-bl-none"
              :title="$t('dtwins.dataviz.quarter')"
              :active="interval === 'quarter'"
              @click="updateInterval('quarter')"
            >
              {{ $t('dtwins.form.dataviz.quarter') }}
            </lxc-button>
          </div>
        </div>

        <!-- charts, only display charts with at least one serie -->
        <div>
          <div
            v-for="(notEmptyChart, i) in charts.filter(chart => chart.series.length > 0)"
            :key="i"
            class="mt-4"
            @drop="$event => onDropSerie($event, notEmptyChart.uuid)"
            @dragover.prevent
            @dragenter.prevent
          >
            <lxc-chart-local
              v-model:series="notEmptyChart.series"
              :locale="userSession?.language"
            />
          </div>
          <lxc-card
            class="mt-4 text-center justify-center"
            @drop="onDropSerie($event)"
            @dragover.prevent
            @dragenter.prevent
          >
            {{ $t('dtwins.form.dataviz.newChart') }}
          </lxc-card>
        </div>
      </div>
    </div>
  </div>
</template>
