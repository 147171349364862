<script setup lang="ts">

const props = defineProps<{
  connectivityState: string
}>()

const { t } = useI18n()

</script>

<template>
  <span
    :title="t(`device.connectivityState.${props.connectivityState}`)"
    class="block mx-auto w-fit"
  >
    <i-lx-success v-if="props.connectivityState === 'active'" />
    <i-lx-warning v-if="props.connectivityState !== 'active'" />
  </span>
</template>
