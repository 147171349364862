<script lang='ts' setup>
import type { UserProfileI } from '@lxc/app-device-types'
import { useToggle } from '~/composables/useToggle'
import ILxTrashAlt from '~icons/lx/trash-alt'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import LxcError from '~/utils/LxcError'
import userProfilesService from '~/services/userProfiles.service'

const props = defineProps<{ userProfile: UserProfileI }>()
const emit = defineEmits(['change'])
const { t } = useI18n()
const [removeModalVisible, toggleRemoveModalVisible] = useToggle()

enum Action {
  REMOVE = 'REMOVE',
}
const showConfirm = computed(() => props.userProfile.nbUsers === 0)
const modalMessagesKey = computed(() => showConfirm.value ? 'confirmDelete' : 'cannotDelete')
const title = computed(() => t(`profile.${modalMessagesKey.value}.title`))
const description = computed(() => t(`profile.${modalMessagesKey.value}.description`, {
  userProfileName: props.userProfile.label,
  usersCount: props.userProfile.nbUsers,
}))

/**
 * When removal confirmation of the user profile
 * @param profileCode
 */
async function onRemoveUserProfile(profileCode: string) {
  const removeResponse = await userProfilesService.deleteUserProfile(profileCode)

  if (LxcError.check(removeResponse)) {
    removeResponse.notify(NotificationKey.removeError)
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess))
    toggleRemoveModalVisible()
    emit('change')
  }
}

/**
 * When selecting an action in menu
 * @param action
 */
function onSelectAction(action: Action) {
  switch (action) {
    case Action.REMOVE:
      toggleRemoveModalVisible(true)
      break
  }
}
</script>

<template>
  <lxc-dropdown @command="onSelectAction">
    <el-dropdown-item
      :command="Action.REMOVE"
      :icon="ILxTrashAlt"
    >
      {{ $t('profile.button.delete') }}
    </el-dropdown-item>
  </lxc-dropdown>

  <lxc-confirm-modal
    :show-confirm="showConfirm"
    :is-dialog-visible="removeModalVisible"
    :title="title"
    :description="description"
    @update:toggle-dialog="toggleRemoveModalVisible"
    @confirm="onRemoveUserProfile(userProfile?.code)"
  />
</template>
