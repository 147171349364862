<script setup lang="ts">
import type { CertificateFormItemI } from '@lxc/app-device-types'
import { CertificateType } from '@lxc/app-device-types'
import type { FormInstance, UploadFile, UploadUserFile } from 'element-plus'
import { caCertTypeToString } from '~/utils/enum-tools'
import ILxUpload from '~icons/lx/upload'
import { showNotificationError } from '~/utils/notifications-tools'

const { t } = useI18n()

// File must be less than 1 MB
const MAX_FILE_SIZE_BYTES_ALLOWED = 1_000_000
const DEFAULT_ROOT_FILE_NAME = 'certificate.pem'
const FILE_EXTENSION_ALLOWED = 'pem'

const props = defineProps<{
  isDialogVisible: boolean
  alreadyAddedCertificateType?: CertificateType[]
  updateCertificateType?: CertificateFormItemI
}>()

const certFormRef = ref<FormInstance>()
const pemUploadRef = ref()
const fileList = ref<UploadUserFile[]>()
const certificateForm = reactive({
  rootCa: '',
  lastKnowFileName: '',
  certType: props.updateCertificateType?.type,
})
const listOfCertificateType = ref()

// Types are defined as 'any' here because this is how it is defined in the
// element-plus library
const rootCaValidator = (rule: any, value: any, callback: any) => {
  if (!certificateForm?.rootCa || certificateForm?.rootCa === '') {
    callback(new Error(t('truststore.validation.rootCa')))
  }
  else {
    callback()
  }
}

const rules = reactive({
  certType: [
    { required: true, message: t('truststore.validation.certType'), whitespace: true, trigger: 'blur' },
  ],
  pemUpload: [
    { validator: rootCaValidator, trigger: 'blur' },
  ],
})

const emit = defineEmits(['update:toggleDialog', 'validate'])

function clearRootCa() {
  pemUploadRef.value.clearFiles()
  certificateForm.rootCa = ''
  certificateForm.lastKnowFileName = ''
}

function showErrorAndCancelUpdloadIfPossible(message: string, file?: UploadFile) {
  showNotificationError(message)

  if (file) {
    pemUploadRef.value.handleRemove(file)
    clearRootCa()
  }
}

function isPemFile(fileName: string) {
  const fileExt = fileName.split('.').pop()
  return fileExt?.toLocaleLowerCase() === FILE_EXTENSION_ALLOWED
}

function isFileCanBeUploaded(fileName: string, fileSize?: number): Boolean {
  let fileAllowed = isPemFile(fileName)
  if (!fileAllowed) {
    showErrorAndCancelUpdloadIfPossible(t('pki.validation.pemFileMandatory'))
  } else {
    if (!fileSize) {
      showErrorAndCancelUpdloadIfPossible(t('pki.validation.pemFileEmpty'))
      fileAllowed = false
    }
    else if (fileSize > MAX_FILE_SIZE_BYTES_ALLOWED) {
      showErrorAndCancelUpdloadIfPossible(t('pki.validation.pemFileSize'))
      fileAllowed = false
    }
  }
  return fileAllowed
}

function handleExceed(files: File[]) {
  const fileToUpload = files?.[0]
  if (fileToUpload && isFileCanBeUploaded(fileToUpload.name, fileToUpload.size)) {
    pemUploadRef.value.clearFiles()
    pemUploadRef.value.handleStart(fileToUpload)
  }
}

function onFileChange(file: UploadFile) {
  if (isFileCanBeUploaded(file.name, file.size)) {
    const reader = new FileReader()
    if (!file.raw) {
      showErrorAndCancelUpdloadIfPossible(t('pki.validation.pemFileEmpty'), file)
    }
    else {
      certFormRef.value?.clearValidate()
      reader.readAsText(file.raw)
      certificateForm.lastKnowFileName = file.name
      reader.onload = function(e) {
        try {
          certificateForm.rootCa = window.btoa(this.result?.toString().trim() ?? '')
        } catch (err) {
          showErrorAndCancelUpdloadIfPossible(t('pki.validation.pemFileInvalid'), file)
        }
      }
    }
  } else {
    pemUploadRef.value.handleRemove(file)
  }
}

function handlePreview(file: UploadFile) {
  const myWindow = window.open()
  myWindow?.document.write(window.atob(certificateForm.rootCa.trim()))
  myWindow?.document.close()
}

async function onConfirmUpdate(formEl: FormInstance | undefined) {
  if (!formEl) { return }
  formEl.clearValidate()
  await formEl.validate((valid, fields) => {
    if (valid) {
      const certToUpdate: CertificateFormItemI = {
        caCertChain: certificateForm.rootCa,
        type: certificateForm.certType!,
        certificateId: props.updateCertificateType?.certificateId,
        lastKnownFileName: certificateForm.lastKnowFileName,
      }
      emit('validate', certToUpdate)
    }
  })
}

function onToggleChange(toggle: boolean) {
  if (!toggle) {
    clearRootCa()
  }
  emit('update:toggleDialog', toggle)
}

function onDialogOpened() {
  certFormRef.value?.clearValidate()
  listOfCertificateType.value = Object.values(CertificateType).flatMap(certType => ({
    value: certType,
    label: caCertTypeToString(certType, t),
    disable: props.alreadyAddedCertificateType?.includes(certType) ?? false,
  }))

  certificateForm.certType = props.updateCertificateType?.type

  certificateForm.rootCa = props.updateCertificateType?.caCertChain ?? ''
  if (props.updateCertificateType && props.updateCertificateType.caCertChain) {
    fileList.value = [{
      name: props.updateCertificateType.lastKnownFileName ?? DEFAULT_ROOT_FILE_NAME,
    }]
  }

  const querySelector = document.querySelector('.el-upload-dragger')
  if (querySelector) {
    querySelector.ondrop = function(e) {
      const fileName = e.dataTransfer?.files?.[0]?.name
      if (!fileName) {
        showErrorAndCancelUpdloadIfPossible(t('pki.validation.pemFileEmpty'))
      }
      if (!isPemFile(fileName)) {
        showErrorAndCancelUpdloadIfPossible(t('pki.validation.pemFileMandatory'))
      }
    }
  }
}

</script>
<template>
  <lxc-modal
    :dialog-visible="isDialogVisible"
    :title="props.updateCertificateType && props.updateCertificateType?.caCertChain ? $t('truststore.updateCertificate') :$t('truststore.addSingleCertificate')"
    @confirm="onConfirmUpdate(certFormRef)"
    @cancel="onToggleChange(false)"
    @opened="onDialogOpened"
    @update:dialog-visible="onToggleChange"
  >
    <div
      class="form-upload"
    >
      <el-form
        ref="certFormRef"
        label-position="top"
        label-width="auto"
        :model="certificateForm"
        :rules="rules"
      >
        <el-form-item
          :label="$t('truststore.certificate.typeTitle')"
          prop="certType"
        >
          <el-select
            v-model="certificateForm.certType"
            :placeholder="$t('truststore.certificate.typeTitle')"
            :disabled="props.updateCertificateType !== undefined"
            size="large"
            name="certificateSelectedType"
          >
            <el-option
              v-for="item in listOfCertificateType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disable"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label-width="0px"
          prop="pemUpload"
        >
          <el-upload
            ref="pemUploadRef"
            class="form-upload"
            accept=".pem"
            action=""
            drag
            :limit="1"
            :multiple="false"
            :auto-upload="false"
            :on-exceed="handleExceed"
            :on-change="onFileChange"
            :on-preview="handlePreview"
            :on-remove="clearRootCa"
            :file-list="fileList"
            show-file-list
          >
            <el-icon
              class="el-icon--upload"
              :size="110"
            >
              <i-lx-upload />
            </el-icon>
            <div class="el-upload__text">
              {{ $t('truststore.dropCertificate') }}
            </div>
            <template #tip>
              <div class="el-upload__tip">
                <lxc-information-row
                  :title="$t('truststore.caRestrictionInformation')"
                />
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
  </lxc-modal>
</template>
<style lang='scss' scoped>

.update-description {
  font-size: 16px;
}

.nota-bene-container {
  margin: 10px;
}

.el-icon--upload{
  color: $primary-color;
}

.el-upload__text{
  padding: 0 12px 12px 12px;
  white-space:pre-wrap;
  word-break:break-word;
}

.form-upload {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}
</style>
