import type { AsyncDeviceTwinsFirmwareCountByVersion, AsyncDeviceTwinsFirmwareStatisticsMap, DashboardCampaign, DashboardCampaignDetail } from '@lxc/app-device-types'
import { DashboardCardStatus } from '~/types'

export function verifyCardStatus(status: DashboardCardStatus, object?: any): DashboardCardStatus {
  if (status === DashboardCardStatus.UNAUTHORIZED) {
    return DashboardCardStatus.UNAUTHORIZED
  }
  return (status === DashboardCardStatus.LOADED && !object) ? DashboardCardStatus.FAILED : status
}

export function isCampaignPieDisplayable(campaignDetail?: DashboardCampaignDetail | null): boolean {
  if (campaignDetail) {
    return (campaignDetail.valid > 0 || campaignDetail.expired > 0 || campaignDetail.expiredSoon > 0)
  }
  return false
}

export function isCampaignTileDisplayable(campaign?: DashboardCampaign): boolean {
  return isCampaignPieDisplayable(campaign?.s4Device) || isCampaignPieDisplayable(campaign?.s4Manager) || isCampaignPieDisplayable(campaign?.s4Tools) || isCampaignPieDisplayable(campaign?.s4View)
}

export function isFirmwareDisplayable(firmware: AsyncDeviceTwinsFirmwareCountByVersion): boolean {
  return Object.keys(firmware).length !== 0
}

export function isFirmwareTileDisplayable(firmwares?: AsyncDeviceTwinsFirmwareStatisticsMap): boolean {
  const firmwaresKeys = firmwares != null ? Object.keys(firmwares) : undefined
  return firmwaresKeys !== undefined
  && firmwaresKeys.length !== 0
  && !firmwaresKeys.every(firmwareName => !isFirmwareDisplayable((firmwares as AsyncDeviceTwinsFirmwareStatisticsMap)[firmwareName]))
}

export function simplifyVisibleVersions(pVersions: AsyncDeviceTwinsFirmwareCountByVersion, localOthersLabel: string): AsyncDeviceTwinsFirmwareCountByVersion {
  let vVisibleVersion: AsyncDeviceTwinsFirmwareCountByVersion
  const maxVisible = 3
  let versionNames = Object.keys(pVersions)

  if (versionNames.length <= maxVisible && !pVersions.Others) {
    vVisibleVersion = pVersions
  } else {
    vVisibleVersion = {}
    // If there are more than 4 different versions, the 4th and more are grouped into the "others" version.
    versionNames = versionNames.sort((v1, v2) => {
      let result: number
      if (v1 === 'Others') {
        result = 1
      } else if (v2 === 'Others') {
        result = -1
      } else {
        result = pVersions[v2] - pVersions[v1]

        if (result === 0) {
          result = v1.localeCompare(v2)
        }
      }
      return result
    })

    const otherIndex = versionNames.indexOf('Others')
    const maxIndex = otherIndex >= 0 && otherIndex < maxVisible ? otherIndex : maxVisible

    for (let index = 0; index < maxIndex; index++) {
      const versionName = versionNames[index]
      vVisibleVersion[versionName] = pVersions[versionName]
    }

    const otherCountList = versionNames.slice(maxIndex).map((versionName: string) => pVersions[versionName])
    const otherCount = otherCountList.reduce((totalCount, currentCount) => totalCount + currentCount, 0)
    vVisibleVersion[localOthersLabel] = otherCount
  }

  return vVisibleVersion
}
