import type { PeriodI } from '@lxc/app-device-common'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function getDateInTheFutureInMonth(numbersOfMonth: number, formatted = false) {
  const date = dayjs().add(numbersOfMonth, 'month').utc()
  return formatted ? date.format('YYYY-MM-DDTHH:mm:ss[Z]') : date.format()
}

export function getDateToLocaleString(dateUtc: string): string {
  return dayjs(dateUtc).format('L LT')
}

export function formatIsoDate(isoDate: string | number | Date | Dayjs | null | undefined, format: string): string {
  return dayjs(isoDate).format(format)
}

export function formatIsoDateToUtc(isoDate: string | number | Date | Dayjs | null | undefined): string {
  return dayjs(isoDate).utc(false).format('YYYY-MM-DDTHH:mm:ss[Z]')
}

export function timeLimitInMonthOrDays(endDate?: string, days?: boolean): number {
  const today = dayjs()
  return days ? dayjs(endDate).diff(today, 'days') : dayjs(endDate).diff(today, 'month', true)
}

export function getTodayPeriod(): PeriodI {
  const startDate = new Date()
  startDate.setHours(0, 0, 0, 0)
  const endDate = new Date()
  endDate.setTime(startDate.getTime() + 86399999) // 23:59:59,999ms

  // Case the there is the change to the daylight saving time today
  if (startDate.getTimezoneOffset() !== endDate.getTimezoneOffset()) {
    endDate.setTime(endDate.getTime() + (endDate.getTimezoneOffset() - startDate.getTimezoneOffset()) * 60000)
  }

  return {
    startDate,
    endDate,
  }
}

export function getDateYearMonthDayInDateFormat(dateYYYYMMDD: string, dateFormat: string) {
  const day = dayjs(dateYYYYMMDD, 'YYYYMMDD')

  if (day.isValid()) {
    return dayjs(dateYYYYMMDD, 'YYYYMMDD').format(dateFormat)
  }
  else {
    return dateYYYYMMDD
  }
}
