import type { DeviceI, DeviceStatsActionCountsI, DeviceStatsActionsI, DeviceStatsI, SectorI } from '@lxc/app-device-types'
import { ActionType, CampaignType, OperationType } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { useAcl } from 'vue-simple-acl'
import DeviceService from '~/services/device.service'
import type { SearchMode } from '~/composables/useSearch'
import { useSearch } from '~/composables/useSearch'
import { ACL_ROLES, Filters, FiltersType } from '~/types'
import { useFetchPage } from '~/composables/useFetchPage'
import { useSort } from '~/composables/useSort'
import { useUserSession } from '~/stores/useUserSession'
import { useConfigStore } from '~/stores/useConfigStore'

export function useDevices(searchMode?: SearchMode) {
  const service = DeviceService.getDevices.bind(DeviceService)
  const itemType = 'device'

  const { t } = useI18n()
  const { can } = useAcl()
  const { userSession } = useUserSession()
  const { defaultSectorCode } = useConfigStore()

  const { searchParams, filterCounts, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.TWO_AMPERSAND_SEPARATOR,
    [
      Filters.NAME,
      Filters.STATE,
      Filters.MODEL_TYPE,
      Filters.MODEL_DECLINATION,
      Filters.CONNECTIVITY,
      Filters.DEVICE_FIRMWARE_VERSIONS,
      Filters.DEVICE_HARDWARE_VERSIONS,
      Filters.CERTIFICATE_EXPIRE_AFTER,
      Filters.CERTIFICATE_EXPIRE_BEFORE,
      Filters.SECTORS,
    ],
    searchMode,
  )
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  function getDescription(item: DeviceI) {
    return item?.name ?? ''
  }

  function canManageItems(sector: SectorI) {
    return !sector.isRootSector && can(ACL_ROLES.UPDATE_DEVICES) && userSession?.sectors.map(sector => sector.code).includes(`${defaultSectorCode}`)
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map(item => item.id)
  }

  function hasActionScheduled(device: DeviceI, campaignType: CampaignType): boolean | undefined {
    if (device.stats?.campaigns?.scheduled) {
      return true
    }

    if (device.stats?.operations) {
      for (const operationStat of device.stats.operations) {
        const operationTypeMatchCampaignType = (campaignType === CampaignType.CRTCLT_RENEWAL_DVC && operationStat.operationType === OperationType.CRTCLT_RENEWAL_DVC)
        || (campaignType === CampaignType.CRTCLT_RENEWAL_APP && operationStat.operationType === OperationType.CRTCLT_RENEWAL_APP)
        || (campaignType === CampaignType.FIRMWARE_UPGRADE_DVC && operationStat.operationType === OperationType.FIRMWARE_UPGRADE_DVC)

        const isOperationScheduled = (operationStat.pending && operationStat.pending > 0)
          || (operationStat.running && operationStat.running > 0)
          || (operationStat.retry && operationStat.retry > 0)

        if (operationTypeMatchCampaignType && isOperationScheduled) {
          return true
        }
      }
    }

    return false
  }

  function getHasActionStatusLabel(device: DeviceI, campaignType: CampaignType) {
    const _hasActionScheduled: boolean | undefined = hasActionScheduled(device, campaignType)
    if (_hasActionScheduled === undefined) {
      return t('application.actions.scheduled.error')
    } else if (_hasActionScheduled) {
      return t('application.actions.scheduled.yes')
    } else {
      return t('application.actions.scheduled.no')
    }
  }

  return {
    itemType,
    isLoading,
    results,
    error,
    filterCounts,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    getDescription,
    canManageItems,
    getItemIds,
    hasActionScheduled,
    getHasActionStatusLabel,
    onSearch,
    search,
  }
}

export function hasActionScheduled(deviceStats: DeviceStatsI, campaignType: CampaignType): boolean | undefined {
  if (deviceStats?.campaigns?.scheduled) {
    return true
  }

  const _hasActionScheduled = (actionType: ActionType): boolean | undefined => {
    const actions: DeviceStatsActionsI | Error | undefined = deviceStats?.actions
    if (!actions) {
      return false
    } else if ((actions as any).status) { // Check if there is an error
      return undefined
    } else {
      const deviceStatsActionCounts: DeviceStatsActionCountsI = (actions as DeviceStatsActionsI)[actionType] ?? {}
      const actionsCount = (deviceStatsActionCounts.pending ?? 0)
            + (deviceStatsActionCounts.delivered ?? 0)
            + (deviceStatsActionCounts.running ?? 0)
            + (deviceStatsActionCounts.retry ?? 0)
      return actionsCount > 0
    }
  }

  switch (campaignType) {
    case CampaignType.CRTCLT_RENEWAL_DVC:
    case CampaignType.CRTCLT_RENEWAL_APP: {
      return _hasActionScheduled(ActionType.RENEW_CERTIFICATE)
    }
    case CampaignType.FIRMWARE_UPGRADE_DVC: {
      return _hasActionScheduled(ActionType.FIRMWARE_UPGRADE)
    }
    default:
      return false
  }
}
