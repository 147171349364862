import type { ApplicationI, DeviceI, UserDataI } from '@lxc/app-device-types'

function getTagText(label: string, value?: string | null): string | undefined {
  if (value) {
    return `${label.toLowerCase()} ${value}`
  }
}

function getDeviceDvtmEsoftTagText(device: DeviceI, deviceLabel: string) {
  return getTagText(deviceLabel, device.name ?? device.id)
}

function getUserTagText(user: UserDataI, userLabel: string) {
  return getTagText(userLabel, user.lastName ? `${user.firstName} ${user.lastName}` : user.id)
}

function getApplicationTagText(application: ApplicationI, applicationLabel: string) {
  return getTagText(applicationLabel, application.name ?? application.id)
}

export default {
  getApplicationTagText,
  getDeviceDvtmEsoftTagText,
  getUserTagText,
  getTagText,
}
