<script setup lang="ts">
import type { DashboardCardStatus } from '~/types/dashboard'
import { verifyCardStatus } from '~/utils/dashboard.utils'

const { t } = useI18n()

const props = defineProps<{
  status: DashboardCardStatus
  percentage?: number
}>()

</script>

<template>
  <lxc-dashboard-card
    :display-header-margin="false"
    :should-expand-body="true"
    :status="props.status"
  >
    <div class="gauge-container">
      <lxc-cert-gauge :percentage="percentage ?? 0" />
      <dl class="percentage-container">
        <dt class="percentage-value">
          {{ percentage ? `${percentage}%` : '--' }}
        </dt>
        <dd class="percentage-label">
          {{ $t('dashboard.certificates.valid') }}
        </dd>
      </dl>
    </div>
  </lxc-dashboard-card>
</template>

<style lang='scss' scoped>
  .gauge-container {
    width: 100%;
    position: relative;
  }

  .percentage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 65%;
    right: 20%;
    left: 25%;
  }

  .percentage-value {
    color: $primary-color;
    font-size: 21px;
    font-weight: bold;
  }

  .percentage-label {
    color: $primary-color;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
</style>
