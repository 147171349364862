import type { ComponentI } from '@lxc/app-device-types'
import RestService from './rest.service'

class PlatformService extends RestService {
  private BASE_URL = '/platform'

  getComponentsVersion(): Promise<ComponentI[]> {
    return this.$api.get(`${this.BASE_URL}/system/versions`)
  }
}

export default new PlatformService()
