<script setup lang="ts">
import dayjs from 'dayjs'
import { type CampaignI, LicenseStatus } from '@lxc/app-device-types'
import { useAcl } from 'vue-simple-acl'
import type { DebuggerEventExtraInfo, Ref } from 'vue'
import { useCampaign, useGetCampaignStat } from '~/composables/useCampaign'
import { PATHS } from '~/constants/paths'
import { ACL_ROLES, Filters } from '~/types'
import { useLicenseStatusStore } from '~/stores/useLicenseStatusStore'

const { t } = useI18n()
const router = useRouter()
const acl = useAcl()
const licenseStatusStore = useLicenseStatusStore()

// redirect to index page when license status is not activated or if feature advanced fleet management is not enabled
licenseStatusStore.$subscribe((mutation, state) => {
  // the mutation concerns the licenseStatus state and the license status is not activated
  const isLicenseStatusNotActivated = (mutation.events as DebuggerEventExtraInfo).key === licenseStatusStore.licenseStateKeys.LICENSE_STATUS && (!state.licenseStatus || state.licenseStatus.statusOfLicense === LicenseStatus.NOT_ACTIVATED)

  // the mutation concerns the license feature advanced fleet management state flag and its value is false
  const isLicenseFeatureAdvancedFleetManagementNotPresent = (mutation.events as DebuggerEventExtraInfo).key === licenseStatusStore.licenseStateKeys.IS_LICENSE_FEATURE_ADVANCED_FLEET_MANAGEMENT_PRESENT && !state.isLicenseFeatureAdvancedFleetManagementPresent

  if (isLicenseStatusNotActivated || isLicenseFeatureAdvancedFleetManagementNotPresent) {
    router.replace('/')
  }
})

const {
  isLoading,
  results: campaigns,
  filters,
  error,
  fetchData,
  setFilter,
} = useCampaign()

const formatDate: any = (date: string) => date ? dayjs(date).format(t('campaign.list.column.dates.format')) : ''

const canManageCampaign = computed(() => acl.can(ACL_ROLES.MANAGE_CAMPAIGNS))

async function onSearchClear() {
  setFilter(Filters.NAME, '')
  await fetchData()
}

// License Check
const isBeyondLicenseDateValidity: Ref<boolean|undefined> = ref(true)
const isBeyondLicenseLimitOfAllDeviceTypes: Ref<boolean|undefined> = ref(true)
const checkLicenseValidityDate = (arg: boolean|undefined) => isBeyondLicenseDateValidity.value = arg
const checkLimitOfAllDeviceTypes = (arg: boolean|undefined) => isBeyondLicenseLimitOfAllDeviceTypes.value = arg

const isLicenseFeatureAdvancedFleetManagementActive = computed(() => {
  const { isLicenseFeatureAdvancedFleetManagementActive } = useLicenseStatusStore()
  return isLicenseFeatureAdvancedFleetManagementActive
})

onMounted(fetchData)

const onSelectCampaign = (row: CampaignI) => {
  router.push(`${PATHS.CAMPAIGNS}/${row.id}/${PATHS.DETAILS_SUBPATH}`)
}
</script>

<template>
  <el-container direction="vertical">
    <lxc-license-warning-messages
      @is-beyond-expiration-date="checkLicenseValidityDate($event)"
      @is-beyond-limit-of-all-device-type="checkLimitOfAllDeviceTypes($event)"
    />

    <lxc-breadcrumb />

    <lx-toolbar>
      <lx-search-bar
        :is-loading="isLoading"
        :search-query="filters.get(Filters.NAME)"
        :search-placeholder="$t('filters.searchByName')"
        @clear="onSearchClear"
        @input-change="setFilter(Filters.NAME, $event)"
        @search="fetchData"
      >
        <!-- TODO Uncomment after search implementation done into BS campaign manager
        <lxc-campaign-filters
          :state-value="filters.state"
          :filters-count="filterCounts"
          @change="setFilter"
        />
         -->
      </lx-search-bar>

      <lxc-campaign-funnel
        :can-manage-campaign="canManageCampaign"
        :disabled="!isLicenseFeatureAdvancedFleetManagementActive || isBeyondLicenseDateValidity || isBeyondLicenseLimitOfAllDeviceTypes"
      />
    </lx-toolbar>

    <lxc-container
      :error="error"
    >
      <lxc-table
        :is-loading="isLoading"
        :data="campaigns?.data"
        :context="campaigns?.context"
        :empty-text="$t('campaign.list.empty')"
        clickable
        @row-click="onSelectCampaign"
        @change-page-and-page-size="fetchData"
      >
        <lxc-table-column
          prop="name"
          :label="$t('campaign.list.column.name.label')"
          width="200"
        >
          <template #default="scope">
            {{ scope.row.config.name }}
          </template>
        </lxc-table-column>
        <lxc-table-column
          prop="type"
          :label="$t('campaign.list.column.type.label')"
        >
          <template #default="scope">
            {{ $t(`campaign.list.column.type.${scope.row.type}`) }}
          </template>
        </lxc-table-column>

        <lxc-table-column
          prop="dates"
          :label="$t('campaign.list.column.dates.label')"
          width="200"
        >
          <template #default="scope">
            {{ formatDate(scope.row.config.plannedStartAt) }} → {{ formatDate(scope.row.config.plannedEndAt) }}
          </template>
        </lxc-table-column>

        <lxc-table-column
          :label="$t('campaign.list.column.numberOfEquipments.label')"
          width="150"
        >
          <template #default="scope">
            {{ $t('campaign.list.column.numberOfEquipments.value', scope.row.objects.length) }}
          </template>
        </lxc-table-column>

        <lxc-table-column
          prop="status"
          :label="$t('campaign.list.column.status.label')"
          width="130"
        >
          <template #default="scope">
            <lxc-campaign-state
              :state="scope.row.state"
            />
          </template>
        </lxc-table-column>

        <lxc-table-column
          :label="$t('campaign.list.column.pending.label')"
          class="column-pending"
          width="100"
        >
          <template #default="scope">
            {{ useGetCampaignStat(scope.row).get('percentPending') }}
          </template>
        </lxc-table-column>

        <lxc-table-column
          :label="$t('campaign.list.column.inProgress.label')"
          class="column-in-progress"
          width="100"
        >
          <template #default="scope">
            {{ useGetCampaignStat(scope.row).get('percentInProgress') }}
          </template>
        </lxc-table-column>

        <lxc-table-column
          :label="$t('campaign.list.column.success.label')"
          class="column-success"
          width="100"
        >
          <template #default="scope">
            {{ useGetCampaignStat(scope.row).get('percentSuccess') }}
          </template>
        </lxc-table-column>

        <lxc-table-column
          :label="$t('campaign.list.column.failed.label')"
          class="column-failed"
          width="100"
        >
          <template #default="scope">
            {{ useGetCampaignStat(scope.row).get('percentFailed') }}
          </template>
        </lxc-table-column>
      </lxc-table>
    </lxc-container>
  </el-container>
</template>

<style lang="scss" scoped>
// The colors must be validated
:deep(td.column-pending) {
  color: grey;
}

:deep(td.column-in-progress) {
  color: #B36F37;
}

:deep(td.column-success) {
  color: #5F7349;
}

:deep(td.column-failed) {
  color: #C4314B;
}
</style>
