<script setup lang="ts">
import { useDtwin } from '~/composables/useDtwins'
import type { DtwinModel } from '~/types/dtwinModel'
import { formatIsoDate } from '~/utils/date-tools'

const props = defineProps<{
  dtwin?: any
  models: DtwinModel[]
}>()
const { t } = useI18n()

const {
  getDtwinTypeLabel,
} = useDtwin()

const dateFormat: string = t('dateFormat.datetime')

const dtwinForm = computed({
  get: () => {
    return {
      attributes: {
        // default values
        friendlyName: '-',
        serialNumber: '-',
        hardwareVersion: '-',
        mobileNetwork: '-',
        gsmFrequencyBand: '-',
        communicationProtocol: '-',

        // dtwin values
        ...props.dtwin.attributes,

        // dtwin type
        type: getDtwinTypeLabel(props.dtwin, props.models),
      },

      state: {
        deviceState: props.dtwin.attributes.deviceState ? t(`dtwins.stateValue.${props.dtwin.attributes.deviceState}`) : '-',
        firstConnection: props.dtwin.firstConnectionDate ? formatIsoDate(props.dtwin.firstConnectionDate, dateFormat) : '-',
        lastConnection: props.dtwin.lastConnectionDate ? formatIsoDate(props.dtwin.lastConnectionDate, dateFormat) : '-',
        batteryStatus: props.dtwin.features.battery?.reported?.level ?? '-',
        lastChange: '-',
        configurationApplicationDate: props.dtwin.features.configuration?.reported?.modificationTimestamp
          ? formatIsoDate(props.dtwin.features.configuration.reported.modificationTimestamp, dateFormat)
          : '-',
        lastRebootDate: '-',
        firstCommissioningDate: '-',
        notValidAfter: '-',
        firmwareVersion: props.dtwin.features.firmware?.reported?.firmwareVersion ?? '-',
        latestFirmwareUpdate: props.dtwin.features.firmware?.modifiedAt
          ? formatIsoDate(props.dtwin.features.firmware.modifiedAt, dateFormat)
          : '-',
      },

      connectivity: {
        antenna: '-',
        mobileNetwork: '-',
        gsmFrequencyBand: '-',
        communicationProtocol: '-',
        accessPointName: '-',
        userName: '-',
      },
    }
  },
  set: () => {},
})

const batteryStatusColorSuffix = computed(() => {
  if (dtwinForm.value.state.batteryStatus && dtwinForm.value.state.batteryStatus !== '-') {
    if (dtwinForm.value.state.batteryStatus <= 5) {
      return 'error-700'
    } else if (dtwinForm.value.state.batteryStatus <= 25) {
      return 'warning-600'
    } else {
      return 'primary-600'
    }
  }
})
</script>

<template>
  <!-- idCard -->
  <div
    class="flex py-5"
  >
    <h3
      class="w-1/3 !mt-0"
    >
      {{ $t('dtwins.form.description.idCard.title') }}
    </h3>
    <div class="w-1/3">
      <lxc-form-item
        :label="$t('dtwins.form.description.idCard.attributes.name')"
      >
        <lxc-input
          v-model="dtwinForm.attributes.friendlyName"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item
        :label="$t('dtwins.form.description.idCard.attributes.type')"
      >
        <lxc-input
          v-model="dtwinForm.attributes.type"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item
        :label="$t('dtwins.form.description.idCard.attributes.serialNumber')"
      >
        <lxc-input
          v-model="dtwinForm.attributes.serialNumber"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item
        :label="$t('dtwins.form.description.idCard.attributes.hardwareVersion')"
      >
        <lxc-input
          v-model="dtwinForm.attributes.hardwareVersion"
          type="text"
          read-only
        />
      </lxc-form-item>
    </div>
  </div>
  <!-- end idCard -->
  <!-- TODO replace with a custom component in lxc-app-common -->
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <!-- State -->
  <div
    class="flex py-5"
  >
    <h3
      class="w-1/3 !mt-0"
    >
      {{ $t('dtwins.form.description.state.title') }}
    </h3>
    <div class="w-2/3">
      <div class="flex">
        <div
          class="w-1/2"
        >
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.state')"
          >
            <lxc-input
              v-model="dtwinForm.state.deviceState"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.firstConnection')"
          >
            <lxc-input
              v-model="dtwinForm.state.firstConnection"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.lastConnection')"
          >
            <lxc-input
              v-model="dtwinForm.state.lastConnection"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            v-if="dtwinForm.state.batteryStatus !== '-'"
            read-only
            display-label
            :label="$t('dtwins.form.description.state.attributes.batteryStatus')"
          >
            <div class="flex">
              <div class="flex w-1/2 items-center">
                <div class="rounded bg-gray-200 w-full h-2">
                  <div
                    :class="`rounded h-2 bg-${batteryStatusColorSuffix}`"
                    :style="{ width: `${dtwinForm.state.batteryStatus}%` }"
                  />
                </div>
              </div>
              <div :class="`items-center ml-3 text-${batteryStatusColorSuffix}`">
                {{ `${dtwinForm.state.batteryStatus}%` }}
              </div>
            </div>
          </lxc-form-item>
          <lxc-form-item
            v-else
            :label="$t('dtwins.form.description.state.attributes.batteryStatus')"
          >
            <lxc-input
              v-model="dtwinForm.state.batteryStatus"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.lastChange')"
          >
            <lxc-input
              v-model="dtwinForm.state.lastChange"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.configurationApplicationDate')"
          >
            <lxc-input
              v-model="dtwinForm.state.configurationApplicationDate"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
        <div
          class="w-1/2"
        >
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.lastRebootDate')"
          >
            <lxc-input
              v-model="dtwinForm.state.lastRebootDate"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.firstCommissioningDate')"
          >
            <lxc-input
              v-model="dtwinForm.state.firstCommissioningDate"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.certificateValidityDate')"
          >
            <lxc-input
              v-model="dtwinForm.state.notValidAfter"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.firmwareVersion')"
          >
            <lxc-input
              v-model="dtwinForm.state.firmwareVersion"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.state.attributes.latestFirmwareUpdate')"
          >
            <lxc-input
              v-model="dtwinForm.state.latestFirmwareUpdate"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
      </div>
    </div>
  </div>
  <!-- end State -->
  <!-- TODO replace with a custom component in lxc-app-common -->
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <!-- Connectivity -->
  <div
    class="flex py-5"
  >
    <h3
      class="w-1/3 !mt-0"
    >
      {{ $t('dtwins.form.description.connectivity.title') }}
    </h3>
    <div class="w-2/3">
      <div class="flex">
        <div
          class="w-1/2"
        >
          <h4>{{ $t('dtwins.form.description.connectivity.communication') }}</h4>
          <lxc-form-item
            :label="$t('dtwins.form.description.connectivity.attributes.antenna')"
          >
            <lxc-input
              v-model="dtwinForm.connectivity.antenna"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.connectivity.attributes.mobileNetwork')"
          >
            <lxc-input
              v-model="dtwinForm.connectivity.mobileNetwork"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.connectivity.attributes.gsmFrequencyBand')"
          >
            <lxc-input
              v-model="dtwinForm.connectivity.gsmFrequencyBand"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.connectivity.attributes.communicationProtocol')"
          >
            <lxc-input
              v-model="dtwinForm.connectivity.communicationProtocol"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
        <div
          class="w-1/2"
        >
          <h4>{{ $t('dtwins.form.description.connectivity.simCard') }}</h4>
          <lxc-form-item
            :label="$t('dtwins.form.description.connectivity.attributes.accessPointName')"
          >
            <lxc-input
              v-model="dtwinForm.connectivity.accessPointName"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="$t('dtwins.form.description.connectivity.attributes.userName')"
          >
            <lxc-input
              v-model="dtwinForm.connectivity.userName"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
      </div>
    </div>
  </div>
  <!-- end Connectivity -->
</template>
