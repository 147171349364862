import type { Certificate, CertificateFormItemI, ListOfDataI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { NotificationKey } from '~/utils/notifications-tools'
import TruststoreService from '~/services/truststore.service'
import LxcError from '~/utils/LxcError'

export function useTruststore() {
  const isLoading = ref(false)
  const certificates = ref<ListOfDataI<Certificate>>()
  const error: Ref<LxcError | null> = ref(null)

  async function fetchTruststoreConfiguration() {
    await fetchTruststore(true)
  }

  async function fetchTruststore(withLoader: boolean) {
    if (withLoader) {
      isLoading.value = withLoader
    }

    const response = await TruststoreService.getTruststoreConfigration()

    if (LxcError.check(response)) {
      error.value = response
    } else {
      certificates.value = response
    }

    if (withLoader) {
      isLoading.value = false
    }
  }

  async function addOrUpdateCertificateAndRefreshTruststoreConfiguration(certificateFormItem: CertificateFormItemI) {
    isLoading.value = true
    const response = await TruststoreService.addOrUpdateCertificate(certificateFormItem)

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError)
    } else if (response) {
      await fetchTruststore(false)
    }

    isLoading.value = false
  }

  return {
    isLoading,
    error,
    certificates,
    fetchTruststoreConfiguration,
    addOrUpdateCertificateAndRefreshTruststoreConfiguration,
  }
}
