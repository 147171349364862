<script setup lang="ts">
import { appModelTypeOptions } from '~/components/applications/applicationList/applicationsFilters.config'

const emit = defineEmits(['select', 'update:modelValue'])
const loading: Ref<boolean> = ref(false)
const props = defineProps<{
  modelValue: string[]
}>()

const selectedItems = computed({
  get() {
    return props.modelValue ?? []
  },
  set(selected?: Array<string>) {
    emit('update:modelValue', selected)
    emit('select', selected)
  },
})

</script>

<template>
  <lxc-filter-checklist
    v-model="selectedItems"
    :data="appModelTypeOptions.options"
    :is-loading="loading"
    :empty-text="$t('logs.filters.applications.type.empty')"
    :header="$t('application.model.type')"
    prop="label"
    checkbox-prop="value"
  />
</template>
