<script setup lang="ts">
import type { Ref } from 'vue'
import ILxcUploadCloud from '~icons/lxc/upload-cloud'
import ILxcFile from '~icons/lxc/file'
import DtwinsService from '~/services/dtwins.service'
import { NotificationKey } from '~/utils/notifications-tools'
import LxcError from '~/utils/LxcError'

const show: Ref<boolean> = ref(false)

const isLoading: Ref<boolean> = ref(false)

const reload: Ref<boolean> = ref(false)

const results: Ref<any | undefined> = ref()

const files: Ref<FileList | undefined> = ref()

const fileSelected = computed(() => files?.value?.length)

const importDtwins = async() => {
  isLoading.value = true

  if (files?.value?.length && files?.value?.length > 0) {
    const response = await DtwinsService.import(files.value[0])

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError)
    } else {
      results.value = response

      // only reload the list if at least one result succeed
      if (results.value.find((result: any) => !result.failed)) {
        reload.value = true
      }
    }
  }

  isLoading.value = false
}
</script>

<template>
  <el-container direction="vertical">
    <div class="flex justify-between">
      <lxc-breadcrumb />
      <lxc-button
        class="self-center me-14"
        :icon="ILxcUploadCloud"
        :title="$t('dtwins.import.title')"
        @click="show = true"
      >
        {{ $t('dtwins.import.text') }}
      </lxc-button>
    </div>
    <lxc-dtwins-list v-model:reload="reload" />
    <lxc-side-canvas
      v-model:show="show"
    >
      <template #header>
        {{ $t('dtwins.import.header.text') }}
      </template>

      <div
        v-if="isLoading"
        class="flex flex-1 items-center justify-center"
      >
        <lxc-loader />
      </div>
      <div v-else>
        <lxc-input
          v-model="files"
          type="file"
          accept=".zip"
        >
          <template #placeholder>
            <div class="mx-8">
              <span class="text-primary-700 font-bold">{{ $t('input.clickToUpload') }}</span>&nbsp;<span>{{ $t('input.orDragAndDrop', { format: '.ZIP' }) }}</span>
            </div>
          </template>
        </lxc-input>

        <div
          v-if="results"
          class="mt-6"
        >
          <div class="flex">
            <div class="mr-4">
              <div class="rounded-full bg-primary-50 p-1 w-fit">
                <div class="rounded-full bg-primary-100 p-2 w-fit">
                  <i-lxc-file
                    class="text-primary-600"
                    height="0.9rem"
                    width="0.9rem"
                  />
                </div>
              </div>
            </div>
            <h4>
              {{ $t('dtwins.import.results.total', results.length) }}
            </h4>
          </div>

          <ul class="leading-10">
            <li>
              {{ $t('dtwins.import.results.succeed') }}&nbsp;<span class="text-white bg-primary-700 rounded-full px-2 py-1 w-fit">{{ results.filter((result: any) => !result.failed).length }}</span>
            </li>
            <li>
              {{ $t('dtwins.import.results.failed') }}&nbsp;<span class="text-white bg-warning-700 rounded-full px-2 py-1 w-fit">{{ results.filter((result: any) => result.failed).length }}</span>
            </li>
          </ul>
        </div>
      </div>

      <template #footer>
        <div class="grid grid-cols-[max-content_auto] gap-4">
          <lxc-button
            type="secondary"
            :title="$t('button.close')"
            @click="show = false"
          >
            {{ $t('button.close') }}
          </lxc-button>
          <lxc-button
            :disabled="isLoading || !fileSelected"
            :title="$t('button.import')"
            @click="importDtwins"
          >
            {{ $t('button.import') }}
          </lxc-button>
        </div>
      </template>
    </lxc-side-canvas>
  </el-container>
</template>
