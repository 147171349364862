<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useVersion } from '~/composables/useVersion'
import { useConfigStore } from '~/stores/useConfigStore'

const { getFunctionalVersion } = useVersion()

const { version } = storeToRefs(useConfigStore())

const functionalVersion = computed(() => version.value ? getFunctionalVersion(version.value) : null)

</script>

<template>
  <div class="version">
    <el-popover
      placement="top-start"
      trigger="hover"
      width="auto"
    >
      <template #reference>
        <div>
          {{ functionalVersion ?? $t('parameters.version.unknown') }}
          <el-icon
            :size="18"
            class="icon-information"
          >
            <i-lx-information />
          </el-icon>
        </div>
      </template>

      <template #default>
        <strong>{{ $t('parameters.technicalVersion.label') }}</strong> {{ (version ?? $t('parameters.technicalVersion.unknown')) }}
      </template>
    </el-popover>
  </div>
</template>

<style lang='scss' scoped>
.version {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 14px;
  opacity: 0.6;
}
.icon-information {
  top: 4px;
}
</style>
