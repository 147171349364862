<script setup lang="ts">
import { LogLevel } from '@lxc/app-device-types'
import type { Option } from '~/types'
const { t } = useI18n()

const emit = defineEmits(['update:modelValue'])
const props = defineProps<{
  modelValue?: string[]
  header: string
}>()
const logLevelOptions: Option[] = (Object.values(LogLevel)).map((value) => {
  const label = value ? t(`logs.level.value.${value}`) : ''
  return {
    value,
    label,
  }
})
const selectedItems = computed({
  get() {
    return props.modelValue ?? []
  },
  set(selected?: Array<string>) {
    emit('update:modelValue', selected)
  },
})

</script>

<template>
  <lxc-value-label-checkboxes
    v-model="selectedItems"
    :options="logLevelOptions"
    :header="props.header"
  />
</template>
