<script lang='ts' setup>
import type { Option } from '~/types'

const emit = defineEmits(['selectChange', 'pollingChange', 'autoActivationChange'])
const props = defineProps<{
  title: string
  delay: number
  polling: number
  isApp?: boolean
  isAutoactivated?: boolean|null
}>()

const { t } = useI18n()

const MIN_POLLING_VALUE = 5
const MAX_POLLING_VALUE = 1440
const MIN_DELAY = 7
const MAX_DELAY = 1095
const autoActivation = ref(props.isAutoactivated)
const isCustomDelay = ref(false)
const delayLabel = computed(() => {
  return t(!isCustomDelay.value
    ? 'parameters.certificates.delayPlaceholder'
    : 'parameters.certificates.delayPlaceholderCustom',
  )
})
const delayOptions: Option[] = [{
  label: t('device.month', 1),
  value: '30',
},
{
  label: t('device.month', 3),
  value: '90',
},
{
  label: t('device.month', 6),
  value: '180',
},
{
  label: t('device.year', 1),
  value: '365',
},
{
  label: t('device.year', 3),
  value: '1095',
},
{
  label: t('device.custom'),
  value: 'custom',
},
]

onMounted(() => {
  isCustomDelay.value = !delayOptions.find(({ value }) => value === props.delay.toString())
})

/**
 * Update delay when selecting, show custom delay input if selected
 * @param duration
 */
function onSelectDuration(duration: string) {
  if (duration !== 'custom') {
    emit('selectChange', duration)
  } else {
    isCustomDelay.value = true
  }
}

/**
 * Update with first select value when cancelling custom delay
 */
function onCancelCustomDelay() {
  emit('selectChange', delayOptions[0].value)
  isCustomDelay.value = false
}
</script>

<template>
  <el-card class="config-card">
    <h3 class="title">
      {{ title }}
    </h3>
    <el-form
      class="select-wrapper"
      label-position="top"
    >
      <el-form-item :label="delayLabel">
        <el-select
          v-if="!isCustomDelay"
          :model-value="delay.toString()"
          class="select"
          size="large"
          @change="onSelectDuration"
        >
          <el-option
            v-for="(option, index) in delayOptions"
            :key="index"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
        <el-row
          v-else
          class="custom-delay-row"
        >
          <el-col :span="16">
            <el-input-number
              :model-value="delay"
              :min="MIN_DELAY"
              :max="MAX_DELAY"
              size="large"
              @input="$emit('selectChange', $event)"
            />
          </el-col>
          <el-col :span="8">
            <el-button
              plain
              size="large"
              class="btn-cancel"
              @click="onCancelCustomDelay"
            >
              {{ $t('button.cancel') }}
            </el-button>
          </el-col>
        </el-row>

        <el-tooltip popper-class="small-popper">
          <i-lx-tooltip-info />
          <template #content>
            <span>{{ $t('parameters.certificates.delayInfo') }}</span>
          </template>
        </el-tooltip>
      </el-form-item>

      <el-form-item :label="$t('parameters.certificates.pollingPlaceholder')">
        <el-input-number
          :model-value="polling"
          :min="MIN_POLLING_VALUE"
          :max="MAX_POLLING_VALUE"
          class="select"
          controls-position="right"
          @change="$emit('pollingChange', $event)"
        />

        <el-tooltip popper-class="small-popper">
          <i-lx-tooltip-info />
          <template #content>
            <span class="config-info">{{
              isApp ? $t('parameters.certificates.pollingInfoApplication') : $t('parameters.certificates.pollingInfoDevice')
            }}</span>
          </template>
        </el-tooltip>
      </el-form-item>
      <el-form-item :label="$t('parameters.certificates.autoActivationLabel')">
        <el-checkbox
          v-model="autoActivation"
          :label="$t('parameters.certificates.autoActivationAction')"
          size="large"
          class="select"
          @change="$emit('autoActivationChange', $event)"
        />
        <el-tooltip popper-class="small-popper">
          <i-lx-tooltip-info />
          <template #content>
            <span class="config-info">{{
              isApp ? $t('parameters.certificates.autoActivationInfoApplication') : $t('parameters.certificates.autoActivationInfoDevice')
            }}</span>
          </template>
        </el-tooltip>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<style lang='scss' scoped>
  .btn-cancel {
    padding: 8px 15px;
  }

  .config-card {
    width: calc(100% / 3 - 20px);
    min-width: 400px;

    .title {
      font-size: 14px;
      margin-top: 0;
    }

    .select-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .select {
        width: 80%;
        margin-right: 5px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .custom-delay-row {
      width: 80%;
      margin-right: 5px;
    }
  }
</style>
