import { useAcl } from 'vue-simple-acl'
import type { MenuI } from '@lxc/app-device-common'
import { LicenseStatus } from '@lxc/app-device-types'
import LxMenuSectorsFilter from './LxMenuSectorsFilter.vue'
import ILxcCopy from '~icons/lxc/copy'
import ILxcCompass from '~icons/lxc/compass'
import ILxcHomeLine from '~icons/lxc-custom/home-line'
import ILxcTablet from '~icons/lxc/tablet'
import ILxcFlag from '~icons/lxc/flag'
import ILxcMapPin from '~icons/lxc/map-pin'
import ILxcUser from '~icons/lxc/user'
import ILxcSettings from '~icons/lxc/settings'
import { PATHS } from '~/constants/paths'
import { ACL_ROLES } from '~/types'
import { useLicenseStatusStore } from '~/stores/useLicenseStatusStore'
import { useConfigStore } from '~/stores/useConfigStore'
import { useUserSession } from '~/stores/useUserSession'
const { anyCan, can } = useAcl()

/** Computed visible items */
const isItemLogsVisible = computed((): boolean => {
  const { isAuthenticated } = useUserSession()
  const { isFeatureDataManagementEnabled } = useConfigStore()
  return isAuthenticated && isFeatureDataManagementEnabled && anyCan([
    ACL_ROLES.CYBER_LOGS_VIEW,
    ACL_ROLES.DEVICE_LOGS_VIEW,
    ACL_ROLES.DEVICE_FLEET_LOGS_VIEW,
    ACL_ROLES.SYSTEM_LOGS_VIEW,
  ])
})

const isItemDevicesVisible = computed((): boolean => useUserSession().isAuthenticated && can(ACL_ROLES.DISPLAY_DEVICES))

const isItemApplicationsVisible = computed((): boolean => (useUserSession().isAuthenticated && can(ACL_ROLES.DISPLAY_APPLICATIONS)))

const isItemDtwinsVisible = computed((): boolean => {
  const { isFeatureDtwinsEnabled } = useConfigStore()
  const { isAuthenticated } = useUserSession()
  return isAuthenticated && isFeatureDtwinsEnabled
})

const isItemCampaignVisible = computed((): boolean => {
  const { licenseStatus, isLicenseFeatureAdvancedFleetManagementPresent } = useLicenseStatusStore()
  const { isAuthenticated } = useUserSession()
  return isAuthenticated && ((licenseStatus && licenseStatus.statusOfLicense !== LicenseStatus.NOT_ACTIVATED) ?? false) && !!isLicenseFeatureAdvancedFleetManagementPresent && can(ACL_ROLES.DISPLAY_CAMPAIGNS)
})

const isItemSectorsVisible = computed((): boolean => useUserSession().isAuthenticated && can(ACL_ROLES.DISPLAY_SECTORS))

const isItemUsersVisible = computed((): boolean => useUserSession().isAuthenticated && can(ACL_ROLES.DISPLAY_USERS))

const isConfigurePkiVisible = computed((): boolean => useUserSession().isAuthenticated && can(ACL_ROLES.CONFIGURE_PKI))

const isManageTrustStoreVisible = computed((): boolean => useUserSession().isAuthenticated && can(ACL_ROLES.MANAGE_TRUSTSTORE))

const isDisplayFirmwaresVisible = computed((): boolean => useUserSession().isAuthenticated && can(ACL_ROLES.DISPLAY_FIRMWARES))

const isManageCertificatesVisible = computed((): boolean => useUserSession().isAuthenticated && can(ACL_ROLES.MANAGE_CERTIFICATES))

const isApplicationManagementVisible = computed((): boolean => {
  const { isFeatureApplicationManagementEnabled } = useConfigStore()
  const { isAuthenticated } = useUserSession()
  return isAuthenticated && isFeatureApplicationManagementEnabled && can(ACL_ROLES.DISPLAY_APPLICATION_MGTS)
})

const isRoleConfigureNotificationVisible = computed((): boolean => useUserSession().isAuthenticated && can(ACL_ROLES.CONFIGURE_NOTIFICATION))
/** */

export const menu = computed<MenuI>(() => {
  return {
    defaultActive: 0,
    items: [
      {
        label: 'layout.menu.overview',
        items: [
          {
            label: 'layout.menu.dashboard',
            iconOrComponent: ILxcHomeLine,
            dataCy: 'dashboard',
            to: '/',
            visible: true,
          },
          {
            label: 'layout.menu.logs',
            iconOrComponent: ILxcCompass,
            dataCy: 'logs',
            to: PATHS.LOGS,
            visible: isItemLogsVisible.value,
          },
        ],
        visible: true,
      },
      {
        label: 'layout.menu.product',
        items: [
          {
            label: 'layout.menu.devices',
            iconOrComponent: ILxcTablet,
            dataCy: 'devices',
            to: PATHS.DEVICES_DVTM_ESOFT,
            visible: isItemDevicesVisible.value,
          },
          {
            label: 'layout.menu.dtwins',
            iconOrComponent: ILxcTablet,
            dataCy: 'dtwins',
            to: PATHS.DTWINS,
            visible: isItemDtwinsVisible.value,
          },
          {
            label: 'layout.menu.applications',
            iconOrComponent: ILxcCopy,
            dataCy: 'applications',
            to: PATHS.APPLICATIONS,
            visible: isItemApplicationsVisible.value,
          },
          {
            label: 'layout.menu.campaigns',
            iconOrComponent: ILxcFlag,
            dataCy: 'campaigns',
            to: PATHS.CAMPAIGNS,
            visible: isItemCampaignVisible.value,
          },
        ],
        visible: isItemDevicesVisible.value || isItemApplicationsVisible.value || isItemDtwinsVisible.value || isItemCampaignVisible.value,
      },
      {
        label: 'layout.menu.sector.label',
        items: [{
          iconOrComponent: LxMenuSectorsFilter,
          visible: true,
        }],
        visible: true,
      },
    ],

    bottomItems: [
      {
        label: 'layout.menu.settings',
        items: [
          {
            label: 'layout.menu.sectors',
            iconOrComponent: ILxcMapPin,
            dataCy: 'sectors',
            to: PATHS.SECTORS,
            visible: isItemSectorsVisible.value,
          },
          {
            label: 'layout.menu.users',
            iconOrComponent: ILxcUser,
            dataCy: 'users',
            to: PATHS.USER_MANAGEMENT,
            visible: isItemUsersVisible.value,
          },
          {
            label: 'layout.menu.parameters.label',
            iconOrComponent: ILxcSettings,
            dataCy: 'paramters',
            items: [
              {
                label: 'layout.menu.parameters.platformMgt',
                items: [
                  {
                    label: 'page.pkiConnector.title',
                    to: PATHS.PARAMETERS_CONNECTOR_PKI,
                    visible: isConfigurePkiVisible.value,
                  },
                  {
                    label: 'page.truststore.title',
                    to: PATHS.PARAMETERS_TRUSTSTORE,
                    visible: isManageTrustStoreVisible.value,
                  },
                ],
                visible: isConfigurePkiVisible.value || isManageTrustStoreVisible.value,
              },
              {
                label: 'layout.menu.parameters.features.label',
                items: [
                  {
                    label: 'layout.menu.parameters.features.firmwareManagement',
                    to: PATHS.PARAMETERS_FIRMWARES,
                    visible: isDisplayFirmwaresVisible.value,
                  },
                  {
                    label: 'layout.menu.parameters.features.deviceConfiguration',
                    to: PATHS.PARAMETERS_DEVICE_CONFIGURATION,
                    visible: isManageCertificatesVisible.value,
                  },
                  {
                    label: 'layout.menu.parameters.features.licenseManagement',
                    to: PATHS.PARAMETERS_LICENSE,
                    visible: true,
                  },
                  {
                    label: 'layout.menu.parameters.features.applicationManagement',
                    to: PATHS.PARAMETERS_APPLICATIONS,
                    visible: isApplicationManagementVisible.value,
                  },
                ],
                visible: true,
              },
              {
                label: 'layout.menu.parameters.monitoring.label',
                items: [
                  {
                    label: 'layout.menu.parameters.monitoring.settings',
                    to: PATHS.PARAMETERS_CERTIFICATE_ALERT_REPORTING,
                    visible: isRoleConfigureNotificationVisible.value,
                  },
                ],
                visible: isRoleConfigureNotificationVisible.value,
              },
            ],
            visible: true,
          },
        ],
        visible: true,
      },
    ],
  }
})
