import type { ErrorCode, ErrorResponse } from '@lxc/app-device-types'
import type { NotificationKey } from '~/utils/notifications-tools'
import { setDetailMessageAsListItem, showNotificationError } from '~/utils/notifications-tools'
import { i18n } from '~/plugins/i18n'

export interface ErrorResponseWithStatus extends ErrorResponse {
  status?: number
}

export default class LxcError implements ErrorResponseWithStatus {
  public code: ErrorCode
  public message: string | undefined
  public timestamp: number | undefined
  public details: string[]
  public status?: number
  public variables: string[][]

  constructor(error: ErrorResponseWithStatus) {
    this.code = error.code
    this.message = error.message
    this.timestamp = error.timestamp
    this.details = error.details ?? []
    this.variables = error.variables ?? []
    this.status = error.status
  }

  static check(error: any): error is LxcError {
    return error instanceof LxcError
  }

  public notify(titleKey: NotificationKey) {
    const details = this.details
      .map((detail, index) => LxcError.getDetailMessage(this.code, detail, this.variables[index]))
      .filter(Boolean)
      .map(setDetailMessageAsListItem)
      .join('')

    showNotificationError(i18n.global.t(titleKey), details)
  }

  public toError(titleKey: NotificationKey) {
    let options: ErrorOptions | undefined

    if (this.details.length) {
      options = {
        cause: {
          code: this.code,
          values: this.details
            .map((detail, index) => LxcError.getDetailMessage(this.code, detail, this.variables[index]))
            .filter(Boolean),
        },
      }
    }

    return new Error(i18n.global.t(titleKey), options)
  }

  private static getDetailMessage(code: ErrorCode, detail: string, variables: string[]) {
    const key = `errors.${code}.details.${detail}`
    const named: any = {}
    for (const index in variables) {
      named[`var${index}`] = variables[index]
    }
    return i18n.global.te(key) ? i18n.global.t(key, named) : false
  }
}
