import type { ApiListResult, UserCertificateStatusI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import userCertificateService from '~/services/userCertificate.service'
import { formatIsoDate } from '~/utils/date-tools'
import LxcError from '~/utils/LxcError'
import { NotificationKey } from '~/utils/notifications-tools'

export interface UserCertificateResult {
  error: Ref<LxcError | null>
  findUserCertificateStatus: (userId: string, dateFormat: string) => Promise<void>
  isLoading: Ref<boolean>
  userCertificatesStatus: Ref<ApiListResult<UserCertificateStatusI> | null>
}

export function useUserAccountCertificates(): UserCertificateResult {
  const userCertificatesStatus = ref<ApiListResult<UserCertificateStatusI> | null>(null)
  const error = ref<LxcError | null>(null)
  const isLoading = ref<boolean>(false)

  function parseUserCertificateStatus(paramCertifcateStatus: UserCertificateStatusI, dateFormat: string): UserCertificateStatusI | undefined {
    let parsedStatus: UserCertificateStatusI | undefined

    if (paramCertifcateStatus.status && paramCertifcateStatus.information) {
      parsedStatus = {
        status: paramCertifcateStatus.status,
        information: {
          creationDate: paramCertifcateStatus.information.creationDate ? formatIsoDate(paramCertifcateStatus.information.creationDate, dateFormat) : '',
          notBefore: paramCertifcateStatus.information.notBefore ? formatIsoDate(paramCertifcateStatus.information.notBefore, dateFormat) : '',
          notAfter: paramCertifcateStatus.information.notAfter ? formatIsoDate(paramCertifcateStatus.information.notAfter, dateFormat) : '',
        },
      }
    }

    return parsedStatus
  }

  async function findUserCertificateStatus(userId: string, dateFormat: string) {
    isLoading.value = true
    error.value = null
    const response = await userCertificateService.findUserCertificateStatus(userId)
    isLoading.value = false

    if (LxcError.check(response)) {
      userCertificatesStatus.value = null
      error.value = response
      response.notify(NotificationKey.error)
    } else {
      const status = parseUserCertificateStatus(response, dateFormat)
      userCertificatesStatus.value = {
        context: {
          count: 1,
          totalCount: 1,
          pageSize: 1,
          page: 1,
        },
        data: !status ? [] : [status],
      }
    }
  }

  return {
    error,
    findUserCertificateStatus,
    isLoading,
    userCertificatesStatus,
  }
}
