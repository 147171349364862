<script lang='ts' setup>
import type { SlideMenuItem } from '@lxc/app-device-common'
import type { Ref } from 'vue'
import ILxcCustomFilterMenu from '~icons/lxc-custom/filter-menu'
const { t } = useI18n()

const props = defineProps<{
  disabled?: boolean
  displayMenu?: number
  displayedPanel?: string | null
  menuItems: SlideMenuItem[]
  selectedFilters?: string[]
  undeletableSelectedFilters?: string[]
  filtersByType: Array<string | Array<any> | null | undefined>
  isButtonRight?: boolean
}>()

const displayMenuLocal: Ref<number> = ref(0)
// Side canvas
const sideCanvasShown = ref(false)

const emit = defineEmits([
  'change',
  'discard',
  'apply',
  'reset',
  'update:selectedFilters',
  'deleteTag',
  'update:displayMenu',
  'update:displayedPanel',
  'showing',
  'hidden',
  'shown',
  'startSlideToPanel',
  'endSlideToPanel',
  'startBackToMenu',
  'endBackToMenu',
])
const onShowFilterClick = (event: MouseEvent) => {
  event.preventDefault()
  event.stopPropagation()
  sideCanvasShown.value = true
  emit('showing', true)
}

const displayMenu = computed({
  get() {
    if (props.displayMenu !== undefined) {
      return props.displayMenu
    } else {
      return displayMenuLocal.value
    }
  },
  set(pDisplayMenu: number) {
    if (props.displayMenu !== undefined) {
      emit('update:displayMenu', pDisplayMenu)
    } else {
      displayMenuLocal.value = pDisplayMenu
    }
  },
})

const filterTypeCount = computed<number>(() => {
  const filteredTypes = props.filtersByType.filter(filter => filter != null && ((typeof filter === 'string' && (filter as string).length !== 0) || (Array.isArray(filter) && (filter as Array<any>).length !== 0 && (filter as Array<any>).every(filterItem => typeof filterItem === 'string' && filterItem.length !== 0))))
  return filteredTypes.length ?? 0
})

const dispatchDiscard = (evt: Event) => {
  displayMenu.value++
  emit('discard', evt)
}

const apply = (evt: Event) => {
  emit('apply', evt)
  sideCanvasShown.value = false
}
const validate = (evt: Event) => {
  displayMenu.value++
}

const reset = (evt: Event) => {
  emit('reset', evt)
  sideCanvasShown.value = false
}

const areAnySelectedFilters = computed(() => {
  const length: number | undefined = props.selectedFilters?.length
  return length != null && length !== 0
})

const formSelectedFilters = computed({
  get(): string[] {
    return props.selectedFilters ?? []
  },
  set(newSelectedFilters?: string[]) {
    if (newSelectedFilters) {
      emit('update:selectedFilters', newSelectedFilters)
    }
  },
})

const formDisplayedPanel = computed({
  get(): string | undefined | null {
    return props.displayedPanel
  },
  set(newPanel?: string | null) {
    emit('update:displayedPanel', newPanel)
  },
})

const onTagDeleteClick = (tag: string) => {
  emit ('deleteTag', tag)
}

const buttonWrapperClass = computed<string[]>((): string[] => {
  let vClass = []

  if (props.isButtonRight) {
    vClass = [
      'flex',
      'items-center',
      'box-border',
      'justify-end',
    ]
  } else {
    vClass.push('inline-block')
  }

  return vClass
})
</script>

<template>
  <div :class="buttonWrapperClass">
    <lxc-button
      html-type="button"
      type="tertiary"
      :title="$t('filters.buttonLabel')"
      :disabled="props.disabled ?? false"
      class="leading-[1.5em] text-[1.143rem]"
      @click="onShowFilterClick"
    >
      <span class="ml-2 w-2 filter-icon text-primary-700">
        <i-lxc-custom-filter-menu />
      </span>
      {{ t('filters.buttonLabel') }}
    </lxc-button>
  </div>

  <lxc-side-canvas
    v-model:show="sideCanvasShown"
    :header="$t('filters.header')"
    :close-tooltip="$t('filters.close')"
    body-class="!p-0"
    @discard="dispatchDiscard"
    @hidden="emit('hidden', $event)"
    @shown="emit('shown', $event)"
  >
    <lxc-slide-menu
      v-model:display-menu="displayMenu"
      v-model:displayed-panel="formDisplayedPanel"
      :menu-items="props.menuItems"
      @start-slide-to-panel="emit('startSlideToPanel', $event)"
      @end-slide-to-panel="emit('endSlideToPanel', $event)"
      @start-back-to-menu="emit('startBackToMenu', $event)"
      @end-back-to-menu="emit('endBackToMenu', $event)"
    >
      <template
        v-if="areAnySelectedFilters"
        #summary
      >
        <div class="px-6 mb-6">
          <lxc-tag-set
            type="primary"
            border-visible
            deletable
            :data="formSelectedFilters"
            :data-disabled="undeletableSelectedFilters"
            :delete-tooltip="$t('filters.deleteSelectedFilter')"
            @delete="onTagDeleteClick"
          />
        </div>
      </template>

      <template #menu-footer>
        <lxc-filter-button-bar>
          <div class="grid grid-cols-[max-content_auto] gap-x-6 gap-y-4">
            <lxc-button
              :title="$t('filters.resetFilter')"
              :disabled="props.disabled"
              class="whitespace-nowrap"
              html-type="button"
              type="tertiary"
              @click="reset"
            >
              {{ t('filters.resetFilter') }}
            </lxc-button>
            <lxc-button
              :title="$t('filters.apply', {count: filterTypeCount ?? 0})"
              :disabled="props.disabled"
              class="whitespace-nowrap"
              html-type="button"
              @click="apply"
            >
              {{ t('filters.apply', {count: filterTypeCount ?? 0}) }}
            </lxc-button>
          </div>
        </lxc-filter-button-bar>
      </template>

      <template
        v-for="menuItem in props.menuItems"
        :key="menuItem.id"
        #[menuItem.id]
      >
        <slot :name="menuItem.id" />
      </template>

      <template
        v-for="menuItem in props.menuItems"
        :key="menuItem.id"
        #[menuItem.footerId]
      >
        <lxc-filter-button-bar>
          <div class="flex justify-end gap-x-6 gap-y-4">
            <lxc-button
              :title="$t('filters.validateFilter')"
              :disabled="props.disabled"
              class="whitespace-nowrap basis-1/2 shrink-0"
              html-type="button"
              @click="validate"
            >
              {{ t('filters.validateFilter') }}
            </lxc-button>
          </div>
        </lxc-filter-button-bar>
      </template>
    </lxc-slide-menu>
  </lxc-side-canvas>
</template>

<style lang="scss" scoped>
  .filter-icon {
    :deep(svg) {
      display: inline;
      vertical-align: baseline;
    }
  }
</style>
