<script lang="ts" setup>
import type { Ref } from 'vue'
import type { ProfileRolesI, UserProfileI } from '@lxc/app-device-types'
import { useRouter } from 'vue-router'
import UserProfilesService from '~/services/userProfiles.service'
import { useRoles } from '~/composables/useRoles'
import { PATHS } from '~/constants/paths'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import LxcError from '~/utils/LxcError'
import { useElTable } from '~/composables/useElTable'

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const { getCellStyle } = useElTable()

const { fetchRoles, roles, error, isRolesLoading } = useRoles()

const isLoading = ref(true)
const profileCode = route.params.profileCode
const profile: Ref<UserProfileI | null> = ref(null)
const selectedRoles = ref<ProfileRolesI[]>([])
const profileLabel = ref('')

const rules = reactive({
  label: [
    { required: true, message: t('profile.validation.name'), whitespace: true, trigger: 'blur' },
    { max: 50, message: t('input.error.maxLength', { maxLength: 50 }), whitespace: true, trigger: 'blur' },
  ],
})

onMounted(async() => {
  await fetchProfileDetails()
  await fetchRoles()
  getAssignedRoles()
  isLoading.value = false
})

/**
 * Retrieve profile details
 */
async function fetchProfileDetails() {
  const response = await UserProfilesService.getUserProfileByCode(profileCode.toString())

  if (LxcError.check(response)) {
    profile.value = null
    error.value = response
  } else {
    profile.value = response
    profileLabel.value = response.label
  }
}

/**
 * Update profile
 */
async function updateProfile() {
  if (profile.value === null) {
    return
  }

  profile.value.roles = selectedRoles.value
  const response = await UserProfilesService.updateUserProfile(profileCode.toString(), profile.value)

  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError)
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess))
    await router.push(PATHS.USER_MANAGEMENT_PROFILES)
  }
}

function getAssignedRoles() {
  selectedRoles.value = profile.value?.roles || []
}

function isRoleAssigned(roleCode: string) {
  return selectedRoles.value.some(role => role.code === roleCode)
}

function handleRoleSelection(code: string, orgCode: string) {
  if (selectedRoles.value.some(role => role.code === code)) {
    selectedRoles.value = selectedRoles.value.filter((selectedRole: ProfileRolesI) => selectedRole.code !== code)
    return
  }

  selectedRoles.value = [...selectedRoles.value, {
    code,
    orgCode,
  }]
}

function toggleAllSelection() {
  if (selectedRoles.value.length === roles?.value?.length) {
    selectedRoles.value = []
  } else if (roles.value) {
    selectedRoles.value = [...roles.value]
  }
}
</script>

<template>
  <el-container direction="vertical">
    <lxc-breadcrumb :name="profileLabel" />
    <lxc-container
      class="half-container"
      :error="error"
      :is-loading="isLoading"
    >
      <h2 class="section-title">
        {{ $t('section.name.title') }}
      </h2>

      <el-form
        :model="profile"
        :rules="rules"
      >
        <el-form-item
          :label="$t('input.profileName')"
          prop="label"
        >
          <el-input
            v-model="profile.label"
            type="text"
            size="large"
          />
        </el-form-item>
      </el-form>

      <el-divider />

      <h2 class="section-title">
        {{ $t('section.roles.title') }}
      </h2>

      <el-table
        ref="selectedRolesTable"
        v-loading="isRolesLoading"
        :data="roles"
        :fit="true"
        stripe
        row-class-name="table-row-clickable"
        :empty-text="$t('profile.empty')"
        :cell-style="getCellStyle"
      >
        <el-table-column
          prop="translation.label"
          :label="$t('profile.roles')"
        />
        <el-table-column width="50">
          <template #header>
            <div class="checkbox-container">
              <el-checkbox
                :model-value="selectedRoles.length !== 0"
                @change="toggleAllSelection"
              />
            </div>
          </template>

          <template #default="scope">
            <div class="checkbox-container">
              <el-checkbox
                :model-value="isRoleAssigned(scope.row.code)"
                @change="handleRoleSelection(scope.row.code, scope.row.orgCode)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>

      <lxc-cancel-or-submit-buttons
        @cancel="router.back()"
        @submit="updateProfile"
      />
    </lxc-container>
  </el-container>
</template>

<style scoped lang='scss'>
  .page-container {
    margin: 1.5rem 0 4rem 6rem;

    .checkbox-container {
      display: flex;
      justify-content: flex-end;
    }
  }

  .page-footer {
    margin-top: 3rem;
  }
</style>
