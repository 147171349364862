<script setup lang="ts">
import Handlebars from 'handlebars'
import type { AsyncLogI } from '@lxc/app-device-types'
import { LogEntityClass } from '@lxc/app-device-types'
import { PATHS } from '~/constants/paths'
import type { LogLabelI } from '~/types'
const { t } = useI18n()
const baseURL = import.meta.env.LXC_FI_DEVICE_URL
const shortenBaseURL = baseURL.replace(/\/$/g, '')
const emit = defineEmits(['click'])

const props = defineProps<{
  modelValue: AsyncLogI
  link?: HTMLLinkElement | null
}>()

const onLabelClick = (event: MouseEvent) => {
  if (event.target
  && (event.target as HTMLElement).tagName === 'A'
  && (event.target as HTMLElement).hasAttribute('HREF')
  && ((event.target as HTMLElement).dataset?.property === 'assetId' || (event.target as HTMLElement)?.dataset?.property === 'authorId')) {
    event.preventDefault()
    emit('click', (event.target as HTMLLinkElement))
  }
}

function initLink(labelModel: LogLabelI, idProp: 'assetId' | 'authorId', nameProp: 'authorName' | 'assetName', classProp: 'authorClass' | 'assetClass', action?: string) {
  let hyperlink: string | undefined
  // Escape the HTML characters making the strings safe for rendering as text within HTML content.
  const id = props.modelValue[idProp] != null ? Handlebars.Utils.escapeExpression(props.modelValue[idProp] as string) : undefined
  const name = props.modelValue[nameProp] != null ? Handlebars.Utils.escapeExpression(props.modelValue[nameProp] as string) : undefined

  switch (props.modelValue[classProp]) {
    case LogEntityClass.USER:
      hyperlink = `${shortenBaseURL}${PATHS.USER_MANAGEMENT_USERS}/${id}`
      break
    case LogEntityClass.DEVICE:
      hyperlink = `${baseURL}dtwins/${id}`
      break
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      hyperlink = `${baseURL}devices/${id}`
      break
    case LogEntityClass.GROUP:
      hyperlink = `${shortenBaseURL}${PATHS.USER_MANAGEMENT_GROUPS}/${id}`
      break
    case LogEntityClass.PROFILE:
      hyperlink = `${shortenBaseURL}${PATHS.USER_MANAGEMENT_PROFILES}/${id}`
      break
    case LogEntityClass.SECTOR:
      hyperlink = `${baseURL}sectors/${id}`
      break
    default:
      break
  }

  if (hyperlink) {
    const dataActionProp = action ? ` data-action="${action}"` : ''
    labelModel[idProp] = `<a href="${hyperlink}" data-entityclass="${props.modelValue[classProp]}" data-property="${idProp}" data-id="${id}" ${dataActionProp}>${name ?? id}</a>`
  } else if (props.modelValue.authorName != null) {
    if (idProp === 'authorId') {
      labelModel[idProp] = name ?? id ?? ''
    } else {
      labelModel[idProp] = name ?? id
    }
  }
}

function initAuthorLink(labelModel: LogLabelI) {
  initLink(labelModel, 'authorId', 'authorName', 'authorClass')
}

function initAssetLink(labelModel: LogLabelI) {
  initLink(labelModel, 'assetId', 'assetName', 'assetClass', props.modelValue.action)
}

function initChangesLink(labelModel: LogLabelI) {
  let hyperlink: string | undefined
  labelModel.changes = {}

  if (props.modelValue.label != null && /\{\{\s?changes(\.\w+)?\s?\}\}/g.test(props.modelValue.label)) {
    const matches: RegExpMatchArray | null = props.modelValue.label.match(/\{\{\s?changes(\.\w+)?\s?\}\}/g)

    if (matches && matches.length !== 0) {
      const changesProperty: string = matches[0].replace(/(\{\{\s?changes\.?)|(\s?\}\})/g, '')

      if (['profileId', 'userId', 'groupId', 'roleId', 'message'].includes(changesProperty)
          && props.modelValue.changes != null
          && props.modelValue.changes instanceof Object) {
        if (props.modelValue.changes?.profileId) {
          const profileId = Handlebars.Utils.escapeExpression(props.modelValue.changes?.profileId as string)
          const profileLabel = props.modelValue.changes?.profileLabel != null ? Handlebars.Utils.escapeExpression(props.modelValue.changes?.profileLabel as string) : undefined
          hyperlink = `${shortenBaseURL}${PATHS.USER_MANAGEMENT_PROFILES}/${profileId}`
          labelModel.changes.profileId = `<a href="${hyperlink}" data-entityclass="${LogEntityClass.PROFILE}" data-property="changes" data-id="${profileId}">${profileLabel ?? profileId}</a>`
        }

        if (props.modelValue.changes?.groupId) {
          const groupId = Handlebars.Utils.escapeExpression(props.modelValue.changes?.groupId as string)
          const groupLabel = props.modelValue.changes?.groupLabel != null ? Handlebars.Utils.escapeExpression(props.modelValue.changes?.groupLabel as string) : undefined
          hyperlink = `${shortenBaseURL}${PATHS.USER_MANAGEMENT_GROUPS}/${groupId}`
          labelModel.changes.groupId = `<a href="${hyperlink}" data-entityclass="${LogEntityClass.GROUP}" data-property="changes" data-id="${groupId}">${groupLabel ?? groupId}</a>`
        }

        if (props.modelValue.changes?.userId) {
          const userId = Handlebars.Utils.escapeExpression(props.modelValue.changes?.userId as string)
          const userLabel = props.modelValue.changes?.userLabel != null ? Handlebars.Utils.escapeExpression(props.modelValue.changes?.userLabel as string) : undefined
          hyperlink = `${shortenBaseURL}${PATHS.USER_MANAGEMENT_USERS}/${userId}`
          labelModel.changes.userId = `<a href="${hyperlink}" data-entityclass="${LogEntityClass.USER}" data-property="changes" data-id="${userId}">${userLabel ?? userId}</a>`
        }

        if (props.modelValue.changes?.roleId) {
          const roleId = Handlebars.Utils.escapeExpression(props.modelValue.changes?.roleId as string)
          const roleLabel = props.modelValue.changes?.roleLabel != null ? Handlebars.Utils.escapeExpression(props.modelValue.changes?.roleLabel as string) : undefined
          labelModel.changes.roleId = roleLabel ?? roleId
        }

        if (props.modelValue.changes?.message) {
          labelModel.changes.message = Handlebars.Utils.escapeExpression(props.modelValue.changes?.message as string)
        }
      } else {
        // If the label does not exist in the known patterns list, write "unknown".
        labelModel.changes[changesProperty] = t('logs.list.unknown')
      }
    }
  }
}

const labelExists = computed(() => {
  return !!props.modelValue.label
})

const groupLabel = computed(() => {
  let resultLabel = ''

  if (props.modelValue.label) {
    const labelModel: LogLabelI = {
      authorId: '',
    }

    if (props.modelValue.label != null) {
      initAuthorLink(labelModel)
      initAssetLink(labelModel)

      if (props.modelValue.changes) {
        initChangesLink(labelModel)
      }
    }

    /// Add 3 curly brackets to be able to insert HTML into the label
    const escapeLabel = props.modelValue.label.replaceAll(/\{\{\s*/g, '{{{').replaceAll(/\s*\}\}/g, '}}}')
    const template = Handlebars.compile(escapeLabel)
    resultLabel = template(labelModel)
  }

  return resultLabel
})

const labelTooltip = computed(() => {
  let resultLabel = ''

  if (props.modelValue.label) {
    const labelModel: LogLabelI = {
      authorId: '',
    }

    labelModel.authorId = props.modelValue.authorName ?? props.modelValue.authorId

    if (props.modelValue.assetId) {
      labelModel.assetId = props.modelValue.assetName ?? props.modelValue.assetId
    }

    if (/\{\{\s?changes(\.\w+)?\s?\}\}/g.test(props.modelValue.label)) {
      const matches: RegExpMatchArray | null = props.modelValue.label.match(/\{\{\s?changes(\.\w+)?\s?\}\}/g)
      labelModel.changes = {}

      if (matches && matches.length !== 0) {
        const changesProperty: string = matches[0].replace(/(\{\{\schanges\.?)|(\s?\}\})/g, '')

        if (['profileId', 'groupId', 'roleId', 'message'].includes(changesProperty)
            && props.modelValue.changes != null
            && props.modelValue.changes instanceof Object) {
          if (props.modelValue.changes?.profileId) {
            labelModel.changes.profileId = props.modelValue.changes?.profileLabel ?? props.modelValue.changes?.profileId
          }

          if (props.modelValue.changes?.groupId) {
            labelModel.changes.groupId = props.modelValue.changes?.groupLabel ?? props.modelValue.changes?.groupId
          }

          if (props.modelValue.changes?.roleId) {
            labelModel.changes.roleId = props.modelValue.changes?.roleName ?? props.modelValue.changes?.roleId
          }

          if (props.modelValue.changes?.message) {
            labelModel.changes.message = props.modelValue.changes?.message
          }
        } else {
          // If the label does not exist in the known patterns list, write "unknown".
          labelModel.changes[changesProperty] = t('logs.list.unknown')
        }
      }
    }

    if (props.modelValue.changes?.changesId) {
      labelModel.changes = {
        changesId: props.modelValue.changes?.changesName ?? props.modelValue.changes?.changesId,
      }
    }

    const template = Handlebars.compile(props.modelValue.label)
    resultLabel = template(labelModel)
  }

  return resultLabel
})

</script>
<template>
  <span
    v-if="labelExists"
    :title="labelTooltip"
    class="log-label"
    @click="onLabelClick"
    v-html="groupLabel"
  />
</template>
<style lang="scss" scoped>
.log-label{
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  white-space: break-spaces;
  overflow: hidden;
}

.filter-icon {
    :deep(svg) {
      display: inline;
      vertical-align: baseline;
    }
  }

</style>
