export function useSort() {
  const sort = ref<string>('')

  async function onSortChange(event: any) {
    switch (event.order) {
      case 'descending':
        sort.value = `-${event.prop}`
        break
      case 'ascending':
        sort.value = `+${event.prop}`
        break
      default: {
        sort.value = ''
      }
    }
  }

  return {
    sort,
    onSortChange,
  }
}
