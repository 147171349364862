import application from './application.json'
import applicationMgt from './applicationMgt.json'
import breadcrumbs from './breadcrumbs.json'
import button from './button.json'
import campaign from './campaign.json'
import card from './card.json'
import certificateAlerting from './certificateAlerting.json'
import certificates from './certificates.json'
import dashboard from './dashboard.json'
import dateFormat from './dateFormat.json'
import device from './device.json'
import dtwins from './dtwins.json'
import errors from './errors.json'
import filters from './filters.json'
import firmware from './firmware.json'
import input from './input.json'
import label from './label.json'
import layout from './layout.json'
import license from './license.json'
import login from './login.json'
import logs from './logs.json'
import message from './message.json'
import notification from './notification.json'
import operation from './operation.json'
import page from './page.json'
import pagination from './pagination.json'
import parameters from './parameters.json'
import pki from './pki.json'
import platform from './platform.json'
import profile from './profile.json'
import section from './section.json'
import sectors from './sectors.json'
import tab from './tab.json'
import table from './table.json'
import truststore from './truststore.json'
import unit from './unit.json'
import user from './user.json'
import userGroup from './userGroup.json'

export default { application, applicationMgt, breadcrumbs, button, campaign, card, certificateAlerting, certificates, dashboard, dateFormat, device, dtwins, errors, filters, firmware, input, label, layout, license, login, logs, message, notification, operation, page, pagination, parameters, pki, platform, profile, section, sectors, tab, table, truststore, unit, user, userGroup }
