import type { ApiListResult, ApplicationMgtI } from '@lxc/app-device-types'
import RestService from './rest.service'

class ApplicationMgtService extends RestService {
  private BASE_URL = '/applicationMgts'

  /**
   * Retrieve application management list
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   */
  getApplicationMgts(page: number, pageSize: number, search?: any, sort?: string): Promise<ApiListResult<ApplicationMgtI>> {
    const params = { page, pageSize, sort, search }

    return this.$api.get(this.BASE_URL, { params })
  }

  /**
   * Retrieve an application management
   * @param id
   */
  getApplicationMgtById(id: string): Promise<ApplicationMgtI> {
    return this.$api.get(`${this.BASE_URL}/${id}`)
  }

  /**
   * Generate an application management QR code
   * @param id
   */
  generateApplicationMgtQrCodeById(id: string): Promise<any> {
    return this.$api.get(`${this.BASE_URL}/${id}/qrCode`, {
      headers: { Accept: 'image/png' },
      responseType: 'arraybuffer',
    })
  }

  /**
   * Create an application management
   * @param applicationMgt
   * @returns
   */
  createApplicationMgt(applicationMgt: ApplicationMgtI) {
    return this.$api.post(`${this.BASE_URL}`, { applicationMgt })
  }

  /**
   * update an application management
   * @param applicationMgt
   * @returns
   */
  updateApplicationMgt(applicationMgt: ApplicationMgtI) {
    return this.$api.patch(`${this.BASE_URL}/${applicationMgt.id}`, { applicationMgt })
  }

  /**
   * delete an application management
   * @param applicationMgt
   */
  deleteApplicationMgt(applicationMgt: ApplicationMgtI) {
    return this.$api.delete(`${this.BASE_URL}/${applicationMgt.id}`)
  }
}

export default new ApplicationMgtService()
