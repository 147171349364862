import { AppDeviceState } from '@lxc/app-device-types'
import type { FilterOptions } from '~/types'

export interface DeviceTypeDeclinations {
  deviceDeclinations: Array<string>
}
export type DeviceTypeDeclinationMap = Record<string, DeviceTypeDeclinations>

export const deviceModelOptions: FilterOptions = {
  label: 'filters.model',
  options:
    [
      {
        value: 'S4GT',
        label: 'S4GT',
      },
      {
        value: 'S4TH',
        label: 'S4TH',
      },
      {
        value: 'S4W',
        label: 'S4W',
      },
    ],
}

export const typeOptions: FilterOptions = {
  label: 'filters.type',
  options:
    [
      {
        value: 'HPP21',
        label: 'HPP21',
      },
      {
        value: 'LPP4',
        label: 'LPP4',
      },
      {
        value: 'S4G-PL',
        label: 'S4G-PL',
      },
    ],
}

export const deviceTypeDeclinationMap: DeviceTypeDeclinationMap = {
  'HPP21': {
    deviceDeclinations: [],
  },
  'LPP4': {
    deviceDeclinations: [],
  },
  'S4G-PL': {
    deviceDeclinations: ['S4GT', 'S4TH', 'S4W'],
  },
}

export const deviceStateOptionNew = AppDeviceState.REGISTERED

export const stateOptions: FilterOptions = {
  label: 'filters.state',
  options: [
    {
      value: AppDeviceState.ACTIVATED,
      label: 'device.states.activated',
    },
    {
      value: AppDeviceState.DEACTIVATED,
      label: 'device.states.deactivated',
    },
    {
      value: deviceStateOptionNew,
      label: 'device.states.registered',
    },
  ],
}

export const connectivityOptions: FilterOptions = {
  label: 'filters.connectivity',
  options: [
    {
      value: 'Active',
      label: 'device.connectivityState.active',
    },
    {
      value: 'Inactive',
      label: 'device.connectivityState.inactive',
    },
  ],
}
