<script lang='ts' setup>
import type { UserDataI } from '@lxc/app-device-types'
import { useToggle } from '~/composables/useToggle'
import userService from '~/services/users.service'
import ILxTrashAlt from '~icons/lx/trash-alt'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import LxcError from '~/utils/LxcError'

const props = defineProps<{ user: UserDataI }>()
const emit = defineEmits(['change'])
const { t } = useI18n()
const [removeModalVisible, toggleRemoveModalVisible] = useToggle()

enum Action {
  REMOVE = 'REMOVE',
}

/**
 * When removal confirmation of the user
 * @param userId
 */
async function onRemoveUser(userId: string) {
  const removeResponse = await userService.removeUser(userId)

  if (LxcError.check(removeResponse)) {
    removeResponse.notify(NotificationKey.removeError)
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess))
    toggleRemoveModalVisible()
    emit('change')
  }
}

/**
 * When selecting an action in menu
 * @param action
 */
function onSelectAction(action: Action) {
  switch (action) {
    case Action.REMOVE:
      toggleRemoveModalVisible(true)
      break
  }
}
</script>

<template>
  <el-dropdown
    trigger="click"
    placement="left"
    @command="onSelectAction"
  >
    <el-button
      text
      size="small"
      @click.stop
    >
      <i-lx-dot-menu />
    </el-button>
    <template #dropdown>
      <el-dropdown-menu class="table-menu">
        <el-dropdown-item
          :command="Action.REMOVE"
          :icon="ILxTrashAlt"
        >
          {{ $t('user.button.delete') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>

  <lxc-confirm-modal
    :is-dialog-visible="removeModalVisible"
    :title="t('user.confirmRemove.title')"
    :description="t('user.confirmRemove.description', { userName: user?.lastName, userFirstname: user?.firstName})"
    @update:toggle-dialog="toggleRemoveModalVisible"
    @confirm="onRemoveUser(user.id)"
  />
</template>
