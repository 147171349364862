<script lang="ts" setup>
import userGroupsService from '~/services/userGroups.service'
import { useUserSession } from '~/stores/useUserSession'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import LxcMandatory from '~/components/shared/LxcMandatory.vue'
import { PATHS } from '~/constants/paths'
import LxcError from '~/utils/LxcError'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { userSession } = useUserSession()

const userGroupCode = route?.params?.code as string
const isCreate = userGroupCode === 'new'
const isLoading = ref(true)
const error = ref<LxcError | null>(null)

const userGroupForm = ref()
const userGroup = reactive({
  label: '',
  orgCode: userSession?.organisation?.code,
  sectors: [],
})
const userGroupLabel = ref('')
const userGroupFormValidationRules = {
  label: [
    { required: true, message: t('input.error.required'), trigger: 'blur' },
    { max: 50, message: t('input.error.maxLength', { maxLength: 50 }) },
  ],
}

const name = computed(() => userGroupLabel.value || 'userGroup.button.create')

onMounted(async() => await getUserGroup())

/**
 * Update or create userGroup resource
 */
async function onSaveUserGroup(): Promise<void> {
  const valid = await userGroupForm.value.validate().catch(() => false)

  if (valid) {
    const response = isCreate
      ? await userGroupsService.createUserGroup(userGroup)
      : await userGroupsService.updateUserGroup(userGroup)

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError)
    } else {
      showNotificationSuccess(t(NotificationKey.saveSuccess))
      await router.push(PATHS.USER_MANAGEMENT_GROUPS)
    }
  }
}

/**
 * Retrieve UserGroup if updating
 */
async function getUserGroup() {
  if (!isCreate) {
    const userGroupResponse = await userGroupsService.getUserGroupByCode(userGroupCode)

    if (LxcError.check(userGroupResponse)) {
      error.value = userGroupResponse
    } else {
      Object.assign(userGroup, userGroupResponse)
      userGroupLabel.value = userGroupResponse.label
    }
  }

  isLoading.value = false
}
</script>

<template>
  <el-container
    data-cy="page-userGroup-component"
    direction="vertical"
  >
    <lxc-breadcrumb :name="isLoading ? '' : name" />
    <lxc-container
      class="half-container"
      :error="error"
      :is-loading="isLoading"
    >
      <lxc-mandatory />
      <el-form
        ref="userGroupForm"
        :rules="userGroupFormValidationRules"
        :model="userGroup"
        label-position="left"
        label-width="200px"
        @submit.prevent="onSaveUserGroup"
      >
        <el-form-item
          :label="$t('input.label')"
          prop="label"
        >
          <el-input
            v-model="userGroup.label"
            data-cy="inputLabel"
            type="text"
          />
        </el-form-item>
      </el-form>

      <lxc-cancel-or-submit-buttons
        @cancel="router.back()"
        @submit="onSaveUserGroup"
      />
    </lxc-container>
  </el-container>
</template>
