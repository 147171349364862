export enum S4Types {
  TOOLS='S4G-Tools',
  MANAGER='S4-Manager',
  VIEW='S4-View',
  SG4000='SG4000',
  DVC='device',
}

export enum S4Declination {
  S4W = 'S4W',
  S4TH= 'S4TH',
  S4GT= 'S4GT',
}

export enum ConfigParam {
  POLLING= 'polling',
  DELAY='delay',
  AUTO_ACTIVATION= 'autoActivation',
}
