import type { Ref } from 'vue'
import type { PaginationContext } from '~/types/PaginationContext'

export function usePagination(item: Ref<any>) {
  const pagination: Ref<PaginationContext> = computed(() => ({
    pageCount: item.value?.context.count ?? 0,
    totalCount: item.value?.context?.totalCount ?? 0,
    currentPage: item.value?.context?.page ?? 0,
    pageSize: item.value?.context?.pageSize ?? 0,
  }))

  return {
    pagination,
  }
}
