<script setup lang='ts'>
import { DoughnutChart, useDoughnutChart } from 'vue-chart-3'
import type { ChartData, ChartOptions } from 'chart.js'
import { Chart, registerables } from 'chart.js'
import type { DashboardCampaignDetail } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import { Filters } from '~/types'
import { useDashboard } from '~/composables/useDashboard'

const props = defineProps<{
  title: string
  detail: DashboardCampaignDetail
  urlRedirect: string
}>()

const { t } = useI18n()
const router = useRouter()

const {
  dashboardExpireSoonInMonth,
} = useDashboard()

Chart.register(...registerables)
// Ovveride here the tooltip to display % after the value
Chart.overrides.doughnut.plugins.tooltip = {
  callbacks: {
    label(context) {
      if (context.parsed && context.dataset?.data !== null) {
        const total = context.dataset.data.reduce((sum, val) => sum + val, 0)
        const percent = Math.round(context.parsed * 100 / total)

        return ` : ${percent}%`
      }

      return ''
    },
    title(context) {
      return context[0] !== null ? `${context[0].label || ''} (${context[0].parsed})` : ''
    },
  },
}

const dataValues = ref([props.detail.valid, props.detail.expiredSoon, props.detail.expired])
const dataLabels = ref([t('dashboard.certificates.legend.valid'), t('dashboard.certificates.legend.expireSoon'), t('dashboard.certificates.legend.expired')])

const chartData = computed<ChartData<'doughnut'>>(() => ({
  labels: dataLabels.value,
  datasets: [
    {
      data: dataValues.value,
      backgroundColor: [
        '#41B6E6',
        '#FF6900',
        '#C4314B',
      ],
    },
  ],
}))

const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]'

const options = computed<ChartOptions<'doughnut'>>(() => ({
  responsive: true,
  rotation: 180,
  cutout: 35,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      color: '#002E5D',
      padding: {
        top: 0,
        bottom: 20,
      },
      text: props.title,
      font: {
        weight: 'bold',
        size: 14,
        family: 'Montserrat',
      },
    },
  },
  onClick(evt, item) {
    let params
    switch (item[0]?.index) {
      case 0: // Valid (> expire soon)
        params = [
          `${Filters.CERTIFICATE_EXPIRE_AFTER}=${dayjs().add(dashboardExpireSoonInMonth, 'month').hour(0).minute(0).second(0).format(dateFormat)}`,
          `${Filters.CERTIFICATE_EXPIRE_BEFORE}=${dayjs().add(10, 'year').hour(23).minute(59).second(59).format(dateFormat)}`,
        ]
        break
      case 1: // Expire soon
        params = [
          `${Filters.CERTIFICATE_EXPIRE_AFTER}=${dayjs().hour(0).minute(0).second(0).format(dateFormat)}`,
          `${Filters.CERTIFICATE_EXPIRE_BEFORE}=${dayjs().add(dashboardExpireSoonInMonth, 'month').hour(23).minute(59).second(59).format(dateFormat)}`,
        ]
        break
      case 2: // Expired
        params = [
          `${Filters.CERTIFICATE_EXPIRE_AFTER}=${dayjs().hour(0).minute(0).second(0).subtract(10, 'year').format(dateFormat)}`,
          `${Filters.CERTIFICATE_EXPIRE_BEFORE}=${dayjs().hour(23).minute(59).second(59).format(dateFormat)}`,
        ]
        break
    }

    if (params) {
      const urlRedirect = (props.urlRedirect) + (props.urlRedirect.includes('?') ? '&' : '?')
      const urlParams = params.join('&')
      router.push(`${urlRedirect}${urlParams}`)
    }
  },
}))

const { doughnutChartProps } = useDoughnutChart({
  chartData,
  options,
})

</script>

<template>
  <div class="chart-style">
    <DoughnutChart v-bind="doughnutChartProps" />
  </div>
</template>

<style lang='scss' scoped>
.chart-style {
  width: 145px;
  height: 170px;
}
</style>
