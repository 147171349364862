<script setup lang="ts">
import type { ApplicationI, CampaignConfigI, CampaignI, DeviceI, FirmwareI, LicenseStatusFeatureByDeviceTypeI, LicenseStatusI } from '@lxc/app-device-types'
import { AppDeviceState, CampaignState, CampaignType, FirmwareStatus, LicenseStatusFeatureByDeviceTypeStatus, ObjectCampaignState, ObjectCampaignType } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'
import { appModelDeclinationOptions, appModelTypeOptions, appStateOptions } from '~/components/applications/applicationList/applicationsFilters.config'
import { deviceModelOptions, stateOptions, typeOptions } from '~/components/devices/deviceList/deviceFilters.config'
import { displayColumns } from '~/components/parameters/firmwares/LxcFirmwaresList.type'
import { useApplication } from '~/composables/useApplication'
import { useDevices } from '~/composables/useDevices'
import { SearchMode } from '~/composables/useSearch'
import { PATHS } from '~/constants/paths'
import { router } from '~/plugins/router'
import campaignService from '~/services/campaign.service'
import firmwareService from '~/services/firmware.service'
import { useLicenseStatusStore } from '~/stores/useLicenseStatusStore'
import type { FilterOptions } from '~/types'
import { useUserSession } from '~/stores/useUserSession'
import { Filters } from '~/types'
import { FeaturesName } from '~/types/deviceFeatures'
import { Roles } from '~/types/roles'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import ILxPlus from '~icons/lx/plus'
import ILxcPlusCircle from '~icons/lxc/plus-circle'
import ILxcTrash2 from '~icons/lxc/trash2'

const props = defineProps<{
  canManageCampaign: boolean
  disabled?: boolean
  campaignId?: number|string
}>()

/**
 * Uses
 */
const { t } = useI18n()
const { licenseStatus } = storeToRefs(useLicenseStatusStore())
const {
  hasActionScheduled: deviceHasActionScheduled,
} = useDevices()
const {
  hasActionScheduled: applicationHasActionScheduled,
} = useApplication()
/** */

/**
 * Local variables
 */
const campaign: Ref<CampaignI | undefined | null> = ref()
const error: Ref<LxcError | undefined | null> = ref()
const showModal = ref(false)
const step = ref(1)
const isSubmitting = ref(false)
const firmwareLoading = ref(false)
const selectedDevices: Ref<Array<DeviceI>> = ref([])
const selectedApplications: Ref<Array<ApplicationI>> = ref([])
const selectedFirmware: Ref<FirmwareI | undefined | null> = ref()
const selectedCampaignType: Ref<selectedCampaignTypeEnum | undefined> = ref()
// Firmware filters and options
const rangeOptions: Ref<FilterOptions|undefined> = ref()
// Device filters and options
const deviceTypeFilters: Ref<string[]> = ref([])
const deviceTypeFilterOptions: Ref<FilterOptions|undefined> = ref()
const deviceModelFilterOptions: Ref<FilterOptions|undefined> = ref()
const deviceStateFilterOptions: Ref<FilterOptions|undefined> = ref()
// Application options
const applicationTypeFilterOptions: Ref<FilterOptions|undefined> = ref()
const applicationModelFilterOptions: Ref<FilterOptions|undefined> = ref()
const applicationStateFilterOptions: Ref<FilterOptions|undefined> = ref()
/** */

/**
 * Local interfaces
 */
interface CampaignSettingsI {
  name: string|undefined
  type: CampaignType|undefined
  firmwareUuid: string|undefined
}

interface InclusionPeriodI {
  day: string
  start: string
  end: string
}

interface CampaignPlanificationExecutionDatesI {
  plannedStartAt: Date|undefined
  plannedEndAt: Date|undefined
}

interface CampaignPlanificationInclusionPeriodsI {
  inclusionPeriods: Array<InclusionPeriodI>
}

enum selectedCampaignTypeEnum {
  FIRMWARE = 'firmware',
  CERTIFICATE = 'certificate',
}
/** */

/**
 * Forms
 */
const campaignSettingsFormRef: Ref<HTMLFormElement | undefined | null> = ref()
const campaignSettingsForm = reactive<CampaignSettingsI>({
  name: undefined,
  type: undefined,
  firmwareUuid: undefined,
})
watch(selectedFirmware, () => {
  if (selectedFirmware.value?.uuid !== campaignSettingsForm.firmwareUuid) {
    campaignSettingsForm.firmwareUuid = selectedFirmware.value?.uuid
    selectedDevices.value = []
  }
})

watch(selectedCampaignType, () => {
  if (!props.campaignId) {
    if (selectedCampaignType.value === selectedCampaignTypeEnum.FIRMWARE) {
      campaignSettingsForm.type = CampaignType.FIRMWARE_UPGRADE_DVC
    }
    // Reset selected firmware if campaing type changes to avoid filtering device list on previously selected firmware
    if (selectedCampaignType.value === selectedCampaignTypeEnum.CERTIFICATE) {
      selectedFirmware.value = undefined
    }
    // Reset selected devices or application if campaign type changes
    selectedDevices.value = []
    selectedApplications.value = []
    deviceTypeFilters.value = []
  }
})

function isSelectedFirmware(): boolean {
  if (!campaignSettingsForm.firmwareUuid) {
    showNotificationError(t('campaign.firmware.update.FirmwareSelectionRequired'))
    return false
  } else {
    return true
  }
}

const campaignPlanificationExecutionDatesFormRef: Ref<HTMLFormElement | undefined | null> = ref()
const campaignPlanificationExecutionDatesForm = reactive<CampaignPlanificationExecutionDatesI>({
  plannedStartAt: undefined,
  plannedEndAt: undefined,
})

const campaignPlanificationInclusionPeriodsFormRef: Ref<HTMLFormElement | undefined | null> = ref()
const campaignPlanificationInclusionPeriodsForm = reactive<CampaignPlanificationInclusionPeriodsI>({
  inclusionPeriods: [],
})
/** */

/**
 * Rules
 */

// Campaign settings rules
const campaignSettingsFormRules = {
  name: [
    { required: true, message: t('campaign.funnel.campaignSettings.validation.name'), whitespace: true, trigger: 'blur' },
    { max: 100, message: t('input.error.maxLength', { maxLength: 100 }), whitespace: true, trigger: 'blur' },
  ],
  type: [
    { required: true, message: t('campaign.funnel.campaignSettings.validation.type'), trigger: 'change' },
  ],
}

const userSessionStore = useUserSession()
const disabledUpdateFirmwareCheckox = computed(() => {
  return (!!campaign.value)
    || (!(userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_FIRM_UPDATE)
      && userSessionStore.userSession?.roles.includes(Roles.DVTM_FIRM_VIEW)
      && userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_VIEW)))
})
const disabledUpdateCertificateCheckox = computed(() => {
  return (!!campaign.value)
    || !((userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_CRT_RENEW)
      || userSessionStore.userSession?.roles.includes(Roles.DVTM_APP_CRT_RENEW)
    ) && userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_VIEW))
})
const disabledUpdateDeviceCertificateCheckox = computed(() => {
  return (!!campaign.value)
    || !userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_CRT_RENEW)
})
const disabledUpdateApplicationCertificateCheckox = computed(() => {
  return (!!campaign.value)
    || !userSessionStore.userSession?.roles.includes(Roles.DVTM_APP_CRT_RENEW)
})

// Possibility to edit the campaign on:
//   - creation
//   - or modification if the campaign is scheduled only
const canEditCampaign = computed(() => !campaign.value || campaign.value.state === CampaignState.SCHEDULED)

// Check if the start date has been validated
const isStartDateValidated = ref(false)
const isStartDateValid = (rule: any, value: any, callback: any) => {
  // To consider checkDate and now time without seconds
  const checkDate = new Date(value)
  checkDate.setSeconds(0)
  checkDate.setMilliseconds(0)
  const now = new Date()
  now.setSeconds(0)
  now.setMilliseconds(0)
  const expirationDate = licenseStatus.value
    ? new Date(Date.parse(licenseStatus.value.expirationDate) + parseInt(licenseStatus.value.toleranceExpirationDate) * 24 * 60 * 60 * 1000)
    : new Date()

  // Check license expiration date with tolerancy
  if (licenseStatus.value && checkDate.getTime() < expirationDate.getTime()) {
    // Make the check if user don't use "Now" button. If now button is used, the check will be true
    if (checkDate.getTime() !== now.getTime() && !isStartDateValidated.value) {
      if (canEditCampaign.value && checkDate.getTime() < now.getTime()) {
        isStartDateValidated.value = false
        callback(new Error(t('campaign.dateInferiorToToday')))
      } else {
        isStartDateValidated.value = true
        callback()
      }
    } else {
      isStartDateValidated.value = true
      callback()
    }
  } else {
    isStartDateValidated.value = false
    callback(new Error(t('campaign.funnel.campaignPlanification.executionDates.validation.startDateNotBeyondLicenseExpirationDateTolerancy')))
  }
}

// Check if the end date has been validated
const isEndDateValid = (rule: any, value: any, callback: any) => {
  const expirationDate = licenseStatus.value
    ? new Date(Date.parse(licenseStatus.value.expirationDate) + parseInt(licenseStatus.value.toleranceExpirationDate) * 24 * 60 * 60 * 1000)
    : new Date()

  if (value) {
    // Check license expiration date with tolerancy
    if (licenseStatus.value && Date.parse(value) < expirationDate.getTime()) {
      if (canEditCampaign.value) {
        // adding 24 hours to plannedStartAt to avoid plannedEndDate to be the same as plannedStartDate
        const plannedStartAtPlusOneDay = campaignPlanificationExecutionDatesForm.plannedStartAt?.getTime()
          ? campaignPlanificationExecutionDatesForm.plannedStartAt?.getTime() + (1440 * 60000)
          : ''

        if (campaignPlanificationExecutionDatesForm.plannedStartAt && value.getTime() < plannedStartAtPlusOneDay) {
          callback(new Error(t('campaign.dateInferiorToDate', { date: dayjs(new Date(plannedStartAtPlusOneDay)).format(t('campaign.dateFormat')) })))
        } else {
          callback()
        }
      } else {
        callback()
      }
    } else {
      callback(new Error(t('campaign.funnel.campaignPlanification.executionDates.validation.endDateNotBeyondLicenseExpirationDateTolerancy')))
    }
  } else {
    callback()
  }
}

// Campaign planification execution dates rules
const campaignPlanificationExecutionDatesFormRules = {
  plannedStartAt: [
    { type: 'date', required: true, message: t('campaign.funnel.campaignPlanification.executionDates.validation.plannedStartAt'), trigger: 'update:model-value' },
    { validator: isStartDateValid, trigger: 'update:model-value' },
  ],
  plannedEndAt: [
    { type: 'date', required: true, message: t('campaign.funnel.campaignPlanification.executionDates.validation.plannedEndAt'), trigger: 'update:model-value' },
    { validator: isEndDateValid, trigger: 'change' },
  ],
}

const disabledEndDate = computed(() => dayjs(campaignPlanificationExecutionDatesForm.plannedStartAt).add(1, 'days').toDate())

// Validate that the span time of an inclusion period slot is at least 15 minutes
const campaignPlanificationInclusionPeriodsstartValidator = (rule: any, value: any, callback: any) => {
  // Get index of the start field
  const index = rule.field.split('[')[1].split(']')[0]
  // Set the the value of start field in milliseconds
  const [valueHour, valueMinutes] = value.split(':')
  const valueMilliseconds = new Date().setHours(valueHour, valueMinutes)
  // Get the value of the end and set in milliseconds
  const [endHour, endMinutes] = campaignPlanificationInclusionPeriodsForm.inclusionPeriods[index].end.split(':')
  const endMilliseconds = new Date().setHours(parseInt(endHour), parseInt(endMinutes))

  if ((valueMilliseconds + (15 * 60000)) > endMilliseconds) {
    callback(new Error(t('campaign.funnel.campaignPlanification.inclusionPeriods.validation.range')))
  } else {
    callback()
  }
}

// Validate that the span time of an inclusion period slot is at least 15 minutes
const campaignPlanificationInclusionPeriodsEndValidator = (rule: any, value: any, callback: any) => {
  // Get index of the end field
  const index = rule.field.split('[')[1].split(']')[0]
  // Set the the value of end field in milliseconds
  const [valueHour, valueMinutes] = value.split(':')
  const valueMilliseconds = new Date().setHours(valueHour, valueMinutes)
  // Get the value of the start and set in milliseconds
  const [startHour, startMinutes] = campaignPlanificationInclusionPeriodsForm.inclusionPeriods[index].start.split(':')
  const startMilliseconds = new Date().setHours(parseInt(startHour), parseInt(startMinutes))

  if (valueMilliseconds < (startMilliseconds + (15 * 60000))) {
    callback(new Error(t('campaign.funnel.campaignPlanification.inclusionPeriods.validation.range')))
  } else {
    callback()
  }
}

// Campaign planification inclusion periods rules
const campaignPlanificationInclusionPeriodsFormRules = computed(() => {
  const campaignPlanificationInclusionPeriodsFormRules: Record<string, Array<any>> = {}

  for (const index in campaignPlanificationInclusionPeriodsForm.inclusionPeriods) {
    campaignPlanificationInclusionPeriodsFormRules[`inclusionPeriods[${index}].day`] = [
      { type: 'string', required: true, message: t('campaign.funnel.campaignPlanification.inclusionPeriods.validation.day'), trigger: 'update:model-value' },
    ]
    campaignPlanificationInclusionPeriodsFormRules[`inclusionPeriods[${index}].start`] = [
      { type: 'string', required: true, message: t('campaign.funnel.campaignPlanification.inclusionPeriods.validation.start'), trigger: 'update:model-value' },
      { validator: campaignPlanificationInclusionPeriodsstartValidator, trigger: 'update:model-value' },
    ]
    campaignPlanificationInclusionPeriodsFormRules[`inclusionPeriods[${index}].end`] = [
      { type: 'string', required: true, message: t('campaign.funnel.campaignPlanification.inclusionPeriods.validation.end'), trigger: 'update:model-value' },
      { validator: campaignPlanificationInclusionPeriodsEndValidator, trigger: 'update:model-value' },
    ]
  }

  return campaignPlanificationInclusionPeriodsFormRules
})

// Validate form depending on current step
async function isFormValid() {
  switch (step.value) {
    case 1:
      if (campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC) {
        return await campaignSettingsFormRef.value?.validate().catch((_: any) => false) && isSelectedFirmware()
      } else {
        return await campaignSettingsFormRef.value?.validate().catch((_: any) => false)
      }
    case 2:
      if (selectedDevices.value.length === 0 && selectedApplications.value.length === 0) {
        showNotificationError(t('campaign.funnel.fleetSelection.validation.required'))
        return false
      } else {
        return true
      }
    case 3: {
      return await campaignPlanificationExecutionDatesFormRef.value?.validate().catch((_: any) => false)
        & await campaignPlanificationInclusionPeriodsFormRef.value?.validate().catch((_: any) => false)
    }
  }
}
/** */

/**
 * Get campaign if ID is define in URL
 */
onMounted(async() => {
  if (props.campaignId) {
    const response = await campaignService.getCampaign(props.campaignId.toString())

    if (LxcError.check(response)) {
      error.value = response
    } else {
      campaign.value = response

      // Set campaign settings
      campaignSettingsForm.name = response.config?.name ?? ''
      campaignSettingsForm.type = response.type
      if (campaignSettingsForm.type !== CampaignType.FIRMWARE_UPGRADE_DVC) {
        selectedCampaignType.value = selectedCampaignTypeEnum.CERTIFICATE
      } else {
        selectedCampaignType.value = selectedCampaignTypeEnum.FIRMWARE
      }

      switch (campaignSettingsForm.type) {
        case CampaignType.FIRMWARE_UPGRADE_DVC:
          campaignSettingsForm.firmwareUuid = response.config?.firmwareUpgrade?.uuid
          getFirmware(campaignSettingsForm.firmwareUuid)
        // eslint-disable-next-line no-fallthrough
        case CampaignType.CRTCLT_RENEWAL_DVC:
          selectedDevices.value = (response.objects ?? []).filter(object => object.state === ObjectCampaignState.SCOPED).map((object) => {
            return {
              id: object.id,
              name: object.label,
              model: {
                type: object.deviceType,
              },
            }
          })
          break
        case CampaignType.CRTCLT_RENEWAL_APP:
          selectedApplications.value = (response.objects ?? []).filter(object => object.state === ObjectCampaignState.SCOPED).map((object) => {
            return {
              id: object.id,
              name: object.label,
              model: {
                type: object.deviceType,
              },
            }
          })
          break
      }

      // Set planned dates
      if (response.config?.plannedStartAt) {
        campaignPlanificationExecutionDatesForm.plannedStartAt = new Date(response.config?.plannedStartAt)
      }
      if (response.config?.plannedEndAt) {
        campaignPlanificationExecutionDatesForm.plannedEndAt = new Date(response.config?.plannedEndAt)
      }

      // Set inclusion periods
      if (response.config?.inclusionPeriods) {
        campaignPlanificationInclusionPeriodsForm.inclusionPeriods = response.config?.inclusionPeriods.map((inclusionPeriod) => {
          return {
            day: inclusionPeriod.day,
            start: inclusionPeriod.startTimeSlot ? inclusionPeriod.startTimeSlot : '',
            end: inclusionPeriod.endTimeSlot ? inclusionPeriod.endTimeSlot : '',
          }
        })
      }
    }
  }
})
/** */

/** Get Firmware if Editing firmware update campaign */
const firmwareError: Ref<LxcError|null> = ref(null)
async function getFirmware(firmwareUuid?: string) {
  if (firmwareUuid) {
    // Get the selected firmware infos
    const response = await firmwareService.getFirmwareByUuid(firmwareUuid)

    if (LxcError.check(response)) {
      firmwareError.value = response
    } else {
      selectedFirmware.value = response
    }
  } else {
    selectedFirmware.value = null
  }
}

/** Funnel steps */
const showConfirm = ref(false)
// Go to the next steps
const next = async() => {
  if (await isFormValid()) {
    if (step.value === 1) {
      // Reset selected devices or application if campaign type changes
      if (campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_DVC) {
        selectedApplications.value = []
      }
      if (campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_APP) {
        selectedDevices.value = []
      }
    }
    // Check and display confirm if the selected equipments has actions scheduled, continue if not
    let found = false
    if (step.value === 2) {
      const campaignType = campaignSettingsForm.type
      switch (campaignType) {
        case CampaignType.CRTCLT_RENEWAL_DVC:
        case CampaignType.FIRMWARE_UPGRADE_DVC:
          found = !!selectedDevices.value.find((selectedDevice: DeviceI) => deviceHasActionScheduled(selectedDevice, campaignType) === true)
          break
        case CampaignType.CRTCLT_RENEWAL_APP:
          found = !!selectedApplications.value.find((selectedApplication: ApplicationI) => applicationHasActionScheduled(selectedApplication, campaignType) === true)
          break
      }
      if (found) {
        showConfirm.value = true
      } else {
        step.value++
      }
    } else {
      step.value++
    }
  }
}

// Return to previous steps
const previous = () => {
  if (step.value > 0) {
    step.value--
  }
}
/** */

/**
 * Inclusion periods
 */
function addInclusionPeriod() {
  campaignPlanificationInclusionPeriodsForm.inclusionPeriods.push({
    day: '',
    start: '',
    end: '',
  })
}

function removeInclusionPeriod(index: number) {
  campaignPlanificationInclusionPeriodsForm.inclusionPeriods.splice(index, 1)
}

const inclusionPeriodsOptions = [
  {
    value: 'MONDAY',
    label: t('campaign.funnel.campaignPlanification.inclusionPeriods.days.monday'),
  },
  {
    value: 'TUESDAY',
    label: t('campaign.funnel.campaignPlanification.inclusionPeriods.days.tuesday'),
  },
  {
    value: 'WEDNESDAY',
    label: t('campaign.funnel.campaignPlanification.inclusionPeriods.days.wednesday'),
  },
  {
    value: 'THURSDAY',
    label: t('campaign.funnel.campaignPlanification.inclusionPeriods.days.thursday'),
  },
  {
    value: 'FRIDAY',
    label: t('campaign.funnel.campaignPlanification.inclusionPeriods.days.friday'),
  },
  {
    value: 'SATURDAY',
    label: t('campaign.funnel.campaignPlanification.inclusionPeriods.days.saturday'),
  },
  {
    value: 'SUNDAY',
    label: t('campaign.funnel.campaignPlanification.inclusionPeriods.days.sunday'),
  },
]
/** */

async function onSubmit() {
  if (await isFormValid()) {
    if (campaignSettingsForm.type) {
      isSubmitting.value = true
      const plannedStartAt = campaignPlanificationExecutionDatesForm.plannedStartAt ? campaignPlanificationExecutionDatesForm.plannedStartAt.toISOString() : ''
      const plannedEndAt = campaignPlanificationExecutionDatesForm.plannedEndAt ? campaignPlanificationExecutionDatesForm.plannedEndAt.toISOString() : ''

      let config: CampaignConfigI | undefined
      let objects: any = []

      switch (campaignSettingsForm.type) {
        case CampaignType.FIRMWARE_UPGRADE_DVC:
          config = {
            name: campaignSettingsForm.name,
            plannedStartAt,
            plannedEndAt,
            firmwareUpgrade: {
              uuid: campaignSettingsForm.firmwareUuid,
            },
          }

          objects = selectedDevices.value.map((device) => {
            return {
              id: device.id,
              type: ObjectCampaignType.DVC,
              label: device.name,
              deviceType: device.model?.type,
            }
          })
          break
        case CampaignType.CRTCLT_RENEWAL_DVC:
          config = {
            name: campaignSettingsForm.name,
            plannedStartAt,
            plannedEndAt,
          }

          objects = selectedDevices.value.map((device) => {
            return {
              id: device.id,
              type: ObjectCampaignType.DVC,
              label: device.name,
              deviceType: device.model?.type,
            }
          })
          break
        case CampaignType.CRTCLT_RENEWAL_APP:
          config = {
            name: campaignSettingsForm.name,
            plannedStartAt,
            plannedEndAt,
          }

          objects = selectedApplications.value.map((device) => {
            return {
              id: device.id,
              type: ObjectCampaignType.APP,
              label: device.name,
              deviceType: device.model?.type === 'S4G-Tools' ? 'S4-Tools' : device.model?.type, // Temporary fix
            }
          })
          break
      }

      if (campaignPlanificationInclusionPeriodsForm.inclusionPeriods.length > 0) {
        // Add inclusion periods
        config.inclusionPeriods = campaignPlanificationInclusionPeriodsForm.inclusionPeriods.map((inclusionPeriod: InclusionPeriodI) => {
          return {
            day: inclusionPeriod.day,
            startTimeSlot: inclusionPeriod.start,
            endTimeSlot: inclusionPeriod.end,
          }
        })
      }

      // Update the campaign
      if (campaign.value) {
        const campaignToUpdate: CampaignI = {
          id: campaign.value.id,
          type: campaignSettingsForm.type,
          objects,
        }

        // Add config only if the campaign is not started
        if (campaign.value.state === CampaignState.SCHEDULED) {
          campaignToUpdate.config = config
        }

        const response = await campaignService.updateCampaign(campaignToUpdate)

        if (LxcError.check(response)) {
          response.notify(NotificationKey.saveError)
        } else {
          showNotificationSuccess(t(NotificationKey.saveSuccess))
          router.push(`${PATHS.CAMPAIGNS}/${campaign.value.id}/${PATHS.DETAILS_SUBPATH}`)
          showModal.value = false
        }
      }
      // Create a campaign
      else {
        const response = await campaignService.createCampaign({
          type: campaignSettingsForm.type,
          config,
          objects,
        })

        if (LxcError.check(response)) {
          response.notify(NotificationKey.saveError)
        } else {
          showNotificationSuccess(t(NotificationKey.saveSuccess))
          router.push(`${PATHS.CAMPAIGNS}/${response.id}/${PATHS.DETAILS_SUBPATH}`)
        }
      }

      isSubmitting.value = false
    }
  }
}

/**
 * Filter devices if beyond the limit of the license
 */
function filterDevices(status?: LicenseStatusI | null) {
  deviceTypeFilters.value = []
  const devicesAdvancedFleetManagementFeature: LicenseStatusFeatureByDeviceTypeI[]|undefined = status?.featureByDeviceTypes.filter((feature) => {
    return feature.name === FeaturesName.ADVANCED_FLEET_MGT
  })
  devicesAdvancedFleetManagementFeature?.forEach((deviceType) => {
    if (deviceType.status !== LicenseStatusFeatureByDeviceTypeStatus.SUSPENDED_DEVICE && deviceType.status !== LicenseStatusFeatureByDeviceTypeStatus.NOT_ACTIVATED) {
      if (deviceType.deviceType === 'S4-Tools') { // Temporary fix
        deviceTypeFilters.value.push('S4G-Tools')
      } else {
        deviceTypeFilters.value.push(deviceType.deviceType)
      }
    }
  })
}

function buildFilterOptions(filterOptions: FilterOptions, isDisabled?: boolean): FilterOptions {
  return {
    label: filterOptions.label,
    options: filterOptions.options.map((option) => {
      return {
        label: option.label,
        value: option.value,
        disabled: isDisabled ?? isDisabledOption(option.value),
      }
    }),
  }
}

function isDisabledOption(optionValue: string): boolean {
  return !deviceTypeFilters.value.includes(optionValue)
}

/**
 * List filters
 */
const firmwaresListDefaultFilters = computed(() => {
  filterDevices(licenseStatus.value)
  rangeOptions.value = buildFilterOptions(typeOptions)
  return new Map<Filters, any>([
    [Filters.STATUS, [FirmwareStatus.ACTIVATED]],
    [Filters.RANGE, deviceTypeFilters.value],
  ])
})

const devicesListDefaultFilters = computed(() => {
  filterDevices(licenseStatus.value)
  const isFirmwareUpdate: boolean|undefined = campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC ? true : undefined
  deviceTypeFilterOptions.value = buildFilterOptions(typeOptions, isFirmwareUpdate)
  deviceModelFilterOptions.value = buildFilterOptions(deviceModelOptions, true)
  deviceStateFilterOptions.value = buildFilterOptions(stateOptions, true)
  return new Map<Filters, any>([
    [Filters.STATE, [AppDeviceState.ACTIVATED]],
    [Filters.MODEL_TYPE, selectedFirmware.value?.range ? [selectedFirmware.value?.range] : deviceTypeFilters.value],
    [Filters.MODEL_DECLINATION, selectedFirmware.value?.declination ? [selectedFirmware.value?.declination] : []],
    [Filters.DEVICE_FIRMWARE_VERSIONS, selectedFirmware.value?.firmwareVersions],
    [Filters.DEVICE_HARDWARE_VERSIONS, selectedFirmware.value?.hardwareVersions],
  ])
})

const applicationsListDefaultFilters = computed(() => {
  filterDevices(licenseStatus.value)
  applicationTypeFilterOptions.value = buildFilterOptions(appModelTypeOptions)
  applicationModelFilterOptions.value = buildFilterOptions(appModelDeclinationOptions, true)
  applicationStateFilterOptions.value = buildFilterOptions(appStateOptions, true)
  return new Map<Filters, any>([
    [Filters.STATE, [AppDeviceState.ACTIVATED]],
    [Filters.MODEL_TYPE, deviceTypeFilters.value],
  ])
})

const statusOptions: FilterOptions = {
  label: t('firmware.filters.status.label'),
  options: Object.values(FirmwareStatus).map((value) => {
    const label = value ? t(`firmware.status.${value}`) : ''
    return {
      value,
      label,
      disabled: true,
    }
  }),
}
</script>

<template>
  <lxc-button
    v-if="canManageCampaign"
    :icon="ILxPlus"
    size="large"
    type="primary"
    :title="campaignId ? $t('campaign.details.buttons.edit.label') : $t('campaign.newCampaign')"
    :disabled="disabled"
    @click="showModal = true"
  >
    {{ campaignId ? $t('campaign.details.buttons.edit.label') : $t('campaign.newCampaign') }}
  </lxc-button>
  <lxc-common-modal
    :show="showModal"
    is-full-screen
    with-steps
    :step="step"
    :number-of-steps="3"
    @close="showModal = false"
  >
    <template #header>
      <h2
        v-if="step === 1"
      >
        {{ campaignId ? $t('campaign.funnel.updateCampaign') : $t('campaign.funnel.newCampaign') }}
      </h2>
      <h2
        v-if="step === 2"
      >
        {{ $t('campaign.funnel.fleetSelection.title') }}
      </h2>
      <h2
        v-if="step === 3"
      >
        {{ $t('campaign.funnel.planification') }}
      </h2>
      <p>{{ $t('campaign.funnel.step') }} {{ step }} {{ $t('campaign.funnel.of') }} 3</p>
      <lxc-button
        v-if="step > 1"
        :title="$t('campaign.funnel.previous')"
        type="tertiary"
        class="w-11 h-11 absolute top-1/2 -translate-y-1/2 left-8"
        @click="previous"
      >
        <i-lxc-arrow-left />
      </lxc-button>
    </template>
    <template #body>
      <div class="mt-14">
        <!-- Campaign settings step -->
        <div
          v-if="step === 1"
        >
          <lxc-form
            ref="campaignSettingsFormRef"
            :model="campaignSettingsForm"
            :rules="campaignSettingsFormRules"
          >
            <h4 class="mb-10">
              {{ $t('campaign.funnel.campaignSettings.title') }}
            </h4>
            <lxc-form-item
              :label="$t('campaign.funnel.campaignSettings.name')"
              class="w-80 mb-6"
              prop="name"
            >
              <lxc-input
                v-model="campaignSettingsForm.name"
                type="text"
                placeholder="Name"
                :disabled="!canEditCampaign"
              />
            </lxc-form-item>
            <lxc-form-item
              :label="$t('campaign.funnel.campaignSettings.typeOfCampaign.title')"
              display-label
              prop="type"
            >
              <div class="flex gap-6">
                <div
                  :class="`py-2 rounded-xl px-4 min-w-[12.6875rem] ${selectedCampaignType === selectedCampaignTypeEnum.FIRMWARE ? 'bg-primary-50 border-2 border-primary-600' : 'bg-white border border-gray-300'}`"
                >
                  <lxc-checkbox
                    v-model="selectedCampaignType"
                    :value="selectedCampaignTypeEnum.FIRMWARE"
                    class="mt-[0.375rem]"
                    :label="$t('campaign.funnel.campaignSettings.typeOfCampaign.firmwareUpdate.label')"
                    :disabled="disabledUpdateFirmwareCheckox"
                  />
                </div>
                <div
                  :class="`py-2 rounded-xl px-4 min-w-[12.6875rem] ${selectedCampaignType === selectedCampaignTypeEnum.CERTIFICATE ? 'bg-primary-50 border-2 border-primary-600' : 'bg-white border border-gray-300'}`"
                >
                  <lxc-checkbox
                    v-model="selectedCampaignType"
                    :value="selectedCampaignTypeEnum.CERTIFICATE"
                    class="mt-[0.375rem]"
                    :label="$t('campaign.funnel.campaignSettings.typeOfCampaign.certificate')"
                    :disabled="disabledUpdateCertificateCheckox"
                  />
                </div>
              </div>
              <div
                v-if="selectedCampaignType === selectedCampaignTypeEnum.CERTIFICATE"
                class="mt-4"
              >
                <hr>
                <div class="mt-4 flex gap-6">
                  <div
                    :class="`py-2 rounded-xl px-4 ${campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_DVC ? 'bg-primary-50 border-2 border-primary-600' : 'bg-white border border-gray-300'}`"
                  >
                    <lxc-checkbox
                      v-model="campaignSettingsForm.type"
                      :value="CampaignType.CRTCLT_RENEWAL_DVC"
                      class="mt-[0.375rem]"
                      :label="$t('campaign.funnel.campaignSettings.typeOfCampaign.deviceCertificateRenewal')"
                      :disabled="disabledUpdateDeviceCertificateCheckox"
                    />
                  </div>
                  <div
                    :class="`py-2 rounded-xl px-4 ${campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_APP ? 'bg-primary-50 border-2 border-primary-600' : 'bg-white border border-gray-300'}`"
                  >
                    <lxc-checkbox
                      v-model="campaignSettingsForm.type"
                      :value="CampaignType.CRTCLT_RENEWAL_APP"
                      class="mt-[0.375rem]"
                      :label="$t('campaign.funnel.campaignSettings.typeOfCampaign.applicationCertificateRenewal')"
                      :disabled="disabledUpdateApplicationCertificateCheckox"
                    />
                  </div>
                </div>
              </div>
            </lxc-form-item>

            <div
              v-if="selectedCampaignType === selectedCampaignTypeEnum.FIRMWARE"
              class="mt-4"
            >
              <hr>
              <lxc-container
                :is-loading="firmwareLoading"
                :error="firmwareError"
                :px="0"
              >
                <div class="mt-4">
                  <lxc-alert
                    type="warning"
                  >
                    <span
                      class="break-normal"
                    >
                      {{ $t('campaign.firmware.update.warningMessage') }}
                    </span>
                  </lxc-alert>
                </div>
                <div
                  class="mt-4"
                >
                  <p class="text-gray-800 dark:text-white pb-[0.750rem]">
                    {{ $t('campaign.funnel.campaignSettings.typeOfCampaign.firmwareUpdate.title') }}
                  </p>
                  <div
                    v-if="!campaign || campaign?.state === CampaignState.SCHEDULED"
                  >
                    <p
                      class="flex opacity-70 text-sm"
                    >
                      <i-lx-round-info class="mr-2 w-5" />
                      <span class="self-center">{{ $t('campaign.firmware.update.firmwareSelection') }}</span>
                    </p>
                    <lxc-firmwares-list
                      v-model:selected-firmware="selectedFirmware"
                      no-action
                      selectable
                      :columns="[displayColumns.NAME, displayColumns.VERSION, displayColumns.RANGE, displayColumns.DECLINATION]"
                      :search-mode="SearchMode.FILTER_SEARCH"
                      :default-filters="firmwaresListDefaultFilters"
                      :status-options="statusOptions"
                      :range-options="rangeOptions"
                      display-info
                    />
                  </div>
                  <div
                    v-else
                  >
                    <lxc-firmware-info
                      v-model:firmware="selectedFirmware"
                    />
                  </div>
                </div>
              </lxc-container>
            </div>
          </lxc-form>
        </div>
        <!-- Equipment fleet selection step -->
        <div
          v-if="step === 2"
        >
          <lxc-devices-list
            v-if="campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC || campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_DVC"
            v-model:selected-devices="selectedDevices"
            no-action
            :default-filters="devicesListDefaultFilters"
            :campaign-type="campaignSettingsForm.type"
            :search-mode="SearchMode.FILTER_SEARCH"
            :type-options="deviceTypeFilterOptions"
            :model-options="deviceModelFilterOptions"
            :state-options="deviceStateFilterOptions"
          />
          <lxc-applications-list
            v-if="campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_APP"
            v-model:selected-applications="selectedApplications"
            no-action
            :default-filters="applicationsListDefaultFilters"
            :campaign-type="campaignSettingsForm.type"
            :search-mode="SearchMode.FILTER_SEARCH"
            :type-options="applicationTypeFilterOptions"
            :model-options="applicationModelFilterOptions"
            :state-options="applicationStateFilterOptions"
          />
          <lxc-campaign-funnel-confirm
            :show-modal="showConfirm"
            @close="showConfirm = false"
            @decline="showConfirm = false"
            @confirm="() => {showConfirm = false; step++}"
          />
        </div>
        <!-- Campaign planification step -->
        <div
          v-if="step === 3"
        >
          <h4 class="mb-10">
            {{ $t('campaign.funnel.campaignPlanification.title') }}
          </h4>

          <lxc-form
            ref="campaignPlanificationExecutionDatesFormRef"
            :model="campaignPlanificationExecutionDatesForm"
            :rules="campaignPlanificationExecutionDatesFormRules"
          >
            <div
              class="flex gap-4"
            >
              <lxc-form-item
                :label="$t('campaign.funnel.campaignPlanification.executionDates.plannedStartAt')"
                prop="plannedStartAt"
              >
                <lxc-input
                  v-model="campaignPlanificationExecutionDatesForm.plannedStartAt"
                  type="datetime"
                  class="!h-[2.625rem]"
                  prefix-read-only
                  :disabled="!canEditCampaign"
                  :prefix-disabled="!canEditCampaign"
                  :min-date="new Date()"
                  @update:model-value="() => isStartDateValidated = false"
                >
                  <template #prefix>
                    <span class="text-gray-700">
                      <i-lxc-light-calendar
                        width="1.25rem"
                        height="1.25rem"
                      />
                    </span>
                  </template>
                </lxc-input>
              </lxc-form-item>
              <lxc-form-item
                :label="$t('campaign.funnel.campaignPlanification.executionDates.endDate')"
                prop="plannedEndAt"
              >
                <lxc-input
                  v-model="campaignPlanificationExecutionDatesForm.plannedEndAt"
                  type="datetime"
                  class="!h-[2.625rem]"
                  prefix-read-only
                  :disabled="!canEditCampaign"
                  :prefix-disabled="!canEditCampaign"
                  :min-date="disabledEndDate"
                >
                  <template #prefix>
                    <span class="text-gray-700">
                      <i-lxc-light-calendar
                        width="1.25rem"
                        height="1.25rem"
                      />
                    </span>
                  </template>
                </lxc-input>
              </lxc-form-item>
            </div>
          </lxc-form>

          <div class="mt-6">
            <div class="flex justify-between w-1/2">
              <h4 class="mb-6">
                {{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.title') }}
              </h4>
              <p class="text-black/50 font-semibold">
                {{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.subTitle') }}
              </p>
            </div>
            <lxc-card
              class="w-1/2"
              body-class="!p-4"
            >
              <lxc-information
                class="!my-0 text-gray-600"
              >
                <p
                  class="!mt-0"
                >
                  {{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.informations.period') }}
                </p>
                <p
                  class="!mb-0"
                >
                  {{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.informations.defaultValue') }}
                </p>
              </lxc-information>
            </lxc-card>
            <!-- ! To be done later: add CTA on lxc-tag -->
            <!-- <div class="mt-4 flex gap-3">
              <lxc-tag>
                <span class="text-sm">{{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.informations.businessHour') }}</span>
              </lxc-tag>
              <lxc-tag>
                <span class="text-sm">{{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.informations.weekendHour') }}</span>
              </lxc-tag>
            </div> -->
            <lxc-form
              ref="campaignPlanificationInclusionPeriodsFormRef"
              :model="campaignPlanificationInclusionPeriodsForm"
              :rules="campaignPlanificationInclusionPeriodsFormRules"
            >
              <div
                v-if="canEditCampaign && campaignPlanificationInclusionPeriodsForm.inclusionPeriods.length === 0"
                class="w-1/2 border rounded-lg border-gray-400 p-4 mt-4"
                @click="addInclusionPeriod"
              >
                <p
                  class="text-gray-400 flex gap-4 hover:cursor-pointer my-0"
                >
                  <i-lxc-light-plus-circle />
                  <span class="font-semibold self-center">{{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.addADay') }}</span>
                </p>
              </div>
              <div
                v-for="(inclusionPeriod, index) in campaignPlanificationInclusionPeriodsForm.inclusionPeriods"
                :key="inclusionPeriod.day + index"
              >
                <div
                  class="w-1/2 mt-4 bg-primary-50 py-2 px-4 rounded-lg border-2 border-primary-50 flex gap-4
                    focus:border-2 focus:border-primary-600 focus-within:border-2 focus-within:border-primary-600
                    hover:border-2 hover:border-primary-600"
                  tabindex="0"
                >
                  <lxc-form-item
                    class="!pb-0"
                    :prop="`inclusionPeriods[${index}].day`"
                  >
                    <lxc-select
                      v-model="inclusionPeriod.day"
                      :placeholder="$t('campaign.funnel.campaignPlanification.inclusionPeriods.selectPlaceholder')"
                      :disabled="!canEditCampaign"
                      :prefix-disabled="!canEditCampaign"
                      prefix-read-only
                    >
                      <template #prefix>
                        <span class="text-gray-500">
                          <i-lxc-light-calendar />
                        </span>
                      </template>
                      <lxc-option
                        v-for="inclusionPeriodOption in inclusionPeriodsOptions"
                        :key="inclusionPeriodOption.value"
                        class="pl-14"
                        :label="inclusionPeriodOption.label"
                        :value="inclusionPeriodOption.value"
                      />
                    </lxc-select>
                  </lxc-form-item>
                  <div class="flex gap-2 max-w-[15.625rem]">
                    <p
                      class="text-gray-600 font-semibold"
                    >
                      {{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.start') }}
                    </p>
                    <lxc-form-item
                      :prop="`inclusionPeriods[${index}].start`"
                      class="!pb-0"
                    >
                      <lxc-input
                        v-model="inclusionPeriod.start"
                        type="time"
                        placeholder="HH:MM"
                        prefix-read-only
                        prefix-class="!py-0 !top-1/2 -translate-y-1/2"
                        :prefix-disabled="!canEditCampaign"
                        :disabled="!canEditCampaign"
                        @blur.prevent
                      >
                        <template #prefix>
                          <span class="text-gray-700">
                            <i-lxc-light-clock
                              width="1.25rem"
                              height="1.25rem"
                            />
                          </span>
                        </template>
                      </lxc-input>
                    </lxc-form-item>
                  </div>
                  <div class="flex gap-2 max-w-[15.625rem]">
                    <p
                      class="text-gray-600 font-semibold"
                    >
                      {{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.end') }}
                    </p>
                    <lxc-form-item
                      :prop="`inclusionPeriods[${index}].end`"
                      class="!pb-0"
                    >
                      <lxc-input
                        v-model="inclusionPeriod.end"
                        type="time"
                        class="max-w-[15.625rem]"
                        placeholder="HH:MM"
                        prefix-read-only
                        prefix-class="!py-0 !top-1/2 -translate-y-1/2"
                        :prefix-disabled="!canEditCampaign"
                        :disabled="!canEditCampaign"
                        @blur.prevent
                      >
                        <template #prefix>
                          <span class="text-gray-700">
                            <i-lxc-light-clock
                              width="1.25rem"
                              height="1.25rem"
                            />
                          </span>
                        </template>
                      </lxc-input>
                    </lxc-form-item>
                  </div>
                  <lxc-button
                    v-if="canEditCampaign"
                    type="borderless"
                    :icon="ILxcTrash2"
                    class="!py-0"
                    :title="$t('campaign.funnel.campaignPlanification.inclusionPeriods.delete')"
                    @click="removeInclusionPeriod(index)"
                    @click.prevent
                  />
                </div>
              </div>
            </lxc-form>
            <lxc-button
              v-if="campaignPlanificationInclusionPeriodsForm.inclusionPeriods.length > 0
                && campaignPlanificationInclusionPeriodsForm.inclusionPeriods.length < 20
                && canEditCampaign"
              type="tertiary"
              :title="$t('campaign.funnel.campaignPlanification.inclusionPeriods.add')"
              :icon="ILxcPlusCircle"
              class="mt-3"
              @click="addInclusionPeriod"
            >
              <span class="font-semibold self-center">{{ $t('campaign.funnel.campaignPlanification.inclusionPeriods.add') }}</span>
            </lxc-button>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <lxc-button
        v-if="step < 3"
        :title="$t('campaign.funnel.next')"
        type="primary"
        @click="next"
      >
        {{ $t('campaign.funnel.next') }}
      </lxc-button>
      <lxc-button
        v-if="step === 3"
        :title="campaignId ? $t('campaign.funnel.updateCampaign') : $t('campaign.funnel.publish')"
        type="primary"
        :disabled="isSubmitting"
        @click="onSubmit"
      >
        {{ campaignId ? $t('campaign.funnel.updateCampaign') : $t('campaign.funnel.publish') }}
      </lxc-button>
    </template>
  </lxc-common-modal>
</template>
