<script setup lang="ts">
import type { FilterSelectionDefinition } from '~/types'

const props = defineProps<{
  searchQuery?: string | FilterSelectionDefinition
  searchPlaceholder: string
  class?: string | string[]
}>()
const emit = defineEmits(['search', 'clear', 'inputChange'])

const searchInputValue = computed<string>({
  get(): string {
    if (props.searchQuery) {
      return (typeof props.searchQuery === 'string'
        ? props.searchQuery
        : (props.searchQuery).value as string)
    } else {
      return ''
    }
  },
  set(input?: string) {
    emit('inputChange', input)
  },
})
</script>

<template>
  <lxc-form
    class="block"
    @submit.prevent="$emit('search', $event)"
  >
    <lxc-search-input
      v-model="searchInputValue"
      :placeholder="searchPlaceholder"
      class="grow shrink basis-0 mb-4"
      :filter-search-txt-min-length="0"
    />
  </lxc-form>
  <slot />
</template>
