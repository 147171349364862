<script setup lang="ts">
import { saveAs } from 'file-saver'
import type { UserDataI } from '@lxc/app-device-types'
import { Download } from '@element-plus/icons-vue'
import userCertificateService from '~/services/userCertificate.service'
import LxcError from '~/utils/LxcError'
import { NotificationKey } from '~/utils/notifications-tools'
const { t } = useI18n()
const isLoading = ref(false)
const props = defineProps<{
  user: UserDataI | null
}>()
async function downloadCertificate() {
  isLoading.value = true
  if (props.user && props.user?.id) {
    const userCertificateResponse: BlobPart | Error = await userCertificateService.downloadUserCertificate(props.user?.id)

    if (LxcError.check(userCertificateResponse)) {
      userCertificateResponse.notify(NotificationKey.error)
    } else {
      const blob = new Blob([userCertificateResponse as BlobPart], { type: 'application/x-pkcs12' })
      const fileName = `certificate-${props.user?.login}.p12`
      saveAs(blob, fileName)
    }

    isLoading.value = false
  }
}
</script>

<template>
  <el-button
    plain
    class="lxc-plain"
    :icon="Download"
    :disabled="isLoading"
    :title="t('button.download')"
    @click="downloadCertificate"
  >
    <lxc-loader
      v-if="isLoading"
      :width="25"
      class="loader"
    />
  </el-button>
</template>

<style lang="scss" scoped>
.loader{
  margin-left: .6em;
}

button {
  :deep i {
    &[class*=el-icon] {

      + span {
        margin-left: 0;
      }
    }
  }
}

</style>
