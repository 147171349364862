<script setup lang="ts">
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'
import ILxcDownload from '~icons/lxc/download'
import ILxUpload from '~icons/lx/upload'
import ILxSearch from '~icons/lx/search'
import ILxcPlus from '~icons/lxc/plus'
import ILxCalendar from '~icons/lx/calendar'
import ILxTablet from '~icons/lx/tablet'
import ILxAlert from '~icons/lx/alert'
import ILxXCircle from '~icons/lx/x-circle'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import licenseService from '~/services/license.service'
import LxcError from '~/utils/LxcError'
import { LicenseI, LicenseFeatureI, LicenseFeatureCriteriaI, LicenseStatusI, LicenseStatusFeatureByDeviceTypeI, LicenseStatus, LicenseStatusFeatureByDeviceTypeStatus, ErrorCode, ErrorMessage } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import { Ref } from 'vue'
import saveAs from 'file-saver'
import { useLicenseStatus } from '~/composables/useLicenseStatus'
import { ACL_ROLES } from '~/types'
import { useAcl } from 'vue-simple-acl'
import { StatusCodes } from 'http-status-codes'

const { t, te } = useI18n()

const licenseFileList = ref<FileList>()

const isLoading = ref(true)
const error = ref()

const licenseProperties: Ref<LicenseI | undefined> = ref()
const licenseStatus: Ref<LicenseStatusI | undefined> = ref()

const [licenseUploadVisible, toggleLicenseUploadVisible] = useToggle()

const { getLicenseStatus } = useLicenseStatus()

const acl = useAcl()

/**
 * Fetch the license status
 */
async function fetchLicenseStatus() {
  isLoading.value = true

  const response = await getLicenseStatus()

  if (LxcError.check(response)) {
    if (response.status === StatusCodes.NOT_FOUND) {
      licenseStatus.value = undefined
    } else {
      response.notify(NotificationKey.error)
      error.value = response
    }
  } else {
    licenseStatus.value = response
  }

  isLoading.value = false
}

/**
 * Fetch the license properties
 */
async function fetchLicenseProperties() {
  isLoading.value = true

  const response = await licenseService.getLicenseProperties()

  if (LxcError.check(response)) {
    if (response.status === StatusCodes.NOT_FOUND) {
      licenseProperties.value = undefined
    } else {
      response.notify(NotificationKey.error)
      error.value = response
    }
  } else {
    licenseProperties.value = response
  }

  isLoading.value = false
}

const canUploadLicense = computed(() => acl.can(ACL_ROLES.LICENSEM_UPLOAD))

/**
 * Upload the license file
 */
async function uploadLicense() {
  licenseUploadVisible.value = false

  isLoading.value = true

  if (licenseFileList?.value && licenseFileList?.value[0] && canUploadFile(licenseFileList.value[0])) {
    const response = await licenseService.uploadLicenseFile(licenseFileList.value[0])
    if (LxcError.check(response)) {
      response.notify(NotificationKey.error)
    } else {
      showNotificationSuccess(t(NotificationKey.success))

      await fetchLicenseProperties()
      await fetchLicenseStatus()
    }
  }

  isLoading.value = false
}

/**
 * Return true if the file is a license file
 */
function isLicenseFile(file: File) {
  return file.name.toLowerCase().endsWith('.jwt') || file.name.toLowerCase().endsWith('.opl')
}

/**
 * Check if the file can be uploaded, show an error if not
 * @param file File to check
 * @return true if the file can be uploaded, false otherwise
 */
function canUploadFile(file: File): boolean {
  let error

  if (!isLicenseFile(file)) {
    error = 'fileMandatory'
  } else if (!file.size) {
    error = 'fileEmpty'
  }

  if (error) {
    showNotificationError(t(`license.upload.validation.${error}`))
  }
  return !error
}

/**
 * Return the ratio about current devices and max number of devices in percent
 * @param feature 
 * @param deviceType 
 */
function percent(feature: string, criteria: LicenseFeatureCriteriaI): number | null {
  let percent = null

  if (criteria.parameters.maxNumberOfDevices !== 0 && licenseStatus.value) {
    const licenseStatusFeatureByDeviceType: LicenseStatusFeatureByDeviceTypeI | undefined = licenseStatus.value.featureByDeviceTypes
      .find((f: LicenseStatusFeatureByDeviceTypeI) => f.name === feature && f.deviceType === criteria.parameters.deviceType)
    if (licenseStatusFeatureByDeviceType) {
      percent = licenseStatusFeatureByDeviceType.parameter.currentNumberOfDevices * 100 / criteria.parameters.maxNumberOfDevices
      percent = Math.round(percent * 100) / 100 // round to 2 decimals
    }
  }

  return percent
}

/**
 * Format license status by feature and device types
 */
const licenseStatusComputed = computed(() => {
  if (licenseStatus.value) {
    const licenseStatusComputed: any = {
      startDate: licenseStatus.value.startDate,
      expirationDate: licenseStatus.value.expirationDate,
      validatedDate: licenseStatus.value.validatedDate,
      toleranceExpirationDate: licenseStatus.value.toleranceExpirationDate,
      statusOfLicense: licenseStatus.value.statusOfLicense
    }

    licenseStatusComputed.featureByDeviceTypes = {}

    for (const featureByDeviceType of licenseStatus.value.featureByDeviceTypes) {
      if (!licenseStatusComputed.featureByDeviceTypes[featureByDeviceType.name]) {
        licenseStatusComputed.featureByDeviceTypes[featureByDeviceType.name] = {}
      }

      licenseStatusComputed.featureByDeviceTypes[featureByDeviceType.name][featureByDeviceType.deviceType] = {
        status: featureByDeviceType.status,
        currentNumberOfDevices: featureByDeviceType.parameter.currentNumberOfDevices
      }

      if (licenseProperties.value) {
        const feature: LicenseFeatureI | undefined = licenseProperties.value.license.features.find(feature => feature.name === featureByDeviceType.name)
        if (feature) {
          const criteria: LicenseFeatureCriteriaI | undefined = feature.criterias.find(criteria => criteria.parameters.deviceType === featureByDeviceType.deviceType)
          if (criteria) {
            licenseStatusComputed.featureByDeviceTypes[featureByDeviceType.name][featureByDeviceType.deviceType].percent = percent(featureByDeviceType.name, criteria)
          }
        }
      }
    }

    return licenseStatusComputed
  } else {
    return null
  }
})

/** License status */
// The license is not active yet
const isLicenseNotActive = computed(() => licenseStatusComputed.value?.statusOfLicense === LicenseStatus.NOT_ACTIVATED)

// The license is actived and at least one feature is active or warning
const isLicenseActive = computed(
  () => [LicenseStatus.ACTIVATED, LicenseStatus.WARNING_DATE].find(licenseStatus => licenseStatusComputed.value?.statusOfLicense === licenseStatus) &&
    licenseStatus.value?.featureByDeviceTypes.find(
      feature => feature.status === LicenseStatusFeatureByDeviceTypeStatus.ACTIVATED || feature.status === LicenseStatusFeatureByDeviceTypeStatus.WARNING_DEVICE
    )
)

// The license is actived but all the features are not active and not in warning device
const isLicenseSuspended = computed(
  () => [LicenseStatus.ACTIVATED, LicenseStatus.WARNING_DATE].find(licenseStatus => licenseStatusComputed.value?.statusOfLicense === licenseStatus) &&
    !licenseStatus.value?.featureByDeviceTypes.find(
      feature => feature.status === LicenseStatusFeatureByDeviceTypeStatus.ACTIVATED || feature.status === LicenseStatusFeatureByDeviceTypeStatus.WARNING_DEVICE
    )
)

// The license is expired
const isLicenseExpired = computed(() => licenseStatusComputed.value?.statusOfLicense === LicenseStatus.EXPIRED_DATE)
/** end license status */

/** License feature status */
// At least one device type is active or warning
const isLicenseFeatureActive = (featureName: string) => licenseStatus.value?.featureByDeviceTypes.find(
  feature => feature.name === featureName && (feature.status === LicenseStatusFeatureByDeviceTypeStatus.ACTIVATED || feature.status === LicenseStatusFeatureByDeviceTypeStatus.WARNING_DEVICE)
)

// All the features are suspended
const isLicenseFeatureSuspended = (featureName: string) => licenseStatus.value?.featureByDeviceTypes.find(
  feature => feature.name === featureName && !(feature.status === LicenseStatusFeatureByDeviceTypeStatus.ACTIVATED || feature.status === LicenseStatusFeatureByDeviceTypeStatus.WARNING_DEVICE)
)
/** End license feature status */

// Generate and export the ID card
const exportIdCard = async () => {
  const response = await licenseService.getPlatformIdCard()

  if (LxcError.check(response)) {
    showNotificationError(t(NotificationKey.error))
  } else {
    const blob = new Blob([JSON.stringify(response, null, '  ')], { type: 'application/json' })
    saveAs(blob, `platformIdCard-${response.platformId}-${response.organizationId}.json`)
  }
}

onMounted(async () => {
  await fetchLicenseProperties()
  await fetchLicenseStatus()
})
</script>

<template>
  <lxc-license-warning-messages />
  <lxc-breadcrumb 
    :name="BreadcrumbConfig.LICENSE.title"
    readonly 
  />
  <lxc-container :is-loading="isLoading" :error="error">
    <!-- case when a license exists -->
    <div v-if="licenseProperties">
      <div class="border border-gray-300 rounded-lt-lg rounded-t-lg px-5 py-3">
        <div
          :class="`flex ${isLicenseActive ? 'text-primary-700' : ''} ${isLicenseNotActive || isLicenseSuspended ? 'text-warning-700' : ''} ${isLicenseExpired ? '!text-error-700' : ''}`">
          <div class="text-lg font-semibold pr-3">{{ $t('license.status.title') }}</div>
          <div>
            <lxc-badge v-if="isLicenseNotActive" type="warning" text-white>{{
              $t('license.status.notActive') }}</lxc-badge>
            <lxc-badge v-else-if="isLicenseActive" type="primary" text-white>{{
              $t('license.status.active') }}</lxc-badge>
            <lxc-badge v-else-if="isLicenseSuspended" type="warning" text-white>{{
              $t('license.status.suspended') }}</lxc-badge>
            <lxc-badge v-else-if="isLicenseExpired" type="danger" text-white>{{
              $t('license.status.expired') }}</lxc-badge>
          </div>
        </div>
      </div>
      <div class="flex w-full">
        <!-- left bloc -->
        <div class="basis-2/3 border-l border-b border-r border-gray-300 rounded-bl-lg px-4 py-5">
          <div class="mb-6 font-semibold">{{ $t('license.content.title') }}</div>
          <div class="mb-3 text-gray-500">{{ $t('license.content.feature.title') }}</div>

          <ul>
            <!-- display feature if it is activated -->
            <li v-for="feature of licenseProperties.license.features.filter((f: LicenseFeatureI) => f.activated)"
              class="mt-4 list-none">
              <div
                :class="`flex items-center ${isLicenseFeatureActive(feature.name) ? 'text-primary-700' : ''} ${isLicenseFeatureSuspended(feature.name) ? 'text-warning-700' : ''}`">
                <i-lx-tablet height="1.72rem" width="1.72rem" class="mr-5" />
                <div class="pr-3">
                  {{ te(`license.content.feature.${feature.name}`) ? $t(`license.content.feature.${feature.name}`) :
                    feature.name }}
                </div>
                <lxc-badge v-if="isLicenseFeatureActive(feature.name)" type="primary" text-white>{{
                  $t('license.content.feature.status.active') }}</lxc-badge>
                <lxc-badge v-else-if="isLicenseFeatureSuspended(feature.name)" type="warning" text-white>{{
                  $t('license.content.feature.status.suspended') }}</lxc-badge>
              </div>
              <ul class="m-4 ml-12 mb-6 leading-10">
                <!-- display criteria if it is activated and does not depends on another one -->
                <li
                  v-for="criteria of feature.criterias.filter((c: LicenseFeatureCriteriaI) => c.criteriaActivated && !c.dependsOn)"
                  class="list-none">
                  <div class="flex w-full">
                    <div class="basis-1/6">
                      {{ $t('license.content.feature.criteria.deviceType', { deviceType: criteria.parameters.deviceType })
                      }}
                      {{ criteria.parameters.maxNumberOfDevices === -1 ? '∞' : criteria.parameters.maxNumberOfDevices }}
                    </div>
                    <div
                      v-if="licenseStatusComputed?.featureByDeviceTypes[feature.name] !== undefined && licenseStatusComputed?.featureByDeviceTypes[feature.name][criteria.parameters.deviceType] !== undefined && licenseStatusComputed?.featureByDeviceTypes[feature.name][criteria.parameters.deviceType].percent !== undefined"
                      class="basis-3/6 flex items-center">
                      <div class="rounded bg-gray-200 w-full h-2">
                        <div :class="`rounded h-2
                            ${licenseStatusComputed.featureByDeviceTypes[feature.name][criteria.parameters.deviceType].status === LicenseStatusFeatureByDeviceTypeStatus.WARNING_DEVICE
                            ? 'bg-warning-600'
                            : licenseStatusComputed.featureByDeviceTypes[feature.name][criteria.parameters.deviceType].status === LicenseStatusFeatureByDeviceTypeStatus.SUSPENDED_DEVICE
                              ? 'bg-error-700'
                              : 'bg-primary-600'
                          }
                          `"
                          :style="{ width: `${Math.min(licenseStatusComputed.featureByDeviceTypes[feature.name][criteria.parameters.deviceType].percent, 100)}%` }" />
                      </div>
                    </div>
                    <div
                      v-if="licenseStatusComputed?.featureByDeviceTypes[feature.name] !== undefined && licenseStatusComputed?.featureByDeviceTypes[feature.name][criteria.parameters.deviceType] !== undefined && licenseStatusComputed.featureByDeviceTypes[feature.name][criteria.parameters.deviceType].percent != null"
                      class="basis-1/6 flex items-center ml-3">
                      {{
                        Math.min(licenseStatusComputed.featureByDeviceTypes[feature.name][criteria.parameters.deviceType].percent,
                          100) }}%
                      <i-lx-alert
                        v-if="licenseStatusComputed.featureByDeviceTypes[feature.name][criteria.parameters.deviceType].status === LicenseStatusFeatureByDeviceTypeStatus.WARNING_DEVICE"
                        height="1.5rem" width="1.5rem" class="ml-3 text-warning-600" />
                      <i-lx-x-circle
                        v-if="licenseStatusComputed.featureByDeviceTypes[feature.name][criteria.parameters.deviceType].status === LicenseStatusFeatureByDeviceTypeStatus.SUSPENDED_DEVICE"
                        height="1.5rem" width="1.5rem" class="ml-3 text-error-700" />
                    </div>
                  </div>

                  <!-- number of interfaces -->
                  <div v-if="criteria.parameters.numberOfInterfaces !== undefined">
                    {{ $t('license.content.feature.criteria.numberOfInterfaces') }} {{
                      criteria.parameters.numberOfInterfaces }}
                  </div>

                  <!-- display dependings criterias which have a max number of devices !== -1 -->
                  <ul class="ml-10">
                    <li
                      v-for="subCriteria of feature.criterias.filter((c: LicenseFeatureCriteriaI) => c.parameters.maxNumberOfDevices !== -1 && c.dependsOn && c.dependsOn.includes(criteria.id))"
                      class="list-none">
                      {{ $t('license.content.feature.criteria.deviceType', {
                        deviceType: subCriteria.parameters.deviceType
                      })
                      }}
                      {{ subCriteria.parameters.maxNumberOfDevices === -1 ? '∞' :
                        subCriteria.parameters.maxNumberOfDevices
                      }}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <!-- right bloc-->
        <div class="basis-1/3 border-r border-b border-gray-300 rounded-br-lg px-6 py-5 relative leading-6">
          <div class="mb-6 font-semibold">{{ $t('license.caracteristics.title') }}</div>
          <div v-if="licenseStatus">
            <div class="mb-3 text-gray-500">{{ $t('license.caracteristics.date.validated') }}</div>
            <div class="mb-3 flex items-center"><i-lx-calendar height="1.72rem" width="1.72rem" class="mr-5" />{{
              $t('license.caracteristics.date.validatedOn', { date: dayjs(licenseStatus.validatedDate).format('LL') }) }}
            </div>
          </div>

          <div class="mb-3 text-gray-500">{{ $t('license.caracteristics.date.start') }}</div>
          <div class="mb-3 flex items-center"><i-lx-calendar height="1.72rem" width="1.72rem" class="mr-5" />{{
            dayjs(licenseStatus?.startDate || licenseProperties.startDate).utc().format('LL LTS') }} (UTC)</div>

          <div class="mb-3 text-gray-500">{{ $t('license.caracteristics.date.end') }}</div>
          <div class="mb-3 flex items-center"><i-lx-calendar height="1.72rem" width="1.72rem" class="mr-5" />{{
            dayjs(licenseStatus?.expirationDate || licenseProperties.expirationDate).utc().format('LL LTS') }} (UTC)</div>

          <!-- Non-visible button which serves as a reference to the button which is absolute -->
          <lxc-button v-if="canUploadLicense" type="tertiary" class="invisible" :title="$t('license.upload.new.title')"
            :icon="ILxcPlus">
            {{ $t('license.upload.new.text') }}
          </lxc-button>
          <lxc-button v-if="canUploadLicense" type="tertiary" class="absolute bottom-4 right-4" :title="$t('license.upload.new.title')"
            :icon="ILxcPlus" @click="licenseUploadVisible = true">
            {{ $t('license.upload.new.text') }}
          </lxc-button>
        </div>
      </div>
    </div>

    <!-- case when no license exists -->
    <div v-else-if="canUploadLicense">
      <div class="bg-gray-50 flex justify-center rounded-lg">
        <div class="pt-10 pb-5">
          <div class="mb-6 flex justify-center">
            <div class="rounded-full bg-primary-50 p-3 w-fit">
              <div class="rounded-full bg-primary-100 p-4 w-fit">
                <i-lx-search class="text-primary-600" height="1.72rem" width="1.72rem" />
              </div>
            </div>
          </div>

          <div class="mb-6 flex justify-center">{{ $t('license.upload.absent.text') }}</div>

          <div class="flex justify-center">
            <lxc-button class="font-semibold" :title="$t('license.upload.title')" :icon="ILxUpload"
              @click="licenseUploadVisible = true">
              {{ $t('license.upload.text') }}
            </lxc-button>
          </div>
        </div>
      </div>
    </div>

    <!-- ID card -->
    <div class="flex border border-gray-300 rounded-lg mt-6 p-4 relative">
      <div class="border border-gray-300 rounded-lg p-2 mr-4 text-gray-500 justify-center items-center">
        <i-lxc-info height="1.5em" width="1.5em" /></div>
      <div>
        <div class="font-semibold">{{ $t('license.idCard.title') }}</div>
        <div>{{ $t('license.idCard.description') }}</div>
      </div>
      <div class="absolute right-4">
        <lxc-button type="tertiary" :title="$t('license.idCard.export.title')" :icon="ILxcDownload" @click="exportIdCard">
          {{ $t('license.idCard.export.text') }}
        </lxc-button>
      </div>
    </div>
  </lxc-container>

  <lxc-modal :dialog-visible="licenseUploadVisible" :title="$t('license.upload.text')" @confirm="uploadLicense"
    @update:dialog-visible="toggleLicenseUploadVisible" @cancel="licenseUploadVisible = false"
  >
    <lxc-input type="file" v-model="licenseFileList" accept=".jwt,.opl">
      <template #placeholder>
        {{ $t('license.upload.dropInformation') }}
      </template>
    </lxc-input>
  </lxc-modal>
</template>