<script lang='ts' setup>
import ILxArrowDownRight from '~icons/lx/arrow-down-right'

const emit = defineEmits(['detach'])
const { t } = useI18n()

enum Action {
  DETACH_FROM_SECTOR = 'DETACH_FROM_SECTOR',
}

const props = defineProps<{
  displayActions: boolean
}>()

/**
 * When selecting an action in menu
 * @param action
 */
function onSelectAction(action: Action) {
  switch (action) {
    case Action.DETACH_FROM_SECTOR:
      emit('detach')
      break
  }
}
</script>

<template>
  <lxc-dropdown
    v-if="displayActions"
    @command="onSelectAction"
  >
    <el-dropdown-item
      :command="Action.DETACH_FROM_SECTOR"
      :icon="ILxArrowDownRight"
    >
      {{ $t(`sectors.details.buttons.detach`) }}
    </el-dropdown-item>
  </lxc-dropdown>
</template>
