<script setup lang='ts'>
import type { DeviceI, OperationType } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import { useDeviceOperations } from '~/composables/useDeviceOperations'

const { t } = useI18n()
const props = defineProps<{
  deviceId: string
  device: DeviceI
  operationType?: OperationType
  hasCallbackUrl?: boolean
}>()

const {
  fetchData,
  isLoading,
  operations,
  error,
} = useDeviceOperations(props.deviceId, props.operationType, props.hasCallbackUrl)

const dateFormat = t('operation.dateFormat')

onMounted(fetchData)
</script>

<template>
  <lxc-table
    :is-loading="isLoading"
    :error="error"
    :data="operations?.data"
    :empty-text="$t('operation.empty')"
    :context="operations?.context"
    data-cy="product-details-operations-table"
    @change-page-and-page-size="fetchData"
  >
    <lxc-table-column
      prop="type"
      :label="$t('operation.type')"
    >
      <template #default="scope">
        {{ t(`operation.operationType.${scope.row.definition.type}`) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="modifiedAt"
      :label="$t('operation.createdAt')"
    >
      <template #default="scope">
        {{ dayjs(scope.row.process.createdAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="startedAt"
      :label="$t('operation.launchedAt')"
    >
      <template #default="scope">
        {{ dayjs(scope.row.process.startedAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="startedAt"
      :label="$t('operation.updatedAt')"
    >
      <template #default="scope">
        {{ dayjs(scope.row.process.modifiedAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="state"
      :label="$t('operation.state')"
    >
      <template #default="scope">
        <lxc-operation-state
          :state="scope.row.definition.state"
          :with-label="false"
        />
      </template>
    </lxc-table-column>

    <lxc-table-column
      v-if="!hasCallbackUrl"
      prop="actions"
      :label="$t('operation.actions')"
    >
      <template #default="scope">
        <lxc-device-operation-actions
          :device="device"
          :operation="scope.row"
          @change="fetchData"
        />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
