<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'
import { onMounted, ref, watch } from 'vue'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-luxon'
import zoomPlugin from 'chartjs-plugin-zoom'
import ILxcClose from '~icons/lxc/x-close'
import { Granularity, type Serie } from '~/types/chart'

Chart.register(zoomPlugin)

const props = defineProps<{
  series: Array<Serie>
  height?: number
  width?: number

  locale?: string

  // X scale properties, cf. https://www.chartjs.org/docs/3.9.1/axes/styling.html
  xScaleProperties?: any

  // Plugin properties, cf. https://www.chartjs.org/docs/3.9.1/developers/plugins.html
  pluginProperties?: any
}>()

const emit = defineEmits(['update:series'])

const chartId = `chart-${uuidv4()}`

const chartRef = ref<HTMLCanvasElement>()

let chartInstance: Chart | undefined

const formatValueByDatasetLabel: any = {}

/**
 * Compare two granularities and return the lowest one
 * Return undefined if one of the granularity is undefined or null
 */
const getMinGranularity = (granularity1: Granularity|undefined, granularity2: Granularity|undefined) => {
  const granularityPriority = [Granularity.MILLISECOND, Granularity.SECOND, Granularity.MINUTE, Granularity.HOUR, Granularity.DAY, Granularity.WEEK, Granularity.MONTH, Granularity.QUARTER, Granularity.YEAR]

  if (!granularity1 || !granularity2) {
    return undefined
  } else if (granularityPriority.indexOf(granularity1) <= granularityPriority.indexOf(granularity2)) {
    return granularity1
  } else {
    return granularity2
  }
}

/**
 * Format a value with precision and unit symbol properties
 * @param precision
 * @param unitSymbol
 */
const formatValue = (value: number, precision = 2, unitSymbol?: string) => {
  const pow = Math.pow(10, precision)
  return `${Math.ceil(value * pow) / pow}${unitSymbol || ''}`
}

/**
 * Return the format value function
 */
const getFormatValueFunction = (serie: Serie) => serie.formatValue ?? ((value: any) => formatValue(value, serie.precision, serie.unitSymbol))

const updateChartScales = () => {
  if (chartInstance) {
    const scales: any = {
      x: {
        ...{
          type: 'time',
          grid: {
            display: false,
          },
          adapters: {
            date: {
              locale: props.locale,
            },
          },
          time: {
            unit: Granularity.YEAR,
            displayFormats: {
              millisecond: 'F',
              second: 'F',
              minute: 'F',
              hour: 'f',
              day: 'D',
              week: 'D (W)',
              month: 'LLLL yyyy',
              quarter: 'qq yyyy',
              year: 'yyyy',
            },
          },
        },
        ...props.xScaleProperties,
      },
    }

    for (const serie of props.series) {
      // build Y scale if it does not already exist (based on the unit)
      if (!scales[serie.unit]) {
        scales[serie.unit] = {
          ...{
            display: 'auto',
            position: Object.keys(scales).length % 2 === 0 ? 'right' : 'left',
            title: {
              display: true,
              text: serie.unit,
            },
            ticks: {
              callback: getFormatValueFunction(serie),
            },
          },
          ...serie.scaleProperties,
        }
      }

      scales.x.time.unit = getMinGranularity(scales.x.time.unit, serie.granularity)
    }

    chartInstance.options.scales = scales

    chartInstance.update('none')
  }
}

const updateChartLocale = () => {
  if (chartInstance) {
    chartInstance.options.locale = props.locale

    updateChartScales()
  }
}

const updateChartDatasets = () => {
  const datasets = []

  if (chartInstance) {
    for (const serie of props.series) {
      const randomColorIndex = () => Math.ceil(Math.random() * 255)
      const randomColor = [randomColorIndex(), randomColorIndex(), randomColorIndex()]

      let label = serie.label || serie.name

      if (serie.unit) {
        label += ` (${serie.unit})`
      }

      const previousDataset = chartInstance.data.datasets.find((dataset: any) => dataset.label === label)

      datasets.push({
        // default properties
        ...{
          type: 'line',
          label,
          data: serie.values,
          backgroundColor: previousDataset?.backgroundColor ?? `rgba(${randomColor[0]}, ${randomColor[1]}, ${randomColor[2]}, 0.7)`,
          borderColor: previousDataset?.borderColor ?? `rgba(${randomColor[0]}, ${randomColor[1]}, ${randomColor[2]}, 1)`,
          borderWidth: 1,
          tension: 0.4,
          pointRadius: 2,
          yAxisID: serie.unit,
        },
        //  override dataset properties
        ...serie.datasetProperties,
      })

      const datasetLabel = datasets[datasets.length - 1].label

      formatValueByDatasetLabel[datasetLabel] = getFormatValueFunction(serie)
    }

    chartInstance.data.datasets = datasets

    updateChartScales()
  }
}

const updateChartPlugin = () => {
  if (chartInstance) {
    chartInstance.options.plugins = {
      ...{
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const datasetLabel = context.dataset.label ?? ''

              let label = datasetLabel ?? ''

              if (label) {
                label += ': '
              }

              if (formatValueByDatasetLabel[datasetLabel]) {
                label += formatValueByDatasetLabel[datasetLabel](context.parsed.y)
              } else {
                label += context.formattedValue
              }

              return label
            },
          },
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            drag: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      ...props.pluginProperties,
    }

    chartInstance.update('none')
  }
}

/**
 * Build the chart
 */
const buildChart = () => {
  const chartCtx = chartRef.value?.getContext('2d')
  if (chartCtx) {
    chartInstance = new Chart(chartCtx, {
      data: {
        datasets: [],
      },
      options: {
        parsing: {
          xAxisKey: 'timestamp',
          yAxisKey: 'value',
        },
      },
    })

    updateChartLocale()
    updateChartDatasets()
    updateChartPlugin()
  }
}
/** End build the chart */

const removeSerie = (serie: Serie) => {
  const index = props.series.indexOf(serie)

  if (index !== -1) {
    const series = props.series
    series.splice(index, 1)
    emit('update:series', series)
  }
}

watch(() => props.series, updateChartDatasets, { deep: true })
watch(() => props.height, () => chartInstance?.render())
watch(() => props.width, () => chartInstance?.render())
watch(() => props.locale, updateChartLocale, { deep: true })
watch(() => props.xScaleProperties, updateChartScales, { deep: true })
watch(() => props.pluginProperties, updateChartPlugin, { deep: true })

onMounted(buildChart)
</script>

<template>
  <lxc-card>
    <template #header>
      <div class="flex">
        <div
          v-for="(serie, i) in series"
          :key="i"
          class="border-l flex"
        >
          <div class="m-2">
            <div>{{ serie.label }}</div>
            <div>{{ serie.name }}</div>
          </div>
          <div class="mr-2">
            <button
              class="ml-4"
              @click="() => removeSerie(serie)"
            >
              <i-lxc-close
                height="0.6rem"
                width="0.6rem"
              />
            </button>
          </div>
        </div>
      </div>
    </template>

    <canvas
      :id="chartId"
      ref="chartRef"
      :height="height ?? 100"
      :width="width ?? 400"
      aria-label="Chart"
      role="img"
    />
  </lxc-card>
</template>
