<script setup lang="ts">
import { typeOptions } from '~/components/devices/deviceList/deviceFilters.config'

const emit = defineEmits(['select', 'update:modelValue'])
const loading: Ref<boolean> = ref(false)
const props = defineProps<{
  modelValue: string[]
}>()

const selectedItems = computed({
  get() {
    return props.modelValue ?? []
  },
  set(selected?: Array<string>) {
    // Note: we are using destructuring assignment syntax here.
    emit('update:modelValue', selected)
    emit('select', selected)
  },
})

</script>

<template>
  <lxc-filter-checklist
    v-model="selectedItems"
    :data="typeOptions.options"
    :is-loading="loading"
    :empty-text="$t('logs.filters.devices-dvtm-esoft.type.empty')"
    :header="$t('device.type')"
    prop="label"
    checkbox-prop="value"
  />
</template>
