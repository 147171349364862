<script setup lang="ts">
import type { Option } from '~/types'

const emit = defineEmits(['update:modelValue'])
const props = defineProps<{
  modelValue?: string[]
  options: Option[]
  header: string
}>()
const selectedItems = computed({
  get() {
    return props.modelValue ?? []
  },
  set(selected?: Array<string>) {
    emit('update:modelValue', selected)
  },
})

</script>

<template>
  <div class="rounded-lg bg-white text-gray-900 p-6">
    <h6
      v-if="props.header != null"
      class="font-bold text-base mb-4 underline"
    >
      {{ props.header }}
    </h6>

    <lxc-form-item>
      <lxc-checkbox
        v-for="option in props.options"
        :key="option"
        v-model="selectedItems"
        :value="option.value"
        :label="option.label"
      />
    </lxc-form-item>
  </div>
</template>
