<script setup lang="ts">
import { Filters } from '~/types'
import type { FilterOptions, FilterSelectionDefinition } from '~/types'
import { typeOptions } from '~/components/devices/deviceList/deviceFilters.config'

const props = defineProps<{
  modelValue?: FilterSelectionDefinition | null
  header?: string
  rangeOptions: FilterOptions
}>()

const emit = defineEmits(['select', 'update:modelValue'])
const options = typeOptions.options.map(paramOption => Object.assign({}, paramOption))

const selectedItems = computed<Array<string>>({
  get() {
    let items: Array<string> = []
    if (props.modelValue?.value && Array.isArray(props.modelValue?.value)) {
      items = props.modelValue?.value as Array<string>
    }
    return items
  },
  set(selected?: Array<string> | null) {
    const modelValue = {
      key: Filters.MODEL_TYPE,
      operator: '=',
      value: selected ?? [],
    }
    emit('update:modelValue', modelValue)
    emit('select', modelValue)
  },
})
</script>

<template>
  <div class="rounded-lg bg-white text-gray-900 p-6">
    <lxc-checkbox
      v-for="rangeOption in rangeOptions.options"
      :key="rangeOption.value"
      v-model="selectedItems"
      :label="rangeOption.label"
      :value="rangeOption.value"
      :disabled="rangeOption.disabled"
    />
  </div>
</template>
