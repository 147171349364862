<script setup lang="ts">
import { LogEventType } from '@lxc/app-device-types'
import { useAcl } from 'vue-simple-acl'
import { ACL_ROLES, type Option } from '~/types'
const { can } = useAcl()
const { t } = useI18n()

const emit = defineEmits(['update:modelValue'])
const props = defineProps<{
  modelValue?: string[]
  header: string
}>()

const eventTypeOptions = computed<Option[]>((): Option[] => {
  return (Object.values(LogEventType).filter((value): boolean => {
    let permission: boolean

    switch (value) {
      case LogEventType.CYBER:
        permission = can(ACL_ROLES.CYBER_LOGS_VIEW)
        break
      case LogEventType.DEVICE:
        permission = can(ACL_ROLES.DEVICE_LOGS_VIEW)
        break
      case LogEventType.DEVICE_FLEET:
        permission = can(ACL_ROLES.DEVICE_FLEET_LOGS_VIEW)
        break
      case LogEventType.SYSTEM:
        permission = can(ACL_ROLES.SYSTEM_LOGS_VIEW)
        break
      default:
        permission = false
    }
    return permission
  }).map((value: LogEventType): Option => {
    const label = value ? t(`logs.eventType.value.${value}`) : ''
    return {
      value,
      label,
    }
  }))
})
const selectedItems = computed({
  get() {
    return props.modelValue ?? []
  },
  set(selected?: Array<string>) {
    emit('update:modelValue', selected)
  },
})

</script>

<template>
  <lxc-value-label-checkboxes
    v-model="selectedItems"
    :options="eventTypeOptions"
    :header="props.header"
  />
</template>
