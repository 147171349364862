import type { SectorI } from '../../../lxc-app-device-types'
import SectorsService from '~/services/sectors.service'
import LxcError from '~/utils/LxcError'

export function useSector() {
  const sectorLabel = ref<string>('')

  async function getSectorLabel(sectorCode?: string) {
    if (!sectorCode) {
      sectorLabel.value = ''
    } else {
      sectorLabel.value = 'Chargement...'
      const response: SectorI = await SectorsService.getSectorByCode(sectorCode)
      sectorLabel.value = LxcError.check(response) ? 'Non disponible' : response.label
    }
  }

  return {
    sectorLabel,
    getSectorLabel,
  }
}
