<script setup lang="ts">
import { ref } from 'vue'
import { filterEmptyValues } from '@lxc/app-device-common'
import filtersUtils from '~/utils/filters.utils'

const props = defineProps<{
  label: string
  modelValue?: Array<string>
}>()

const emit = defineEmits(['update:modelValue'])
const actionName = ref('')

function initFilterMap(paramActions?: string[]): Record<string, string | undefined> {
  const vFilterMap: Record<string, string | undefined> = {}

  if (paramActions && paramActions.length !== 0) {
    for (const action of paramActions) {
      const tag = filtersUtils.getTagText(props.label, action)
      if (tag) {
        vFilterMap[tag] = action
      }
    }
  }

  return vFilterMap
}

function deleleTagFromList(actionToDelete: string, list?: string[]) {
  if (list) {
    const index = list.findIndex(currentAction => currentAction === actionToDelete)

    if (index != null && index >= 0) {
      list.splice(index, 1)
    }
  }
}

const selectedActions = computed({
  get(): string[] {
    return props.modelValue ?? []
  },
  set(selected?: string[]) {
    emit('update:modelValue', selected)
  },
})
const isAnyAppliedFilter = computed(() => {
  return (props.modelValue?.length ?? 0) > 0
})

const filterTags = computed((): string[] => {
  return filterEmptyValues<string>(props.modelValue?.map(action => filtersUtils.getTagText(props.label, action)) ?? [])
})

const filterMap = computed((): Record<string, string | undefined> => {
  return initFilterMap(props.modelValue)
})

const onTagDeleteClick = (tag: string) => {
  const action = filterMap.value[tag]

  if (action) {
    deleleTagFromList(action, selectedActions.value)
  }
}
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>
  <div class="rounded-lg bg-white text-gray-900 pt-6">
    <lxc-log-action-name-filter v-model="actionName" />
    <lxc-log-action-list-filter
      v-model="selectedActions"
      :header="label"
      :name="actionName"
    />
  </div>
</template>
