import type { Ref } from 'vue'
import type { ReportingConfigurationI, ReportingRecipientI } from '@lxc/app-device-types'
import CampaignService from '~/services/campaign.service'
import LxcError from '~/utils/LxcError'

export function useCertificateUpdateReporting() {
  const configuration = ref<ReportingConfigurationI>()
  const configurationBackup = ref<ReportingConfigurationI>()
  const isLoading = ref(false)
  const error: Ref<LxcError | null> = ref(null)

  function cloneConfiugration(response: ReportingConfigurationI): ReportingConfigurationI {
    return Object.assign({}, response)
  }

  async function fetchConfiguration() {
    isLoading.value = true
    const response = await CampaignService.getReportingConfiguration()

    if (LxcError.check(response)) {
      error.value = response
    } else {
      configuration.value = response
      configurationBackup.value = cloneConfiugration(response)
    }

    isLoading.value = false

    return response
  }

  async function updateConfiguration(config: ReportingConfigurationI) {
    isLoading.value = true
    const response = await CampaignService.postReportingConfiguration(config)

    if (!LxcError.check(response)) {
      configuration.value = response
      configurationBackup.value = cloneConfiugration(response)
    }
    isLoading.value = false

    return response
  }

  async function addReportingRecipients(configurationId: string, recipients: Array<ReportingRecipientI>) {
    return await CampaignService.addReportingRecipients(configurationId, recipients)
  }

  async function removeReportingRecipients(configurationId: string, recipients: Array<ReportingRecipientI>) {
    return await CampaignService.removeReportingRecipients(configurationId, recipients)
  }

  return {
    configuration,
    configurationBackup,
    error,
    isLoading,
    fetchConfiguration,
    updateConfiguration,
    addReportingRecipients,
    removeReportingRecipients,
  }
}
