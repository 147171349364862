import type { RoleI } from '@lxc/app-device-types'
import RestService from './rest.service'

export class RolesService extends RestService {
  protected BASE_URL = '/roles'

  /**
   * Retrieve all grantable roles
   */
  getAllGrantableRoles(): Promise<RoleI[]> {
    return this.$api.get(this.BASE_URL)
  }
}

export default new RolesService()
