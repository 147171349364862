<script setup lang="ts">
import type { PeriodI } from '@lxc/app-device-common'
import { IntlDateTools } from '@lxc/app-device-common'
import { LogType } from '@lxc/app-device-types'
import type { AsyncLogI, LogActionType, LogComponentId, LogEntityClass, LogEventType, LogLevel, LogSortBy, LogSortDirection, LogStatus } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import { useLogs } from '~/composables/useLogs'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import type { FilterSelectionValue } from '~/types'
import { Filters } from '~/types'
import type { LogEntityI, LogEntitySubClass } from '~/types/logEntity'
import { formatIsoDate, getTodayPeriod } from '~/utils/date-tools'

const { locale, t } = useI18n()
const {
  error,
  fetchData,
  filters,
  isLoading,
  results,
  setFilter,
  search,
  onSearch,
} = useLogs()
const todayPeriod: PeriodI = getTodayPeriod()
const actionType = ref<LogActionType | undefined>()
const componentId = ref<LogComponentId | undefined>()
const endDate = ref<Date | undefined | null>(todayPeriod.endDate)
const entityClass = ref<LogEntityClass | undefined>()
const entitySubClass = ref<LogEntitySubClass | undefined>()
const entityId = ref<string[] | undefined>()
const eventTypes = ref<LogEventType[] | undefined>()
const levels = ref<LogLevel[] | undefined>()
const actions = ref<string[] | undefined>()
const sortBy = ref<LogSortBy | undefined>()
const sortDirection = ref<LogSortDirection | undefined>()
const startDate = ref<Date | undefined | null>(todayPeriod.startDate)
const status = ref<LogStatus | undefined>()
const dropdownLink: Ref<HTMLLinkElement | undefined | null> = ref()
const rowsSelected: Ref<Array<AsyncLogI>> = ref([])

const filterDateFormat = 'YYYY-MM-DD'
const dateFormat: string = t('logs.list.column.timestamp.format')
let filterChangeLoading = false

function updateLogFilterbyKey(key: string, value?: string | string[] | null) {
  switch (key) {
    case Filters.LOG_COMPONENT_ID:
      componentId.value = value as LogComponentId | undefined
      break
    case Filters.LOG_ENTITY_CLASS:
      entityClass.value = value as LogEntityClass | undefined
      break
    case Filters.LOG_ENTITY_ID:
      entityId.value = value as string[] | undefined
      break
    case Filters.LOG_EVENT_TYPE:
      eventTypes.value = value as LogEventType[] | undefined
      break
    case Filters.LOG_LEVEL:
      levels.value = value as LogLevel[] | undefined
      break
    case Filters.LOG_ACTION:
      actions.value = value as string[] | undefined
      break
    case Filters.STATUS:
      status.value = value as LogStatus | undefined
      break
    case Filters.LOG_START_DATE:
      if (value) {
        startDate.value = IntlDateTools.parse(value as string, filterDateFormat, locale.value)
      }
      break
    case Filters.LOG_END_DATE:
      if (value) {
        endDate.value = IntlDateTools.parse(value as string, filterDateFormat, locale.value)
      }
      break
    case Filters.SORT_BY:
      sortBy.value = value as LogSortBy | undefined
      break
    case Filters.SORT_DIRECTION:
      sortDirection.value = value as LogSortDirection | undefined
      break
    default:
      break
  }
}

function updateLogFiltersFromUrl() {
  const valuesIterator = filters.values()

  for (const searchFilter of valuesIterator) {
    let value: FilterSelectionValue | undefined | null = searchFilter.value

    if (Array.isArray(value)) {
      value = (value as Array<any>).length !== 0 ? value : undefined
    } else {
      value = value !== '' ? value : undefined
    }

    updateLogFilterbyKey(searchFilter.key, value)
  }
}

async function loadData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  setFilter(Filters.LOG_TYPE, LogType.AUDIT)

  if (startDate.value) {
    setFilter(Filters.LOG_START_DATE, dayjs(startDate.value).format(filterDateFormat))
  }
  if (endDate.value) {
    setFilter(Filters.LOG_END_DATE, dayjs(endDate.value).format(filterDateFormat))
  }

  await fetchData(page, pageSize)
  dropdownLink.value = null
}

const updateSearchFilters = (): void => {
  setFilter(Filters.LOG_TYPE, LogType.AUDIT)
  // Set by default to a emtpy string or array because the search method does not support empty arrays
  setFilter(Filters.LOG_COMPONENT_ID, componentId.value ?? '')
  setFilter(Filters.STATUS, status.value ?? '')
  setFilter(Filters.LOG_ENTITY_CLASS, entityClass.value ?? '')
  setFilter(Filters.LOG_ENTITY_ID, entityId.value ?? [])
  setFilter(Filters.LOG_EVENT_TYPE, eventTypes.value ?? [])
  setFilter(Filters.LOG_LEVEL, levels.value ?? [])
  setFilter(Filters.LOG_ACTION, actions.value ?? [])
  setFilter(Filters.LOG_START_DATE, dayjs(startDate.value).format(filterDateFormat))
  setFilter(Filters.LOG_END_DATE, dayjs(endDate.value).format(filterDateFormat))
  setFilter(Filters.SORT_BY, sortBy.value ?? '')
  setFilter(Filters.SORT_DIRECTION, sortDirection.value ?? '')
}

const onPageChange = async(page?: number, pageSize?: number) => {
  await loadData(page, pageSize)
}

const onFilterChanged = async() => {
  filterChangeLoading = true
  updateSearchFilters()
  search()
}

const onSearchCallback = async() => {
  if (!filterChangeLoading) {
    updateLogFiltersFromUrl()
  }

  await loadData()
  filterChangeLoading = false
}

const openDropdown = (element?: HTMLLinkElement | null) => {
  dropdownLink.value = element
}

const onApplyEntityAsAFilter = async(logEntity: LogEntityI) => {
  componentId.value = undefined
  status.value = undefined
  entityClass.value = logEntity.entityClass
  entityId.value = logEntity.entityId ? [logEntity.entityId] : []
  eventTypes.value = undefined
  levels.value = undefined
  actions.value = undefined
  onFilterChanged()
}

onSearch(onSearchCallback)
</script>

<template>
  <lxc-log-filters
    v-model:action-type="actionType"
    v-model:entity-class="entityClass"
    v-model:entity-sub-class="entitySubClass"
    v-model:entity-id="entityId"
    v-model:component-id="componentId"
    v-model:end-date="endDate"
    v-model:event-types="eventTypes"
    v-model:levels="levels"
    v-model:actions="actions"
    v-model:sort-by="sortBy"
    v-model:sort-direction="sortDirection"
    v-model:start-date="startDate"
    v-model:status="status"
    :rows-selected="rowsSelected"
    @change="onFilterChanged"
  />
  <lxc-container
    :error="error"
  >
    <lxc-table
      :empty-text="$t('logs.list.empty')"
      :data="results?.data"
      :context="results?.context"
      :is-loading="isLoading"
      @change-page-and-page-size="onPageChange"
      @select="rowsSelected = $event"
    >
      <lxc-table-column type="selection" />
      <lxc-table-column
        align="left"
        :label="$t('logs.timestamp.label')"
      >
        <template
          #default="scope"
        >
          {{ formatIsoDate(scope.row.timestamp, dateFormat) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        align="left"
        :label="$t('logs.level.label')"
      >
        <template
          #default="scope"
        >
          {{ t(`logs.level.value.${scope.row.level}`) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        :label="$t('logs.eventType.label')"
      >
        <template
          #default="scope"
        >
          {{ t(`logs.eventType.value.${scope.row.eventType}`) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        :label="$t('logs.list.column.label.label')"
        prop="label"
      >
        <template
          #default="scope"
        >
          <lxc-log-label
            v-model="scope.row"
            @click="openDropdown"
          />
        </template>
      </lxc-table-column>
      <lxc-table-column
        prop="action"
        :label="$t('logs.action.label')"
      />
    </lxc-table>
  </lxc-container>
  <lxc-log-dropdown
    :element="dropdownLink"
    @apply-as-a-filter="onApplyEntityAsAFilter"
  />
</template>
<style lang="scss" scoped>
.log-label{
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  white-space: break-spaces;
  word-break: break-all;
  overflow: hidden;
}
</style>
