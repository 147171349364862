<script setup lang="ts">
import { InfoFilled, SwitchButton, User } from '@element-plus/icons-vue'
import { PATHS } from '~/constants/paths'
import { logout } from '~/utils/auth.utils'

const router = useRouter()

function toUserAccount() {
  router.push(PATHS.USER_ACCOUNT)
}

function toComponentsVersion() {
  router.push(PATHS.ABOUT)
}

</script>
<template>
  <div
    class="user-menu-container"
  >
    <h3>
      {{ $t('layout.header.userMenu.title') }}
    </h3>

    <el-dropdown-menu>
      <el-dropdown-item
        :icon="User"
        divided
        @click="toUserAccount"
      >
        {{ $t('layout.header.userMenu.userAccount') }}
      </el-dropdown-item>

      <el-dropdown-item
        :icon="InfoFilled"
        @click="toComponentsVersion"
      >
        {{ $t('layout.header.userMenu.about') }}
      </el-dropdown-item>

      <el-dropdown-item
        :icon="SwitchButton"
        divided
        @click="logout"
      >
        {{ $t('layout.header.userMenu.logout') }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </div>
</template>
<style lang="scss" scoped>
.user-menu-container {
  width: 250px;

  h3 {
    padding: 0 12px;
    margin-bottom: 0;
  }
}
</style>
