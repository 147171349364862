<script setup lang="ts">
import type { CertificateI } from '@lxc/app-device-types'
import { timeLimitInMonthOrDays } from '~/utils/date-tools'

const props = defineProps<{
  certificate: CertificateI
}>()

const { t } = useI18n()

const certificateStatusData = computed(() => {
  const timeLimitProvided = timeLimitInMonthOrDays(props.certificate?.notValidAfter)
  const timeInDays: number = timeLimitInMonthOrDays(props.certificate?.notValidAfter, true)
  let icon = ''
  let title = ''

  if (timeLimitProvided >= 6) {
    icon = 'certificate-validity-greater-than-six-months'
    title = `${t('certificates.valid')} (> ${t('certificates.month', 6)}) - ${t('certificates.days', timeInDays)}`
  }
  else if (timeLimitProvided < 6 && timeLimitProvided >= 3) {
    icon = 'certificate-validity-less-than-six-months'
    title = `${t('certificates.valid')} (< ${t('certificates.month', 6)}) - ${t('certificates.days', timeInDays)}`
  }
  else if (timeLimitProvided < 3 && timeLimitProvided >= 1) {
    icon = 'certificate-validity-less-than-three-months'
    title = `${t('certificates.valid')} (< ${t('certificates.month', 3)}) - ${t('certificates.days', timeInDays)}`
  }
  else if (timeLimitProvided < 1 && timeLimitProvided >= 0) {
    icon = 'certificate-validity-less-than-one-months'
    title = `${t('certificates.valid')} (< ${t('certificates.month', 1)}) - ${t('certificates.days', timeInDays)}`
  }
  else {
    icon = 'certificate-expired'
    title = t(`certificates.expired`)
  }
  return { icon, title }
})

</script>

<template>
  <span
    :title="certificateStatusData.title"
    class="block mx-auto w-fit"
  >
    <i-lx-certificate-validity-greater-than-six-months v-if="certificateStatusData.icon === 'certificate-validity-greater-than-six-months'" />
    <i-lx-certificate-validity-less-than-six-months v-if="certificateStatusData.icon === 'certificate-validity-less-than-six-months'" />
    <i-lx-certificate-validity-less-than-three-months v-if="certificateStatusData.icon === 'certificate-validity-less-than-three-months'" />
    <i-lx-certificate-validity-less-than-one-months v-if="certificateStatusData.icon === 'certificate-validity-less-than-one-months'" />
    <i-lx-certificate-expired v-if="certificateStatusData.icon === 'certificate-expired'" />
  </span>
</template>
