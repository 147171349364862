<script lang='ts' setup>
import type { Breadcrumb } from './breadcrumb.config'
import ILxArrowLeft from '~icons/lx/angle-down'

const { te } = useI18n()
const route = useRoute()
const router = useRouter()

const props = defineProps<{
  name?: string | Breadcrumb | (string|Breadcrumb)[]
  readonly?: boolean
}>()

const breadcrumb = route.meta.breadcrumb as Breadcrumb

function getHref(breadcrumb: string | Breadcrumb): string {
  return typeof breadcrumb === 'string' ? breadcrumb : breadcrumb.href
}

function getTitle(breadcrumb: string | Breadcrumb): string {
  return typeof breadcrumb === 'string' ? breadcrumb : breadcrumb.title
}

const enabled = computed<boolean>(() => props.name !== undefined && props.name !== '' && !props.readonly)
</script>

<template>
  <el-header
    class="header"
    :style="{'margin-left': name ? '1rem': '5rem' }"
  >
    <el-button
      v-if="enabled"
      class="back-button"
      @click="router.back()"
    >
      <el-icon
        class="icon arrow-left"
        :size="22"
      >
        <component :is="ILxArrowLeft" />
      </el-icon>
    </el-button>

    <el-row
      data-cy="lxHeadingToolbar-component"
    >
      <el-icon class="icon">
        <component :is="breadcrumb.icon" />
      </el-icon>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          class="breadcrumb-item"
          :to="enabled ? breadcrumb.href : ''"
        >
          {{ $t(breadcrumb.title) }}
        </el-breadcrumb-item>

        <el-breadcrumb-item
          v-if="typeof name === 'string'"
          class="breadcrumb-item"
        >
          {{ te(name) ? $t(name) : name }}
        </el-breadcrumb-item>

        <template v-if="Array.isArray(name)">
          <el-breadcrumb-item
            v-for="(nameAsBreadcrumb, index) in name"
            :key="index"
            class="breadcrumb-item"
            :to="getHref(nameAsBreadcrumb)"
          >
            {{ te(getTitle(nameAsBreadcrumb)) ? $t(getTitle(nameAsBreadcrumb)) : getTitle(nameAsBreadcrumb) }}
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </el-row>
  </el-header>
</template>

<style lang="scss" scoped>
.header {
  height: 6.5rem;
  font-size: 30px;
  display: flex;
  align-items: center;

  .back-button {
    border: none;
    margin-right: 0.5rem;

    .arrow-left {
      transform: rotate(90deg);
      margin: 0;
    }
  }

    .icon {
      margin-right: 0.5rem;
    }

    .breadcrumb-item {
      font-size: 30px;
      line-height: 1.2;
    }

    :deep(.el-breadcrumb) {
      .el-breadcrumb__inner {
        max-width: 600px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

</style>
