import type { ACL_ROLES } from '~/types'

export interface TabItem {
  label: string
  to: string
  can: ACL_ROLES
}

export enum Visibility {
  HIDING = 'HIDING',
  HIDDEN = 'HIDDEN',
  SHOWING = 'SHOWING',
  SHOWN = 'SHOWN',
}
