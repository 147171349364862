import { router } from '~/plugins/router'
import type { FilterSelectionDefinition, FilterSelectionValue, FiltersSelection } from '~/types'
import { Filters, FiltersType } from '~/types'

export enum SearchMode {
  URL_SEARCH = 'urlSearch',
  FILTER_SEARCH = 'filterSearch',
}

export function useSearch(filterType: FiltersType, useFilters?: Filters[], searchMode?: SearchMode) {
  const route = useRoute()

  const defaultFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: 'name', operator: '=', value: '' }],
    [Filters.MODEL_DECLINATION, { key: 'lxcModelDeclination', operator: '=', value: [] }],
    [Filters.MODEL_TYPE, { key: 'lxcModelType', operator: '=', value: [] }],
    [Filters.CONNECTIVITY, { key: 'connectivityState', operator: '=', value: [] }],
    [Filters.STATE, { key: 'state', operator: '=', value: [] }],
    [Filters.STATUS, { key: 'status', operator: '=', value: [] }],
    [Filters.SECTORS, { key: 'sectors', operator: '=', value: [] }],
    [Filters.CERTIFICATE_EXPIRE_BEFORE, { key: 'certificateExpireBefore', operator: '=', value: '' }],
    [Filters.CERTIFICATE_EXPIRE_AFTER, { key: 'certificateExpireAfter', operator: '=', value: '' }],
    [Filters.FIRMWARE_NAME, { key: 'name', operator: '=', value: '', valueFormatter: (value: FilterSelectionValue) => `*${value}*` }],
    [Filters.FIRMWARE_NAME_OR_VERSION, { key: ['name', 'version'], operator: '=', value: '', valueFormatter: (value: FilterSelectionValue) => `*${value}*` }],
    [Filters.RANGE, { key: 'lxcModelType', operator: '=', value: [] }],
    [Filters.DATE_ADDED_BEFORE, { key: 'creationDate', operator: '<=', value: '' }],
    [Filters.DATE_ADDED_AFTER, { key: 'creationDate', operator: '>=', value: '' }],
    [Filters.FIRMWARE_VERSIONS, { key: 'compatibleSwVersion', operator: '=', value: [] }],
    [Filters.HARDWARE_VERSIONS, { key: 'compatibleHwVersion', operator: '=', value: [] }],
    [Filters.APPLICATION_NAME, { key: 'label', operator: '=', value: '', valueFormatter: (value: FilterSelectionValue) => `*${value}*` }],
    [Filters.APPMGT_DEVICE_TYPES, { key: 'deviceTypes', operator: '=', value: [] }],
    [Filters.APPMGT_DATE_UPDATED_BEFORE, { key: 'modified_at', operator: '<=', value: '' }],
    [Filters.APPMGT_DATE_UPDATED_AFTER, { key: 'modified_at', operator: '>=', value: '' }],
    [Filters.APPMGT_DATE_ADDED_BEFORE, { key: 'created_at', operator: '<=', value: '' }],
    [Filters.APPMGT_DATE_ADDED_AFTER, { key: 'created_at', operator: '>=', value: '' }],
    [Filters.DEVICE_FIRMWARE_VERSIONS, { key: 'firmwareVersion', operator: '=', value: [] }],
    [Filters.DEVICE_HARDWARE_VERSIONS, { key: 'hardwareVersion', operator: '=', value: [] }],
    [Filters.LOG_ACTION, { key: Filters.LOG_ACTION, operator: '=', value: [] }],
    [Filters.LOG_COMPONENT_ID, { key: Filters.LOG_COMPONENT_ID, operator: '=', value: '' }],
    [Filters.LOG_END_DATE, { key: Filters.LOG_END_DATE, operator: '=', value: '' }],
    [Filters.LOG_EVENT_TYPE, { key: Filters.LOG_EVENT_TYPE, operator: '=', value: '' }],
    [Filters.LOG_ENTITY_CLASS, { key: Filters.LOG_ENTITY_CLASS, operator: '=', value: '' }],
    [Filters.LOG_ENTITY_ID, { key: Filters.LOG_ENTITY_ID, operator: '=', value: [] }],
    [Filters.LOG_LEVEL, { key: Filters.LOG_LEVEL, operator: '=', value: [] }],
    [Filters.LOG_START_DATE, { key: Filters.LOG_START_DATE, operator: '=', value: '' }],
    [Filters.LOG_TYPE, { key: Filters.LOG_TYPE, operator: '=', value: '' }],
    [Filters.SORT_BY, { key: Filters.SORT_BY, operator: '=', value: '' }],
    [Filters.SORT_DIRECTION, { key: Filters.SORT_DIRECTION, operator: '=', value: '' }],
  ])

  // Restrict filters to provided used filters
  const managedFilters: FiltersSelection = new Map()
  defaultFilters.forEach((filterSelectionDefinition: FilterSelectionDefinition, filter: Filters) => {
    if (!useFilters || useFilters.includes(filter)) {
      managedFilters.set(filter, Object.assign({}, filterSelectionDefinition))
    }
  })
  const filters = reactive<FiltersSelection>(managedFilters)
  let onSearchCallback: Function

  const filterCounts = computed(() => getDropdownFiltersCount())
  const searchParams = computed(() => getSearchParams())

  // Default case => URL_SEARCH, watch the route changes and set the filters, then execute the search
  if (searchMode !== SearchMode.FILTER_SEARCH) {
    watch(route, () => setFiltersFromQueryParams())
  }

  const routePath = route.path
  /**
   * If filters set in url, apply as active filters
   */
  function setFiltersFromQueryParams() {
    if (route.path === routePath) {
      // Reset all the filters
      filters.forEach((_: FilterSelectionDefinition, filter: Filters) => {
        setFilter(filter, defaultFilters.get(filter)?.value)
      })

      // Apply URL filters
      for (const param in route.query) {
        const isParamArray = /\[\]$/.test(param)
        const filterName = isParamArray ? param.substring(0, param.length - 2) : param
        const filterValue = route.query[param]
        const filter = filterName as Filters
        if (filters.has(filter)) {
          setFilter(filter, isParamArray && !Array.isArray(filterValue) ? [filterValue] : filterValue)
        }
      }

      // Call callback
      if (onSearchCallback) {
        onSearchCallback()
      }
    }
  }

  /**
   * Set a filter
   * @param filter
   * @param value
   * @param fallbackValue
   */
  function setFilter(filter: Filters, value: any, fallbackValue?: any): void {
    const filtersSelection: FilterSelectionDefinition|undefined = filters.get(filter)

    if (filtersSelection) {
      filtersSelection.value = value
      filtersSelection.fallbackValue = fallbackValue
    }
  }

  /**
   * Execute a search with the filters set
   */
  function search() {
    // Default case => URL_SEARCH
    if (searchMode !== SearchMode.FILTER_SEARCH) {
      // Update the URL parameters, the search is launched by the route watcher
      const query = Object.assign({}, route.query)
      filters.forEach((filterSelectionDefinition: FilterSelectionDefinition, filter: Filters) => {
        if (Array.isArray(filterSelectionDefinition.value)) {
          if (filterSelectionDefinition.value.length === 0) {
            delete query[`${filter}[]`]
          } else {
            query[`${filter}[]`] = filterSelectionDefinition.value
          }
        } else {
          if (filterSelectionDefinition.value === '') {
            delete query[filter]
          } else {
            query[filter] = filterSelectionDefinition.value
          }
        }
      })
      query.t = Date.now().toString()
      router.push({ path: route.path, query })
    }
    // Case FILTER_SEARCH
    else if (onSearchCallback) {
      onSearchCallback()
    }
  }

  /**
   * Define the function to call on search execution
   * Execute the callback at the same time
   * @param callback
   */
  function onSearch(callback: Function) {
    onSearchCallback = callback

    // Default case => URL_SEARCH
    if (searchMode !== SearchMode.FILTER_SEARCH) {
      setFiltersFromQueryParams()
    } else {
      onSearchCallback()
    }
  }

  function getDropdownFiltersCount() {
    return [
      filters.get(Filters.NAME),
      filters.get(Filters.STATE),
      filters.get(Filters.STATUS),
      filters.get(Filters.MODEL_DECLINATION),
      filters.get(Filters.CONNECTIVITY),
      filters.get(Filters.MODEL_TYPE),
      filters.get(Filters.CERTIFICATE_EXPIRE_BEFORE),
      filters.get(Filters.RANGE),
      filters.get(Filters.DATE_ADDED_BEFORE),
      filters.get(Filters.FIRMWARE_VERSIONS),
      filters.get(Filters.APPMGT_DEVICE_TYPES),
      filters.get(Filters.APPMGT_DATE_ADDED_BEFORE),
      filters.get(Filters.APPMGT_DATE_UPDATED_BEFORE),
      filters.get(Filters.DEVICE_FIRMWARE_VERSIONS),
      filters.get(Filters.DEVICE_HARDWARE_VERSIONS),
      filters.get(Filters.LOG_ACTION),
      filters.get(Filters.LOG_COMPONENT_ID),
      filters.get(Filters.LOG_EVENT_TYPE),
      filters.get(Filters.LOG_ENTITY_CLASS),
      filters.get(Filters.LOG_ENTITY_ID),
      filters.get(Filters.LOG_LEVEL),
      filters.get(Filters.LOG_TYPE),
      filters.get(Filters.SORT_BY),
      filters.get(Filters.SORT_DIRECTION),
    ]
      .filter(filter => filter && filter.value.length !== 0)
      .flat()
      .length ?? 0
  }

  function initSearchFilterSelection() {
    const searchFilterSelection = new Map<Filters, FilterSelectionDefinition>()
    const searchKeys = Array.from(filters.keys())
      .filter((filterName: Filters) => {
        const definition = filters.get(filterName)
        if (definition != null) {
          return (definition.value != null && definition.value.length !== 0)
        }
        return false
      })
    for (const filterName of searchKeys) {
      searchFilterSelection.set(filterName, filters.get(filterName) as FilterSelectionDefinition)
    }
    return searchFilterSelection
  }

  function getSearchParams(): string | FiltersSelection {
    switch (filterType) {
      case FiltersType.TWO_AMPERSAND_SEPARATOR:
        return [...filters.values()]
          .filter(filterSelectionDefintion => filterSelectionDefintion.value.length || filterSelectionDefintion.fallbackValue?.length)
          .map(formatComplexFilter)
          .join('&&')
      case FiltersType.PIPE_SEPARATOR:
        return `(${[...filters.values()]
          .filter(filterSelectionDefintion => filterSelectionDefintion.value.length || filterSelectionDefintion.fallbackValue?.length)
          .map(formatComplexFilterAndOr)
          .join('|')})`
      case FiltersType.JSON:
        return JSON.stringify([...filters.values()]
          .filter(filterSelectionDefintion => filterSelectionDefintion.value.length || filterSelectionDefintion.fallbackValue?.length))
      case FiltersType.RAW:
        return filters.get(Filters.NAME)?.value as string
      case FiltersType.FILTERS_SELECTION:
        return initSearchFilterSelection()
      default:
        return ''
    }
  }

  function formatComplexFilterByKey(filterSelectionDefinition: FilterSelectionDefinition, key?: string): string {
    const value = filterSelectionDefinition.value.length
      ? filterSelectionDefinition.value
      : filterSelectionDefinition.fallbackValue

    const formattedValue = typeof value === 'string'
      ? filterSelectionDefinition.valueFormatter
        ? filterSelectionDefinition.valueFormatter(value)
        : value
      : `[${value?.join(',')}]`
    return `${key || filterSelectionDefinition.key}${filterSelectionDefinition.operator}${formattedValue}`
  }

  function formatComplexFilter(filterSelectionDefinition: FilterSelectionDefinition): string {
    return formatComplexFilterByKey(filterSelectionDefinition)
  }

  function formatComplexFilterAndOr(filterSelectionDefinition: FilterSelectionDefinition): string {
    if (Array.isArray(filterSelectionDefinition.key)) {
      const formattedValues = filterSelectionDefinition.key.map(key => formatComplexFilterByKey(filterSelectionDefinition, key))
      return `(${formattedValues.join("|'")})`
    } else {
      return formatComplexFilterByKey(filterSelectionDefinition)
    }
  }

  return {
    searchParams,
    filterCounts,
    filters,
    setFilter,
    onSearch,
    search,
  }
}
