import { AppDeviceState } from '@lxc/app-device-types'
import ILxcLightSlash from '~icons/lxc-light/slash'
import ILxcLightRotateCw from '~icons/lxc-light/rotate-cw'

export function useUpdateState() {
  const { t } = useI18n()

  function getState(state: AppDeviceState): AppDeviceState {
    switch (state) {
      case AppDeviceState.DEACTIVATED:
      case AppDeviceState.REGISTERED:
        return AppDeviceState.ACTIVATED
      case AppDeviceState.ACTIVATED:
        return AppDeviceState.DEACTIVATED
      default:
        return AppDeviceState.ACTIVATED
    }
  }

  function createDataForStateUpdate(isActivated: AppDeviceState, type: string, name: string) {
    switch (isActivated) {
      case AppDeviceState.ACTIVATED:
        return {
          activatedButton: t(`${type}.updateState.deactivate.execute`),
          title: t(`${type}.updateState.deactivate.execute`),
          description: t(`${type}.updateState.deactivate.description`, { name }),
          warning: t(`${type}.updateState.deactivate.warning`),
          icon: ILxcLightSlash,
        }
      case AppDeviceState.REGISTERED:
        return {
          activatedButton: t(`${type}.updateState.activate.execute`),
          title: t(`${type}.updateState.activate.execute`),
          description: t(`${type}.updateState.activate.description`, { name }),
          warning: '',
          icon: ILxcLightRotateCw,
        }
      case AppDeviceState.DEACTIVATED:
        return {
          activatedButton: t(`${type}.updateState.activate.execute`),
          title: t(`${type}.updateState.activate.execute`),
          description: t(`${type}.updateState.activate.description`, { name }),
          warning: '',
          icon: ILxcLightRotateCw,
        }
    }
  }

  return {
    getState,
    createDataForStateUpdate,
  }
}
