import type { SearchMode } from '~/composables/useSearch'
import { useSearch } from '~/composables/useSearch'
import { Filters, FiltersType } from '~/types'
import DtwinsService from '~/services/dtwins.service'
import { useFetchPage } from '~/composables/useFetchPage'
import { useSort } from '~/composables/useSort'
import type { DtwinModel } from '~/types/dtwinModel'
import type { Dtwin } from '~/types/dtwin'

export function useDtwins(searchMode?: SearchMode) {
  const service = DtwinsService.getDtwins.bind(DtwinsService)

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.TWO_AMPERSAND_SEPARATOR,
    [
      Filters.NAME,
      Filters.STATE,
      Filters.MODEL_TYPE,
      Filters.MODEL_DECLINATION,
      Filters.CONNECTIVITY,
      Filters.DEVICE_FIRMWARE_VERSIONS,
      Filters.DEVICE_HARDWARE_VERSIONS,
      Filters.CERTIFICATE_EXPIRE_AFTER,
      Filters.CERTIFICATE_EXPIRE_BEFORE,
      Filters.SECTORS,
    ],
    searchMode,
  )
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  return {
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    onSearch,
    search,
  }
}

export function useDtwin() {
  /**
   * Return the label of the model for a device twin
   * @param dtwin
   * @param models
   * @returns
   */
  const getDtwinTypeLabel = function(dtwin: Dtwin, models: DtwinModel[]): string {
    const model = models.find((model: DtwinModel) => model.uid === dtwin.deviceModelUid)
    return model?.friendlyName || model?.name || ''
  }

  return {
    getDtwinTypeLabel,
  }
}
