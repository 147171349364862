<script setup lang="ts">
import type { UserDataI } from '@lxc/app-device-types'
import { useAcl } from 'vue-simple-acl'
import LxcUserAccountCertificateStatus from './LxcUserAccountCertificateStatus.vue'
import userCertificateService from '~/services/userCertificate.service'
import { useConfigStore } from '~/stores/useConfigStore'
import type { UserCertificateI } from '~/types'
import { ACL_ROLES } from '~/types'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
const { featureToggle } = useConfigStore()
const acl = useAcl()
const { t } = useI18n()
const formRef = ref()
const isLoading = ref(false)
const refreshUserCertificateStatus = ref(false)
const userCertificateForm: UserCertificateI = reactive({
  id: '',
})
const props = defineProps<{
  user: UserDataI | null
}>()

const canViewCertificate = computed(() => featureToggle.isVisible('user-certificate') && acl.can(ACL_ROLES.USR_CERTIFICATES))

onMounted(() => {
  Object.assign(userCertificateForm, {
    id: props.user?.id,
  })
})

async function onRequestUserCertificate() {
  if (userCertificateForm.id) {
    isLoading.value = true
    const response = await userCertificateService.requestUserCertificate(userCertificateForm.id)

    isLoading.value = false

    if (LxcError.check(response)) {
      response.notify(NotificationKey.error)
    } else {
      refreshUserCertificateStatus.value = !refreshUserCertificateStatus.value
      showNotificationSuccess(t(NotificationKey.certificateGenerationSuccess))
    }
  }
}

</script>
<template>
  <el-container
    v-if="canViewCertificate"
    direction="vertical"
  >
    <lxc-container>
      <h2>{{ $t('user.userAccount.certificate.title') }}</h2>
    </lxc-container>

    <lxc-user-account-certificate-status
      :refresh-user-certificate-status="refreshUserCertificateStatus"
      :user="user"
    />
    <lxc-container>
      <el-form
        ref="formRef"
        :model="userCertificateForm"
      >
        <el-form-item>
          <el-button
            class="lxc-plain request-certificate-button"
            type="primary"
            :disabled="isLoading"
            @click="onRequestUserCertificate"
          >
            {{ $t('user.userAccount.forms.requestNewCertificate') }}
            <lxc-loader
              v-if="isLoading"
              class="loader"
            />
          </el-button>
        </el-form-item>
      </el-form>
    </lxc-container>
  </el-container>
</template>
<style lang="scss" scoped>
.loader{
  margin-left: .6em;
}
.request-certificate-button {
  margin: 2em 0
}
</style>
