<script setup lang="ts">
import dayjs from 'dayjs'
import { computed } from 'vue'
import type { FilterSelectionDefinition, FilterSelectionValue } from '~/types'

const { t } = useI18n()
const emit = defineEmits(['change:start', 'change:end'])

const props = defineProps<{
  label: string
  start: FilterSelectionDefinition
  end: FilterSelectionDefinition
  shortcuts?: Array<{ text: string; value: Date | Function }>
}>()

const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]'

const selection = computed({
  get(): string[] {
    return [
      Array.isArray(props.start) ? props.start.value[0] : props.start.value.toString(),
      Array.isArray(props.end) ? props.end.value[0] : props.end.value.toString(),
    ]
  },
  set(selection: string[]) {
    const [start, end] = selection ?? ['', '']
    const startValue = start ? dayjs(start, dateFormat).hour(0).minute(0).second(0).format(dateFormat) : ''
    const endValue = end ? dayjs(end, dateFormat).hour(23).minute(59).second(59).format(dateFormat) : ''

    emit('change:start', startValue)
    emit('change:end', endValue)
  },
})

</script>

<template>
  <div class="dd-item">
    <h3>
      {{ label }}
    </h3>
    <el-date-picker
      v-model="selection"
      type="daterange"
      unlink-panels
      :range-separator="$t('filters.date.to')"
      :start-placeholder="$t('filters.date.startPlaceholder')"
      :end-placeholder="$t('filters.date.endPlaceholder')"
      :format="$t('dateFormat.filterValidityFormat')"
      :value-format="dateFormat"
      :shortcuts="shortcuts"
      :teleported="false"
    />
  </div>
</template>
