<script lang="ts" setup>
import { type FirmwareI, FirmwareStatus } from '@lxc/app-device-types'
import ILxcLightRotateCw from '~icons/lxc-light/rotate-cw'
import { useActivateFirmware } from '~/composables/useFirmware'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
const { t } = useI18n()
const {
  activateConfirmDialogVisible,
  activateFirmware,
  activateError,
  canActivateFirmware,
  isActivateLoading,
  toggleActivateConfirmVisible,
} = useActivateFirmware()

const props = defineProps<{
  firmware: FirmwareI
}>()
const emit = defineEmits(['change'])
const buttonVisible = computed<boolean>(() => canActivateFirmware.value && props.firmware?.status === FirmwareStatus.DEACTIVATED)

const onActivateFirmware = async() => {
  await activateFirmware(props.firmware)

  if (LxcError.check(activateError.value)) {
    (activateError.value as LxcError).notify(NotificationKey.saveError)
  } else {
    const newFirwmare = { ...props.firmware }
    newFirwmare.status = FirmwareStatus.ACTIVATED
    showNotificationSuccess(t(NotificationKey.saveSuccess))
    emit('change', newFirwmare)
  }
}

const confirmActivateFirmware = async(event: MouseEvent) => {
  event?.preventDefault()
  event?.stopPropagation()

  if (props.firmware.uuid) {
    toggleActivateConfirmVisible()
  }
}

</script>

<template>
  <lxc-button
    v-if="buttonVisible"
    html-type="button"
    type="borderless"
    :disabled="isActivateLoading"
    :icon="(!isActivateLoading) ? ILxcLightRotateCw : undefined"
    :title="t('firmware.actions.activate.label')"
    class="invisible"
    @click.prevent="confirmActivateFirmware"
  >
    <lxc-loader
      v-if="isActivateLoading"
    />
  </lxc-button>

  <lxc-confirm-modal
    :is-dialog-visible="activateConfirmDialogVisible"
    :title="$t('firmware.actions.activate.label')"
    :description="$t('firmware.actions.activate.description', { name: props.firmware?.name ?? '' })"
    @update:toggle-dialog="toggleActivateConfirmVisible"
    @confirm="onActivateFirmware"
  />
</template>
<style lang="scss" scoped>
  button {
    :deep(svg) {
      height: 20px;
    }
  }
</style>
