<script setup lang="ts">
import type { DebuggerEventExtraInfo } from 'vue'
import { useLicenseStatus } from '~/composables/useLicenseStatus'
import { useLicenseStatusStore } from '~/stores/useLicenseStatusStore'

const {
  isBeyondExpirationDate,
  isBeyondLimitOfAllDeviceType,
  licenseExpirationDateWarning,
  featureBeyondLimitWarnings,
  getLicenseStatus,
  checkWarnings,
} = useLicenseStatus()

const emit = defineEmits(['isBeyondExpirationDate', 'isBeyondLimitOfAllDeviceType'])

// Add a listener to the license status in order to check the warnings every time the license status has changed
const licenseStatusStore = useLicenseStatusStore()
licenseStatusStore.$subscribe(async(mutation) => {
  if ((mutation.events as DebuggerEventExtraInfo).key === licenseStatusStore.licenseStateKeys.LICENSE_STATUS) {
    await checkWarnings()
    emit('isBeyondExpirationDate', isBeyondExpirationDate.value)
    emit('isBeyondLimitOfAllDeviceType', isBeyondLimitOfAllDeviceType.value)
  }
})

onMounted(getLicenseStatus)
</script>

<template>
  <div
    v-if="licenseExpirationDateWarning"
    :class="featureBeyondLimitWarnings.length > 0 ? 'mt-3 mx-3' : 'm-3'"
  >
    <lxc-alert
      :type="licenseExpirationDateWarning.alertType"
    >
      <template #title>
        {{ $t('license.caracteristics.date.end') }}
      </template>
      <p>{{ licenseExpirationDateWarning.message }}</p>
      <p>{{ $t('license.warningMessages.contactCutomerService') }}</p>
    </lxc-alert>
  </div>
  <div
    v-if="featureBeyondLimitWarnings?.length > 0"
    :class="licenseExpirationDateWarning ? 'mb-3 mx-3' : 'm-3'"
  >
    <lxc-alert
      v-for="featureBeyondLimitWarning in featureBeyondLimitWarnings"
      :key="featureBeyondLimitWarnings.indexOf(featureBeyondLimitWarning)"
      :type="featureBeyondLimitWarning.alertType"
    >
      <template #title>
        {{ $t(`license.content.feature.${featureBeyondLimitWarning.featureName}`) }}
      </template>
      <p>{{ featureBeyondLimitWarning.message }}</p>
      <p>{{ $t('license.warningMessages.contactCutomerService') }}</p>
    </lxc-alert>
  </div>
</template>
