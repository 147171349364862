<script setup  lang="ts">
import type { SectorI } from '@lxc/app-device-types'
import { storeToRefs } from 'pinia'
import { useSectorStore } from '~/stores/useSectorStore'

const { selectedSectors, availableSectors } = storeToRefs(useSectorStore())
const { changeSelection, retrieveAvailableSectors } = useSectorStore()

let delay: number
const selectedSectorCodes = ref<string[]>()
const options = ref<SectorI[]>([])
const queryString = ref<string>('')
let selectedSectorCodesChanging = false

const filteredOptions = computed(() => {
  if (queryString.value) {
    return options.value.filter(sector => sector.label.toLowerCase().includes(queryString.value.toLowerCase()))
  } else {
    return options.value
  }
})

const updateSelectedSectors: () => void = () => {
  if (!selectedSectorCodesChanging) {
    selectedSectorCodes.value = selectedSectors.value.map(sector => sector.code)
  }
}

const updateSelectedSectorCodes: () => void = () => {
  if (selectedSectorCodesChanging) {
    clearTimeout(delay)
    const newSelectedSectors = availableSectors.value.filter((sector: SectorI) => selectedSectorCodes.value?.includes(sector.code))
    delay = window.setTimeout(() => {
      changeSelection(newSelectedSectors)
      selectedSectorCodesChanging = false
    }, 500)
  }
}

watch(selectedSectorCodes, updateSelectedSectorCodes)
watch(selectedSectors, updateSelectedSectors)
watch(availableSectors, () => options.value = availableSectors.value)

onMounted(async() => {
  updateSelectedSectors()
  await retrieveAvailableSectors()
  options.value = availableSectors.value
})

/**
 * Apply debounce to avoid sending multiple request if multiple selection in short time
 */
function onSelectSectors() {
  selectedSectorCodesChanging = true
}
</script>

<template>
  <lxc-select
    v-model="selectedSectorCodes"
    :placeholder="$t('layout.menu.sector.placeholder')"
    class="w-full display-block"
    multiple
    @change="onSelectSectors"
  >
    <lxc-option
      v-for="option in filteredOptions"
      :key="option.code"
      :label="option.label"
      :value="option.code"
    />
  </lxc-select>
</template>
