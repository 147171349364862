<script setup lang="ts">
import type { ApplicationI } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import { getDateYearMonthDayInDateFormat } from '~/utils/date-tools'

const props = defineProps<{
  application: ApplicationI
}>()

const { t } = useI18n()
const dateFormat: string = t('dateFormat.date')

const options = computed(() => {
  const options: Array<string> = []
  const optionValueKey1 = props.application.specific?.options?.numericSoftware.find(current => current.key === 1)?.value || '-'
  const optionValueKey2 = props.application.specific?.options?.numericSoftware.find(current => current.key === 2)?.value || '-'
  const optionValueKey2InDate = getDateYearMonthDayInDateFormat(optionValueKey2.toString(), dateFormat)
  const licenseId = (props.application.specific?.licenseId) ? props.application.specific.licenseId : '-'

  options.push(t(`application.additionalMetadata.options.numericSoftware.1`, { value: optionValueKey1 }))
  options.push(t(`application.additionalMetadata.options.numericSoftware.2`, { value: optionValueKey2InDate }))
  options.push(t(`application.additionalMetadata.licenseId`, { value: licenseId }))

  return options
})

const divideIntoRows = computed(() => {
  const rows = []
  for (let i = 0; i < (options.value.length / 2); i++) {
    rows.push([options.value[i * 2], options.value[i * 2 + 1]])
  }
  return rows
})

const itemsColumnOne = computed(() => {
  const items = []
  items.push({ label: t('application.additionalMetadata.username'), input: props.application.specific?.username || '-' })
  items.push({ label: t('application.additionalMetadata.computerName'), input: props.application.specific?.computerName || '-' })
  return items
})

const itemsColumnTwo = computed(() => {
  const items = []
  const installationDate = dayjs(props.application.specific?.installationDate)
  items.push({ label: t('application.additionalMetadata.installationDate'), input: (installationDate.isValid() ? installationDate.format(dateFormat) : props.application.specific?.installationDate) || '-' })
  return items
})

const isSG400 = computed(() => {
  if (props.application?.model?.type === 'SG4000') {
    return true
  }
  return false
})

</script>

<template>
  <div class="flex flex-col gap-8">
    <h3>{{ $t('application.additionalMetadata.title') }}</h3>
  </div>
  <div v-if="!isSG400">
    <div class="flex">
      <div class="w-1/2">
        <lxc-form-item
          v-for="item in itemsColumnOne"
          :key="item"
          :label="item.label"
        >
          <lxc-input
            v-model="item.input"
            type="text"
            read-only
          />
        </lxc-form-item>
      </div>
      <div class="w-1/2">
        <lxc-form-item
          v-for="item in itemsColumnTwo"
          :key="item"
          :label="item.label"
        >
          <lxc-input
            v-model="item.input"
            type="text"
            read-only
          />
        </lxc-form-item>
      </div>
    </div>
  </div>
  <div v-if="isSG400">
    <h5>{{ $t('application.additionalMetadata.options.title') }}</h5>
    <table
      class="bg-white w-full mt-5"
    >
      <caption />
      <th />
      <tr
        v-for="(row, index) in divideIntoRows"
        :key="index"
        class="bg-gray-50 text-gray-900 border-b text-sm"
      >
        <td
          v-for="(item, innerIndex) in row"
          :key="innerIndex"
          class="w-1/2 text-left py-2 px-4 border border-collapse-slate-300"
        >
          {{ item }}
        </td>
      </tr>
    </table>
  </div>
</template>
