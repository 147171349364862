<script setup lang="ts">
import { Message, Phone, Plus } from '@element-plus/icons-vue'
import type { UserDataI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import usersService from '~/services/users.service'
import { useUserSession } from '~/stores/useUserSession'
import type { UserEmailI, UserInformationsI, UserLanguageI, UserPhoneI } from '~/types'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'

const props = defineProps<{
  user: UserDataI | null
  isModeAD: boolean
}>()

const emits = defineEmits(['update:user'])

const formInformationRef = ref()
const formEmailRef = ref()
const formPhoneRef = ref()
const { t, locale } = useI18n()
const { userSession } = useUserSession()
const isDisabledInformation = ref(true)
const isDisabledEmail = ref(true)
const isPhoneAdding = ref(false)
const isPhoneEditing = ref(false)

const newLanguage: Ref<UserLanguageI|null> = ref(null)

defineExpose({ saveLanguage })

const userInformationsForm: UserInformationsI = reactive({
  login: '',
  organisationName: '',
  firstName: '',
  lastName: '',
  groups: '',
  sectors: '',
  profiles: '',
})

const userEmailForm: UserEmailI = reactive ({
  email: '',
})

const userPhoneForm: UserPhoneI = reactive ({
  phone: '',
})

const userInformationsFormValidationRules = reactive({
  login: [
    { required: true, message: t('input.error.required'), trigger: 'blur' },
    { max: 80, message: t('input.error.maxLength', { maxLength: 80 }) },
  ],
  firstName: [
    { required: true, message: t('input.error.required'), trigger: 'blur' },
    { max: 80, message: t('input.error.maxLength', { maxLength: 80 }) },
  ],
  lastName: [
    { required: true, message: t('input.error.required'), trigger: 'blur' },
    { max: 80, message: t('input.error.maxLength', { maxLength: 80 }) },
  ],
})

const userEmailFormValidationRules = reactive({
  email: [
    { required: true, message: t('input.error.required'), trigger: 'blur' },
    { max: 255, message: t('input.error.maxLength', { maxLength: 255 }) },
    { pattern: /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: t('input.error.invalidFormat'), trigger: 'blur' },
  ],
})

// Get user groups
const userGroups = computed(() => {
  const groupsLabel: Array<string> = []
  props.user?.userGroups?.map(group => groupsLabel.push(group.label))
  return groupsLabel.join(', ')
})

// Get user sectors
const userSectorsLabels = computed(() => {
  const sectorsLabel: Array<string> = []
  userSession?.sectors?.map(sector => sectorsLabel.push(sector.label))
  return sectorsLabel.join(', ')
})

// Get user profil
const userProfiles = computed(() => {
  const profilesLabel: Array<string> = []
  props.user?.userProfiles?.map(profil => profilesLabel.push(profil.label))
  return profilesLabel.join(', ')
})

onMounted(() => {
  Object.assign(userInformationsForm, {
    login: props.user?.login,
    organisationName: props.user?.organization.name,
    firstName: props.user?.firstName,
    lastName: props.user?.lastName,
    groups: userGroups,
    sectors: userSectorsLabels,
    profiles: userProfiles,
  })
  Object.assign(userEmailForm, {
    email: props.user?.email,
  })
  Object.assign(userPhoneForm, {
    phone: props.user?.phone,
  })
})

function saveLanguage(language: UserLanguageI) {
  newLanguage.value = language
  saveUser()
}

async function saveUser() {
  const isFormInformationValid = await formInformationRef.value.validate().catch(() => false)
  const isFormEmailValid = await formEmailRef.value.validate().catch(() => false)

  if (props.user && (isFormInformationValid && isFormEmailValid)) {
    const response = await usersService.updateUser({
      id: props.user?.id,
      login: userInformationsForm.login,
      firstName: userInformationsForm.firstName,
      lastName: userInformationsForm.lastName,
      email: userEmailForm.email,
      organization: props.user.organization,
      language: newLanguage.value ? newLanguage.value.language : props.user?.language,
      status: props.user.status,
      phone: userPhoneForm.phone,
    })
    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError)
    } else {
      showNotificationSuccess(t(NotificationKey.saveSuccess))

      // build a user with the updated information
      const user = {
        ...props.user,
        ...response,
      }

      // update i18n locale
      locale.value = user.language

      // update the user session information on front side only
      if (userSession) {
        userSession.login = user.login
        userSession.firstName = user.firstName
        userSession.lastName = user.lastName
        userSession.email = user.email
        userSession.language = user.language
      }

      emits('update:user', user)
    }
  }
}
</script>

<template>
  <lxc-container>
    <h2>{{ $t('user.userAccount.userInformations.title') }}</h2>
    <lxc-mandatory />
    <p>{{ $t('user.userAccount.instructions.informations') }}</p>

    <el-form
      ref="formInformationRef"
      :rules="userInformationsFormValidationRules"
      :model="userInformationsForm"
      label-position="top"
      label-width="200px"
      class="user-informations-form"
    >
      <el-row :gutter="30">
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            :label="$t('user.userAccount.forms.organisationName')"
          >
            <el-input
              v-model="userInformationsForm.organisationName"
              type="text"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            :label="$t('user.form.userGroups.title')"
          >
            <el-input
              v-model="userInformationsForm.groups"
              type="text"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            :label="$t('user.userAccount.forms.sectors')"
          >
            <el-input
              v-model="userInformationsForm.sectors"
              type="text"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            :label="$t('user.userAccount.forms.profils')"
          >
            <el-input
              v-model="userInformationsForm.profiles"
              type="text"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            :label="$t('user.userAccount.forms.login')"
            prop="login"
          >
            <el-input
              v-model="userInformationsForm.login"
              type="text"
              :disabled="isDisabledInformation"
            />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            :label="$t('user.userAccount.forms.firstName')"
            prop="firstName"
          >
            <el-input
              v-model="userInformationsForm.firstName"
              type="text"
              :disabled="isDisabledInformation"
            />
          </el-form-item>
        </el-col>
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            :label="$t('user.userAccount.forms.lastName')"
            prop="lastName"
          >
            <el-input
              v-model="userInformationsForm.lastName"
              type="text"
              :disabled="isDisabledInformation"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        v-if="!isModeAD"
      >
        <el-button
          v-if="isDisabledInformation"
          type="primary"
          class="lxc-plain"
          @click="isDisabledInformation=!isDisabledInformation"
        >
          {{ $t('user.userAccount.forms.edit') }}
        </el-button>
        <el-button
          v-if="!isDisabledInformation"
          type="primary"
          @click="saveUser(); isDisabledInformation=!isDisabledInformation;"
        >
          {{ $t('user.userAccount.forms.validate') }}
        </el-button>
        <el-button
          v-if="!isDisabledInformation"
          type="primary"
          class="lxc-plain"
          @click="isDisabledInformation=!isDisabledInformation"
        >
          {{ $t('user.userAccount.forms.cancel') }}
        </el-button>
      </el-form-item>
    </el-form>

    <el-form
      ref="formEmailRef"
      :rules="userEmailFormValidationRules"
      :model="userEmailForm"
      label-position="top"
      label-width="200px"
      class="user-email-form"
    >
      <el-row :gutter="30">
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            :label="$t('user.userAccount.forms.email')"
            prop="email"
          >
            <p
              class="email-instruction"
            >
              {{ $t('user.userAccount.instructions.email') }}
            </p>
            <el-input
              v-model="userEmailForm.email"
              type="email"
              :disabled="isDisabledEmail"
              :prefix-icon="Message"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        v-if="!isModeAD"
      >
        <el-button
          v-if="isDisabledEmail"
          type="primary"
          class="lxc-plain"
          @click="isDisabledEmail=!isDisabledEmail"
        >
          {{ $t('user.userAccount.forms.edit') }}
        </el-button>
        <el-button
          v-if="!isDisabledEmail"
          type="primary"
          @click="saveUser(); isDisabledEmail=!isDisabledEmail;"
        >
          {{ $t('user.userAccount.forms.validate') }}
        </el-button>
        <el-button
          v-if="!isDisabledEmail"
          type="primary"
          class="lxc-plain"
          @click="isDisabledEmail=!isDisabledEmail"
        >
          {{ $t('user.userAccount.forms.cancel') }}
        </el-button>
      </el-form-item>
    </el-form>
    <el-form
      ref="formPhoneRef"
      :model="userPhoneForm"
      label-position="top"
      label-width="200px"
      class="user-phone-form"
    >
      <el-row
        :gutter="30"
      >
        <el-col
          :lg="6"
          :sm="12"
          :xs="24"
        >
          <el-form-item
            :label="$t('user.userAccount.forms.phoneNumber')"
          >
            <div
              v-if="!isModeAD"
            >
              <p
                v-if="!user?.phone"
                class="phone-instruction"
              >
                {{ $t('user.userAccount.instructions.phone') }}
              </p>
              <el-button
                v-if="!user?.phone && !isPhoneAdding"
                type="primary"
                :icon="Plus"
                link
                @click="isPhoneAdding=!isPhoneAdding; isPhoneEditing=!isPhoneEditing;"
              >
                {{ $t('button.add') }}
              </el-button>
            </div>
            <el-input
              v-if="user?.phone || isPhoneAdding"
              v-model="userPhoneForm.phone"
              type="text"
              :disabled="!isPhoneEditing"
              :prefix-icon="Phone"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        v-if="(user?.phone || isPhoneAdding) && !isModeAD"
      >
        <el-button
          v-if="!isPhoneEditing"
          type="primary"
          class="lxc-plain"
          @click="isPhoneEditing=!isPhoneEditing"
        >
          {{ $t('user.userAccount.forms.edit') }}
        </el-button>
        <el-button
          v-if="isPhoneEditing"
          type="primary"
          @click="saveUser(); isPhoneEditing=!isPhoneEditing;"
        >
          {{ $t('user.userAccount.forms.validate') }}
        </el-button>
        <el-button
          v-if="isPhoneEditing"
          type="primary"
          class="lxc-plain"
          @click="isPhoneEditing=!isPhoneEditing"
        >
          {{ $t('user.userAccount.forms.cancel') }}
        </el-button>
      </el-form-item>
    </el-form>
  </lxc-container>
</template>
<style lang="scss" scoped>
.user-informations-form {
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}

.user-email-form {
  margin-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
  .email-instruction {
    margin: 0;
    color: #666;
  }
}
.user-phone-form {
  margin-top: 1em;
  padding-bottom: 1em;
  .phone-instruction{
    margin: 0;
    color: #666;
  }
}
</style>
