<script setup lang="ts">
import type { ComponentI } from '@lxc/app-device-types'
import { ErrorCode } from '@lxc/app-device-types'
import LxcErrorClass from '~/utils/LxcError'

defineProps<{
  component: ComponentI
}>()

const error = new LxcErrorClass({ code: ErrorCode.UNEXPECTED })
</script>

<template>
  <h2>{{ $t(`platform.componentName.${component.name}`) }}</h2>
  <div
    v-if="component.componentVersion.modules"
  >
    <h4>{{ $t('platform.modules') }}</h4>
    <p
      v-for="(module, moduleIndex) in component.componentVersion.modules"
      :key="moduleIndex"
    >
      {{ module.name }} : {{ module.version }}
    </p>
    <h4>{{ $t('platform.apiVersion') }}</h4>
    <p>{{ $t('platform.apiVersion') }} : {{ component.componentVersion.apiVersions.toString() }}</p>
  </div>
  <div
    v-else
  >
    <lxc-error
      :error="error"
      class="half-container"
    />
  </div>
</template>
