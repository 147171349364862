import { useSearch } from '~/composables/useSearch'
import { FiltersType } from '~/types'
import { useFetchPage } from '~/composables/useFetchPage'
import { useSort } from '~/composables/useSort'
import ApplicationMgtService from '~/services/applicationMgt.service'
import { usePagination } from '~/composables/shared/usePagination'

export function useApplicationMgt() {
  const service = ApplicationMgtService.getApplicationMgts.bind(ApplicationMgtService)

  const { searchParams, filterCounts, filters, setFilter, search, onSearch } = useSearch(FiltersType.PIPE_SEPARATOR)
  const { sort, onSortChange } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })
  const { pagination } = usePagination(results)

  return {
    isLoading,
    results,
    error,
    filterCounts,
    filters,
    pagination,
    fetchData,
    setFilter,
    onSortChange,
    search,
    onSearch,
  }
}
