import { useAcl } from 'vue-simple-acl'
import { useSearch } from '~/composables/useSearch'
import type { SearchMode } from '~/composables/useSearch'

import { ACL_ROLES, FiltersType } from '~/types'
import { useSort } from '~/composables/useSort'
import { useFetchPage } from '~/composables/useFetchPage'
import actionsService from '~/services/actions.service'

export function useActions(searchMode?: SearchMode) {
  const service = actionsService.getActions.bind(actionsService)
  const itemType = 'action'

  const { can } = useAcl()

  const { searchParams, filterCounts, filters, setFilter, search, onSearch } = useSearch(FiltersType.RAW, undefined, searchMode)
  const { sort } = useSort()
  const { isLoading, results, error, fetchData } = useFetchPage({ service, searchParams, sort })

  function canManageItems() {
    return can(ACL_ROLES.LOGS_LIST_AUDIT)
  }

  return {
    canManageItems,
    error,
    fetchData,
    filterCounts,
    filters,
    isLoading,
    itemType,
    onSearch,
    results,
    search,
    setFilter,
  }
}
