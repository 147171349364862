<script setup lang="ts">
import { useDtwin, useDtwins } from '~/composables/useDtwins'
import { SearchMode } from '~/composables/useSearch'
import { PATHS } from '~/constants/paths'
import DtwinsService from '~/services/dtwins.service'
import type { DtwinModelPagination } from '~/types/dtwinModel'
import LxcError from '~/utils/LxcError'
import { formatIsoDate } from '~/utils/date-tools'

const props = defineProps<{
  reload: boolean
}>()

const emit = defineEmits(['update:reload'])

const {
  isLoading,
  results,
  error,
  fetchData,
  onSearch,
} = useDtwins(SearchMode.URL_SEARCH)

const {
  getDtwinTypeLabel,
} = useDtwin()

watch(() => props.reload, (reload) => {
  if (reload) {
    fetchData()
    emit('update:reload', false)
  }
})

const isLoadingModels: Ref<boolean> = ref(false)
const errorModels: Ref<LxcError | undefined> = ref()
const models: Ref<any | undefined> = ref()

const fetchModels = async() => {
  isLoadingModels.value = true

  const response: DtwinModelPagination = await DtwinsService.getModels(1, 999999999)
  if (LxcError.check(response)) {
    errorModels.value = response
  } else {
    models.value = response.result
    onSearch(fetchData)
  }

  isLoadingModels.value = false
}

fetchModels()
</script>

<template>
  <lxc-container
    :error="errorModels"
    :is-loading="isLoadingModels"
  >
    <lxc-table
      :context="results"
      :data="results?.result"
      :is-loading="isLoading"
      :error="error"
      :empty-text="$t('dtwins.list.empty')"
      @change-page-and-page-size="fetchData"
      @row-click="$router.push(`${PATHS.DTWINS}/${$event.uid}`)"
    >
      <lxc-table-column
        prop="attributes.friendlyName"
        :label="$t('dtwins.list.attributes.friendlyName')"
      />
      <lxc-table-column
        prop="attributes.type"
        :label="$t('dtwins.list.attributes.type')"
      >
        <template #default="scope">
          {{ getDtwinTypeLabel(scope.row, models) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        prop="attributes.serialNumber"
        :label="$t('dtwins.list.attributes.serialNumber')"
      />
      <lxc-table-column
        prop="features.firmware.reported.firmwareVersion"
        :label="$t('dtwins.list.attributes.firmwareVersion')"
      />
      <lxc-table-column
        prop="attributes.hardwareVersion"
        :label="$t('dtwins.list.attributes.hardwareVersion')"
      />
      <lxc-table-column
        :label="$t('dtwins.list.attributes.state')"
      >
        <template #default="scope">
          {{ $t(`dtwins.stateValue.${scope.row.attributes.deviceState}`) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        :label="$t('dtwins.list.attributes.createdAt')"
      >
        <template #default="scope">
          {{ formatIsoDate(scope.row.createdAt, $t('dateFormat.datetime')) }}
        </template>
      </lxc-table-column>
    </lxc-table>
  </lxc-container>
</template>
