<script setup lang='ts'>
const { t } = useI18n()
const props = defineProps({
  showConfirm: {
    type: Boolean,
    default: true,
  },
  dialogVisible: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    required: false,
  },
  disabledConfirm: {
    type: Boolean,
    required: false,
  },
})
const emit = defineEmits([
  'update:dialogVisible',
  'open',
  'opened',
  'cancel',
  'confirm',
])
</script>

<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="props.title"
    :width="props.width ?? '30%'"
    :append-to-body="true"
    @open="$emit('open')"
    @opened="$emit('opened')"
    @update:model-value="$emit('update:dialogVisible', $event)"
  >
    <slot />

    <template #footer>
      <div class="dialog-footer flex justify-end gap-4">
        <lxc-button
          type="secondary"
          html-type="button"
          data-cy="cancel-modal-button"
          :title="t('campaign.cancel')"
          @click="$emit('cancel')"
        >
          {{ $t('campaign.cancel') }}
        </lxc-button>
        <lxc-button
          v-if="showConfirm"
          type="primary"
          data-cy="confirm-modal-button"
          :title="t('campaign.confirm')"
          :disabled="disabledConfirm"
          @click="$emit('confirm')"
        >
          {{ $t('campaign.confirm') }}
        </lxc-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang='scss' scoped>

.dialog-footer {
  background-color: $menu-background-color;
  padding: 10px;
  margin : -20px;
  border-radius: inherit;
}

</style>
