<script setup lang="ts">
import type { UserDataI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { ref } from 'vue'
import filtersUtils from '~/utils/filters.utils'

const props = defineProps<{
  modelValue: UserDataI[]
  label: string
}>()

const emit = defineEmits(['update:modelValue'])
const userName: Ref<string> = ref('')

function initFilterMap(paramDevices?: UserDataI[]): Record<string, string | undefined> {
  const vFilterMap: Record<string, string | undefined> = {}

  if (paramDevices && paramDevices.length !== 0) {
    for (const device of paramDevices) {
      const tag = filtersUtils.getUserTagText(device, props.label)
      if (tag) {
        vFilterMap[tag] = device.id
      }
    }
  }

  return vFilterMap
}

function deleleTagFromList(id: string, list?: UserDataI[]) {
  if (list) {
    const index = list.findIndex(user => user.id === id)

    if (index != null && index >= 0) {
      list.splice(index, 1)
    }
  }
}

const selectedUsers = computed({
  get(): UserDataI[] {
    return props.modelValue ?? []
  },
  set(selected?: Array<UserDataI>) {
    emit('update:modelValue', selected)
  },
})

const isAnyAppliedFilter = computed(() => {
  return props.modelValue.length !== 0
})

const filterTags = computed((): string[] => {
  return props.modelValue.map(user => filtersUtils.getUserTagText(user, props.label)).filter(tag => tag !== undefined) as string[]
})

const filterMap = computed((): Record<string, string | undefined> => {
  return initFilterMap(props.modelValue)
})

const onTagDeleteClick = (tag: string) => {
  const userId = filterMap.value[tag]

  if (userId) {
    deleleTagFromList(userId, selectedUsers.value)
  }
}
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>

  <div class="rounded-lg bg-white text-gray-900 pt-6">
    <lxc-log-user-name-filter v-model="userName" />
    <lxc-log-user-list-filter
      v-model="selectedUsers"
      :name="userName"
    />
  </div>
</template>
