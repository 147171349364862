import type { PkiConnectorI, TestPkiConnectivity } from '@lxc/app-device-types'
import RestService from './rest.service'

class PkiConnectorService extends RestService {
  private BASE_URL = '/connector-pki'

  /**
   * Get PKI Connector configuration
   */
  getPkiConnectorConfiguration(): Promise<PkiConnectorI> {
    return this.$api.get(this.BASE_URL)
  }

  /**
   * Update Pki Connector Configuration
   * @param pkiConfiguration
   */
  updatePkiConnectorConfiguration(pkiConfiguration: PkiConnectorI): Promise<PkiConnectorI> {
    PkiConnectorService.verifyPkiConfiguration(pkiConfiguration)

    return this.$api.patch(`${this.BASE_URL}`, { pkiConfiguration })
  }

  /**
   * Test Pki Connector Configuration
   * @param pkiConfiguration
   */
  testPkiConnectorConfiguration(pkiConfiguration: PkiConnectorI): Promise<TestPkiConnectivity> {
    PkiConnectorService.verifyPkiConfiguration(pkiConfiguration)

    return this.$api.post(`${this.BASE_URL}/connectivity-test`, { pkiConfiguration })
  }

  private static verifyPkiConfiguration(pkiConfiguration: PkiConnectorI) {
    const HTTPS_PREFIX = 'https://'

    if (pkiConfiguration.url && !pkiConfiguration.url.startsWith(HTTPS_PREFIX)) {
      pkiConfiguration.url = HTTPS_PREFIX.concat(pkiConfiguration.url)
    }

    if (!pkiConfiguration.bootstrapTemplateName) {
      pkiConfiguration.bootstrapIntervalTime = undefined
    }
  }
}

export default new PkiConnectorService()
