<script setup lang="ts">
import { Filters } from '~/types'
import type { FilterOptions, FilterSelectionDefinition } from '~/types'
const { t } = useI18n()
const emit = defineEmits(['select', 'update:modelValue'])
const props = defineProps<{
  modelValue?: FilterSelectionDefinition
  header: string
  statusOptions: FilterOptions
}>()

const getRadioValue = (item: any, prop: string): any => {
  const vProps = prop.split('.')

  if (vProps.length > 1) {
    const attr = vProps.shift()
    if (attr) {
      return getRadioValue(item[attr], vProps.join('.'))
    }
  }

  return item[prop]
}

const selectedItem = computed<string>({
  get() {
    let items: Array<string> = []
    if (props.modelValue?.value && Array.isArray(props.modelValue?.value)) {
      items = props.modelValue?.value as Array<string>
    }
    return items.length !== 0 ? items[0] : ''
  },
  set(selected?: string | null) {
    const modelValue = {
      key: Filters.STATUS,
      operator: '=',
      value: (selected ? [selected] : []),
    }
    emit('update:modelValue', modelValue)
    emit('select', modelValue)
  },
})
</script>
<template>
  <div class="rounded-lg bg-white text-gray-900 p-6">
    <div
      v-for="(option, n) in statusOptions.options"
      :key="n"
    >
      <lxc-radio
        v-model="selectedItem"
        name="firmwareStatusFilter"
        :value="getRadioValue(option, 'value')"
        :label="getRadioValue(option, 'label')"
        :disabled="option.disabled"
      />
    </div>
  </div>
</template>
