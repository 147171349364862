import { LicenseI, LicenseStatusI, PlatformIdCardI } from '@lxc/app-device-types'
import RestService from './rest.service'

class LicenseService extends RestService {
  private BASE_URL = '/license'

  /**
   * Get the license properties
   * @returns 
   */
  getLicenseProperties(): Promise<LicenseI> {
    return this.$api.get(this.BASE_URL)
  }

  /**
   * Get the license status
   * @returns 
   */
  getLicenseStatus(): Promise<LicenseStatusI> {
    return this.$api.get(`${this.BASE_URL}/status`)
  }

  /**
   * Upload a license file
   * @param licenseFile
   */
  uploadLicenseFile(licenseFile: File): Promise<any> {
    const formData = new FormData()
    formData.append('licenseFile', licenseFile)
    return this.$api.post(`${this.BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: Infinity,
    })
  }

  /**
   * Return the platform ID card
   * @returns 
   */
  getPlatformIdCard(): Promise<PlatformIdCardI> {
    return this.$api.get(`${this.BASE_URL}/platformIdCard`)
  }
}

export default new LicenseService()