<script setup lang="ts">
import { DEFAULT_PAGE_SIZE } from '~/constants/constants'
import type { PaginationContext } from '~/types/PaginationContext'

const props = defineProps<{
  context: PaginationContext
  typeOfElement: string
}>()

const emit = defineEmits(['update:currentPage'])

const firstElementIndex = computed(() => props.context.pageSize * (props.context.currentPage - 1) + 1)
const lastElementIndex = computed(() => props.context.pageSize * (props.context.currentPage - 1) + props.context.pageCount)

</script>

<template>
  <footer class="pagination-wrapper">
    <p v-if="context.totalCount > 0">
      {{ firstElementIndex }} - {{ lastElementIndex }} {{ $t('pagination.on') }} {{ context.totalCount }}
      {{ typeOfElement }}
    </p>
    <p v-else>
      {{ $t('pagination.none') }} {{ typeOfElement }}
    </p>
    <el-pagination
      :current-page="context.currentPage"
      :page-size="DEFAULT_PAGE_SIZE"
      background
      layout="prev, pager, next"
      :total="context.totalCount"
      @current-change="$emit('update:currentPage', $event, context.pageSize)"
    />
  </footer>
</template>

<style lang="scss" scoped>
.pagination-wrapper {
  margin: 2rem auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
