import LxcCommon from '@lxc/app-device-common'
import ElementPlus from 'element-plus'
import { createApp } from 'vue'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import { FeatureToggleComponent as featureToggle } from 'vue-feature-toggle'
import { clickOutside } from './directives/clickOutside.directive'
import App from './App.vue'
import { router } from '~/plugins/router'
import '~/assets/styles/vendors/element-plus.scss'
import '~/assets/styles/main/main.scss'
import acl from '~/plugins/acl'
import { i18n, locale } from '~/plugins/i18n'
import { pinia } from '~/plugins/pinia'

// Import Tailwind Elements plugin
import 'tw-elements'

// Import Tailwind CSS
import '@lxc/css/main.css'

// Import common CSS
import '@lxc/app-device-common/dist/assets/index.css'

// Import ack CSS to reapply element-plus styles, to remove when element-plus will be be removed
import '~/assets/styles/ack-element-plus.scss'

const app = createApp(App)

// Use common components
app.use(LxcCommon)

app.directive('click-outside', clickOutside)

dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.locale(locale)

app.use(router)
app.use(pinia)
app.use(i18n)
app.use(ElementPlus)
app.use(acl)

app.component('FeatureToggle', featureToggle)

app.mount('#app')
