<script setup lang="ts">
import type { SlideMenuItem } from '@lxc/app-device-common/src/interfaces/slideMenuItem.interface'
import { CampaignOperationState, CampaignType } from '@lxc/app-device-types'
import { operationStateOptions } from './campaignOperationsFilters.config'
import { type FilterSelectionDefinition, Filters, Visibility } from '~/types'
import { appModelTypeOptions } from '~/components/applications/applicationList/applicationsFilters.config'
import { typeOptions } from '~/components/devices/deviceList/deviceFilters.config'

const { t } = useI18n()

const props = defineProps<{
  stateValue?: FilterSelectionDefinition
  objectTypeValue?: FilterSelectionDefinition
  currentCampaignType?: CampaignType|undefined
}>()

const selectedStates: Ref<Array<string>> = ref([])
const selectedObjectTypes: Ref<Array<string>> = ref([])
const isApplicationCertificateRenewalCampaignType: Ref<boolean> = computed(() => {
  return props.currentCampaignType === CampaignType.CRTCLT_RENEWAL_APP
})
const isShowTagSet = computed<boolean>(() => !!(props.stateValue?.value.length || props.objectTypeValue?.value.length))

const emit = defineEmits(['change', 'enter'])

enum FilterTagsToDisplay {
  DONE = CampaignOperationState.DONE,
  ERROR = CampaignOperationState.ERROR,
  CANCELLING = CampaignOperationState.CANCELLING,
  CANCELLED = CampaignOperationState.CANCELLED,
  PAUSED = CampaignOperationState.PAUSED,
  OP_RUNNING = CampaignOperationState.OP_RUNNING,
  PENDING = CampaignOperationState.PENDING,
  EXPIRED = CampaignOperationState.EXPIRED,
}

function onClearFilters() {
  selectedStates.value = []
  selectedObjectTypes.value = []
  applyFilter()
}

function applyFilter() {
  if (props.stateValue) {
    if (selectedStates.value.includes(CampaignOperationState.ERROR) && !selectedStates.value.includes(CampaignOperationState.OP_ERROR)) {
      selectedStates.value.push(CampaignOperationState.OP_ERROR)
    } else if (selectedStates.value.includes(CampaignOperationState.OP_ERROR) && !selectedStates.value.includes(CampaignOperationState.ERROR)) {
      selectedStates.value.splice(selectedStates.value.indexOf(CampaignOperationState.OP_ERROR), 1)
    }
    if (selectedStates.value.includes(CampaignOperationState.CANCELLED) && !selectedStates.value.includes(CampaignOperationState.OP_CANCELLED)) {
      selectedStates.value.push(CampaignOperationState.OP_CANCELLED)
    } else if (selectedStates.value.includes(CampaignOperationState.OP_CANCELLED) && !selectedStates.value.includes(CampaignOperationState.CANCELLED)) {
      selectedStates.value.splice(selectedStates.value.indexOf(CampaignOperationState.OP_CANCELLED), 1)
    }
    if (selectedStates.value.includes(CampaignOperationState.OP_RUNNING)
        && !selectedStates.value.includes(CampaignOperationState.RETRY)
        && !selectedStates.value.includes(CampaignOperationState.OP_RETRY)
        && !selectedStates.value.includes(CampaignOperationState.OP_PENDING)
        && !selectedStates.value.includes(CampaignOperationState.DELIVERED)
    ) {
      selectedStates.value.push(
        CampaignOperationState.RETRY,
        CampaignOperationState.OP_RETRY,
        CampaignOperationState.OP_PENDING,
        CampaignOperationState.DELIVERED,
      )
    } else if (selectedStates.value.includes(CampaignOperationState.RETRY)
              && selectedStates.value.includes(CampaignOperationState.OP_RETRY)
              && selectedStates.value.includes(CampaignOperationState.OP_PENDING)
              && selectedStates.value.includes(CampaignOperationState.DELIVERED)
              && !selectedStates.value.includes(CampaignOperationState.OP_RUNNING)
    ) {
      selectedStates.value.splice(selectedStates.value.indexOf(CampaignOperationState.RETRY), 1)
      selectedStates.value.splice(selectedStates.value.indexOf(CampaignOperationState.OP_RETRY), 1)
      selectedStates.value.splice(selectedStates.value.indexOf(CampaignOperationState.OP_PENDING), 1)
      selectedStates.value.splice(selectedStates.value.indexOf(CampaignOperationState.DELIVERED), 1)
    }
    emit('change', Filters.STATE, selectedStates.value)
  }
  if (props.objectTypeValue) {
    emit('change', Filters.MODEL_TYPE, selectedObjectTypes.value)
  }
  emit('enter')
}

const selectedFilterMap: Map<string, string> = new Map()
const stateTag = (stateValue: string) => `${t('filters.state').toLowerCase()} : ${t(`operation.${stateValue === CampaignOperationState.PENDING ? 'delivered' : stateValue.toLowerCase()}`)}`
const selectedTypeTag = (typeValue: string) => `${t('filters.type').toLowerCase()} : ${typeValue}`

const appliedFilterTags = computed((): string[] => {
  const tagFilters: string[] = []

  if (props.stateValue) {
    for (const stateValueCurrent of props.stateValue.value) {
      if (stateValueCurrent !== undefined && Object.values(FilterTagsToDisplay).includes(stateValueCurrent as FilterTagsToDisplay)) {
        const tag = stateTag(stateValueCurrent)
        tagFilters.push(tag)
      }
    }
  }
  if (props.objectTypeValue) {
    for (const selectedType of props.objectTypeValue.value) {
      const tag = selectedTypeTag(selectedType)
      tagFilters.push(tag)
    }
  }

  return tagFilters
})

const selectedFilterTags = computed((): string[] => {
  const tagFilters: string[] = []

  for (const stateValueCurrent of selectedStates.value) {
    if (Object.values(FilterTagsToDisplay).includes(stateValueCurrent as FilterTagsToDisplay)) {
      const tag = stateTag(stateValueCurrent)
      tagFilters.push(tag)
      selectedFilterMap.set(tag, stateValueCurrent)
    }
  }
  for (const selectedType of selectedObjectTypes.value) {
    const tag = selectedTypeTag(selectedType)
    tagFilters.push(tag)
    selectedFilterMap.set(tag, selectedType)
  }

  return tagFilters
})

const selectedFiltersByTypes = computed<Array<string | Array<string | undefined | null> | undefined | null>>(() =>
  [
    selectedStates.value,
    selectedObjectTypes.value,
  ],
)

function removeFilterDefinition(items: Ref<string[]> | undefined, filterDefinition: string|undefined) {
  if (filterDefinition !== undefined && items !== undefined) {
    const index = items.value.findIndex(value => value === filterDefinition)
    if (index !== -1) {
      switch (filterDefinition) {
        case CampaignOperationState.OP_RUNNING:
        case CampaignOperationState.ERROR:
        case CampaignOperationState.CANCELLED:
          removeSelectedStatesMultipleValues(filterDefinition)
          break
        default:
          items.value.splice(index, 1)
          break
      }
    }
  }
}

function removeSelectedStatesMultipleValues(filterDefinition: string) {
  const valuesToRemove: Ref<Array<CampaignOperationState>> = ref([])
  switch (filterDefinition) {
    case CampaignOperationState.OP_RUNNING:
      valuesToRemove.value.push(
        CampaignOperationState.OP_RUNNING,
        CampaignOperationState.RETRY,
        CampaignOperationState.OP_RETRY,
        CampaignOperationState.OP_PENDING,
        CampaignOperationState.DELIVERED,
      )
      break
    case CampaignOperationState.ERROR:
      valuesToRemove.value.push(CampaignOperationState.ERROR, CampaignOperationState.OP_ERROR)
      break
    case CampaignOperationState.CANCELLED:
      valuesToRemove.value.push(CampaignOperationState.CANCELLED, CampaignOperationState.OP_CANCELLED)
      break
  }
  valuesToRemove.value.forEach(value => selectedStates.value.splice(selectedStates.value.indexOf(value), 1))
}

function onDeleteSelectedTag(tag: string) {
  const filterDefinition: string | undefined = selectedFilterMap.get(tag)
  removeFilterDefinition(selectedStates, filterDefinition)
  removeFilterDefinition(selectedObjectTypes, filterDefinition)
}

function onAppliedTagDeleteClick(tag: string) {
  const filterDefinition = selectedFilterMap.get(tag)
  removeFilterDefinition(selectedStates, filterDefinition)
  removeFilterDefinition(selectedObjectTypes, filterDefinition)

  applyFilter()
}

// Does display some menus depending on props values
function getMenuItems() {
  const menuItems: SlideMenuItem[] = []
  if (props.stateValue) {
    menuItems.push({
      disabled: false,
      id: 'state',
      menuLabel: t('filters.state'),
      header: t('filters.state'),
      footerId: 'state-footer',
      footerEnabled: true,
    })
  }
  if (props.objectTypeValue) {
    menuItems.push({
      disabled: false,
      id: 'type',
      menuLabel: t('filters.type'),
      header: t('filters.type'),
      footerId: 'type-footer',
      footerEnabled: true,
    })
  }
  return menuItems
}
const menuItems: SlideMenuItem[] = getMenuItems()

const filterVisiblity = ref<Visibility>(Visibility.HIDDEN)

const onFilterShow = () => {
  filterVisiblity.value = Visibility.SHOWN
}

const onFilterHidden = () => {
  filterVisiblity.value = Visibility.HIDDEN
}

function discardFilter() {
  selectedStates.value = (props.stateValue ? Object.assign([], (props.stateValue as FilterSelectionDefinition).value as Array<string>) : [])
  selectedObjectTypes.value = (props.objectTypeValue ? Object.assign([], (props.objectTypeValue as FilterSelectionDefinition).value as Array<string>) : [])

  filterVisiblity.value = Visibility.HIDING
}
</script>
<template>
  <div class="relative mb-4">
    <lxc-filters
      :menu-items="menuItems"
      :selected-filters="selectedFilterTags"
      :filters-by-type="selectedFiltersByTypes"
      is-button-right
      @apply="applyFilter"
      @reset="onClearFilters"
      @delete-tag="onDeleteSelectedTag"
      @shown="onFilterShow"
      @hidden="onFilterHidden"
      @discard="discardFilter"
    >
      <template #state>
        <div class="rounded-lg bg-white text-gray-900 p-6">
          <lxc-checkbox
            v-for="stateOption in operationStateOptions.options"
            :key="stateOption.value"
            v-model="selectedStates"
            :label="$t(stateOption.label)"
            :value="stateOption.value"
          />
        </div>
      </template>
      <template #type>
        <div
          v-if="isApplicationCertificateRenewalCampaignType"
          class="rounded-lg bg-white text-gray-900 p-6"
        >
          <lxc-checkbox
            v-for="typeOption in appModelTypeOptions.options"
            :key="typeOption.value"
            v-model="selectedObjectTypes"
            :label="typeOption.label"
            :value="typeOption.value"
          />
        </div>
        <div
          v-else
          class="rounded-lg bg-white text-gray-900 p-6"
        >
          <lxc-checkbox
            v-for="typeOption in typeOptions.options"
            :key="typeOption.value"
            v-model="selectedObjectTypes"
            :label="typeOption.label"
            :value="typeOption.value"
          />
        </div>
      </template>
    </lxc-filters>
  </div>
  <div
    v-if="isShowTagSet"
  >
    <Teleport to="#appliedFilterTags">
      <div
        class="flex items-center justify-end mb-4"
      >
        <lxc-tag-set
          deletable
          type="primary"
          :data="appliedFilterTags"
          :delete-tooltip="$t('filters.deleteSelectedFilter')"
          @delete="onAppliedTagDeleteClick"
        />
        <a
          class="font-medium whitespace-nowrap ml-3"
          href="#"
          @click="onClearFilters"
        >
          {{ $t('filters.resetFilter', {count: appliedFilterTags.length}) }}
        </a>
      </div>
    </Teleport>
  </div>
</template>
