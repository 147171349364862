<script setup lang='ts'>
import { CampaignType, OperationType } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import { useCampaignObjectOperations } from '~/composables/useCampaignObjectOperations'

const { t } = useI18n()
const props = defineProps<{
  deviceId: string
  operationType?: OperationType
}>()

const campaignType = computed(() => {
  switch (props.operationType) {
    case OperationType.CRTCLT_RENEWAL_DVC:
      return CampaignType.CRTCLT_RENEWAL_DVC
    case OperationType.FIRMWARE_UPGRADE_DVC:
      return CampaignType.FIRMWARE_UPGRADE_DVC
  }
})

const {
  fetchData,
  isLoading,
  operations,
  error,
} = useCampaignObjectOperations(props.deviceId, campaignType.value)

const dateFormat = t('operation.dateFormat')

const operationType = (campaignType: CampaignType) => {
  switch (campaignType) {
    case CampaignType.CRTCLT_RENEWAL_DVC:
      return OperationType.CRTCLT_RENEWAL_DVC
    case CampaignType.FIRMWARE_UPGRADE_DVC:
      return OperationType.FIRMWARE_UPGRADE_DVC
  }
}

onMounted(fetchData)
</script>

<template>
  <lxc-table
    :is-loading="isLoading"
    :error="error"
    :data="operations?.data"
    :empty-text="$t('operation.empty')"
    :context="operations?.context"
    data-cy="product-details-operations-table"
    @change-page-and-page-size="fetchData"
  >
    <lxc-table-column
      prop="type"
      :label="$t('operation.type')"
    >
      <template #default="scope">
        {{ t(`operation.operationType.${operationType(scope.row.type)}`) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="createdAt"
      :label="$t('operation.createdAt')"
    >
      <template #default="scope">
        {{ dayjs(scope.row.createdAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="plannedStartAt"
      :label="$t('operation.launchedAt')"
    >
      <template #default="scope">
        {{ dayjs(scope.row.plannedStartAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="modifiedAt"
      :label="$t('operation.updatedAt')"
    >
      <template #default="scope">
        {{ dayjs(scope.row.modifiedAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="state"
      :label="$t('operation.state')"
    >
      <template #default="scope">
        <lxc-operation-state
          :state="scope.row.state"
          :with-label="false"
        />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
