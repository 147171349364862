import type { ApiListResult, AsyncLogI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import type { ReactiveVariable } from 'vue/macros'
import { useSort } from './useSort'
import { useFetchPage } from './useFetchPage'
import { SearchMode, useSearch } from '~/composables/useSearch'
import logsService, { LogsService } from '~/services/logs.service'
import type { FiltersSelection } from '~/types'
import { Filters, FiltersType } from '~/types'
import type LxcError from '~/utils/LxcError'

export interface LogResult {
  error: Ref<LxcError | null>
  isLoading: Ref<boolean>
  fetchData: (page: number, pageSize: number, sortOverride?: string, params?: Map<string, any>) => Promise<void>
  results: Ref<ApiListResult<AsyncLogI> | null>
  searchParams: ComputedRef<string | FiltersSelection>
  filterCounts: ComputedRef<number>
  filters: ReactiveVariable<FiltersSelection>
  setFilter: (filter: Filters, event: any) => void
  search: () => void
  onSearch: (callback: Function) => void
}

export function useLogs(): LogResult {
  const { searchParams, filterCounts, filters, setFilter, search, onSearch } = useSearch(FiltersType.FILTERS_SELECTION, [
    Filters.LOG_COMPONENT_ID,
    Filters.LOG_END_DATE,
    Filters.LOG_ENTITY_CLASS,
    Filters.LOG_ENTITY_ID,
    Filters.LOG_EVENT_TYPE,
    Filters.LOG_LEVEL,
    Filters.LOG_ACTION,
    Filters.LOG_START_DATE,
    Filters.LOG_TYPE,
    Filters.STATUS],
  SearchMode.URL_SEARCH)
  const service = LogsService.prototype.getLogs.bind(logsService)
  const { sort } = useSort()

  const { isLoading, results, error, fetchData } = useFetchPage<ApiListResult<AsyncLogI> | null>({ service, searchParams, sort })

  return {
    error,
    fetchData,
    isLoading,
    results,
    searchParams,
    filterCounts,
    filters,
    setFilter,
    search,
    onSearch,
  }
}
