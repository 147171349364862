<script setup lang="ts">
import type { Ref } from 'vue'
import { onMounted } from 'vue'
import type { SectorI, UserI } from '@lxc/app-device-types'
import { useUsers } from '~/composables/useUsers'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import { PATHS } from '~/constants/paths'
import { SectorResource } from '~/types'
import { router } from '~/plugins/router'

const { t } = useI18n()
const props = defineProps<{
  sector: SectorI
}>()

const {
  isLoading,
  results: users,
  error,
  fetchData,
  canManageItems,
  getDescription,
} = useUsers()

const selectedItems: Ref<any[]> = ref([])
const displayActions = computed(() => canManageItems(props.sector))

onMounted(fetchSectorUsers)

async function fetchSectorUsers(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  const params = new Map()
  params.set('sectorsInclude', [props.sector.code])
  await fetchData(page, pageSize, undefined, params)
}

async function refresh() {
  await fetchSectorUsers()
  selectedItems.value = []
}

function onRowClick(user: UserI) {
  router.push(`${PATHS.USER_MANAGEMENT_USERS}/${user.id}`)
}
</script>

<template>
  <lxc-sector-toolbar
    ref="sectorToolbar"
    primary-key="id"
    :selected-items="selectedItems"
    :sector="sector"
    :resource="SectorResource.USER"
    @change="refresh"
  >
    <el-table-column
      prop="lastName"
      :label="$t('table.header.lastname')"
    />
    <el-table-column
      prop="firstName"
      :label="$t('table.header.firstname')"
    />
    <el-table-column :label="$t('table.header.profile')">
      <template #default="scope">
        <el-tag
          v-for="(profile, index) in scope.row.userProfiles"
          :key="index"
        >
          {{ profile?.label }}
        </el-tag>
      </template>
    </el-table-column>
  </lxc-sector-toolbar>

  <lxc-container
    :is-loading="isLoading && !users"
    :error="error"
  >
    <lxc-sectors-details-table
      v-model="selectedItems"
      :is-loading="isLoading"
      :selection="displayActions"
      :items="users"
      :display-actions="displayActions"
      :resource="SectorResource.USER"
      :get-description="getDescription"
      @pagination:get-next-page="fetchSectorUsers"
      @actions:detach="(item) => $refs.sectorToolbar.openDetachModal(item)"
      @row-click="onRowClick"
    >
      <el-table-column
        prop="lastName"
        :label="$t('table.header.lastname')"
      />
      <el-table-column
        prop="firstName"
        :label="$t('table.header.firstname')"
      />
      <el-table-column :label="$t('table.header.profile')">
        <template #default="scope">
          <el-tag
            v-for="(profile, index) in scope.row.userProfiles"
            :key="index"
          >
            {{ profile?.label }}
          </el-tag>
        </template>
      </el-table-column>
    </lxc-sectors-details-table>
  </lxc-container>
</template>
