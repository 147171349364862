<script lang="ts" setup>
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/it'
import fr from 'element-plus/es/locale/lang/fr'
import en from 'element-plus/es/locale/lang/en'
import it from 'element-plus/es/locale/lang/it'
import es from 'element-plus/es/locale/lang/es'
import { _UnwrapAll } from 'pinia'
import webComponentsService from './services/webComponents.service'
import { useLicenseStatusStore } from './stores/useLicenseStatusStore'
import { useConfigStore } from '~/stores/useConfigStore'
import { useUserSession } from '~/stores/useUserSession'

const { getConfig } = useConfigStore()
const { getLicenseStatus } = useLicenseStatusStore()

const store = useUserSession()
const { locale } = useI18n()

const elementPlusLangs: Record<string, Object> = {
  fr,
  en,
  it,
  es,
}

const updateLanguage = () => {
  const currentLocale = store.userSession?.language ?? locale.value
  locale.value = currentLocale
  dayjs.locale(currentLocale)
}

/**
 * Load the Web Components scripts
 * Does not block if the loading failed
 */
const loadWebComponents = async() => {
  // Get Web Components configurations from AS
  const webComponents = await webComponentsService.getWebComponents()

  if (Array.isArray(webComponents)) {
    for (const webComponent of webComponents) {
      // Add Web Components script
      if (webComponent.url) {
        const script = document.createElement('script')
        script.src = webComponent.url
        script.type = 'module' // This attribute is required to make Web Components working
        document.head.appendChild(script)
      }
    }
  }
}

// Init after user session has changed
watch(() => store.userSession, () => {
  // Case when the user is authenticated
  if (store.isAuthenticated) {
    getLicenseStatus()
    updateLanguage()
    loadWebComponents()
  }
})

// Warning: the user can be not already authenticated here
onBeforeMount(updateLanguage)
onMounted(getConfig)
</script>

<template>
  <el-config-provider :locale="elementPlusLangs[locale] || elementPlusLangs.en">
    <router-view />
    <lxc-version />
  </el-config-provider>
</template>
