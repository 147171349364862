import type { Certificate, CertificateFormItemI, ListOfDataI } from '@lxc/app-device-types'
import RestService from './rest.service'

class TruststoreService extends RestService {
  private BASE_URL = '/truststore'

  /**
   * Get truststore configuration
   */
  getTruststoreConfigration(): Promise<ListOfDataI<Certificate>> {
    return this.$api.get(this.BASE_URL)
  }

  /**
   * Add or update certificate
   * @param certificate
   */
  addOrUpdateCertificate(certificate: CertificateFormItemI): Promise<CertificateFormItemI> {
    return this.$api.post(`${this.BASE_URL}`, { certificate })
  }
}

export default new TruststoreService()
