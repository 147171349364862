<script setup lang="ts">
import { FirmwareStatus } from '@lxc/app-device-types'

const props = defineProps<{
  status?: string
}>()

const statusColor = computed<string>((): string => {
  let color: string

  switch (props.status) {
    case FirmwareStatus.ACTIVATED:
      color = 'primary'
      break
    case FirmwareStatus.DEACTIVATED:
    default:
      color = 'danger'
      break
  }
  return color
})
</script>

<template>
  <lxc-badge
    v-if="status != null"
    :type="statusColor"
    text-white
  >
    {{ $t(`firmware.status.${props.status}`) }}
  </lxc-badge>
</template>
