<script setup lang="ts">
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { ApplicationI } from '@lxc/app-device-types'
import { useApplication } from '~/composables/useApplication'
import { SearchMode } from '~/composables/useSearch'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import { Filters } from '~/types'
const emit = defineEmits(['update:modelValue'])

const props = defineProps<{
  declinations?: Array<string>
  name?: string
  modelValue?: ApplicationI[]
  noAction?: boolean
  selectedApplications?: Array<ApplicationI>
  types?: Array<string>
}>()

const {
  isLoading,
  results: applications,
  filters,
  error,
  fetchData,
  setFilter,
  onSortChange,
  getHasActionStatusLabel,
  search,
  onSearch,
} = useApplication(SearchMode.FILTER_SEARCH)

const filterMap: Ref<Map<Filters, any> | undefined> = ref()

async function loadData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  const params = new Map()
  await fetchData(page, pageSize, undefined, params)
}

// Apply provided default filters
const updateListFilter = () => {
  if (filterMap.value != null) {
    for (const [filter, value] of filterMap.value) {
      setFilter(filter, value ?? '')
    }
  }
}

watch(() => props.name, (filterByName) => {
  if (!filterMap.value) {
    filterMap.value = new Map<Filters, any>()
  }
  if (!filterByName) {
    if (filterMap.value.has(Filters.NAME)) {
      filterMap.value.set(Filters.NAME, '')
    }
  } else {
    filterMap.value.set(Filters.NAME, filterByName)
  }

  updateListFilter()
  search()
})

const updateFilterMap = (filterMapKey: Filters, filterValues?: string[]) => {
  if (!filterMap.value) {
    filterMap.value = new Map<Filters, any>()
  }
  if (!filterValues || filterValues.length === 0) {
    if (filterMap.value.has(filterMapKey)) {
      filterMap.value.set(filterMapKey, [])
    }
  } else {
    filterMap.value.set(filterMapKey, filterValues)
  }

  updateListFilter()
  search()
}

watch(() => props.types, (filterValues) => {
  updateFilterMap(Filters.MODEL_TYPE, filterValues)
})

watch(() => props.declinations, (filterValues) => {
  updateFilterMap(Filters.MODEL_DECLINATION, filterValues)
})

const visibleData = computed(() => {
  return applications.value?.data.length ?? 0 <= 10 ? applications.value?.data : applications.value?.data.slice(0, 10) ?? []
})

const selectedItems = computed({
  get() {
    if (props.modelValue) {
      return props.modelValue.map(device => device.id ?? '')
    }

    return []
  },
  set(selected?: Array<string>) {
    if (selected != null) {
      const applicationItems: Array<ApplicationI | undefined> = selected.map((currentApplicationId) => {
        // If the application is not in the application list found, use modelValue where there was the previous selected application list
        let application: ApplicationI | undefined = applications.value?.data.find(currentApplication => currentApplication.id === currentApplicationId)
        if (!application) {
          application = props.modelValue?.find(currentApplication => currentApplication.id === currentApplicationId)
        }
        return application
      }).filter(currentApplication => currentApplication !== undefined)
      emit('update:modelValue', applicationItems)
    } else {
      emit('update:modelValue', [])
    }
  },
})

onSearch(loadData)
</script>

<template>
  <div class="pb-6 px-6 overlow-y-auto">
    <lxc-filter-checklist
      v-model="selectedItems"
      :data="visibleData"
      :is-loading="isLoading"
      :empty-text="$t('application.empty')"
      :header="$t('logs.filters.applications.label')"
      header-class="underline text-base"
      prop="name"
      checkbox-prop="id"
    />
  </div>
</template>
