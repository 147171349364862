<script lang='ts' setup>
import ILxValid from '~icons/lx/valid'

const emit = defineEmits(['cancel', 'submit'])
const { t } = useI18n()
</script>
<template>
  <el-row
    justify="center"
    class="action-button-row"
  >
    <el-button
      plain
      class="lxc-plain"
      @click="$emit('cancel', $event)"
    >
      {{ $t('button.cancel') }}
    </el-button>
    <el-button
      type="primary"
      :icon="ILxValid"
      @click="$emit('submit', $event)"
    >
      {{ $t('button.validate') }}
    </el-button>
  </el-row>
</template>

<style lang='scss' scoped>
.action-button-row {
  margin-top: 56px;
}
</style>
