<script setup lang="ts">
import { ServicesStatus } from '@lxc/app-device-types'

const props = defineProps<{
  title: string
  serviceStatus: ServicesStatus
}>()

const isServiceLoaded = computed(() => props?.serviceStatus === ServicesStatus.LOADED)
</script>

<template>
  <dl
    v-if="serviceStatus !== ServicesStatus.DISABLED"
    class="description-container"
  >
    <dt class="description-title">
      <span
        v-if="isServiceLoaded"
        class="circle-status-loaded"
      />
      <span
        v-else
        class="circle-status-not-loaded"
      />
    </dt>
    <dd class="description-content">
      {{ props.title }}
    </dd>
  </dl>
</template>

<style lang='scss' scoped>
.description-container {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

.circle-status {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius:50%;
}

.circle-status-loaded {
  @extend .circle-status;
  border: 1px solid $success-color;
  background-color: $light-success-color;
}

.circle-status-not-loaded {
  @extend .circle-status;
  border: 1px solid $high-priority-color;
  background-color: $light-high-priority-color;
}

.description-content {
  margin-left: 20px;
  font-size: 14px;
}

</style>
