<script lang='ts' setup>
import { reactive } from 'vue'
import type { RoleI, UserProfileI } from '@lxc/app-device-types'
import userProfilesService from '~/services/userProfiles.service'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import { useRoles } from '~/composables/useRoles'
import { PATHS } from '~/constants/paths'
import LxcError from '~/utils/LxcError'
import { useElTable } from '~/composables/useElTable'

const { t } = useI18n()
const router = useRouter()
const { fetchRoles, roles, error } = useRoles()
const { getCellStyle } = useElTable()

const profileFormRef = ref()
const profileForm = reactive<UserProfileI>({
  label: '',
  roles: [],
})
const rules = reactive({
  label: [
    { required: true, message: t('profile.validation.name'), whitespace: true, trigger: 'blur' },
    { max: 50, message: t('input.error.maxLength', { maxLength: 50 }), whitespace: true, trigger: 'blur' },
  ],
})

onMounted(async() => await fetchRoles())

async function onSubmit() {
  const isFormValid = await profileFormRef.value.validate().catch(() => false)

  if (isFormValid) {
    const response = await userProfilesService.createUserProfile(profileForm)

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError)
    } else {
      showNotificationSuccess(t(NotificationKey.saveSuccess))
      await router.push(PATHS.USER_MANAGEMENT_PROFILES)
    }
  }
}

function cancelProfileCreation() {
  router.push(PATHS.USER_MANAGEMENT_PROFILES)
}

function handleSelectionChange(value: RoleI[]) {
  profileForm.roles = (value ?? []).map(v => ({ code: v.code, orgCode: v.orgCode }))
}
</script>

<template>
  <el-container direction="vertical">
    <lxc-breadcrumb name="profile.button.create" />

    <lxc-container class="half-container">
      <lxc-mandatory />

      <el-form
        ref="profileFormRef"
        :model="profileForm"
        :rules="rules"
        @submit.prevent
      >
        <h2 class="section-title">
          {{ $t('section.identity.title') }}
        </h2>

        <el-form-item
          :label="$t('input.profileName')"
          prop="label"
        >
          <el-input
            v-model="profileForm.label"
            type="text"
            size="large"
          />
        </el-form-item>

        <el-divider />

        <h2 class="section-title">
          {{ $t('section.roles.title') }}
        </h2>

        <el-table
          :data="roles"
          :fit="true"
          stripe
          row-class-name="table-row-clickable"
          :cell-style="getCellStyle"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            prop="translation.label"
            :label="$t('profile.roles')"
          />
          <el-table-column
            type="selection"
            width="50"
          />
        </el-table>

        <lxc-cancel-or-submit-buttons
          @cancel="cancelProfileCreation"
          @submit="onSubmit"
        />
      </el-form>
    </lxc-container>
  </el-container>
</template>
