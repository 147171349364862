<script setup lang="ts">
import type { DashboardActions } from '@lxc/app-device-types'
import { useAcl } from 'vue-simple-acl'
import type { Ref } from 'vue'
import { deviceStateOptionNew } from '~/components/devices/deviceList/deviceFilters.config'
import { appStateOptionNew } from '~/components/applications/applicationList/applicationsFilters.config'
import { PATHS } from '~/constants/paths'
import type { DashboardCardStatus } from '~/types'
import { verifyCardStatus } from '~/utils/dashboard.utils'
import ILxExtLink from '~icons/lx/external-link'
import { ACL_ROLES } from '~/types'

const { t } = useI18n()
const { can } = useAcl()
const router = useRouter()

const props = defineProps<{
  status: DashboardCardStatus
  actions?: DashboardActions
}>()

const canDisplayDevices = computed(() => can(ACL_ROLES.DISPLAY_DEVICES))
const canDisplayApplications = computed(() => can(ACL_ROLES.DISPLAY_APPLICATIONS))

function navigateToDevices() {
  if (canDisplayDevices.value) {
    router.push({ path: PATHS.DEVICES_DVTM_ESOFT, query: { state: deviceStateOptionNew } })
  }
}

function navigateToApplications() {
  if (canDisplayApplications.value) {
    router.push({ path: PATHS.APPLICATIONS, query: { state: appStateOptionNew } })
  }
}

const deviceDisplay: Ref<string> = computed(() => `${props.actions?.devices ?? '-'}`)
const applicationsDisplay: Ref<string> = computed(() => `${props.actions?.applications ?? '-'}`)

</script>

<template>
  <lxc-dashboard-card
    :title="t('dashboard.actions.title')"
    :display-header-margin="true"
    :status="verifyCardStatus(props.status, props.actions)"
  >
    <div>
      <dl
        class="description-container"
        @click="navigateToDevices"
      >
        <dt class="description-title">
          {{ deviceDisplay }}
        </dt>
        <dd class="description-content">
          {{ $t('dashboard.actions.devices') }}
          <el-icon
            v-if="canDisplayDevices"
            :size="8"
          >
            <i-lx-ext-link />
          </el-icon>
        </dd>
      </dl>
      <dl
        class="description-container"
        @click="navigateToApplications"
      >
        <dt class="description-title">
          {{ applicationsDisplay }}
        </dt>
        <dd class="description-content">
          {{ $t('dashboard.actions.applications') }}
          <el-icon
            v-if="canDisplayApplications"
            :size="8"
          >
            <i-lx-ext-link />
          </el-icon>
        </dd>
      </dl>
    </div>
  </lxc-dashboard-card>
</template>

<style lang='scss' scoped>
.description-container {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

.description-title {
  color: $light-blue-color;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  width: 30px;
}

.description-content {
  margin-left: 25px;
  font-size: 14px;
  cursor: pointer;
}

</style>
