import type { ListOfDataI, UserDataI, UserSessionI } from '@lxc/app-device-types'
import RestService from './rest.service'
import { BASE_URL_MAP, DEFAULT_PAGE_SIZE, SERVICE_URL_MAP } from '~/constants/constants'
import type { UserIdentityI } from '~/types'

export class UsersService extends RestService {
  protected BASE_URL = BASE_URL_MAP.USERS

  /**
   * Retrieve current user
   */
  getCurrentUser(): Promise<UserSessionI> {
    return this.$api.get(`${this.BASE_URL}${SERVICE_URL_MAP.CURRENT_USER}`)
  }

  /**
   * Retrieve users
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @param otherParams
   */
  getUsers(page: number, pageSize: number = DEFAULT_PAGE_SIZE, search: string, sort?: string, otherParams?: Map<string, any>): Promise<ListOfDataI<UserDataI>> {
    const params = {
      page,
      pageSize,
      search,
      sort,
      ...(otherParams ? Object.fromEntries(otherParams) : {}),
    }

    return this.$api.get(this.BASE_URL, { params })
  }

  /**
   * Set session
   * @param code
   * @param redirectUri
   */
  setSession(code: string, redirectUri: string): Promise<UserSessionI> {
    const params = {
      code,
      redirectUri,
    }

    return this.$api.get(`${this.BASE_URL}/session`, { params })
  }

  /**
   * Get user by id
   * @param userId
   */
  getUserById(userId: string): Promise<UserDataI> {
    return this.$api.get(`${this.BASE_URL}/${userId}`)
  }

  /**
   * Get multiple users details
   * @param userId
   */
  getUsersById(userId: string[]): Promise<Array<UserDataI>> {
    const data = {
      id: userId,
    }
    const config = {
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
      },
    }
    return this.$api.post(`${this.BASE_URL}/getUsersById`, data, config)
  }

  /**
   * Retrieve login url
   * @param redirectUri
   */
  getLoginUrl(redirectUri: string): Promise<{ loginUrl: string }> {
    const params = { redirectUri }

    return this.$api.get(`${this.BASE_URL}/getLoginUrl`, { params })
  }

  /**
   * Retrieve logout url
   * @param redirectUri
   */
  logout(redirectUri: string): Promise<{ logoutUrl: string }> {
    const params = { redirectUri }

    return this.$api.get(`${this.BASE_URL}/logout`, { params })
  }

  /**
   * Create user
   * @param user
   */
  createUser(user: UserIdentityI): Promise<UserIdentityI> {
    return this.$api.post(`${this.BASE_URL}`, { user })
  }

  /**
   * Update user
   * @param user
   */
  updateUser(user: UserIdentityI): Promise<UserIdentityI> {
    return this.$api.put(`${this.BASE_URL}/${user.id}`, { user })
  }

  /**
   * Add user groups
   * @param userId
   * @param userGroupCodes
   */
  addUserGroups(userId: string, userGroupCodes: string[]) {
    return this.$api.put(`${this.BASE_URL}/${userId}/addUserGroups`, { userGroupCodes })
  }

  /**
   * remove user groups
   * @param userId
   * @param userGroupCodes
   */
  removeUserGroups(userId: string, userGroupCodes: string[]) {
    return this.$api.put(`${this.BASE_URL}/${userId}/removeUserGroups`, { userGroupCodes })
  }

  /**
   * Set user profile
   * @param userId
   * @param userProfileCode
   * @param oldUserProfileCode
   */
  setUserProfile(userId: string, userProfileCode: string, oldUserProfileCode?: string): Promise<void> {
    return this.$api.put(`${this.BASE_URL}/${userId}/setUserProfile`, {
      userProfileCode,
      oldUserProfileCode,
    })
  }

  /**
   * Add user profiles
   * @param userId
   * @param userProfileCodes
   */
  addUserProfiles(userId: string, userProfileCodes: string[]) {
    return this.$api.put(`${this.BASE_URL}/${userId}/addUserProfiles`, { userProfileCodes })
  }

  /**
   * remove user groups
   * @param userId
   * @param userProfileCodes
   */
  removeUserProfiles(userId: string, userProfileCodes: string[]) {
    return this.$api.put(`${this.BASE_URL}/${userId}/removeUserProfiles`, { userProfileCodes })
  }

  /**
   * Remove user
   * @param userId
   */
  removeUser(userId: string): Promise<void> {
    return this.$api.delete(`${this.BASE_URL}/${userId}`)
  }
}

export default new UsersService()
