export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_FIRST_PAGE = 1
export const FILTER_SEARCH_TXT_MIN_LENGTH = 3

export const DEFAULT_SORT_OPERATION = '-modifiedAt'

export const DONNUT_TITLE_COLOR = '#002f5d'
export const DONNUT_FONT_FAMILY = 'Montserrat'
export const BASE_URL_MAP = {
  ACTIONS: '/actions',
  USERS: '/users',
}
export const SERVICE_URL_MAP = {
  CURRENT_USER: '/me',
}
