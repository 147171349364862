import type { ApiListResult, OperationI, OperationType } from '@lxc/app-device-types'
import DeviceService from '~/services/device.service'
import LxcError from '~/utils/LxcError'
import { DEFAULT_SORT_OPERATION } from '~/constants/constants'

export function useDeviceOperations(deviceId: string, operationType?: OperationType, hasCallbackUrl?: boolean) {
  const operations = ref<ApiListResult<OperationI> | null>(null)
  const error = ref<LxcError | null>(null)
  const isLoading = ref<boolean>(false)

  async function fetchData(page?: number, pageSize?: number, sort: string = DEFAULT_SORT_OPERATION) {
    isLoading.value = true
    error.value = null

    const response = await DeviceService.getDeviceOperations(deviceId, page, pageSize, sort, operationType, hasCallbackUrl)

    if (LxcError.check(response)) {
      operations.value = null
      error.value = response
    } else {
      operations.value = response
    }

    isLoading.value = false
  }

  return {
    fetchData,
    isLoading,
    operations,
    error,
  }
}
