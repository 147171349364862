import RestService from './rest.service'
import type { Dtwin, DtwinPagination } from '~/types/dtwin'
import type { DtwinModelPagination } from '~/types/dtwinModel'

export class DtwinsService extends RestService {
  protected BASE_URL = '/dtwins'
  protected PROXY_URL_DTWINS = '/proxy?serviceId=DTWINS'
  protected PROXY_URL_DATAM = '/proxy?serviceId=DATAM'

  /**
   * Retrieve the device models
   * @param page
   * @param pageSize
   * @returns
   */
  getModels(page: number, pageSize: number): Promise<DtwinModelPagination> {
    const params: Record<string, any> = {
      page,
      pageSize,
    }

    const target = `deviceModels?${new URLSearchParams(params).toString()}`

    return this.$api.get(this.PROXY_URL_DTWINS, { params: { target } })
  }

  /**
   * Retrieve devices
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @param otherParams
   */
  getDtwins(page: number, pageSize: number, search?: any, sort?: string, otherParams?: Map<string, any>): Promise<DtwinPagination> {
    const params: Record<string, any> = {
      page,
      pageSize,
      sort,
      search,
      ...(otherParams ? Object.fromEntries(otherParams) : {}),
    }

    const target = `deviceTwins?${new URLSearchParams(params).toString()}`

    return this.$api.get(this.PROXY_URL_DTWINS, { params: { target } })
  }

  /**
   * Retrieve device
   * @param id
   */
  getDtwin(id: string): Promise<Dtwin> {
    return this.$api.get(`${this.PROXY_URL_DTWINS}`, { params: { target: `deviceTwins/${id}` } })
  }

  /**
   * Import devices
   */
  import(file: File) {
    const formData = new FormData()
    formData.append('file', file)
    return this.$api.post(`${this.BASE_URL}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: Infinity,
    })
  }

  /**
   * Retrieve device configuration
   * @param id
   * @returns
   */
  getDtwinTelemetries(id: string): Promise<any> {
    return this.$api.get(`${this.PROXY_URL_DTWINS}`, { params: { target: `deviceTwins/${id}/dataviz/configuration` } })
  }

  getDtwinTelemetryValues(id: string, name: string, startTimestampNs: number, endTimestampNs: number, calculatedTelemetry: boolean): Promise<any> {
    return this.$api.get(
      this.PROXY_URL_DATAM,
      {
        params: {
          target: `devices/${id}/telemetry/${name}?startDateTime=${startTimestampNs}&endDateTime=${endTimestampNs}&calculatedTelemetry=${calculatedTelemetry}`,
        },
      },
    )
  }
}

export default new DtwinsService()
