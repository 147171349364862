import type { AboutI } from '@lxc/app-device-types'
import RestService from './rest.service'

class AboutService extends RestService {
  private BASE_URL = '/about'

  /**
   * Retrieve about with version
   */
  getAbout(): Promise<AboutI> {
    return this.$api.get(this.BASE_URL)
  }
}

export default new AboutService()
