import { ErrorCode, ErrorMessage } from '@lxc/app-device-types'
import type { AxiosInstance, AxiosResponse } from 'axios'
import axios from 'axios'
import { BASE_URL_MAP, SERVICE_URL_MAP } from '~/constants/constants'
import LxcError from '~/utils/LxcError'

export default class RestService {
  protected $api: AxiosInstance

  constructor(baseURL: string = import.meta.env.LXC_AS_DEVICE_URL) {
    this.$api = axios.create({
      baseURL,
      timeout: 10000,
      withCredentials: true,
    })

    this.$api.interceptors.response.use(
      RestService.onFullFilled,
      RestService.onRejected,
    )
  }

  /**
   * Return data from response on success
   * @private
   * @param response
   */
  private static onFullFilled(response: AxiosResponse) {
    if (response.status === 204) {
      return null
    }
    return response.data
  }

  /**
   * Logout if 401 is received
   * Prepare Error otherwise
   * @param error
   * @private
   */
  private static async onRejected(error: any): Promise<LxcError> {
    const status = error.response?.status
    // If the page is /users/me, the redirection is done in the useUserSession store
    // otherwise it would loop to the login page
    if (status === 401 && error.response?.config?.url !== `${BASE_URL_MAP.USERS}${SERVICE_URL_MAP.CURRENT_USER}`) {
      const { logout } = await import('~/utils/auth.utils')
      await logout()
    }

    const code = error?.response?.data?.code

    const lxcError = code
      ? { ...error.response.data, ...{ status } }
      : {
        code: ErrorCode.UNEXPECTED,
        message: ErrorMessage.UNKNOWN,
        timestamp: new Date().getTime(),
        status,
      }

    return new LxcError(lxcError)
  }
}
