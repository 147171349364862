<script setup lang="ts">
import type { ApplicationI, CampaignConfigI, CampaignI, DeviceI, FirmwareI, LicenseStatusFeatureByDeviceTypeI } from '@lxc/app-device-types'
import { AppDeviceState, CampaignState, CampaignType, FirmwareStatus, LicenseStatus, LicenseStatusFeatureByDeviceTypeStatus, ObjectCampaignState, ObjectCampaignType } from '@lxc/app-device-types'
import type { DebuggerEventExtraInfo, Ref } from 'vue'
import { useAcl } from 'vue-simple-acl'
import dayjs from 'dayjs'
import { ElMessageBox } from 'element-plus'
import { storeToRefs } from 'pinia'
import LxcError from '~/utils/LxcError'
import FirmwareService from '~/services/firmware.service'
import ILxValid from '~icons/lx/valid'
import campaignService from '~/services/campaign.service'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import { router } from '~/plugins/router'
import { ACL_ROLES, Filters } from '~/types'
import { useDevices } from '~/composables/useDevices'
import { useApplication } from '~/composables/useApplication'
import { SearchMode } from '~/composables/useSearch'
import { displayColumns } from '~/components/parameters/firmwares/LxcFirmwaresList.type'
import { PATHS } from '~/constants/paths'
import { useConfigStore } from '~/stores/useConfigStore'
import { FeaturesName } from '~/types/deviceFeatures'
import { useLicenseStatusStore } from '~/stores/useLicenseStatusStore'

/**
 * Locale interfaces
 */
interface CampaignSettingsI {
  name: string|undefined
  type: CampaignType|undefined
  firmwareUuid: string|undefined
}

interface InclusionPeriodI {
  day: string
  range: Array<Date|undefined>
}

interface CampaignPlanificationExecutionDatesI {
  plannedStartAt: Date|undefined
  plannedEndAt: Date|undefined
}

interface CampaignPlanificationInclusionPeriodsI {
  inclusionPeriods: Array<InclusionPeriodI>
}
/** */

/**
 * Locale variables
 */
const campaign: Ref<CampaignI|null> = ref(null)
const error: Ref<LxcError|null> = ref(null)
const isLoading = ref(false)
const isSubmitting = ref(false)
const step = ref(0)
const firmwareLoading = ref(false)
const selectedDevices: Ref<Array<DeviceI>> = ref([])
const selectedApplications: Ref<Array<ApplicationI>> = ref([])
const selectedFirmware: Ref<FirmwareI|undefined> = ref()
const firmwareInfos: Ref<FirmwareI|undefined> = ref()
const firmwareInfosError: Ref<LxcError|null> = ref(null)
const firmwareInfosLoading = ref(false)
/** */

/**
 * Uses
 */
const { t } = useI18n()
const acl = useAcl()
const route = useRoute()
const { featureToggle } = useConfigStore()
const licenseStatusStore = useLicenseStatusStore()
const { licenseStatus } = storeToRefs(useLicenseStatusStore())
const {
  hasActionScheduled: deviceHasActionScheduled,
} = useDevices()
const {
  hasActionScheduled: applicationHasActionScheduled,
} = useApplication()
/** */

// redirect to index page when license status is disabled (not activated or suspended) or if feature advanced fleet management is not enabled
licenseStatusStore.$subscribe((mutation, state) => {
  // the mutation concerns the licenseStatus state and the license status disabled
  const isLicenseStatusDisabled = ((mutation.events as DebuggerEventExtraInfo).key === licenseStatusStore.licenseStateKeys.LICENSE_STATUS && state.licenseStatus && [LicenseStatus.NOT_ACTIVATED, LicenseStatus.EXPIRED_DATE].includes(state.licenseStatus.statusOfLicense))

  // the mutation concerns the license feature advanced fleet management state flag and its value is false
  const isLicenseFeatureAdvancedFleetManagementNotPresent = (mutation.events as DebuggerEventExtraInfo).key === licenseStatusStore.licenseStateKeys.IS_LICENSE_FEATURE_ADVANCED_FLEET_MANAGEMENT_PRESENT && !state.isLicenseFeatureAdvancedFleetManagementPresent

  if (isLicenseStatusDisabled || isLicenseFeatureAdvancedFleetManagementNotPresent) {
    router.replace('/')
  }
})

/**
 * Forms
 */
const campaignSettingsFormRef = ref()
const campaignSettingsForm = reactive<CampaignSettingsI>({
  name: undefined,
  type: undefined,
  firmwareUuid: undefined,
})
watch(selectedFirmware, () => {
  if (selectedFirmware.value?.uuid !== campaignSettingsForm.firmwareUuid) {
    campaignSettingsForm.firmwareUuid = selectedFirmware.value?.uuid
    onChangeFirmware(selectedFirmware.value?.uuid)
  }
})

function isSelectedFirmware(): boolean {
  if (!campaignSettingsForm.firmwareUuid) {
    showNotificationError(t('campaign.firmware.update.FirmwareSelectionRequired'))
    return false
  } else {
    return true
  }
}

const campaignPlanificationExecutionDatesFormRef = ref()
const campaignPlanificationExecutionDatesForm = reactive<CampaignPlanificationExecutionDatesI>({
  plannedStartAt: undefined,
  plannedEndAt: undefined,
})

const campaignPlanificationInclusionPeriodsFormRef = ref()
const campaignPlanificationInclusionPeriodsForm = reactive<CampaignPlanificationInclusionPeriodsI>({
  inclusionPeriods: [],
})
/** */

/**
 * Rules
 */

// Campaign settings rules
const campaignSettingsFormRules = {
  name: [
    { required: true, message: t('campaign.form.campaignSettings.validation.name'), whitespace: true, trigger: 'blur' },
    { max: 100, message: t('input.error.maxLength', { maxLength: 100 }), whitespace: true, trigger: 'blur' },
  ],
  type: [
    { required: true, message: t('campaign.form.campaignSettings.validation.type'), trigger: 'change' },
  ],
}

// Possibility to edit the campaign on:
//   - creation
//   - or modification if the campaign is scheduled only
const canEditCampaign = computed(() => !campaign.value || campaign.value.state === CampaignState.SCHEDULED)

// Check if the start date has been validated
const isStartDateValidated = ref(false)
const isStartDateValid = (rule: any, value: any, callback: any) => {
  // To consider checkDate and now time without seconds
  const checkDate = new Date(value)
  checkDate.setSeconds(0)
  checkDate.setMilliseconds(0)
  const now = new Date()
  now.setSeconds(0)
  now.setMilliseconds(0)

  // Check license expiration date with tolerancy
  if (licenseStatus.value && checkDate.getTime() < new Date(Date.parse(licenseStatus.value.expirationDate) + parseInt(licenseStatus.value.toleranceExpirationDate) * 24 * 60 * 60 * 1000).getTime()) {
    // Make the check if user don't use "Now" button. If now button is used, the check will be true
    if (checkDate.getTime() !== now.getTime() && !isStartDateValidated.value) {
      if (canEditCampaign.value && checkDate.getTime() < now.getTime()) {
        isStartDateValidated.value = false
        callback(new Error(t('campaign.dateInferiorToToday')))
      } else {
        isStartDateValidated.value = true
        callback()
      }
    } else {
      isStartDateValidated.value = true
      callback()
    }
  } else {
    isStartDateValidated.value = false
    callback(new Error(t('campaign.form.campaignPlanification.executionDates.validation.startDateNotBeyondLicenseExpirationDateTolerancy')))
  }
}

// Check if the end date has been validated
const isEndDateValid = (rule: any, value: any, callback: any) => {
  if (value) {
    // Check license expiration date with tolerancy
    if (licenseStatus.value && Date.parse(value) < new Date(Date.parse(licenseStatus.value.expirationDate) + parseInt(licenseStatus.value.toleranceExpirationDate) * 24 * 60 * 60 * 1000).getTime()) {
      if (canEditCampaign.value) {
        // adding 24 hours to plannedStartAt to avoid plannedEndDate to be the same as plannedStartDate
        const plannedStartAtPlusOneDay = campaignPlanificationExecutionDatesForm.plannedStartAt?.getTime()
          ? campaignPlanificationExecutionDatesForm.plannedStartAt?.getTime() + (1440 * 60000)
          : ''

        if (campaignPlanificationExecutionDatesForm.plannedStartAt && value.getTime() < plannedStartAtPlusOneDay) {
          callback(new Error(t('campaign.dateInferiorToDate', { date: dayjs(new Date(plannedStartAtPlusOneDay)).format(t('campaign.dateFormat')) })))
        } else {
          callback()
        }
      } else {
        callback()
      }
    } else {
      callback(new Error(t('campaign.form.campaignPlanification.executionDates.validation.endDateNotBeyondLicenseExpirationDateTolerancy')))
    }
  } else {
    callback()
  }
}

// Campaign planification execution dates rules
const campaignPlanificationExecutionDatesFormRules = {
  plannedStartAt: [
    { type: 'date', required: true, message: t('campaign.form.campaignPlanification.executionDates.validation.plannedStartAt'), whitespace: true, trigger: 'blur' },
    { validator: isStartDateValid, trigger: 'change' },
    { validator: isStartDateValid, trigger: 'blur' }, // To check date validity also on blur event if the blur happens too late
  ],
  plannedEndAt: [
    { type: 'date', required: true, message: t('campaign.form.campaignPlanification.executionDates.validation.plannedEndAt'), whitespace: true, trigger: 'blur' },
    { validator: isEndDateValid, trigger: 'change' },
  ],
}

function disabledStartDate(time: Date) {
  let isDisabled = false
  if (time.getTime() < dayjs().subtract(1, 'day').valueOf()) {
    isDisabled = true
  }
  if (campaignPlanificationExecutionDatesForm.plannedEndAt && time > campaignPlanificationExecutionDatesForm.plannedEndAt) {
    isDisabled = true
  }
  return isDisabled
}

function disabledEndDate(time: Date) {
  return time <= (campaignPlanificationExecutionDatesForm.plannedStartAt || Date.now())
}

// Validate that the span time of an inclusion period slot is at least 15 minutes
const campaignPlanificationInclusionPeriodsRangeValidator = (rule: any, value: any, callback: any) => {
  if (value.length === 2) {
    const startDate = value[0] instanceof Date ? value[0] : value[0].$d
    const endDate = value[1] instanceof Date ? value[1] : value[1].$d
    if (endDate.getTime() < (startDate.getTime() + (15 * 60000))) {
      callback(new Error(t('campaign.form.campaignPlanification.inclusionPeriods.validation.range')))
    } else {
      callback()
    }
  }
}

// Campaign planification inclusion periods rules
const campaignPlanificationInclusionPeriodsFormRules = computed(() => {
  const campaignPlanificationInclusionPeriodsFormRules: Record<string, Array<any>> = {}

  for (const index in campaignPlanificationInclusionPeriodsForm.inclusionPeriods) {
    campaignPlanificationInclusionPeriodsFormRules[`inclusionPeriods[${index}].day`] = [
      { required: true, message: '', trigger: 'change' },
    ]
    campaignPlanificationInclusionPeriodsFormRules[`inclusionPeriods[${index}].range`] = [
      { type: 'array', required: true, message: '', trigger: 'change' },
      { validator: campaignPlanificationInclusionPeriodsRangeValidator, trigger: 'blur' },
      { validator: campaignPlanificationInclusionPeriodsRangeValidator, trigger: 'change' },
    ]
  }

  return campaignPlanificationInclusionPeriodsFormRules
})

// Validate form depending on current step
async function isFormValid() {
  switch (step.value) {
    case 0:
      if (campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC) {
        return await campaignSettingsFormRef.value.validate().catch((_: any) => false) && isSelectedFirmware()
      } else {
        return await campaignSettingsFormRef.value.validate().catch((_: any) => false)
      }
    case 1:
      if (selectedDevices.value.length === 0 && selectedApplications.value.length === 0) {
        showNotificationError(t('campaign.form.fleetSelection.validation.required'))
        return false
      } else {
        return true
      }
    case 2: {
      return await campaignPlanificationExecutionDatesFormRef.value.validate().catch((_: any) => false)
        && await campaignPlanificationInclusionPeriodsFormRef.value.validate().catch((_: any) => false)
    }
  }
}
/** */

/**
 * Get campaign if ID is define in URL
 */
onMounted(async() => {
  const campaignId = route?.params?.id as string
  filterDevices()

  if (campaignId) {
    isLoading.value = true

    const response = await campaignService.getCampaign(campaignId)

    if (LxcError.check(response)) {
      error.value = response
    } else {
      campaign.value = response

      // Set campaign settings
      campaignSettingsForm.name = response.config?.name || ''
      campaignSettingsForm.type = response.type

      switch (campaignSettingsForm.type) {
        case CampaignType.FIRMWARE_UPGRADE_DVC:
          campaignSettingsForm.firmwareUuid = response.config?.firmwareUpgrade?.uuid
          fetchFirmwares()
          await getFirmwareInfos(campaignSettingsForm.firmwareUuid)
          selectedFirmware.value = firmwareInfos.value
        // eslint-disable-next-line no-fallthrough
        case CampaignType.CRTCLT_RENEWAL_DVC:
          selectedDevices.value = (response.objects || []).filter(object => object.state === ObjectCampaignState.SCOPED).map((object) => {
            return {
              id: object.id,
              name: object.label,
              model: {
                type: object.deviceType,
              },
            }
          })
          break
        case CampaignType.CRTCLT_RENEWAL_APP:
          selectedApplications.value = (response.objects || []).filter(object => object.state === ObjectCampaignState.SCOPED).map((object) => {
            return {
              id: object.id,
              name: object.label,
              model: {
                type: object.deviceType,
              },
            }
          })
          break
      }

      // Set planned dates
      if (response.config?.plannedStartAt) {
        campaignPlanificationExecutionDatesForm.plannedStartAt = new Date(response.config?.plannedStartAt)
      }
      if (response.config?.plannedEndAt) {
        campaignPlanificationExecutionDatesForm.plannedEndAt = new Date(response.config?.plannedEndAt)
      }

      // Set inclusion periods
      if (response.config?.inclusionPeriods) {
        campaignPlanificationInclusionPeriodsForm.inclusionPeriods = response.config?.inclusionPeriods.map((inclusionPeriod) => {
          let dateStartTimeSlot
          let dateEndTimeSlot
          if (inclusionPeriod.startTimeSlot) {
            const hourMinuteStartTimeSlot = inclusionPeriod.startTimeSlot.split(':').map(value => parseInt(value))
            dateStartTimeSlot = new Date()
            dateStartTimeSlot.setHours(hourMinuteStartTimeSlot[0])
            dateStartTimeSlot.setMinutes(hourMinuteStartTimeSlot[1])
          }
          if (inclusionPeriod.endTimeSlot) {
            const hourMinuteEndTimeSlot = inclusionPeriod.endTimeSlot.split(':').map(value => parseInt(value))
            dateEndTimeSlot = new Date()
            dateEndTimeSlot.setHours(hourMinuteEndTimeSlot[0])
            dateEndTimeSlot.setMinutes(hourMinuteEndTimeSlot[1])
          }
          return {
            day: inclusionPeriod.day,
            range: [dateStartTimeSlot, dateEndTimeSlot],
          }
        })
      }
    }

    isLoading.value = false
  }
})

/**
 * Steps
 */

// Go to next steps
const next = async() => {
  if (await isFormValid()) {
    // Check and display confirm if the selected equipments has actions scheduled, continue if not
    let found = false
    if (step.value === 1) {
      const campaignType = campaignSettingsForm.type
      switch (campaignType) {
        case CampaignType.CRTCLT_RENEWAL_DVC:
        case CampaignType.FIRMWARE_UPGRADE_DVC:
          found = !!selectedDevices.value.find((selectedDevice: DeviceI) => deviceHasActionScheduled(selectedDevice, campaignType) === true)
          break
        case CampaignType.CRTCLT_RENEWAL_APP:
          found = !!selectedApplications.value.find((selectedApplication: ApplicationI) => applicationHasActionScheduled(selectedApplication, campaignType) === true)
          break
      }
      if (found) {
        ElMessageBox.confirm(
          t('campaign.form.fleetSelection.actions.scheduled.confirm.message'),
          t('campaign.form.fleetSelection.actions.scheduled.confirm.title'),
          {
            confirmButtonText: t('campaign.form.fleetSelection.actions.scheduled.confirm.yes'),
            cancelButtonText: t('campaign.form.fleetSelection.actions.scheduled.confirm.no'),
            type: 'warning',
          },
        )
          .then(() => {
            step.value++
          })
      } else {
        step.value++
      }
    } else {
      step.value++
    }
  }
}

// Return to previous steps
const previous = () => {
  step.value--
}
/** */

/**
 * Campaign type
 */

// Campaign type options
const campaignTypeOptions: { value: CampaignType; label: string }[] = []
if (featureToggle.isVisible('fota') && acl.can(ACL_ROLES.ASK_MANUAL_FIRMWARE_UPDATE_DEVICE) && acl.can(ACL_ROLES.DISPLAY_FIRMWARES) && acl.can(ACL_ROLES.DISPLAY_DEVICES)) {
  campaignTypeOptions.push({
    value: CampaignType.FIRMWARE_UPGRADE_DVC,
    label: t('campaign.form.campaignSettings.typeOfCampaign.firmwareUpdate.option'),
  })
}
if (acl.can(ACL_ROLES.ASK_MANUAL_CERTIFICATE_RENEWAL_DEVICE) && acl.can(ACL_ROLES.DISPLAY_DEVICES)) {
  campaignTypeOptions.push({
    value: CampaignType.CRTCLT_RENEWAL_DVC,
    label: t('campaign.form.campaignSettings.typeOfCampaign.deviceCertificateRenewal'),
  })
}
if (acl.can(ACL_ROLES.ASK_MANUAL_CERTIFICATE_RENEWAL_APP) && acl.can(ACL_ROLES.DISPLAY_APPLICATIONS)) {
  campaignTypeOptions.push({
    value: CampaignType.CRTCLT_RENEWAL_APP,
    label: t('campaign.form.campaignSettings.typeOfCampaign.applicationCertificateRenewal'),
  })
}

// On campaign type changed
const onChangedCampaignType = (campaignType: CampaignType) => {
  switch (campaignType) {
    case CampaignType.FIRMWARE_UPGRADE_DVC:
      fetchFirmwares()
      break
    case CampaignType.CRTCLT_RENEWAL_DVC:
      selectedFirmware.value = undefined
      break
    case CampaignType.CRTCLT_RENEWAL_APP:
      selectedFirmware.value = undefined
      break
  }
}
/** */

/**
 * Firmwares
 */
const firmwares: Ref<Array<FirmwareI|undefined>> = ref([])
const firmwareListError: Ref<LxcError|null> = ref(null)

// Fetch the firmwares
async function fetchFirmwares() {
  firmwareListError.value = null
  firmwareLoading.value = true
  const response = await FirmwareService.getFirmwares()

  if (LxcError.check(response)) {
    firmwareListError.value = response
    firmwareLoading.value = false
  } else {
    for (const firmware of response.data) {
      firmwares.value.push(firmware)
    }
  }
  firmwareLoading.value = false
}

// On firmware selection changed
async function onChangeFirmware(firmwareUuid?: string) {
  firmwareInfosLoading.value = true

  // Reset the scope
  selectedDevices.value = []

  // Get firmware infos
  await getFirmwareInfos(firmwareUuid)

  firmwareInfosLoading.value = false
}

// Get firmware infos
async function getFirmwareInfos(firmwareUuid?: string) {
  if (firmwareUuid) {
    // Get the selected firmware infos
    const response = await FirmwareService.getFirmwareByUuid(firmwareUuid)

    if (LxcError.check(response)) {
      firmwareInfosError.value = response
    } else {
      firmwareInfos.value = response
    }
  } else {
    firmwareInfos.value = undefined
  }
}
/** */

/**
 * Inclusion periods
 */
function addInclusionPeriod() {
  campaignPlanificationInclusionPeriodsForm.inclusionPeriods.push({
    day: '',
    range: [],
  })
}

function removeInclusionPeriod(index: number) {
  campaignPlanificationInclusionPeriodsForm.inclusionPeriods.splice(index, 1)
}

const inclusionPeriodsOptions = [
  {
    value: 'MONDAY',
    label: t('campaign.form.campaignPlanification.inclusionPeriods.days.monday'),
  },
  {
    value: 'TUESDAY',
    label: t('campaign.form.campaignPlanification.inclusionPeriods.days.tuesday'),
  },
  {
    value: 'WEDNESDAY',
    label: t('campaign.form.campaignPlanification.inclusionPeriods.days.wednesday'),
  },
  {
    value: 'THURSDAY',
    label: t('campaign.form.campaignPlanification.inclusionPeriods.days.thursday'),
  },
  {
    value: 'FRIDAY',
    label: t('campaign.form.campaignPlanification.inclusionPeriods.days.friday'),
  },
  {
    value: 'SATURDAY',
    label: t('campaign.form.campaignPlanification.inclusionPeriods.days.saturday'),
  },
  {
    value: 'SUNDAY',
    label: t('campaign.form.campaignPlanification.inclusionPeriods.days.sunday'),
  },
]
/** */

/**
 * On submit at the last step
 */
async function onSubmit() {
  if (await isFormValid()) {
    if (campaignSettingsForm.type) {
      isSubmitting.value = true
      const plannedStartAt = campaignPlanificationExecutionDatesForm.plannedStartAt ? campaignPlanificationExecutionDatesForm.plannedStartAt.toISOString() : ''
      const plannedEndAt = campaignPlanificationExecutionDatesForm.plannedEndAt ? campaignPlanificationExecutionDatesForm.plannedEndAt.toISOString() : ''

      let config: CampaignConfigI | undefined
      let objects = []

      switch (campaignSettingsForm.type) {
        case CampaignType.FIRMWARE_UPGRADE_DVC:
          config = {
            name: campaignSettingsForm.name,
            plannedStartAt,
            plannedEndAt,
            firmwareUpgrade: {
              uuid: campaignSettingsForm.firmwareUuid,
            },
          }

          objects = selectedDevices.value.map((device) => {
            return {
              id: device.id,
              type: ObjectCampaignType.DVC,
              label: device.name,
              deviceType: device.model?.type,
            }
          })
          break
        case CampaignType.CRTCLT_RENEWAL_DVC:
          config = {
            name: campaignSettingsForm.name,
            plannedStartAt,
            plannedEndAt,
          }

          objects = selectedDevices.value.map((device) => {
            return {
              id: device.id,
              type: ObjectCampaignType.DVC,
              label: device.name,
              deviceType: device.model?.type,
            }
          })
          break
        case CampaignType.CRTCLT_RENEWAL_APP:
          config = {
            name: campaignSettingsForm.name,
            plannedStartAt,
            plannedEndAt,
          }

          objects = selectedApplications.value.map((device) => {
            return {
              id: device.id,
              type: ObjectCampaignType.APP,
              label: device.name,
              deviceType: device.model?.type === 'S4G-Tools' ? 'S4-Tools' : device.model?.type, // Temporary fix
            }
          })
          break
      }

      // Add inclusion periods
      config.inclusionPeriods = campaignPlanificationInclusionPeriodsForm.inclusionPeriods.map((inclusionPeriod: InclusionPeriodI) => {
        return {
          day: inclusionPeriod.day,
          startTimeSlot: dayjs(inclusionPeriod.range[0]).format('HH:mm'),
          endTimeSlot: dayjs(inclusionPeriod.range[1]).format('HH:mm'),
        }
      })

      // Update the campaign
      if (campaign.value) {
        const campaignToUpdate: CampaignI = {
          id: campaign.value.id,
          type: campaignSettingsForm.type,
          objects,
        }

        // Add config only if the campaign is not started
        if (campaign.value.state === CampaignState.SCHEDULED) {
          campaignToUpdate.config = config
        }

        const response = await campaignService.updateCampaign(campaignToUpdate)

        if (LxcError.check(response)) {
          response.notify(NotificationKey.saveError)
        } else {
          showNotificationSuccess(t(NotificationKey.saveSuccess))
          router.push(`${PATHS.CAMPAIGNS}/${campaign.value.id}/${PATHS.DETAILS_SUBPATH}`)
        }
      }
      // Create a campaign
      else {
        const response = await campaignService.createCampaign({
          type: campaignSettingsForm.type,
          config,
          objects,
        })

        if (LxcError.check(response)) {
          response.notify(NotificationKey.saveError)
        } else {
          showNotificationSuccess(t(NotificationKey.saveSuccess))
          router.push(`${PATHS.CAMPAIGNS}/${response.id}/${PATHS.DETAILS_SUBPATH}`)
        }
      }

      isSubmitting.value = false
    }
  }
}

/**
 * Filter devices if beyond the limit of the license
 */
const deviceTypeFilters: Ref<string[]> = ref([])
function filterDevices() {
  const devicesAdvancedFleetManagementFeature: LicenseStatusFeatureByDeviceTypeI[]|undefined = licenseStatus.value?.featureByDeviceTypes.filter((feature) => {
    return feature.name === FeaturesName.ADVANCED_FLEET_MGT
  })
  devicesAdvancedFleetManagementFeature?.forEach((deviceType) => {
    if (deviceType.status !== LicenseStatusFeatureByDeviceTypeStatus.SUSPENDED_DEVICE && deviceType.status !== LicenseStatusFeatureByDeviceTypeStatus.NOT_ACTIVATED) {
      if (deviceType.deviceType === 'S4-Tools') { // Temporary fix
        deviceTypeFilters.value.push('S4G-Tools')
      } else {
        deviceTypeFilters.value.push(deviceType.deviceType)
      }
    }
  })
}
/** */
</script>

<template>
  <el-container
    direction="vertical"
  >
    <lxc-license-warning-messages />

    <lxc-breadcrumb :name="isLoading ? '' : campaign ? campaignSettingsForm.name : $t('campaign.newCampaign')" />

    <lxc-container
      :is-loading="isLoading"
      :error="error"
    >
      <el-steps
        :active="step"
        finish-status="success"
        class="half-container"
        align-center
      >
        <el-step :title="$t('campaign.form.campaignSettings.title')" />
        <el-step :title="$t('campaign.form.fleetSelection.title')" />
        <el-step :title="$t('campaign.form.campaignPlanification.title')" />
      </el-steps>

      <lxc-mandatory />

      <!-- Campaign settings step -->
      <div
        v-if="step === 0"
      >
        <el-form
          ref="campaignSettingsFormRef"
          :model="campaignSettingsForm"
          label-position="left"
          label-width="200px"
          :rules="campaignSettingsFormRules"
          @submit.prevent
        >
          <el-container
            class="half-container"
            direction="vertical"
          >
            <div>
              <h2 class="section-title">
                {{ $t('campaign.form.campaignSettings.title') }}
              </h2>
              <el-form-item
                :label="$t('campaign.form.campaignSettings.name')"
                prop="name"
              >
                <el-input
                  v-model="campaignSettingsForm.name"
                  type="text"
                  :placeholder="$t('campaign.form.campaignSettings.name')"
                  :disabled="!canEditCampaign"
                />
              </el-form-item>
              <el-form-item
                :label="$t('campaign.form.campaignSettings.typeOfCampaign.title')"
                prop="type"
              >
                <el-select
                  v-model="campaignSettingsForm.type"
                  class="m-2"
                  :placeholder="$t('campaign.form.campaignSettings.typeOfCampaign.title')"
                  :disabled="!!campaign"
                  @change="onChangedCampaignType"
                >
                  <el-option
                    v-for="campaignTypeOption in campaignTypeOptions"
                    :key="campaignTypeOption.value"
                    :label="campaignTypeOption.label"
                    :value="campaignTypeOption.value"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-container>

          <hr v-if="campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC">

          <el-container
            v-if="campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC"
            v-loading="firmwareLoading"
            direction="vertical"
          >
            <div>
              <div class="mt-5">
                <lxc-alert
                  type="warning"
                >
                  <span
                    class="break-normal"
                  >
                    {{ $t('campaign.firmware.update.warningMessage') }}
                  </span>
                </lxc-alert>
              </div>
              <h2 class="section-title">
                <span class="requiredStar">*</span>&nbsp;{{ $t('campaign.form.campaignSettings.typeOfCampaign.firmwareUpdate.title') }}
              </h2>
              <div
                v-if="canEditCampaign"
              >
                <p
                  class="firmware-selection-instruction"
                >
                  <i-lx-round-info class="icon" />
                  {{ $t('campaign.firmware.update.firmwareSelection') }}
                </p>
                <lxc-firmwares-list
                  v-model:selected-firmware="selectedFirmware"
                  no-action
                  selectable
                  :columns="[displayColumns.NAME, displayColumns.VERSION, displayColumns.RANGE, displayColumns.DECLINATION]"
                  :search-mode="SearchMode.FILTER_SEARCH"
                  :default-filters="new Map<Filters, any>([
                    [Filters.STATUS, [FirmwareStatus.ACTIVATED]],
                    [Filters.RANGE, deviceTypeFilters]
                  ])"
                />
              </div>
              <el-form-item
                v-else
                :label="$t('campaign.form.campaignSettings.typeOfCampaign.firmwareUpdate.label')"
              >
                <el-input
                  v-loading="firmwareInfosLoading"
                  type="text"
                  disabled
                  :value="firmwareInfos?.name"
                />
              </el-form-item>
              <el-form-item
                :label="$t('campaign.form.campaignSettings.typeOfCampaign.firmwareUpdate.dependencies')"
              >
                <el-input
                  v-loading="firmwareInfosLoading"
                  type="text"
                  disabled
                  :placeholder="$t('campaign.form.campaignSettings.typeOfCampaign.firmwareUpdate.dependencies')"
                  :value="firmwareInfos?.firmwareVersions"
                />
              </el-form-item>
            </div>
          </el-container>
        </el-form>
      </div>

      <!-- Equipment fleet selection step -->
      <div
        v-if="step === 1"
      >
        <el-container
          direction="vertical"
        >
          <h2 class="section-title">
            <span class="requiredStar">*</span>&nbsp;{{ $t('campaign.form.fleetSelection.title') }}
          </h2>
          <lxc-devices-list
            v-if="campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC || campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_DVC"
            v-model:selected-devices="selectedDevices"
            no-action
            :default-filters="new Map<Filters, any>([
              [Filters.STATE, [AppDeviceState.ACTIVATED]],
              [Filters.MODEL_TYPE, firmwareInfos?.range || deviceTypeFilters],
              [Filters.MODEL_DECLINATION, firmwareInfos?.declination],
              [Filters.DEVICE_FIRMWARE_VERSIONS, firmwareInfos?.firmwareVersions],
              [Filters.DEVICE_HARDWARE_VERSIONS, firmwareInfos?.hardwareVersions]
            ])"
            :campaign-type="campaignSettingsForm.type"
            :search-mode="SearchMode.FILTER_SEARCH"
          />
          <lxc-applications-list
            v-if="campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_APP"
            v-model:selected-applications="selectedApplications"
            no-action
            :default-filters="new Map<Filters, any>([
              [Filters.STATE, [AppDeviceState.ACTIVATED]],
              [Filters.MODEL_TYPE, deviceTypeFilters]
            ])"
            :campaign-type="campaignSettingsForm.type"
            :search-mode="SearchMode.FILTER_SEARCH"
          />
        </el-container>
      </div>

      <!-- Campaign planification step -->
      <div
        v-if="step === 2"
      >
        <el-container
          class="half-container"
          direction="vertical"
        >
          <el-form
            ref="campaignPlanificationExecutionDatesFormRef"
            :model="campaignPlanificationExecutionDatesForm"
            label-position="left"
            label-width="200px"
            :rules="campaignPlanificationExecutionDatesFormRules"
            @submit.prevent
          >
            <h2 class="section-title">
              {{ $t('campaign.form.campaignPlanification.executionDates.title') }}
            </h2>
            <el-form-item
              :label="$t('campaign.form.campaignPlanification.executionDates.plannedStartAt')"
              prop="plannedStartAt"
            >
              <!-- checking also the date on blur event if user blur field after a minute -->
              <el-date-picker
                v-model="campaignPlanificationExecutionDatesForm.plannedStartAt"
                type="datetime"
                :format="$t('device.dateFormat')"
                :placeholder="$t('campaign.form.campaignPlanification.executionDates.plannedStartAt')"
                :disabled="!canEditCampaign"
                :disabled-date="disabledStartDate"
                @change="() => isStartDateValidated = false"
                @blur="() => isStartDateValidated = false"
              />
            </el-form-item>
            <el-form-item
              :label="$t('campaign.form.campaignPlanification.executionDates.endDate')"
              prop="plannedEndAt"
            >
              <el-date-picker
                v-model="campaignPlanificationExecutionDatesForm.plannedEndAt"
                type="datetime"
                :format="$t('device.dateFormat')"
                popper-class="end-date-picker"
                :placeholder="$t('campaign.form.campaignPlanification.executionDates.endDate')"
                :disabled="!canEditCampaign"
                :disabled-date="disabledEndDate"
                :default-value="campaignPlanificationExecutionDatesForm.plannedStartAt?.getTime()
                  ? campaignPlanificationExecutionDatesForm.plannedStartAt?.getTime() + (1440 * 60000)
                  : ''"
              />
            </el-form-item>

            <h2 class="section-title">
              <span
                v-if="campaignPlanificationInclusionPeriodsForm.inclusionPeriods.length > 0"
                class="requiredStar"
              >*</span>&nbsp;{{ $t('campaign.form.campaignPlanification.inclusionPeriods.title') }}
            </h2>
            <el-form
              ref="campaignPlanificationInclusionPeriodsFormRef"
              :model="campaignPlanificationInclusionPeriodsForm"
              :rules="campaignPlanificationInclusionPeriodsFormRules"
              @submit.prevent
            >
              <div
                v-for="(inclusionPeriod, index) in campaignPlanificationInclusionPeriodsForm.inclusionPeriods"
                :key="inclusionPeriod.day + index"
              >
                <el-row>
                  <el-col :span="8">
                    <el-form-item :prop="`inclusionPeriods[${index}].day`">
                      <el-select
                        v-model="inclusionPeriod.day"
                        :placeholder="$t('campaign.form.campaignPlanification.inclusionPeriods.day')"
                        :disabled="!canEditCampaign"
                      >
                        <el-option
                          v-for="inclusionPeriodOption in inclusionPeriodsOptions"
                          :key="inclusionPeriodOption.value"
                          :label="inclusionPeriodOption.label"
                          :value="inclusionPeriodOption.value"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="16">
                    <el-form-item :prop="`inclusionPeriods[${index}].range`">
                      <el-time-picker
                        v-model="inclusionPeriod.range"
                        arrow-control
                        is-range
                        format="HH:mm"
                        :style="{ margin: '0 10px' }"
                        :disabled="!canEditCampaign"
                      />
                      <a
                        v-if="canEditCampaign"
                        href="#"
                        @click="removeInclusionPeriod(index)"
                      ><el-icon :size="14"><i-lx-circle-remove-filled /></el-icon>&nbsp;{{ $t(('campaign.form.campaignPlanification.inclusionPeriods.remove')) }}</a>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>

              <a
                v-if="canEditCampaign && campaignPlanificationInclusionPeriodsForm.inclusionPeriods.length < 20"
                href="#"
                @click="addInclusionPeriod()"
              ><el-icon :size="14"><i-lx-circle-plus-filled /></el-icon>&nbsp;{{ $t(('campaign.form.campaignPlanification.inclusionPeriods.add')) }}</a>
            </el-form>
          </el-form>
        </el-container>
      </div>
      <div class="buttons">
        <!-- "Previous" Button -->
        <el-button
          v-show="step <= 2 && step > 0"
          data-cy="button-previous"
          type="secondary"
          @click="previous"
        >
          {{ $t('campaign.form.buttons.previous') }}
        </el-button>
        <!-- "Next" Button -->
        <el-button
          v-show="step < 2"
          data-cy="button-next"
          type="primary"
          @click="next"
        >
          {{ $t('campaign.form.buttons.next') }}
        </el-button>
        <!-- Submit Button -->
        <el-button
          v-show="step === 2"
          type="primary"
          :icon="ILxValid"
          :disabled="isSubmitting"
          @click="onSubmit"
        >
          {{ $t('campaign.form.buttons.submit') }}
        </el-button>
      </div>
    </lxc-container>
  </el-container>
</template>

<style scope lang="scss">
.radio-buttons{
  display: flex;
  flex-direction: column;
}
.buttons{
  margin: 12px 0 2.5em 0;
  text-align: center;
}
.firmware-selection-instruction{
  font-size: .9em;
  color: rgba($color: #000000, $alpha: .8);
  fill: rgba($color: #000000, $alpha: .8);
}
.end-date-picker{
  .el-picker-panel{
    .el-picker-panel__footer{
      .el-button:first-child{
        display: none;
      }
    }
  }
}
</style>
