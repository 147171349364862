<script setup lang="ts">
import dayjs from 'dayjs'
import type { SlideMenuItem } from '@lxc/app-device-common/src/interfaces/slideMenuItem.interface'
import type { DateShortcutCallback } from '@lxc/app-device-common/src/interfaces/dateshortcut.interface'
import { appConnectivityOptions } from '~/components/applications/applicationList/applicationsFilters.config'
import type { FilterOptions, FilterSelectionDefinition, FilterSelectionValue, Option } from '~/types'
import { Filters, Visibility } from '~/types'

const { t } = useI18n()

/**
 * If a filter prop is undefined, it means that it is not managed in this component and does not appear on filters panel
 */
const props = defineProps<{
  stateValue?: FilterSelectionDefinition
  connectivityValue?: FilterSelectionDefinition
  modelTypeValue?: FilterSelectionDefinition
  modelDeclinationValue?: FilterSelectionDefinition
  certificateExpireAfter?: FilterSelectionDefinition
  certificateExpireBefore?: FilterSelectionDefinition
  typeOptions: FilterOptions
  modelOptions: FilterOptions
  stateOptions: FilterOptions
}>()

const selectStates: Ref<Array<string>> = ref([])
const selectedTypes: Ref<Array<string>> = ref([])
const selectedModels: Ref<Array<string>> = ref([])
const selectedConnectivitys: Ref<Array<string>> = ref([])
const selectCertificateExpireAfter: Ref<FilterSelectionDefinition|undefined|null> = ref<FilterSelectionDefinition | undefined | null>(props.certificateExpireAfter ? Object.assign({}, props.certificateExpireAfter) : undefined)
const selectedCertificateExpireBefore: Ref<FilterSelectionDefinition|undefined|null> = ref<FilterSelectionDefinition | undefined | null>(props.certificateExpireBefore ? Object.assign({}, props.certificateExpireBefore) : undefined)

const isShowTagSet = computed<boolean>(() => !!(props.stateValue?.value.length || props.connectivityValue?.value.length || props.modelTypeValue?.value.length || props.modelDeclinationValue?.value.length || props.certificateExpireAfter?.value || props.certificateExpireBefore?.value))

const undeletableAppliedFilterTags: Ref<string[]> = ref([])
const undeletableStateFilters: Ref<string[]> = ref([])
const undeletableTypesFilters: Ref<string[]> = ref([])
const undeletableModelsFilters: Ref<string[]> = ref([])

const modelTypeValueComputed = computed((): FilterSelectionDefinition => {
  const modelTypeFilterValue: FilterSelectionValue = []
  props.typeOptions.options.forEach((option) => {
    if (props.modelTypeValue?.value.includes(option.value)) {
      modelTypeFilterValue.push(option.value)
    }
  })
  const modelTypeFilter: FilterSelectionDefinition = {
    key: props.modelTypeValue!.key,
    operator: props.modelTypeValue!.operator,
    value: modelTypeFilterValue,
  }

  return modelTypeFilter
})

const emit = defineEmits(['change', 'enter'])

const shortcuts: Ref<DateShortcutCallback> = ref(() => [
  {
    label: t('filters.certificateValidity.expired'),
    atClick: (): Date[] => {
      const date = new Date()
      return [
        new Date(date.setFullYear(date.getFullYear() - 10)),
        new Date(),
      ]
    },
  },
  {
    label: t('filters.certificateValidity.validityUnder1'),
    atClick: (): Date[] => {
      const date = new Date()
      return [
        new Date(),
        new Date(date.setMonth(date.getMonth() + 1)),
      ]
    },
  },
  {
    label: t('filters.certificateValidity.validityUnder3'),
    atClick: (): Date[] => {
      const date = new Date()
      return [
        new Date(),
        new Date(date.setMonth(date.getMonth() + 3)),
      ]
    },
  },
  {
    label: t('filters.certificateValidity.validityUnder6'),
    atClick: (): Date[] => {
      const date = new Date()
      return [
        new Date(),
        new Date(date.setMonth(date.getMonth() + 6)),
      ]
    },
  },
  {
    label: t('filters.certificateValidity.validityAfter6'),
    atClick: (): Date[] => {
      const date = new Date()
      const dateTmp = new Date()
      const dateWithSiwMonth = new Date(dateTmp.setMonth(dateTmp.getMonth() + 6))
      return [
        new Date(date.setMonth(date.getMonth() + 6)),
        new Date(dateWithSiwMonth.setFullYear(dateWithSiwMonth.getFullYear() + 10)),
      ]
    },
  },

])

function onClearFilters() {
  selectStates.value = undeletableStateFilters.value
  selectedTypes.value = undeletableTypesFilters.value
  selectedModels.value = undeletableModelsFilters.value
  selectCertificateExpireAfter.value = null
  selectedCertificateExpireBefore.value = null
  applyFilter()
}

function applyFilter() {
  if (props.stateValue) {
    emit('change', Filters.STATE, selectStates.value.slice(0))
  }
  if (props.modelTypeValue) {
    emit('change', Filters.MODEL_TYPE, selectedTypes.value.slice(0))
  }
  if (props.modelDeclinationValue) {
    emit('change', Filters.MODEL_DECLINATION, selectedModels.value.slice(0))
  }
  if (props.connectivityValue) {
    emit('change', Filters.CONNECTIVITY, selectedConnectivitys.value.slice(0))
  }
  if (props.certificateExpireAfter) {
    emit('change', Filters.CERTIFICATE_EXPIRE_AFTER, (selectCertificateExpireAfter.value) ? selectCertificateExpireAfter.value.value : '')
  }
  if (props.certificateExpireBefore) {
    emit('change', Filters.CERTIFICATE_EXPIRE_BEFORE, (selectedCertificateExpireBefore.value) ? selectedCertificateExpireBefore.value.value : '')
  }
  emit('enter')
}

const selectedFilterMap: Map<string, string> = new Map()

function pushUndeletableTagsAndFilters(option: Option, currentValue: string, tag: string, undeletableFilterArray: string[]) {
  if (option.value === currentValue && option.disabled) {
    undeletableAppliedFilterTags.value.push(tag)
    if (!undeletableFilterArray.includes(currentValue)) {
      undeletableFilterArray.push(currentValue)
    }
  }
}

const appliedFilterTags = computed((): string[] => {
  const tagFilters: string[] = []

  if (props.stateValue) {
    for (const stateValueCurrent of props.stateValue.value) {
      if (stateValueCurrent !== undefined) {
        const tag = `${t('filters.state').toLowerCase()} ${t(`application.states.${stateValueCurrent}`)}`
        tagFilters.push(tag)

        props.stateOptions.options.forEach((option) => {
          pushUndeletableTagsAndFilters(option, stateValueCurrent, tag, undeletableStateFilters.value)
        })
      }
    }
  }
  if (props.modelTypeValue) {
    for (const selectedType of modelTypeValueComputed.value.value) {
      const tag = `${t('filters.type').toLowerCase()} ${selectedType}`
      tagFilters.push(tag)

      props.typeOptions.options.forEach((option) => {
        pushUndeletableTagsAndFilters(option, selectedType, tag, undeletableTypesFilters.value)
      })
    }
  }
  if (props.modelDeclinationValue) {
    for (const selectedModel of props.modelDeclinationValue.value) {
      const tag = `${t('filters.model').toLowerCase()} ${selectedModel}`
      tagFilters.push(tag)

      props.modelOptions.options.forEach((option) => {
        pushUndeletableTagsAndFilters(option, selectedModel, tag, undeletableModelsFilters.value)
      })
    }
  }
  if (props.connectivityValue) {
    for (const selectedConnectivity of props.connectivityValue.value) {
      const tag = `${t('filters.connectivity').toLowerCase()} ${selectedConnectivity}`
      tagFilters.push(tag)
    }
  }
  if (props.certificateExpireAfter && props.certificateExpireAfter.value) {
    const tag = `${t('filters.certificate').toLowerCase()} ${getCreationDateTagText(props.certificateExpireAfter.value, props.certificateExpireBefore?.value)}`
    tagFilters.push(tag)
  }

  return tagFilters
})

const selectedFilterTags = computed((): string[] => {
  const tagFilters: string[] = []

  for (const stateValueCurrent of selectStates.value) {
    const tag = `${t('filters.state').toLowerCase()} ${t(`application.states.${stateValueCurrent}`)}`
    tagFilters.push(tag)
    selectedFilterMap.set(tag, stateValueCurrent)
  }
  for (const selectedType of selectedTypes.value) {
    const tag = `${t('filters.type').toLowerCase()} ${selectedType}`
    tagFilters.push(tag)
    selectedFilterMap.set(tag, selectedType)
  }
  for (const selectedModel of selectedModels.value) {
    const tag = `${t('filters.model').toLowerCase()} ${selectedModel}`
    tagFilters.push(tag)
    selectedFilterMap.set(tag, selectedModel)
  }
  for (const selectedConnectivity of selectedConnectivitys.value) {
    const tag = `${t('filters.connectivity').toLowerCase()} ${selectedConnectivity}`
    tagFilters.push(tag)
    selectedFilterMap.set(tag, selectedConnectivity)
  }
  if (selectCertificateExpireAfter && selectCertificateExpireAfter.value && selectCertificateExpireAfter.value.value !== '') {
    const tag = `${t('filters.certificate').toLowerCase()} ${getCreationDateTagText(selectCertificateExpireAfter.value.value, selectedCertificateExpireBefore.value?.value)}`
    tagFilters.push(tag)
    selectedFilterMap.set(tag, selectCertificateExpireAfter.value.value as string)
  }

  return tagFilters
})

const selectedFiltersByTypes = computed<Array<string | Array<string | undefined | null> | undefined | null>>(() =>
  [
    selectStates.value,
    selectedTypes.value,
    selectedModels.value,
    selectedConnectivitys.value,
    [selectedCertificateExpireBefore.value?.value as string | undefined, selectCertificateExpireAfter.value?.value as string | undefined],
  ])
const datePeriodSeparator = t('filters.creationDate.periodSeparator')
const tagFormatter = t('filters.creationDate.formatter.tag')

function getCreationDateTagText(paramStartDate?: FilterSelectionValue, paramEndDate?: FilterSelectionValue) {
  let result = ''

  if (paramStartDate) {
    const startDate = new Date(paramStartDate as string)
    const displayStartDate = dayjs(startDate).format(tagFormatter)
    if (!paramEndDate || paramStartDate === paramEndDate) {
      result = `${t('filters.creationDate.label')?.toLowerCase()} ${displayStartDate}`
    } else {
      const endDate = new Date(paramEndDate as string)
      const displayEndDate = dayjs(endDate).format(tagFormatter)
      result = `${t('filters.creationDate.label')?.toLowerCase()} ${displayStartDate} ${datePeriodSeparator} ${displayEndDate}`
    }
  }

  return result
}

function removeFilterDefinition(items: Ref<string[]> | undefined, filterDefinition: string|undefined) {
  if (filterDefinition !== undefined && items !== undefined) {
    const index = items.value.findIndex(value => value === filterDefinition)
    if (index !== -1) {
      items.value.splice(index, 1)
    }
  }
}

function onDeletSelectedTag(tag: string) {
  const filterDefinition: string | undefined = selectedFilterMap.get(tag)
  removeFilterDefinition(selectStates, filterDefinition)
  removeFilterDefinition(selectedTypes, filterDefinition)
  removeFilterDefinition(selectedModels, filterDefinition)
  removeFilterDefinition(selectedConnectivitys, filterDefinition)

  if (tag.startsWith(t('filters.certificate').toLowerCase())) {
    selectCertificateExpireAfter.value = null
    selectedCertificateExpireBefore.value = null
  }
}

function onAppliedTagDeleteClick(tag: string) {
  const filterDefinition = selectedFilterMap.get(tag)
  removeFilterDefinition(selectStates, filterDefinition)
  removeFilterDefinition(selectedTypes, filterDefinition)
  removeFilterDefinition(selectedModels, filterDefinition)
  removeFilterDefinition(selectedConnectivitys, filterDefinition)

  if (tag.startsWith(t('filters.certificate').toLowerCase())) {
    selectCertificateExpireAfter.value = null
    selectedCertificateExpireBefore.value = null
  }

  applyFilter()
}

// Does display some menus depending on props values
function getMenuItems() {
  const menuItems: SlideMenuItem[] = []
  if (props.stateValue) {
    menuItems.push({
      disabled: false,
      id: 'state',
      menuLabel: t('filters.state'),
      header: t('filters.state'),
      footerId: 'state-footer',
      footerEnabled: true,
    })
  }
  if (props.modelTypeValue) {
    menuItems.push({
      disabled: false,
      id: 'type',
      menuLabel: t('filters.type'),
      header: t('filters.type'),
      footerId: 'type-footer',
      footerEnabled: true,
    })
  }
  if (props.modelTypeValue && props.modelDeclinationValue) {
    menuItems.push({
      disabled: false,
      id: 'model',
      menuLabel: t('filters.model'),
      header: t('filters.model'),
      footerId: 'model-footer',
      footerEnabled: true,
    })
  }
  if (props.connectivityValue) {
    menuItems.push({
      disabled: false,
      id: 'connectivity',
      menuLabel: t('filters.connectivity'),
      header: t('filters.connectivity'),
      footerId: 'connectivity-footer',
      footerEnabled: true,
    })
  }
  if (props.certificateExpireAfter) {
    menuItems.push({
      disabled: false,
      id: 'certificate',
      menuLabel: t('filters.certificate'),
      header: t('filters.certificate'),
      footerId: 'certificate-footer',
      footerEnabled: true,
    })
  }
  return menuItems
}
const menuItems: SlideMenuItem[] = getMenuItems()

const filterVisiblity = ref<Visibility>(Visibility.HIDDEN)
const creationDatePanelVisibliy = ref<Visibility>(Visibility.HIDDEN)

function getMenuItemByHtmlId(pHtmlId?: string | null): SlideMenuItem | undefined {
  return menuItems.find(menuItem => menuItem.htmlId === pHtmlId)
}

const onFilterShow = () => {
  filterVisiblity.value = Visibility.SHOWN
}

const onFilterHidden = () => {
  filterVisiblity.value = Visibility.HIDDEN
}

const onEndSlideToPanel = (htmlId: string | null) => {
  const menuItem = getMenuItemByHtmlId(htmlId)
  creationDatePanelVisibliy.value = (menuItem?.id === 'certificate') ? Visibility.SHOWN : Visibility.HIDDEN
}

const onStartBackToMenu = () => {
  if (creationDatePanelVisibliy.value === Visibility.SHOWN) {
    creationDatePanelVisibliy.value = Visibility.HIDDEN
  }
}

function discardFilter() {
  selectStates.value = (props.stateValue ? Object.assign([], (props.stateValue as FilterSelectionDefinition).value as Array<string>) : [])
  selectedTypes.value = (props.modelTypeValue ? Object.assign([], (modelTypeValueComputed.value as FilterSelectionDefinition).value as Array<string>) : [])
  selectedModels.value = (props.modelDeclinationValue ? Object.assign([], (props.modelDeclinationValue as FilterSelectionDefinition).value as Array<string>) : [])
  selectedConnectivitys.value = (props.connectivityValue ? Object.assign([], (props.connectivityValue as FilterSelectionDefinition).value as Array<string>) : [])

  selectCertificateExpireAfter.value = (props.certificateExpireAfter ? Object.assign({}, props.certificateExpireAfter) : undefined)
  selectedCertificateExpireBefore.value = (props.certificateExpireBefore ? Object.assign({}, props.certificateExpireBefore) : undefined)

  filterVisiblity.value = Visibility.HIDING
}

if (props.stateValue) {
  watch(() => props.stateValue?.value, (newState?: FilterSelectionValue) => {
    if (newState) {
      selectStates.value = (newState as Array<string> | undefined)?.slice(0) ?? []
    }
  }, {
    deep: true,
  })
}

if (props.connectivityValue) {
  watch(() => props.connectivityValue?.value, (newConnectivity?: FilterSelectionValue) => {
    if (newConnectivity) {
      selectedConnectivitys.value = (newConnectivity as Array<string> | undefined)?.slice(0) ?? []
    }
  }, {
    deep: true,
  })
}

if (props.modelTypeValue) {
  watch(() => modelTypeValueComputed.value.value, (newModelType?: FilterSelectionValue) => {
    if (newModelType) {
      selectedTypes.value = (newModelType as Array<string> | undefined)?.slice(0) ?? []
    }
  }, {
    deep: true,
  })
}

if (props.modelDeclinationValue) {
  watch(() => props.modelDeclinationValue?.value, (newModelDeclination?: FilterSelectionValue) => {
    if (newModelDeclination) {
      selectedModels.value = (newModelDeclination as Array<string> | undefined)?.slice(0) ?? []
    }
  }, {
    deep: true,
  })
}

if (props.certificateExpireAfter) {
  watch(() => props.certificateExpireAfter, (newCertificateExpireAfter?: FilterSelectionDefinition) => {
    selectCertificateExpireAfter.value = newCertificateExpireAfter ? Object.assign({}, newCertificateExpireAfter) : undefined
  }, {
    deep: true,
  })
}

if (props.certificateExpireBefore) {
  watch(() => props.certificateExpireBefore, (certificateExpireBefore?: FilterSelectionDefinition) => {
    selectedCertificateExpireBefore.value = certificateExpireBefore ? Object.assign({}, props.certificateExpireBefore) : undefined
  }, {
    deep: true,
  })
}

if (props.stateValue) {
  selectStates.value = (props.stateValue.value as Array<string> | undefined) ?? []
}

if (props.connectivityValue) {
  selectedConnectivitys.value = (props.connectivityValue.value as Array<string> | undefined)?.slice(0) ?? []
}

if (props.modelTypeValue) {
  selectedTypes.value = (modelTypeValueComputed.value.value as Array<string> | undefined)?.slice(0) ?? []
}

if (props.modelDeclinationValue) {
  selectedModels.value = (props.modelDeclinationValue.value as Array<string> | undefined)?.slice(0) ?? []
}

selectCertificateExpireAfter.value = props.certificateExpireAfter ? Object.assign({}, props.certificateExpireAfter) : undefined
selectedCertificateExpireBefore.value = props.certificateExpireBefore ? Object.assign({}, props.certificateExpireBefore) : undefined
</script>

<template>
  <div class="relative mb-4">
    <lxc-filters
      :menu-items="menuItems"
      :selected-filters="selectedFilterTags"
      :undeletable-selected-filters="undeletableAppliedFilterTags"
      :filters-by-type="selectedFiltersByTypes"
      is-button-right
      @apply="applyFilter"
      @reset="onClearFilters"
      @delete-tag="onDeletSelectedTag"
      @end-slide-to-panel="onEndSlideToPanel"
      @start-back-to-menu="onStartBackToMenu"
      @shown="onFilterShow"
      @hidden="onFilterHidden"
      @discard="discardFilter"
    >
      <template #state>
        <div class="rounded-lg bg-white text-gray-900 p-6">
          <lxc-checkbox
            v-for="stateOption in stateOptions.options"
            :key="stateOption.value"
            v-model="selectStates"
            :label="$t(stateOption.label)"
            :value="stateOption.value"
            :disabled="stateOption.disabled"
          />
        </div>
      </template>
      <template #type>
        <div class="rounded-lg bg-white text-gray-900 p-6">
          <lxc-checkbox
            v-for="typeOption in typeOptions.options"
            :key="typeOption.value"
            v-model="selectedTypes"
            :label="typeOption.label"
            :value="typeOption.value"
            :disabled="typeOption.disabled"
          />
        </div>
      </template>
      <template #model>
        <div class="rounded-lg bg-white text-gray-900 p-6">
          <lxc-checkbox
            v-for="modelOption in modelOptions.options"
            :key="modelOption.value"
            v-model="selectedModels"
            :label="modelOption.label"
            :value="modelOption.value"
            :disabled="modelOption.disabled"
          />
        </div>
      </template>
      <template #connectivity>
        <div class="rounded-lg bg-white text-gray-900 p-6">
          <lxc-checkbox
            v-for="connectivityOption in appConnectivityOptions.options"
            :key="connectivityOption.value"
            v-model="selectedConnectivitys"
            :label="$t(connectivityOption.label)"
            :value="connectivityOption.value"
          />
        </div>
      </template>
      <template #certificate>
        <lxc-date-filter
          v-model:creation-start-date="selectCertificateExpireAfter"
          v-model:creation-end-date="selectedCertificateExpireBefore"
          :filter-panel-visibiliy="filterVisiblity"
          :visibiliy="creationDatePanelVisibliy"
          :shortcuts="shortcuts"
        />
      </template>
    </lxc-filters>
  </div>
  <div
    v-if="isShowTagSet"
    class="flex items-center justify-end mb-4"
  >
    <lxc-tag-set
      deletable
      type="primary"
      :data="appliedFilterTags"
      :data-disabled="undeletableAppliedFilterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      @delete="onAppliedTagDeleteClick"
    />
    <a
      class="font-medium whitespace-nowrap ml-3"
      href="#"
      @click="onClearFilters"
    >
      {{ $t('filters.resetFilter') }}
    </a>
  </div>
</template>
