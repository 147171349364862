<script setup lang="ts">
import type { SlideMenuItem } from '@lxc/app-device-common'
import dayjs from 'dayjs'
import { Filters, Visibility } from '~/types'
import type { FilterOptions, FilterSelectionDefinition, FilterSelectionValue, Option } from '~/types'
import type { ReactiveArrayObject } from '~/types/reactiveArrayObject'

const props = defineProps<{
  status?: FilterSelectionDefinition
  ranges?: FilterSelectionDefinition
  creationStartDate?: FilterSelectionDefinition
  creationEndDate?: FilterSelectionDefinition
  statusOptions: FilterOptions
  rangeOptions: FilterOptions
}>()

const { t } = useI18n()

// restrict filters on ranges to the provided ranges options
const rangesComputed = computed((): FilterSelectionDefinition => {
  const rangeFilterValue: FilterSelectionValue = []
  props.rangeOptions.options.forEach((option) => {
    if (props.ranges?.value.includes(option.value)) {
      rangeFilterValue.push(option.value)
    }
  })
  const rangesComputed: FilterSelectionDefinition = {
    key: props.ranges!.key,
    operator: props.ranges!.operator,
    value: rangeFilterValue,
  }

  return rangesComputed
})

const rangesFallback = props.rangeOptions.options.filter(option => !option.disabled).map(option => option.value)

const undeletableAppliedFilterTags: Ref<string[]> = ref([])
const undeletableStatusFilters: Ref<string[]> = ref([])
const undeletableRangeFilters: Ref<string[]> = ref([])

function pushUndeletableTagsAndFilters(option: Option, currentValue: string, tag: string, undeletableFilterArray: string[]) {
  if (option.value === currentValue && option.disabled) {
    undeletableAppliedFilterTags.value.push(tag)
    if (!undeletableFilterArray.includes(currentValue)) {
      undeletableFilterArray.push(currentValue)
    }
  }
}

function cloneRanges(paramRange?: FilterSelectionDefinition | null) {
  return paramRange && (paramRange?.value != null && Array.isArray(paramRange.value))
    ? {
      key: paramRange.key,
      operator: paramRange.operator,
      value: (((paramRange as FilterSelectionDefinition).value) as Array<string>).slice(0),
    }
    : undefined
}

function cloneStatus(paramStatus?: FilterSelectionDefinition | null) {
  return paramStatus && (paramStatus?.value != null && Array.isArray(paramStatus.value))
    ? {
      key: paramStatus.key,
      operator: paramStatus.operator,
      value: (((paramStatus as FilterSelectionDefinition).value) as Array<string>).slice(0),
    }
    : undefined
}

const statusLabel = t('firmware.filters.status.label')
const rangeLabel = t('firmware.filters.range.label')
const creationDateLabel = t('firmware.filters.creationDate.label')
const tagFormatter = t('firmware.filters.creationDate.formatter.tag')
const datePeriodSeparator = t('firmware.filters.creationDate.periodSeparator')
const status = ref<FilterSelectionDefinition | undefined | null>(props.status ? Object.assign({}, props.status) : undefined)
const ranges = ref<FilterSelectionDefinition | undefined | null>(cloneRanges(rangesComputed.value))
const appliedRangeOptions = reactive<ReactiveArrayObject<Option>>({ values: [] })

const creationStartDate = ref<FilterSelectionDefinition | undefined | null>(props.creationStartDate ? Object.assign({}, props.creationStartDate) : undefined)
const creationEndDate = ref<FilterSelectionDefinition | undefined | null>(props.creationEndDate ? Object.assign({}, props.creationEndDate) : undefined)
const menuItems: SlideMenuItem[] = [
  {
    disabled: false,
    id: 'status',
    footerId: 'status-footer',
    footerEnabled: true,
    header: statusLabel,
    menuLabel: statusLabel,
  }, {
    disabled: false,
    id: 'ranges',
    footerId: 'ranges-footer',
    footerEnabled: true,
    header: rangeLabel,
    menuLabel: rangeLabel,
  },
  {
    disabled: false,
    id: 'creationDate',
    footerId: 'creationDate-footer',
    footerEnabled: true,
    header: creationDateLabel,
    menuLabel: creationDateLabel,
  },
]

const displayedPanel = ref<string | undefined | null>()
const displayMenu = ref<number | undefined>()
const filterVisiblity = ref<Visibility>(Visibility.HIDDEN)
const creationDatePanelVisibliy = ref<Visibility>(Visibility.HIDDEN)

function getMenuItemByHtmlId(pHtmlId?: string | null): SlideMenuItem | undefined {
  return menuItems.find(menuItem => menuItem.htmlId === pHtmlId)
}

const onFilterShow = () => {
  filterVisiblity.value = Visibility.SHOWN
}

const onFilterShowing = () => {
  filterVisiblity.value = Visibility.SHOWING
}

const onFilterHidden = () => {
  filterVisiblity.value = Visibility.HIDDEN
}

const onEndSlideToPanel = (htmlId: string | null) => {
  const menuItem = getMenuItemByHtmlId(htmlId)
  creationDatePanelVisibliy.value = (menuItem?.id === 'creationDate' && displayMenu.value !== 0) ? Visibility.SHOWN : Visibility.HIDDEN
}

const onStartBackToMenu = () => {
  if (creationDatePanelVisibliy.value === Visibility.SHOWN) {
    creationDatePanelVisibliy.value = Visibility.HIDDEN
  }
}

const emit = defineEmits(['change', 'enter'])

const selectedFiltersByTypes = computed<Array<FilterSelectionValue | Array<FilterSelectionValue | undefined> | null | undefined>>(() =>
  [
    status?.value?.value,
    ranges?.value?.value,
    [creationStartDate?.value?.value, creationEndDate?.value?.value],
  ])

function getStatusTagText(paramStatus: string) {
  return `${t('firmware.filters.status.label')?.toLowerCase()} ${paramStatus ? t(`firmware.status.${paramStatus}`) : ''}`
}

function getRangeTagText(paramRange: string) {
  const rangeOption = appliedRangeOptions.values.find(option => option.value === paramRange)
  return `${t('device.type')?.toLowerCase()} ${rangeOption?.label ?? paramRange}`
}

function getCreationDateTagText(paramStartDate?: FilterSelectionValue, paramEndDate?: FilterSelectionValue) {
  let result = ''

  if (paramStartDate) {
    const startDate = new Date(paramStartDate as string)
    const displayStartDate = dayjs(startDate).format(tagFormatter)
    if (!paramEndDate || paramStartDate === paramEndDate) {
      result = `${t('firmware.filters.creationDate.label')?.toLowerCase()} ${displayStartDate}`
    } else {
      const endDate = new Date(paramEndDate as string)
      const displayEndDate = dayjs(endDate).format(tagFormatter)
      result = `${t('firmware.filters.creationDate.label')?.toLowerCase()} ${displayStartDate} ${datePeriodSeparator} ${displayEndDate}`
    }
  }

  return result
}

function initTagFilters(
  paramStatus?: FilterSelectionDefinition | null,
  paramRanges?: FilterSelectionDefinition | null,
  paramCreationStartDate?: FilterSelectionDefinition | null,
  paramCreationEndDate?: FilterSelectionDefinition | null,
): string[] {
  const tagFilters: string[] = []

  if (paramStatus != null && paramStatus.value && Array.isArray(paramStatus.value) && paramStatus.value.length !== 0) {
    const statusTagText = getStatusTagText(paramStatus.value[0])
    tagFilters.push(statusTagText)
    props.statusOptions.options.forEach((option) => {
      pushUndeletableTagsAndFilters(option, paramStatus.value[0], statusTagText, undeletableStatusFilters.value)
    })
  }

  if (paramRanges && paramRanges.value != null && Array.isArray(paramRanges.value)) {
    for (const range of paramRanges.value) {
      const tagRange = getRangeTagText(range)
      tagFilters.push(tagRange)
      props.rangeOptions.options.forEach((option) => {
        pushUndeletableTagsAndFilters(option, paramRanges.value[0], tagRange, undeletableRangeFilters.value)
      })
    }
  }

  if (paramCreationStartDate?.value ?? paramCreationEndDate?.value) {
    tagFilters.push(getCreationDateTagText(paramCreationStartDate?.value, paramCreationEndDate?.value))
  }

  return tagFilters
}

function initFilterMapForArray(pFilterMap: Record<string, FilterSelectionDefinition>, filterDefinition: FilterSelectionDefinition, tagTextGenerator: (val: string) => string) {
  if (filterDefinition.value && Array.isArray(filterDefinition.value)) {
    for (const value of filterDefinition.value) {
      const tag = tagTextGenerator(value)
      pFilterMap[tag] = {
        key: filterDefinition.key,
        operator: filterDefinition.operator,
        value,
      }
    }
  }
}

function initFilterMap(
  paramStatus?: FilterSelectionDefinition | null,
  paramRanges?: FilterSelectionDefinition | null,
  paramCreationStartDate?: FilterSelectionDefinition | null,
  paramCreationEndDate?: FilterSelectionDefinition | null): Record<string, FilterSelectionDefinition> {
  const cFilterMap: Record<string, FilterSelectionDefinition> = {}

  if (paramStatus && paramStatus.value && Array.isArray(paramStatus.value) && paramStatus.value.length !== 0) {
    const tag = getStatusTagText(paramStatus.value[0])
    cFilterMap[tag] = paramStatus
  }

  if (paramRanges) {
    initFilterMapForArray(cFilterMap, paramRanges, getRangeTagText)
  }

  if (paramCreationStartDate?.value ?? paramCreationEndDate?.value) {
    const tag = getCreationDateTagText(paramCreationStartDate?.value, paramCreationEndDate?.value)
    const tagValue: FilterSelectionDefinition = {
      key: 'creationDate',
      operator: '=',
      value: [
        (paramCreationStartDate?.value as string | undefined) ?? '',
        (paramCreationEndDate?.value as string | undefined) ?? '',
      ],
    }
    cFilterMap[tag] = tagValue
  }
  return cFilterMap
}

function mapRangeFromFilter(newRanges?: FilterSelectionDefinition | null): Option[] {
  let filteredRangeOptions: Option[] = []

  if (newRanges != null && Array.isArray(newRanges.value)) {
    filteredRangeOptions = props.rangeOptions.options
      .filter(paramOption => newRanges.value.includes(paramOption.value))
      .map(paramOption => Object.assign({}, paramOption))
  }

  return filteredRangeOptions
}

const selectedFilterTags = computed((): string[] => {
  return initTagFilters(status.value, ranges.value, creationStartDate.value, creationEndDate.value)
})

const selectedFilterMap = computed((): Record<string, FilterSelectionDefinition> => {
  return initFilterMap(status.value, ranges.value, creationStartDate.value, creationEndDate.value)
})

const appliedFilterTags = computed((): string[] => {
  return initTagFilters(props.status, rangesComputed.value, props.creationStartDate, props.creationEndDate)
})

const appliedFilterMap = computed((): Record<string, FilterSelectionDefinition> => {
  return initFilterMap(props.status, rangesComputed.value, props.creationStartDate, props.creationEndDate)
})

function deleleTagFromList(valueToDelete: string, list: Ref<FilterSelectionDefinition | null | undefined>) {
  if (list.value && Array.isArray(list.value?.value)) {
    const index = list.value.value.findIndex(value => value === valueToDelete)

    if (index != null && index >= 0) {
      list.value.value.splice(index, 1)
    }
  }
}

function onDeletSelectedTag(tag: string) {
  const filterDefinition: FilterSelectionDefinition | Record<string, FilterSelectionDefinition> | undefined = selectedFilterMap.value[tag]

  if (filterDefinition && filterDefinition.key) {
    switch (filterDefinition.key) {
      case Filters.STATUS:
        status.value = {
          key: Filters.STATUS,
          operator: '=',
          value: [],
        }
        break
      case Filters.MODEL_TYPE:
        deleleTagFromList(filterDefinition.value as string, ranges)
        break
      case 'creationDate':
        creationStartDate.value = null
        creationEndDate.value = null
        break
    }
  }
}

function onAppliedTagDeleteClick(tag: string) {
  const filterSelectionDefinition = appliedFilterMap.value[tag]

  if (filterSelectionDefinition) {
    switch (filterSelectionDefinition.key) {
      case Filters.STATUS:
        status.value = {
          key: Filters.STATUS,
          operator: '=',
          value: [],
        }

        emit('change', Filters.STATUS, [])
        break
      case Filters.MODEL_TYPE:
        deleleTagFromList(filterSelectionDefinition.value as string, ranges)
        emit('change', Filters.RANGE, (ranges.value as FilterSelectionDefinition).value, rangesFallback)
        break
      case 'creationDate':
        if ((filterSelectionDefinition.value as string[]).length <= 2) {
          creationStartDate.value = {
            key: Filters.DATE_ADDED_AFTER,
            operator: '>=',
            value: '',
          }
          emit('change', Filters.DATE_ADDED_BEFORE, '')

          creationEndDate.value = {
            key: Filters.DATE_ADDED_BEFORE,
            operator: '<=',
            value: '',
          }
          emit('change', Filters.DATE_ADDED_AFTER, '')
        }
        break
    }

    delete appliedFilterMap.value[tag]
    emit('enter')
  }
}

const isAnyAppliedFilter = computed<boolean>((): boolean => !!(props.status?.value?.length || props.ranges?.value?.length || props.creationStartDate?.value || props.creationEndDate?.value))

function applyFilter() {
  emit('change', Filters.STATUS, (status.value as FilterSelectionDefinition)?.value ?? [])
  emit('change', Filters.RANGE, (ranges.value as FilterSelectionDefinition)?.value ?? [], rangesFallback)
  emit('change', Filters.DATE_ADDED_AFTER, creationStartDate.value?.value)
  emit('change', Filters.DATE_ADDED_BEFORE, creationEndDate.value?.value)

  emit('enter')

  filterVisiblity.value = Visibility.HIDING
}

function discardFilter() {
  status.value = cloneStatus(props.status) ?? null
  ranges.value = cloneRanges(props.ranges) ?? null
  creationStartDate.value = props.creationStartDate ? Object.assign({}, props.creationStartDate) : null
  creationEndDate.value = props.creationEndDate ? Object.assign({}, props.creationEndDate) : null

  filterVisiblity.value = Visibility.HIDING
}

function clearFilter() {
  emit('change', Filters.STATUS, undeletableStatusFilters.value.slice(0))
  emit('change', Filters.RANGE, undeletableRangeFilters.value.slice(0), rangesFallback)
  emit('change', Filters.DATE_ADDED_AFTER, '')
  emit('change', Filters.DATE_ADDED_BEFORE, '')
  emit('enter')

  filterVisiblity.value = Visibility.HIDING
}

function onPropsStatusChange(newStatus?: FilterSelectionDefinition | null) {
  status.value = Object.assign({}, newStatus)
}

function onPropsRangesChange(newRanges?: FilterSelectionDefinition | null) {
  ranges.value = cloneRanges(newRanges)
  appliedRangeOptions.values = mapRangeFromFilter(newRanges)
}

function onPropsCreationStartDateChange(newCreationStartDate?: FilterSelectionDefinition | null) {
  creationStartDate.value = Object.assign({}, newCreationStartDate)
}

function onPropsCreationEndDateChange(newCreationEndDate?: FilterSelectionDefinition | null) {
  creationEndDate.value = Object.assign({}, newCreationEndDate)
}

watch(() => props.status, onPropsStatusChange, { deep: true })
watch(() => props.ranges, onPropsRangesChange, { deep: true })
watch(() => props.creationStartDate, onPropsCreationStartDateChange, { deep: true })
watch(() => props.creationEndDate, onPropsCreationEndDateChange, { deep: true })
</script>

<template>
  <div class="relative mb-4">
    <lxc-filters
      v-model:displayed-panel="displayedPanel"
      v-model:display-menu="displayMenu"
      :filters-by-type="selectedFiltersByTypes"
      :menu-items="menuItems"
      :selected-filters="selectedFilterTags"
      :undeletable-selected-filters="undeletableAppliedFilterTags"
      is-button-right
      @apply="applyFilter"
      @delete-tag="onDeletSelectedTag"
      @discard="discardFilter"
      @reset="clearFilter"
      @showing="onFilterShowing"
      @shown="onFilterShow"
      @hidden="onFilterHidden"
      @end-slide-to-panel="onEndSlideToPanel"
      @start-back-to-menu="onStartBackToMenu"
    >
      <template #status>
        <lxc-firmware-status-filter
          v-model="status"
          :header="statusLabel"
          :status-options="statusOptions"
        />
      </template>

      <template #ranges>
        <lxc-firmware-ranges-filter
          v-model="ranges"
          :range-options="rangeOptions"
        />
      </template>

      <template #creationDate>
        <lxc-firmware-creation-date-filter
          v-model:creation-start-date="creationStartDate"
          v-model:creation-end-date="creationEndDate"
          :filter-panel-visibiliy="filterVisiblity"
          :visibiliy="creationDatePanelVisibliy"
        />
      </template>
    </lxc-filters>
  </div>

  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-end mb-4"
  >
    <lxc-tag-set
      deletable
      type="primary"
      :data="appliedFilterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      :data-disabled="undeletableAppliedFilterTags"
      @delete="onAppliedTagDeleteClick"
    />
    <a
      class="font-medium whitespace-nowrap ml-3"
      href="#"
      @click="clearFilter"
    >
      {{ $t('filters.resetFilter') }}
    </a>
  </div>
</template>
