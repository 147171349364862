<script setup lang="ts">
import type { CampaignI } from '@lxc/app-device-types'
import { PATHS } from '~/constants/paths'
import UsersService from '~/services/users.service'
import LxcError from '~/utils/LxcError'

const props = defineProps<{
  campaign?: CampaignI | null
}>()

const { t } = useI18n()
const createdByUsr = ref()

async function getCreatedByUsr(id: string) {
  let createdByUsr
  const response = await UsersService.getUserById(id)

  if (LxcError.check(response)) {
    createdByUsr = `${id} id`
  } else {
    createdByUsr = `${response.firstName} ${response.lastName}`
  }

  return createdByUsr
}

const baseURL = import.meta.env.LXC_FI_DEVICE_URL.replace(/\/$/g, '')
const hyperlink = computed(() => `${baseURL}${PATHS.USER_MANAGEMENT_USERS}/${props.campaign?.createdByUsr}`)

onMounted(async() => {
  if (props.campaign?.createdByUsr) {
    createdByUsr.value = await getCreatedByUsr(props.campaign?.createdByUsr.toString())
  }
})

</script>

<template>
  <div class="grid gap-4 grid-cols-2 grid-rows-1">
    <lxc-card>
      <h3>
        {{ $t('campaign.details.characteristics.title') }}
      </h3>
      <div class="grid gap-4 grid-cols-2 grid-rows-1">
        <div class="flex gap-4 flex-col">
          <div class="flex gap-2  flex-col">
            <div
              id="characteristics-status"
              class="flex gap-4"
            >
              <div class="text-gray-500">
                {{ $t('campaign.details.characteristics.status.label') }}
              </div>
              <div>{{ t(`campaign.details.characteristics.status.value.${campaign?.state}`) }}</div>
            </div>
            <div
              id="characteristics-type"
              class="flex gap-4"
            >
              <div class="text-gray-500">
                {{ $t('campaign.details.characteristics.type.label') }}
              </div>
              <div>{{ t(`campaign.details.characteristics.type.value.${campaign?.type}`) }}</div>
            </div>
            <p>
              {{ $t('campaign.details.characteristics.createdBy') }} <a
                :href="`${hyperlink}`"
              >{{ createdByUsr }}</a>
            </p>
          </div>
          <div>
            <lxc-campaign-timeline :campaign="campaign" />
          </div>
        </div>
        <div>
          <lxc-campaign-inclusion-periods :campaign="campaign" />
        </div>
      </div>
    </lxc-card>
    <lxc-card>
      <h3>
        {{ $t('campaign.details.characteristics.equipments.title', campaign?.statistics?.total || 0) }}
      </h3>
      <lxc-campaign-pie
        class="h-60"
        :campaign="campaign"
      />
    </lxc-card>
  </div>
</template>
