import { CampaignOperationState } from '@lxc/app-device-types'
import type { FilterOptions } from '~/types'

export const operationStateOptions: FilterOptions = {
  label: 'filters.state',
  options: [
    {
      value: CampaignOperationState.DONE,
      label: 'operation.done',
    },
    {
      value: CampaignOperationState.ERROR,
      label: 'operation.error',
    },
    {
      value: CampaignOperationState.CANCELLING,
      label: 'operation.cancelling',
    },
    {
      value: CampaignOperationState.CANCELLED,
      label: 'operation.cancelled',
    },
    {
      value: CampaignOperationState.PAUSED,
      label: 'operation.paused',
    },
    {
      value: CampaignOperationState.OP_RUNNING,
      label: 'operation.running',
    },
    {
      value: CampaignOperationState.PENDING,
      label: 'operation.delivered',
    },
    {
      value: CampaignOperationState.EXPIRED,
      label: 'operation.expired',
    },
  ],
}
