<script setup lang="ts">
import type { Ref } from 'vue'
import { ref } from 'vue'
import type { UserDataI } from '@lxc/app-device-types'
import { useUsers } from '~/composables/useUsers'
import { SearchMode } from '~/composables/useSearch'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import type { UserSearchI } from '~/types'
import { Filters } from '~/types'
const emit = defineEmits(['update:modelValue'])

const props = defineProps<{
  declinations?: Array<string>
  name?: string
  modelValue?: UserDataI[]
  noAction?: boolean
  selectedUsers?: Array<UserDataI>
  types?: Array<string>
}>()

const {
  isLoading,
  results: users,
  filters,
  error,
  fetchData,
  setFilter,
  onSortChange,
  search,
  onSearch,
} = useUsers(SearchMode.FILTER_SEARCH)

const filterMap: Ref<Map<Filters, any> | undefined> = ref()

function userDataToUserSearch(user: UserDataI): UserSearchI {
  const { id, login, email, firstName, lastName, organization, language, phone, status } = user

  return {
    email,
    firstName,
    fullName: (firstName ? `${firstName} ` : '') + lastName,
    id,
    language,
    lastName,
    login,
    organization,
    phone,
    status,
  }
}

async function loadData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  const params = new Map()
  await fetchData(page, pageSize, undefined, params)
}

// Apply provided default filters
const updateListFilter = () => {
  if (filterMap.value != null) {
    for (const [filter, value] of filterMap.value) {
      setFilter(filter, value ?? '')
    }
  }
}

watch(() => props.name, (filterByName) => {
  if (!filterMap.value) {
    filterMap.value = new Map<Filters, any>()
  }
  if (!filterByName) {
    if (filterMap.value.has(Filters.NAME)) {
      filterMap.value.set(Filters.NAME, '')
    }
  } else {
    filterMap.value.set(Filters.NAME, filterByName)
  }

  updateListFilter()
  search()
})

const visibleData = computed(() => {
  return users.value?.data.length ?? 0 <= 10 ? users.value?.data.map(userDataToUserSearch) : users.value?.data.slice(0, 10).map(userDataToUserSearch) ?? []
})

const selectedItems = computed({
  get() {
    if (props.modelValue) {
      return props.modelValue.map(user => user.id ?? '')
    }

    return []
  },
  set(selected?: Array<string>) {
    if (selected != null) {
      const userItems: Array<UserDataI> = selected.map((currentUserId) => {
        // If the device is not in the user list found, use modelValue where there was the previous selected user list
        let user = users.value?.data.find(currentUser => currentUser.id === currentUserId)

        if (!user) {
          user = props.modelValue?.find(user => user.id === currentUserId)
        }
        return user
      }).filter(currentUser => currentUser !== undefined) as Array<UserDataI>
      emit('update:modelValue', userItems)
    } else {
      emit('update:modelValue', [])
    }
  },
})

onSearch(loadData)
</script>

<template>
  <div class="pb-6 px-6 overlow-y-auto">
    <lxc-filter-checklist
      v-model="selectedItems"
      :data="visibleData"
      :is-loading="isLoading"
      :empty-text="$t('user.empty')"
      :header="$t('logs.filters.entityClass.value.user')"
      header-class="underline text-base"
      prop="fullName"
      checkbox-prop="id"
    />
  </div>
</template>
