<script setup lang="ts">
import type { DashboardServices } from '@lxc/app-device-types'
import { useAcl } from 'vue-simple-acl'
import DashboardService from '~/services/dashboard.service'
import { DashboardCardStatus } from '~/types/dashboard'
import { verifyCardStatus } from '~/utils/dashboard.utils'
import LxcError from '~/utils/LxcError'
import { ACL_ROLES } from '~/types'

const { t } = useI18n()
const { can } = useAcl()
const dashboardLoadingStatus = ref<DashboardCardStatus>(DashboardCardStatus.LOADING)
const dashboardServices = ref<DashboardServices>()

const displayPkiService = ACL_ROLES.CONFIGURE_PKI

async function fetchDashboardServices() {
  dashboardLoadingStatus.value = DashboardCardStatus.LOADING

  const response = await DashboardService.getDashboardServices()

  if (LxcError.check(response)) {
    dashboardLoadingStatus.value = DashboardCardStatus.FAILED
  } else {
    dashboardServices.value = response
    dashboardLoadingStatus.value = DashboardCardStatus.LOADED
  }
}

onMounted(async() => {
  if (can(displayPkiService)) {
    await fetchDashboardServices()
  } else {
    dashboardLoadingStatus.value = DashboardCardStatus.UNAUTHORIZED
  }
})

const isAuthorized = computed(() => dashboardLoadingStatus.value !== DashboardCardStatus.UNAUTHORIZED)

</script>

<template>
  <lxc-dashboard-card
    :title="$t('dashboard.services.title')"
    :display-header-margin="true"
    :status="verifyCardStatus(dashboardLoadingStatus, dashboardServices)"
  >
    <div
      v-if="isAuthorized"
    >
      <lxc-services-status-item
        :title="$t('dashboard.services.activeDirectory')"
        :service-status="dashboardServices?.activeDirectory"
      />

      <lxc-services-status-item
        v-if="isAuthorized"
        :title="$t('dashboard.services.pkiConnector')"
        :service-status="dashboardServices?.pkiConnector"
      />

      <lxc-services-status-item
        v-if="isAuthorized"
        :title="$t('dashboard.services.smtpServer')"
        :service-status="dashboardServices?.smtpServer"
      />
    </div>
    <div
      v-else
      class="description-container"
    >
      <div class="description-content">
        {{ $t('dashboard.services.unauthorized') }}
      </div>
    </div>
  </lxc-dashboard-card>
</template>

<style lang='scss' scoped>
.description-container {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

.description-title {
  color: $light-blue-color;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  width: 30px;
}

.description-content {
  margin-left: 25px;
  font-size: 12px;
}

</style>
