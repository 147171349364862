<script setup lang="ts">
import type { DashboardSector, SectorI } from '@lxc/app-device-types'
import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { useSectorStore } from '~/stores/useSectorStore'
import type { DashboardCardStatus } from '~/types/dashboard'
import { verifyCardStatus } from '~/utils/dashboard.utils'

const { t } = useI18n()
const {
  selectedSectors,
  availableSectors,
} = storeToRefs(useSectorStore())

const props = defineProps<{
  status: DashboardCardStatus
  sector?: DashboardSector
}>()

const subtitle = computed(() => {
  const sectors = selectedSectors.value.length !== 0 ? selectedSectors.value : availableSectors.value
  return (sectors ?? []).filter((sector: SectorI) => props.sector?.sectors.includes(sector.code)).map(sector => sector.label).join(', ')
})

const deviceDisplay: Ref<string> = computed(() => `${props.sector?.devices ?? '-'}`)
const applicationsDisplay: Ref<string> = computed(() => `${props.sector?.applications ?? '-'}`)

</script>

<template>
  <lxc-dashboard-card
    :title="$t('dashboard.sector.title')"
    :subtitle="subtitle"
    :display-header-margin="false"
    :status="verifyCardStatus(props.status, props.sector)"
  >
    <div>
      <dl class="description-container">
        <dt class="content-value">
          {{ deviceDisplay }}
        </dt>
        <dd class="content-label">
          {{ $t('dashboard.sector.devices') }}
        </dd>
      </dl>
      <dl class="description-container">
        <dt class="content-value">
          {{ applicationsDisplay }}
        </dt>
        <dd class="content-label">
          {{ $t('dashboard.sector.applications') }}
        </dd>
      </dl>
    </div>
  </lxc-dashboard-card>
</template>

<style lang='scss' scoped>
.content-container {
  margin-top: 0;
}

.description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
}

.content-value {
  color: $light-blue-color;
  font-size: 20px;
  font-weight: bold;
}

.content-label {
  color: $primary-color;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

</style>
