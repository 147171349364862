export type FilterSelectionValue = string | string[]

export interface FilterSelectionDefinition {
  key: string | string[]
  operator: string
  value: FilterSelectionValue
  fallbackValue?: FilterSelectionValue
  valueFormatter?: (value: FilterSelectionValue) => FilterSelectionValue
}

export type FiltersSelection = Map<Filters, FilterSelectionDefinition>

export interface Option {
  label: string
  value: string
  disabled?: boolean
}

export interface FilterOptions {
  label?: string
  options: Option[]
}

export enum Filters {
  NAME = 'name',
  SECTORS = 'sectors',
  CONNECTIVITY ='connectivityState',
  STATE ='state',
  STATUS ='status',
  MODEL_TYPE ='lxcModelType',
  MODEL_DECLINATION ='lxcModelDeclination',
  CERTIFICATE_EXPIRE_BEFORE = 'certificateExpireBefore',
  CERTIFICATE_EXPIRE_AFTER = 'certificateExpireAfter',
  FIRMWARE_NAME = 'firmwareName',
  FIRMWARE_NAME_OR_VERSION = 'firmwareNameOrVersion',
  RANGE = 'range',
  DATE_ADDED_BEFORE = 'dateAddedBefore',
  DATE_ADDED_AFTER = 'dateAddedAfter',
  FIRMWARE_VERSIONS = 'firmwareVersions',
  HARDWARE_VERSIONS = 'hardwareVersions',
  APPLICATION_NAME = 'applicationName',
  APPMGT_DEVICE_TYPES = 'appmgtDeviceTypes',
  APPMGT_DATE_ADDED_BEFORE = 'appmgtDateAddedBefore',
  APPMGT_DATE_ADDED_AFTER = 'appmgtDateAddedAfter',
  APPMGT_DATE_UPDATED_BEFORE = 'appmgtDateUpdatedBefore',
  APPMGT_DATE_UPDATED_AFTER = 'appmgtDateUpdatedAfter',
  DEVICE_FIRMWARE_VERSIONS = 'deviceFirmwareVersions',
  DEVICE_HARDWARE_VERSIONS = 'deviceHardwareVersions',
  LOG_ACTION = 'action',
  LOG_TYPE = 'type',
  LOG_START_DATE = 'startDate',
  LOG_END_DATE = 'endDate',
  LOG_COMPONENT_ID = 'componentId',
  LOG_ENTITY_CLASS = 'entityClass',
  LOG_ENTITY_ID = 'entityId',
  LOG_EVENT_TYPE = 'eventType',
  LOG_LEVEL = 'level',
  SORT_BY = 'sortBy',
  SORT_DIRECTION = 'sortDirections',
}

export enum FiltersType {
  TWO_AMPERSAND_SEPARATOR,
  PIPE_SEPARATOR,
  MICROSOFT_FORMAT,
  JSON,
  RAW,
  FILTERS_SELECTION,
}
