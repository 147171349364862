<script setup lang="ts">
import type { ApplicationI, AsyncLogI, DeviceI, LogActionType, LogEventType, LogLevel, LogStatus, RoleI, UserDataI, UserGroupI, UserI, UserProfileI, UserSessionSector } from '@lxc/app-device-types'
import { LogComponentId, LogEntityClass, LogSortBy, LogSortDirection } from '@lxc/app-device-types'
import type { PeriodI, SlideMenuItem } from '@lxc/app-device-common'
import { filterEmptyValues } from '@lxc/app-device-common'
import type { Ref } from 'vue'
import { watch } from 'vue'
import DeviceService from '~/services/device.service'
import UsersService from '~/services/users.service'
import UserProfilesService from '~/services/userProfiles.service'
import SectorsService from '~/services/sectors.service'
import UserGroupsService from '~/services/userGroups.service'
import type { FilterOptions, LogFilterCriterion } from '~/types'
import filtersUtils from '~/utils/filters.utils'
import { getTodayPeriod } from '~/utils/date-tools'
import LxcError from '~/utils/LxcError'
import { NotificationKey } from '~/utils/notifications-tools'
import applicationService from '~/services/application.service'
import type { Option } from '~/types/filters'
import { LogEntitySubClass } from '~/types/logEntity'
import type { ReactiveArrayObject } from '~/types/reactiveArrayObject'
import { appModelTypeOptions } from '~/components/applications/applicationList/applicationsFilters.config'
import { typeOptions } from '~/components/devices/deviceList/deviceFilters.config'
const { t } = useI18n()

const props = defineProps<{
  actionType?: LogActionType
  actions?: string[]
  componentId?: LogComponentId
  entityClass?: LogEntityClass
  entitySubClass?: LogEntitySubClass
  entityId?: string[]
  endDate?: Date | null
  eventTypes?: LogEventType[]
  levels?: LogLevel[]
  rowsSelected: AsyncLogI[]
  sortBy?: LogSortBy
  sortDirection?: LogSortDirection
  startDate?: Date | null
  status?: LogStatus
}>()

let updatePending = false
let onPropsEntityChangedPending = false

const emit = defineEmits(['change',
  'update:actions',
  'update:actionType',
  'update:componentId',
  'update:endDate',
  'update:entityClass',
  'update:entitySubClass',
  'update:entityId',
  'update:levels',
  'update:eventTypes',
  'update:sortBy',
  'update:sortDirection',
  'update:startDate',
  'update:status'])

const componentId: Ref<LogComponentId | undefined> = ref(props.componentId)
const entityClass: Ref<LogEntityClass | undefined> = ref(props.entityClass)
const entitySubClass: Ref<LogEntitySubClass | undefined> = ref(props.entitySubClass)
const entityId: Ref<string[] | undefined> = ref(props.entityId)
const actions: Ref<string[] | undefined> = ref(props.actions)
const levels: Ref<LogLevel[] | undefined> = ref(props.levels)
const devicesDvtmEsoft = reactive<ReactiveArrayObject<DeviceI>>({ values: [] })
const applications = reactive<ReactiveArrayObject<ApplicationI>>({ values: [] })
const users = reactive<ReactiveArrayObject<UserDataI>>({ values: [] })
const appliedDevicesDvtmEsoft = reactive<ReactiveArrayObject<DeviceI>>({ values: [] })
const appliedApplications = reactive<ReactiveArrayObject<ApplicationI>>({ values: [] })
const appliedUserGroups = reactive<ReactiveArrayObject<UserGroupI>>({ values: [] })
const appliedProfiles = reactive<ReactiveArrayObject<UserProfileI>>({ values: [] })
const appliedRoles = reactive<ReactiveArrayObject<RoleI>>({ values: [] })
const appliedSectors = reactive<ReactiveArrayObject<UserSessionSector>>({ values: [] })
const appliedUsers = reactive<ReactiveArrayObject<UserDataI>>({ values: [] })
const appliedActions = reactive<ReactiveArrayObject<string>>({ values: [] })
const eventTypes: Ref<LogEventType[] | undefined> = ref(props.eventTypes)
const sortBy: Ref<LogSortBy | undefined> = ref(props.sortBy)
const sortDirection: Ref<LogSortDirection | undefined> = ref(props.sortDirection)
const isDevicesLoading = ref(false)
const isApplicationsLoading = ref(false)
const isUsersLoading = ref(false)
const isUserGroupsLoading = ref(false)
const isProfileLoading = ref(false)
const isRolesLoading = ref(false)
const isSectorLoading = ref(false)

const componentIdOptions: FilterOptions = {
  label: t('logs.filters.componentId.label'),
  options: (['']).concat(Object.values(LogComponentId)).map((value) => {
    let label = ''
    if (value) {
      label = (value !== LogComponentId.ALL) ? value : t('logs.filters.componentId.value.all')
    }

    return {
      value,
      label,
    }
  }),
}

const deviceDvtmEsoftLabel = t('logs.filters.entityClass.value.device-dvtm-esoft')
const applicationLabel = t('logs.filters.applications.label')
const sectorLabel = t('logs.filters.entityClass.value.sector')
const profileLabel = t('logs.filters.entityClass.value.profile')
const roleLabel = t('logs.filters.entityClass.value.role')
const userGroupLabel = t('logs.filters.entityClass.value.group')
const userLabel = t('logs.filters.entityClass.value.user')
const actionLabel = t('logs.action.label')
const periodSeparator = ref(` ${t('logs.filters.timestamp.periodSeparator')} `)
const eventTypeLabel = t('logs.eventType.label')
const levelLabel = t('logs.level.label')
const editableEntityClasses = [LogEntityClass.DEVICE_DVTM_ESOFT, LogEntityClass.USER]
const editableEntitySubClasses: Record<string, Array<LogEntitySubClass>> = {
  'device-dvtm-esoft': [LogEntitySubClass.APPLICATION, LogEntitySubClass.DEVICE_DVTM_ESOFT],
}

const sortByOptions: FilterOptions = {
  label: t('logs.filters.sortBy.label'),
  options: ((['']).concat(Object.values(LogSortBy))).map((value) => {
    const label = value ? t(`logs.filters.sortBy.value.${value}`) : ''
    return {
      value,
      label,
    }
  }),
}

const sortDirectionOptions: FilterOptions = {
  label: t('logs.filters.sortDirection.label'),
  options: ((['']).concat(Object.values(LogSortDirection))).map((value) => {
    const label = value ? t(`logs.filters.sortDirection.value.${value}`) : ''
    return {
      value,
      label,
    }
  }),
}

const { locale } = useI18n()

const isEntityLoading = computed((): boolean => {
  return isDevicesLoading.value || isApplicationsLoading.value || isUsersLoading.value || isUserGroupsLoading.value || isProfileLoading.value || isSectorLoading.value || isRolesLoading.value
})

const formPeriod = computed({
  get(): PeriodI {
    if (props.startDate != null && props.endDate != null) {
      return {
        startDate: props.startDate,
        endDate: props.endDate,
      }
    } else {
      return getTodayPeriod()
    }
  },
  set(newValue: PeriodI) {
    if (newValue.startDate == null || newValue.endDate == null) {
      const todayPeriod = getTodayPeriod()
      emit('update:startDate', todayPeriod.startDate)
      emit('update:endDate', todayPeriod.endDate)
    } else {
      emit('update:startDate', newValue.startDate)
      emit('update:endDate', newValue.endDate)
    }

    if (!updatePending) {
      emit('change')
    }
  },
})

function checkDeviceType(device?: DeviceI | ApplicationI): boolean {
  return (typeOptions.options.find((deviceType: Option) => (deviceType.value === device?.model?.type)) != null)
}

async function fetchAppliedEntities<T>(
  appliedList: ReactiveArrayObject<T>,
  service: any,
  detailsMethod: string,
  primaryKey: string,
  loading: Ref<boolean>,
  entityIds?: string[],
  list?: ReactiveArrayObject<T>): Promise<T[]>
{
  let entitiesResult: T[] = []

  if (entityIds && !loading.value) {
    loading.value = true
    let entityIdsToFetch: string[] = []

    if (list != null) {
      entityIdsToFetch = entityIds.filter(paramId => list.values.every((currentEntigy: T) => (currentEntigy as any)[primaryKey] !== paramId) && appliedList.values.every(currentEntity => (currentEntity as any)[primaryKey] !== paramId))

      entitiesResult = list.values.filter((currentEntity: T) => entityIds.includes(((currentEntity as any)[primaryKey]) ?? '')).concat(appliedList.values.filter((currentEntity) => {
        return entityIds.includes((currentEntity as any)[primaryKey] ?? '')
            && !list.values.some((pEntity: T) => (pEntity as any)[primaryKey] === (currentEntity as any)[primaryKey])
      }))
    } else {
      entityIdsToFetch = entityIds.filter(paramId => appliedList.values.every(currentEntity => (currentEntity as any)[primaryKey] !== paramId))
    }

    if (entityIdsToFetch.length > 0) {
      // Fetch the devices name if the device entityId is in the URL when the page is loaded.
      const response = await service[detailsMethod](entityIdsToFetch)

      if (LxcError.check(response)) {
        if (response.status === 404 || response.status === 410) {
          // If the resource does not exist or is deleted, then display the filter with the id
          entitiesResult = entitiesResult.concat(entityIdsToFetch.map((entityId) => {
            const fakeEntity: any = {}
            fakeEntity[primaryKey] = entityId
            fakeEntity.label = entityId
            return fakeEntity as T
          }))
        } else {
          response.notify(NotificationKey.error)
        }
      } else {
        entitiesResult = entitiesResult.concat(response)
      }
    }

    loading.value = false
  }

  return entitiesResult
}

async function fetchAppliedDevicesDvtmEsoft(deviceIds?: string[]) {
  const devicesResult = await fetchAppliedEntities<DeviceI>(appliedDevicesDvtmEsoft, DeviceService, 'getDevicesDetails', 'id', isDevicesLoading, deviceIds, devicesDvtmEsoft)
  appliedDevicesDvtmEsoft.values = devicesResult.filter((currentDevice: DeviceI) => checkDeviceType(currentDevice))
}

function checkApplicationType(application?: DeviceI | ApplicationI): boolean {
  return (appModelTypeOptions.options.find((appType: Option) => (appType.value === application?.model?.type)) != null)
}

async function fetchAppliedApplications(applicationIds?: string[]) {
  const applicationResult = await fetchAppliedEntities<ApplicationI>(appliedApplications, applicationService, 'getApplicationsDetails', 'id', isApplicationsLoading, applicationIds, applications)
  appliedApplications.values = applicationResult.filter((currentApplication: ApplicationI) => checkApplicationType(currentApplication))
}

function getDeviceIds(devices: DeviceI[]): string[] {
  return devices.filter(device => device.id !== undefined).map(device => device.id as string)
}

function getApplicationIds(applications: ApplicationI[]): string[] {
  return applications.filter(app => app.id !== undefined).map(app => app.id as string)
}

function getUserIds(users: UserDataI[]): string[] {
  return users.filter(user => user.id !== undefined).map(user => user.id)
}

const onPropsEntityChanged = async(paramEntityClass?: string, paramEntitySubClass?: LogEntitySubClass, paramEntityIds?: string[]) => {
  if (isEntityLoading.value) {
    onPropsEntityChangedPending = true
    return
  }

  switch (paramEntityClass) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      await fetchAppliedDevicesDvtmEsoft(paramEntityIds)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      devicesDvtmEsoft.values = appliedDevicesDvtmEsoft.values?.slice(0) ?? []

      if (appliedDevicesDvtmEsoft.values.length !== 0) {
        applications.values = []
        entitySubClass.value = LogEntitySubClass.DEVICE_DVTM_ESOFT
      } else {
        await fetchAppliedApplications(paramEntityIds)
        applications.values = appliedApplications.values?.slice(0) ?? []

        if (appliedApplications.values.length !== 0) {
          entitySubClass.value = LogEntitySubClass.APPLICATION
          devicesDvtmEsoft.values = []
        } else {
          entitySubClass.value = undefined
          devicesDvtmEsoft.values = []
          applications.values = []
        }
      }
      break
    case LogEntityClass.DEVICE:
      appliedUsers.values = await fetchAppliedEntities<UserDataI>(appliedUsers, UsersService, 'getUsersById', 'id', isUsersLoading, paramEntityIds, users)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      users.values = appliedUsers.values?.slice(0) ?? []
      break
    case LogEntityClass.USER:
      appliedUsers.values = await fetchAppliedEntities<UserDataI>(appliedUsers, UsersService, 'getUsersById', 'id', isUsersLoading, paramEntityIds, users)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      users.values = appliedUsers.values?.slice(0) ?? []
      break
    case LogEntityClass.GROUP:
      appliedUserGroups.values = await fetchAppliedEntities<UserGroupI>(appliedUserGroups, UserGroupsService, 'getUserGroupsByCode', 'code', isUserGroupsLoading, paramEntityIds)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      break
    case LogEntityClass.PROFILE:
      appliedProfiles.values = await fetchAppliedEntities<UserProfileI>(appliedProfiles, UserProfilesService, 'getUserProfilesByCodes', 'code', isProfileLoading, paramEntityIds)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      break
    case LogEntityClass.ROLE:
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      break
    case LogEntityClass.SECTOR:
      appliedSectors.values = await fetchAppliedEntities<UserSessionSector>(appliedSectors, SectorsService, 'getSectorsByCodes', 'code', isSectorLoading, paramEntityIds)
      entityClass.value = paramEntityClass
      entityId.value = paramEntityIds
      break
    default:
      entityClass.value = undefined
      entityId.value = undefined
      devicesDvtmEsoft.values = []
      applications.values = []
      users.values = []
      break
  }
}

const onPropsEntityIdChanged = (entityId?: string[]) => {
  onPropsEntityChanged(props.entityClass, props.entitySubClass, entityId)
}
const onPropsEntityClassChanged = (entityClass?: string) => {
  onPropsEntityChanged(entityClass, props.entitySubClass, props.entityId)
}
const onPropsEntitySubClassChanged = (entitySubClass?: string) => {
  onPropsEntityChanged(props.entityClass, entitySubClass as LogEntitySubClass, props.entityId)
}

const onDevicesDvtmEsoftSelected = (devicesDvtmEsoft: Array<DeviceI>) => {
  if (!devicesDvtmEsoft.length) {
    entityClass.value = undefined
    entityId.value = undefined
  } else {
    entityClass.value = LogEntityClass.DEVICE_DVTM_ESOFT
    entitySubClass.value = LogEntitySubClass.DEVICE_DVTM_ESOFT
    entityId.value = getDeviceIds(devicesDvtmEsoft)
  }
}

const onEntityLoadingUpdated = (loading: boolean) => {
  if (!loading && onPropsEntityChangedPending) {
    onPropsEntityChangedPending = false
    onPropsEntityChanged(props.entityClass, props.entitySubClass, props.entityId)
  }
}

watch(() => devicesDvtmEsoft.values, onDevicesDvtmEsoftSelected, { deep: true })

watch(() => isEntityLoading.value, onEntityLoadingUpdated)

const onApplicationsSelected = (applications: Array<ApplicationI>) => {
  if (!applications.length) {
    entityClass.value = undefined
    entityId.value = undefined
  } else {
    entityClass.value = LogEntityClass.DEVICE_DVTM_ESOFT
    entitySubClass.value = LogEntitySubClass.APPLICATION
    entityId.value = getApplicationIds(applications)
  }
}

watch(() => applications.values, onApplicationsSelected, { deep: true })

const onUsersSelected = (users: Array<UserDataI>) => {
  if (!users.length) {
    entityClass.value = undefined
    entityId.value = undefined
  } else {
    entityClass.value = LogEntityClass.USER
    entitySubClass.value = undefined
    entityId.value = getUserIds(users)
  }
}

watch(() => users.values, onUsersSelected, { deep: true })
watch(() => props.entityId, onPropsEntityIdChanged)
watch(() => props.entityClass, onPropsEntityClassChanged)
watch(() => props.entitySubClass, onPropsEntitySubClassChanged)
watch(() => entityClass.value, onEntityClassChanged)
watch(() => entitySubClass.value, onEntitySubClassChanged)
onMounted(() => {
  onPropsEntityIdChanged(props.entityId)
})

const selectedFiltersByTypes = computed<Array<string | LogComponentId | LogEntityClass | Array<string | LogLevel | undefined | null> | undefined | null>>(() =>
  [
    componentId.value,
    entityId.value,
    eventTypes.value,
    levels.value,
    actions.value,
    sortBy.value,
    sortDirection.value,
  ])

const isAnyAppliedFilter = computed(() => {
  const result = props.componentId != null
  || props.entityClass != null
  || (props.entityId != null && props.eventTypes?.length)
  || (props.eventTypes != null && props.eventTypes?.length)
  || (props.levels != null && props.levels?.length)
  || (props.actions != null && props.actions?.length)
  || props.sortBy != null
  || props.sortDirection != null
  return result
})

const menuItems: SlideMenuItem[] = [
  {
    disabled: false,
    id: 'level',
    footerId: 'level-footer',
    footerEnabled: true,
    header: levelLabel,
    menuLabel: levelLabel,
  },
  {
    disabled: false,
    id: 'eventType',
    footerId: 'eventType-footer',
    footerEnabled: true,
    header: eventTypeLabel,
    menuLabel: eventTypeLabel,
  },
  {
    disabled: false,
    id: 'action',
    footerId: 'action-footer',
    footerEnabled: true,
    header: actionLabel,
    menuLabel: actionLabel,
  },
  {
    disabled: false,
    id: 'user',
    footerId: 'user-footer',
    footerEnabled: true,
    header: userLabel,
    menuLabel: userLabel,
  },
  {
    disabled: false,
    id: 'device-dvtm-esoft-device',
    footerId: 'device-dvtm-esoft-device-footer',
    footerEnabled: true,
    header: deviceDvtmEsoftLabel,
    menuLabel: deviceDvtmEsoftLabel,
  },
  {
    disabled: false,
    id: 'device-application',
    footerId: 'device-application-footer',
    footerEnabled: true,
    header: applicationLabel,
    menuLabel: applicationLabel,
  },
]

function getMenuItemById(id: string, subclassId?: string) {
  return menuItems.find((menuItem) => {
    const testId = !subclassId ? id : (`${id}-${subclassId}`)
    return menuItem.id === testId
  })
}

const displayedPanel = ref()

function onEntityClassAndSubChanged(newEntityClass?: LogEntityClass, newEntitySubClass?: LogEntitySubClass) {
  for (const currentClass of editableEntityClasses) {
    let menuItem: SlideMenuItem | undefined

    if (currentClass !== LogEntityClass.DEVICE_DVTM_ESOFT) {
      menuItem = getMenuItemById(currentClass as string)

      if (menuItem) {
        menuItem.disabled = newEntityClass != null && (currentClass !== newEntityClass)
      }
    } else {
      for (const currentSubClass of editableEntitySubClasses[currentClass as string]) {
        menuItem = getMenuItemById(currentClass as string, currentSubClass)

        if (menuItem) {
          menuItem.disabled = newEntityClass != null && (currentClass !== newEntityClass || currentSubClass !== newEntitySubClass)
        }
      }
    }
  }
}

function onEntityClassChanged(newEntityClass?: LogEntityClass) {
  onEntityClassAndSubChanged(newEntityClass, entitySubClass.value)
}

function onEntitySubClassChanged(newEntitySubClass?: LogEntitySubClass) {
  onEntityClassAndSubChanged(entityClass.value, newEntitySubClass)
}

function applyFilter() {
  emit('update:componentId', componentId.value)
  emit('update:entityClass', entityClass.value)
  emit('update:entitySubClass', entitySubClass.value)
  emit('update:entityId', entityId.value)
  emit('update:levels', levels.value)
  emit('update:eventTypes', eventTypes.value)
  emit('update:actions', actions.value)
  emit('update:sortBy', sortBy.value)
  emit('update:sortDirection', sortDirection.value)

  if (!updatePending) {
    emit('change')
  }
}

function clearFilter() {
  updatePending = true
  componentId.value = undefined
  entityClass.value = undefined
  entitySubClass.value = undefined
  entityId.value = undefined
  levels.value = undefined
  eventTypes.value = undefined
  actions.value = []
  sortBy.value = undefined
  sortDirection.value = undefined
  devicesDvtmEsoft.values = []
  applications.values = []
  users.values = []
  appliedDevicesDvtmEsoft.values = []
  updatePending = false
  applyFilter()
}

function discardFilter() {
  updatePending = true
  componentId.value = props.componentId
  entityClass.value = props.entityClass
  entitySubClass.value = props.entitySubClass
  entityId.value = props.entityId
  levels.value = props.levels
  eventTypes.value = props.eventTypes
  actions.value = props.actions
  sortBy.value = props.sortBy
  sortDirection.value = props.sortDirection

  switch (entityClass.value) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      devicesDvtmEsoft.values = appliedDevicesDvtmEsoft.values?.slice(0) ?? []
      applications.values = appliedApplications.values?.slice(0) ?? []
      break
    case LogEntityClass.USER:
      users.values = appliedUsers.values?.slice(0) ?? []
      break
    default:
      break
  }

  updatePending = false
}

function onFilterShowing() {
  onEntityClassAndSubChanged(entityClass.value, entitySubClass.value)
}

function getLevelTagText(level: string) {
  const translationKey = `logs.level.value.${level}`
  return filtersUtils.getTagText(levelLabel, t(translationKey))
}
function getEventTypeTagText(eventType: string) {
  const translationKey = `logs.eventType.value.${eventType}`
  return filtersUtils.getTagText(eventTypeLabel, t(translationKey))
}

function getProfileTagText(profile: UserProfileI) {
  return filtersUtils.getTagText(profileLabel, profile.label ?? profile.code)
}

function getRoleTagText(role: RoleI) {
  return filtersUtils.getTagText(roleLabel, role.translation?.label ?? role.code)
}

function getSectorTagText(sector: UserSessionSector) {
  if (sector.label != null || sector.code != null) {
    return filtersUtils.getTagText(sectorLabel, sector.label ?? sector.code)
  }
}

function getUserGroupTagText(userGroup: UserGroupI) {
  return filtersUtils.getTagText(userGroupLabel, userGroup.label ?? userGroup.code)
}

function initEntityFilterMap(cFilterMap: Record<string, LogFilterCriterion>,
  entityClass: LogEntityClass,
  entitySubclass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: DeviceI[],
  paramApplications?: ApplicationI[],
  paramUsers?: UserDataI[],
  paramUserGroups?: UserGroupI[],
  paramProfiles?: UserProfileI[],
  paramRoles?: RoleI[],
  paramSectors?: UserSessionSector[],
) {
  switch (entityClass) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      if (entitySubclass === LogEntitySubClass.DEVICE_DVTM_ESOFT) {
        if (paramDevicesDvtmEsoft) {
          for (const deviceDvtmEsoft of paramDevicesDvtmEsoft) {
            const tag = filtersUtils.getDeviceDvtmEsoftTagText(deviceDvtmEsoft, deviceDvtmEsoftLabel)
            if (tag) {
              cFilterMap[tag] = {
                type: 'entityId',
                value: deviceDvtmEsoft.id ?? '',
              }
            }
          }
        }
      } if (entitySubclass === LogEntitySubClass.APPLICATION) {
        if (paramApplications) {
          for (const application of paramApplications) {
            const tag = filtersUtils.getApplicationTagText(application, applicationLabel)
            if (tag) {
              cFilterMap[tag] = {
                type: 'entityId',
                value: application.id ?? '',
              }
            }
          }
        }
      }
      break
    case LogEntityClass.GROUP:
      if (paramUserGroups) {
        for (const userGroup of paramUserGroups) {
          const tag = getUserGroupTagText(userGroup)
          if (tag) {
            cFilterMap[tag] = {
              type: 'entityId',
              value: userGroup.code ?? '',
            }
          }
        }
      }
      break
    case LogEntityClass.PROFILE:
      if (paramProfiles) {
        for (const profile of paramProfiles) {
          const tag = getProfileTagText(profile)
          if (tag) {
            cFilterMap[tag] = {
              type: 'entityId',
              value: profile.code ?? '',
            }
          }
        }
      }
      break
    case LogEntityClass.ROLE:
      if (paramRoles) {
        for (const role of paramRoles) {
          const tag = getRoleTagText(role)
          if (tag) {
            cFilterMap[tag] = {
              type: 'entityId',
              value: role.id ? String(role.id) : '',
            } }
        }
      }
      break
    case LogEntityClass.SECTOR:
      if (paramSectors) {
        for (const sector of paramSectors) {
          const tag = getSectorTagText(sector)
          if (tag) {
            cFilterMap[tag] = {
              type: 'entityId',
              value: sector.code ?? '',
            }
          }
        }
      }
      break
    case LogEntityClass.USER:
      if (paramUsers) {
        for (const user of paramUsers) {
          const tag = filtersUtils.getUserTagText(user, userLabel)
          if (tag) {
            cFilterMap[tag] = {
              type: 'entityId',
              value: user.id ?? '',
            }
          }
        }
      }
      break
    default:
      break
  }
}

function initFilterMap(
  levels?: string[],
  eventTypes?: string[],
  actions?: string[],
  entityClass?: LogEntityClass,
  entitySubClass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: DeviceI[],
  paramApplications?: ApplicationI[],
  paramUsers?: UserDataI[],
  paramGroups?: UserGroupI[],
  paramProfiles?: UserProfileI[],
  paramRoles?: RoleI[],
  paramSectors?: UserSessionSector[],
): Record<string, LogFilterCriterion> {
  const cFilterMap: Record<string, LogFilterCriterion> = {}

  if (levels && levels.length !== 0) {
    for (const level of levels) {
      const tag = getLevelTagText(level)
      if (tag) {
        cFilterMap[tag] = {
          type: 'level',
          value: level,
        }
      }
    }
  }

  if (eventTypes && eventTypes?.length !== 0) {
    for (const eventType of eventTypes) {
      const tag = getEventTypeTagText(eventType)
      if (tag) {
        cFilterMap[tag] = {
          type: 'eventType',
          value: eventType,
        }
      }
    }
  }

  if (actions && actions.length !== 0) {
    for (const action of actions) {
      const tag = filtersUtils.getTagText(actionLabel, action)
      if (tag) {
        cFilterMap[tag] = {
          type: 'action',
          value: action,
        }
      }
    }
  }

  if (entityClass) {
    initEntityFilterMap(cFilterMap, entityClass, entitySubClass, paramDevicesDvtmEsoft, paramApplications, paramUsers, paramGroups, paramProfiles, paramRoles, paramSectors)
  }
  return cFilterMap
}

function deleteDeviceDvtmEsoftFilter(id: string) {
  const index = devicesDvtmEsoft.values.findIndex(device => device.id === id)
  devicesDvtmEsoft.values.splice(index, 1)

  if (devicesDvtmEsoft.values.length === 0) {
    entityClass.value = undefined
  }
}

function deleteDeviceFilter(id: string) {
  const index = devicesDvtmEsoft.values.findIndex(device => device.id === id)
  devicesDvtmEsoft.values.splice(index, 1)

  if (devicesDvtmEsoft.values.length === 0) {
    entityClass.value = undefined
  }
}

function deleteApplicationFilter(id: string) {
  const index = applications.values.findIndex(application => application.id === id)
  applications.values.splice(index, 1)

  if (applications.values.length === 0) {
    entityClass.value = undefined
  }
}

function deleteUserFilter(id: string) {
  const index = users.values.findIndex(user => user.id === id)
  users.values.splice(index, 1)

  if (users.values.length === 0) {
    entityClass.value = undefined
  }
}

function deleteEntityTag(id: string) {
  switch (entityClass.value) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both DVTM E-Soft devices and applications have the same entity class "device-dvtm-esoft"
      if (entitySubClass.value === LogEntitySubClass.DEVICE_DVTM_ESOFT) {
        deleteDeviceDvtmEsoftFilter(id)
      } else if (entitySubClass.value === LogEntitySubClass.APPLICATION) {
        deleteApplicationFilter(id)
      }
      break
    case LogEntityClass.DEVICE:
      deleteDeviceFilter(id)
      break
    case LogEntityClass.USER:
      deleteUserFilter(id)
      break
    default:
      break
  }
}

function deleleTagFromList(valueToDelete: string, list: Ref<string[] | undefined>) {
  if (list.value) {
    const index = list.value.findIndex(value => value === valueToDelete)

    if (index != null && index >= 0) {
      list.value.splice(index, 1)
    }

    if (list.value.length === 0) {
      list.value = undefined
    }
  }
}

function initEntityTagFilter(
  entityClass?: LogEntityClass,
  entitySubClass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: DeviceI[],
  paramApplications?: ApplicationI[],
  paramUsers?: UserDataI[],
  paramGroups?: UserGroupI[],
  paramProfiles?: UserProfileI[],
  paramRoles?: RoleI[],
  paramSectors?: UserSessionSector[],
) {
  let tagFilters: string[] = []
  switch (entityClass) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      if (entitySubClass === LogEntitySubClass.DEVICE_DVTM_ESOFT) {
        if (paramDevicesDvtmEsoft != null && Array.isArray(paramDevicesDvtmEsoft)) {
          tagFilters = filterEmptyValues<string>(paramDevicesDvtmEsoft.map(deviceDvtmEsoft => filtersUtils.getDeviceDvtmEsoftTagText(deviceDvtmEsoft, deviceDvtmEsoftLabel)))
        }
      } else if (entitySubClass === LogEntitySubClass.APPLICATION) {
        if (paramApplications != null && Array.isArray(paramApplications)) {
          tagFilters = filterEmptyValues<string>(paramApplications.map(application => filtersUtils.getApplicationTagText(application, applicationLabel)))
        }
      }
      break
    case LogEntityClass.GROUP:
      if (paramGroups != null) {
        tagFilters = filterEmptyValues<string>(paramGroups.map(getUserGroupTagText))
      }
      break
    case LogEntityClass.PROFILE:
      if (paramProfiles != null) {
        tagFilters = filterEmptyValues<string>(paramProfiles.map(getProfileTagText))
      }
      break
    case LogEntityClass.ROLE:
      if (paramRoles != null) {
        tagFilters = filterEmptyValues<string>(paramRoles.map(getRoleTagText))
      }
      break
    case LogEntityClass.SECTOR:
      if (paramSectors != null) {
        tagFilters = filterEmptyValues<string>(paramSectors.map(getSectorTagText))
      }
      break
    case LogEntityClass.USER:
      if (paramUsers != null) {
        tagFilters = filterEmptyValues<string>(paramUsers.map(user => filtersUtils.getUserTagText(user, userLabel)))
      }
      break
    default:
  }

  return tagFilters
}

function initTagFilters(
  levels?: string[],
  eventTypes?: string[],
  actions?: string[],
  entityClass?: LogEntityClass,
  entitySubClass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: DeviceI[],
  paramApplications?: ApplicationI[],
  paramUsers?: UserDataI[],
  paramGroups?: UserGroupI[],
  paramProfiles?: UserProfileI[],
  paramRoles?: RoleI[],
  paramSectors?: UserSessionSector[],

): string[] {
  const tagFilters: string[] = []

  if (levels && levels.length !== 0) {
    for (const level of levels) {
      const filterTag = getLevelTagText(level)
      if (filterTag) {
        tagFilters.push(filterTag)
      }
    }
  }

  if (eventTypes && eventTypes.length !== 0) {
    for (const eventType of eventTypes) {
      const filterTag = getEventTypeTagText(eventType)
      if (filterTag) {
        tagFilters.push(filterTag)
      }
    }
  }

  if (actions && actions.length !== 0) {
    for (const action of actions) {
      const filterTag = filtersUtils.getTagText(actionLabel, action)
      if (filterTag) {
        tagFilters.push(filterTag)
      }
    }
  }

  if (entityClass) {
    tagFilters.push(...initEntityTagFilter(entityClass, entitySubClass, paramDevicesDvtmEsoft, paramApplications, paramUsers, paramGroups, paramProfiles, paramRoles, paramSectors))
  }

  return tagFilters
}

const selectedFilterTags = computed((): string[] => {
  return initTagFilters(levels.value, eventTypes.value, actions.value, entityClass.value, entitySubClass.value, devicesDvtmEsoft.values, applications.values, users.values)
})

const selectedFilterMap = computed((): Record<string, LogFilterCriterion> => {
  return initFilterMap(levels.value, eventTypes.value, actions.value, entityClass.value, entitySubClass.value, devicesDvtmEsoft.values, applications.values, users.values)
})

const appliedFilterTags = computed((): string[] => {
  return initTagFilters(props.levels, props.eventTypes, props.actions, props.entityClass, entitySubClass.value, appliedDevicesDvtmEsoft.values, appliedApplications.values, appliedUsers.values, appliedUserGroups.values, appliedProfiles.values, appliedRoles.values, appliedSectors.values)
})

const appliedFilterMap = computed((): Record<string, LogFilterCriterion> => {
  return initFilterMap(props.levels, props.eventTypes, props.actions, props.entityClass, entitySubClass.value, appliedDevicesDvtmEsoft.values, appliedApplications.values, appliedUsers.values, appliedUserGroups.values, appliedProfiles.values, appliedSectors.values)
})

function onDeletSelectedTag(tag: string) {
  const logFilterCriterion = selectedFilterMap.value[tag]

  if (logFilterCriterion) {
    switch (logFilterCriterion.type) {
      case 'action':
        deleleTagFromList(logFilterCriterion.value, actions)
        break
      case 'level':
        deleleTagFromList(logFilterCriterion.value, levels)
        break
      case 'eventType':
        deleleTagFromList(logFilterCriterion.value, eventTypes)
        break
      case 'entityId':
        deleteEntityTag(logFilterCriterion.value)
    }
  }
}

function onAppliedTagDeleteClick(tag: string) {
  const logFilterCriterion = appliedFilterMap.value[tag]
  let tempEntityIds: Array<string>| undefined

  if (logFilterCriterion) {
    updatePending = true

    switch (logFilterCriterion.type) {
      case 'action':
        deleleTagFromList(logFilterCriterion.value, actions)
        emit('update:actions', actions.value)
        break
      case 'level':
        deleleTagFromList(logFilterCriterion.value, levels)
        emit('update:levels', levels.value)
        break
      case 'eventType':
        deleleTagFromList(logFilterCriterion.value, eventTypes)
        emit('update:eventTypes', eventTypes.value)
        break
      case 'entityId':
        tempEntityIds = Array.isArray(entityId.value) && entityId.value.length !== 0 ? entityId.value.filter((id: string) => id !== logFilterCriterion.value) : undefined
        // the entity ids is in a local variable because if it en empty array, the "delete filters" link is displayed althrought there are not any filters
        entityId.value = Array.isArray(tempEntityIds) && tempEntityIds.length !== 0 ? tempEntityIds : undefined
        entityClass.value = entityId.value != null ? entityClass.value : undefined
        entitySubClass.value = (entityClass.value === LogEntityClass.DEVICE_DVTM_ESOFT && entityId.value != null) ? entitySubClass.value : undefined
        emit('update:entityClass', entityClass.value)
        emit('update:entitySubClass', entitySubClass.value)
        emit('update:entityId', entityId.value)
        break
    }

    updatePending = false
    emit('change')
  }
}

</script>
<template>
  <div class="relative flex justify-between px-14">
    <lxc-log-download
      :data="props.rowsSelected"
    />
    <div class="relative flex justify-end">
      <lxc-period-picker
        v-model="formPeriod"
        type="primary"
        button-size
        :formatter="$t('logs.filters.timestamp.formatter')"
        :i18n="locale"
        :separator="periodSeparator"
        :placeholder="$t('logs.filters.timestamp.placeholder')"
        class="mr-3"
      />

      <lxc-filters
        v-model:displayed-panel="displayedPanel"
        :filters-by-type="selectedFiltersByTypes"
        :menu-items="menuItems"
        :selected-filters="selectedFilterTags"
        @apply="applyFilter"
        @delete-tag="onDeletSelectedTag"
        @discard="discardFilter"
        @reset="clearFilter"
        @showing="onFilterShowing"
      >
        <template #level>
          <lxc-log-level-filter
            v-model="levels"
            :header="levelLabel"
          />
        </template>

        <template #eventType>
          <lxc-log-event-type-filter
            v-model="eventTypes"
            :header="eventTypeLabel"
          />
        </template>

        <template #action>
          <lxc-log-action-filter
            v-model="actions"
            :label="actionLabel"
          />
        </template>

        <template #device-dvtm-esoft-device>
          <lxc-log-device-filter
            v-model="devicesDvtmEsoft.values"
            :label="deviceDvtmEsoftLabel"
          />
        </template>

        <template #device-application>
          <lxc-log-application-filter
            v-model="applications.values"
            :label="applicationLabel"
          />
        </template>

        <template #user>
          <lxc-log-user-filter
            v-model="users.values"
            :label="userLabel"
          />
        </template>
      </lxc-filters>
    </div>
  </div>

  <div
    v-if="isAnyAppliedFilter"
    class="px-14 flex items-center justify-end my-4"
  >
    <lxc-tag-set
      deletable
      type="primary"
      :data="appliedFilterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      :is-loading="isEntityLoading"
      @delete="onAppliedTagDeleteClick"
    />
    <a
      class="font-medium whitespace-nowrap ml-3"
      href="#"
      @click="clearFilter"
    >
      {{ $t('filters.resetFilter') }}
    </a>
  </div>
</template>
