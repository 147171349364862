<script setup lang="ts">
import type { Certificate, CertificateFormItemI, ListOfDataI } from '@lxc/app-device-types'
import { CertificateType } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import dayjs from 'dayjs'
import { caCertTypeToString } from '~/utils/enum-tools'
import ILxPlus from '~icons/lx/plus'
import ILxUpdate from '~icons/lx/arrow-circle-down'
import type LxcError from '~/utils/LxcError'
import { useElTable } from '~/composables/useElTable'

const emit = defineEmits(['onCertificateUpdated'])
const { getCellStyle } = useElTable()

const props = defineProps<{
  certificates: ListOfDataI<Certificate> | null
  isLoading: boolean
  error: LxcError
}>()

const { t } = useI18n()
const truststore = ref<ListOfDataI<Certificate> | null>(null)
const [dialogVisible, toggleVisible] = useToggle()
const updateCertificateType = ref<CertificateFormItemI>()

const alreadyAddedCertificateType: Ref<CertificateType[]> = computed(() => (props.certificates?.data ?? []).map(cert => cert.caCertType))
const areAllCertificatesAdded = computed(() => (props.certificates?.data ?? []).length === Object.values(CertificateType).length)
const populatedCertificates = computed(() => {
  return (props.certificates?.data ?? []).map(cert => ({
    name: cert?.commonName ?? '',
    type: caCertTypeToString(cert.caCertType, t),
    services: cert.subscriptions?.map(s => s.name).reduce((s1, s2) => `${s1}, ${s2}`) ?? '',
    expirationDate: dayjs(cert.notAfterDate).format(t('truststore.column.expirationDateFormat')),
    certificateDescription: {
      type: cert.caCertType,
      caCertChain: cert.caCertChain,
      certificateId: cert.id,
    },
  }))
})

const addNewCertificate = () => {
  updateCertificateType.value = undefined
  dialogVisible.value = true
}

const updateCertificate = (certDescription: CertificateFormItemI) => {
  updateCertificateType.value = certDescription
  dialogVisible.value = true
}

const onCertificateUpdated = (certificateDescription: CertificateFormItemI) => {
  dialogVisible.value = false
  emit('onCertificateUpdated', certificateDescription)
}
</script>
<template>
  <el-container direction="vertical">
    <lx-toolbar :is-right="true">
      <el-button
        v-if="!areAllCertificatesAdded && !isLoading"
        :icon="ILxPlus"
        type="primary"
        @click="addNewCertificate"
      >
        {{ $t('truststore.addCertificate') }}
      </el-button>
    </lx-toolbar>
    <lxc-container
      :is-loading="isLoading && !certificates"
      :error="error"
    >
      <el-table
        v-loading="isLoading"
        :data="populatedCertificates"
        :border="false"
        stripe
        :empty-text="$t('truststore.noCertificate')"
        :cell-style="getCellStyle"
      >
        <el-table-column
          prop="name"
          :label="$t('truststore.column.name')"
        />
        <el-table-column
          prop="type"
          :label="$t('truststore.column.type')"
        />
        <el-table-column
          prop="services"
          :label="$t('truststore.column.subscribedServices')"
        />
        <el-table-column
          prop="expirationDate"
          :label="$t('truststore.column.expirationDate')"
        />
        <el-table-column
          fixed="right"
          align="right"
        >
          <template #default="scope">
            <lxc-dropdown>
              <el-dropdown-item>
                <el-button
                  :icon="ILxUpdate"
                  text
                  @click.stop="updateCertificate(scope.row.certificateDescription)"
                >
                  {{ $t('truststore.updateCertificate') }}
                </el-button>
              </el-dropdown-item>
            </lxc-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <lxc-certificate-selection-modal
        :already-added-certificate-type="alreadyAddedCertificateType"
        :update-certificate-type="updateCertificateType"
        :is-dialog-visible="dialogVisible"
        @update:toggle-dialog="toggleVisible"
        @validate="onCertificateUpdated"
      />
    </lxc-container>
  </el-container>
</template>
