<script setup lang="ts">
import { UnexpectedErrorTypes } from '~/types/unexpectedError'
import UsersService from '~/services/users.service'

const route = useRoute()

const errorType = route.query.errorType

// Case fail to get login URL
if (errorType === UnexpectedErrorTypes.URL_LOGIN_ERROR) {
  /**
   * Try to get login URL and redirect to URI query param if succeed
   */
  const check = async() => {
    const loginUrlResponse = await UsersService.getLoginUrl(
      (
        Array.isArray(route.query.redirectUri)
          ? route.query.redirectUri[0]
          : route.query.redirectUri
      )?.toString() || '',
    )

    // Misunderstood: Importing and using LxcError here cause the following error
    // "Uncaught (in promise) TypeError: class constructors must be invoked with 'new'"
    if (loginUrlResponse.loginUrl) {
      window.location.href = loginUrlResponse.loginUrl
    }
  }

  check()

  // Retry periodically
  setInterval(check, 3000)
}
</script>

<template>
  <section>
    <lxc-error :error="{ code: 'UNEXPECTED' }" />
  </section>
</template>
