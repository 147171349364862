<script lang="ts" setup>
import type { ApplicationMgtI } from '@lxc/app-device-types'
import { FirmwareStatus } from '@lxc/app-device-types'
import { useAcl } from 'vue-simple-acl'
import { remove } from 'lodash'
import firmwareService from '~/services/firmware.service'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import LxcError from '~/utils/LxcError'
import { ACL_ROLES } from '~/types'
import applicationMgtService from '~/services/applicationMgt.service'

const props = defineProps<{
  applicationMgt: ApplicationMgtI
}>()
const emit = defineEmits(['change'])

const { t } = useI18n()
const acl = useAcl()

const [removeConfirmDialogVisible, toggleRemoveConfirmVisible] = useToggle()

enum Action {
  REMOVE = 'REMOVE',
}

function onSelectAction(action: Action) {
  if (action === Action.REMOVE) {
    toggleRemoveConfirmVisible(true)
  }
}

/**
 * Call remove application management, display notification and close confirm dialog
 */
async function removeApplicationMgt() {
  const response = await applicationMgtService.deleteApplicationMgt(props.applicationMgt)

  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError)
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess))
    emit('change')
  }

  toggleRemoveConfirmVisible()
}

const canRemoveAppliationMgt = computed(() => acl.can(ACL_ROLES.MANAGE_APPLICATION_MGTS))
</script>

<template>
  <lxc-dropdown
    v-if="canRemoveAppliationMgt"
    @command="onSelectAction"
  >
    <el-dropdown-item
      v-if="canRemoveAppliationMgt"
      :command="Action.REMOVE"
      data-cy="activate-button"
    >
      {{ $t('applicationMgt.actions.remove.label') }}
    </el-dropdown-item>
  </lxc-dropdown>

  <lxc-confirm-modal
    :is-dialog-visible="removeConfirmDialogVisible"
    :title="$t('applicationMgt.actions.remove.label')"
    :description="$t('applicationMgt.actions.remove.description', { name: applicationMgt.name })"
    @update:toggle-dialog="toggleRemoveConfirmVisible"
    @confirm="removeApplicationMgt"
  />
</template>
