<script setup lang="ts">
import LxcError from '~/utils/LxcError'
import DtwinsService from '~/services/dtwins.service'

const route = useRoute()

const dtwin: Ref<any> = ref(null)
const errorDtwin: Ref<LxcError | null> = ref(null)
const isLoadingDtwin = ref(true)

async function fetchDevice() {
  const response = await DtwinsService.getDtwin(route.params.id.toString())

  if (LxcError.check(response)) {
    dtwin.value = null
    errorDtwin.value = response
  } else {
    dtwin.value = response
  }

  isLoadingDtwin.value = false
}

const isLoadingModels: Ref<boolean> = ref(false)
const errorModels: Ref<LxcError | undefined> = ref()
const models: Ref<any> = ref()

const fetchModels = async() => {
  isLoadingModels.value = true

  const response = await DtwinsService.getModels(1, 999999999)
  if (LxcError.check(response)) {
    errorModels.value = response
  } else {
    models.value = response.result
    fetchDevice()
  }

  isLoadingModels.value = false
}

fetchModels()
</script>

<template>
  <div
    class="flex justify-between"
  >
    <div class="flex">
      <lxc-breadcrumb :name="dtwin?.attributes.friendlyName" />
    </div>
  </div>

  <lxc-container
    :error="errorModels || errorDtwin"
    :is-loading="isLoadingModels || isLoadingDtwin"
  >
    <lxc-tabs>
      <lxc-tab-pane
        :label="$t('dtwins.form.description.title')"
        name="description"
      >
        <lxc-dtwins-description
          :dtwin="dtwin"
          :models="models"
        />
      </lxc-tab-pane>
      <lxc-tab-pane
        :label="$t('dtwins.form.configuration.title')"
        name="configuration"
      >
        <lxc-dtwins-configuration
          :dtwin="dtwin"
        />
      </lxc-tab-pane>
      <lxc-tab-pane
        :label="$t('dtwins.form.dataviz.title')"
        name="dataviz"
      >
        <lxc-dtwins-dataviz
          :dtwin="dtwin"
        />
      </lxc-tab-pane>
    </lxc-tabs>
  </lxc-container>
</template>
