<script setup lang="ts">
import type { ApplicationMgtI, Certificate, FirmwareI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { useAcl } from 'vue-simple-acl'
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'
import LxcError from '~/utils/LxcError'
import { typeOptions } from '~/components/devices/deviceList/deviceFilters.config'
import applicationMgtService from '~/services/applicationMgt.service'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import FirmwareService from '~/services/firmware.service'
import { useUserSession } from '~/stores/useUserSession'
import { ACL_ROLES, Filters, FiltersType } from '~/types'
import { useSearch } from '~/composables/useSearch'
import truststoreService from '~/services/truststore.service'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { userSession } = useUserSession()
const acl = useAcl()

const defaultDeviceType = {
  type: '',
  name: '',
  description: '',
  properties: {
    estUrl: '',
    estCert: '',
    IdEstRootCa: '',
    lwm2mUrl: '',
    lwm2mCert: '',
    IdLwm2mRootCa: '',
    // IdFactoryRootCA: '',
    featureList: [],
    firmwareList: [],
  },
}

const applicationMgtFormRef = ref()
let applicationMgtFormOriginalJSON = ''
const applicationMgtForm = reactive<ApplicationMgtI>({
  id: route?.params?.id as string || undefined,
  name: '',
  description: '',
  properties: {
    lxConnectUrl: '',
    lxConnectCert: '',
    IdLxConnectRootCA: undefined,
    oidcCallbackUrl: '',
    devicesTypeList: [JSON.parse(JSON.stringify(defaultDeviceType))], // Set one device type by default because it is required
  },
  organizationId: userSession?.organisation.id,
  organizationName: userSession?.organisation.name,
  organizationTimeZone: userSession?.organisation.timezone,
})

const error: Ref<LxcError|null> = ref(null)
const isLoading = ref(false)

const canEdit = computed(() => acl.can(ACL_ROLES.MANAGE_APPLICATION_MGTS))

/**
 * Validator rules
 */
const rules = computed(() => {
  if (canEdit.value) {
    let rules = {
      'name': [
        { required: true, message: t('applicationMgt.validation.name'), whitespace: true, trigger: 'blur' },
        { max: 50, message: t('input.error.maxLength', { maxLength: 50 }), whitespace: true, trigger: 'blur' },
      ],
      'properties.lxConnectUrl': [
        { required: true, message: t('applicationMgt.validation.lxConnectUrl'), whitespace: true, trigger: 'blur' },
        { type: 'url', message: t('applicationMgt.validation.urlFormat'), trigger: 'blur' },
      ],
      'properties.lxConnectCert': [
        { required: true, message: t('applicationMgt.validation.lxConnectCert'), whitespace: true, trigger: 'blur' },
      ],
      'properties.IdLxConnectRootCA': [
        { required: true, message: t('applicationMgt.validation.IdLxConnectRootCA'), trigger: 'change' },
      ],
      'properties.oidcCallbackUrl': [
        { required: true, message: t('applicationMgt.validation.oidcCallbackUrl'), whitespace: true, trigger: 'blur' },
      ],
    }

    const devicesTypeRules: Record<string, Array<any>> = {}
    for (const i in applicationMgtForm.properties.devicesTypeList) {
      devicesTypeRules[`properties.devicesTypeList[${i}].type`] = [
        { required: true, message: t('applicationMgt.validation.deviceType'), trigger: 'change' },
      ]
      devicesTypeRules[`properties.devicesTypeList[${i}].name`] = [
        { required: true, message: t('applicationMgt.validation.deviceTypeName'), whitespace: true, trigger: 'blur' },
        { max: 50, message: t('input.error.maxLength', { maxLength: 50 }), whitespace: true, trigger: 'blur' },
      ]
      devicesTypeRules[`properties.devicesTypeList[${i}].properties.estUrl`] = [
        { required: true, message: t('applicationMgt.validation.estUrl'), whitespace: true, trigger: 'blur' },
        { type: 'url', message: t('applicationMgt.validation.urlFormat'), trigger: 'blur' },
      ]
      devicesTypeRules[`properties.devicesTypeList[${i}].properties.estCert`] = [
        { required: true, message: t('applicationMgt.validation.estCert'), whitespace: true, trigger: 'blur' },
      ]
      devicesTypeRules[`properties.devicesTypeList[${i}].properties.IdEstRootCa`] = [
        { required: true, message: t('applicationMgt.validation.IdEstRootCa'), trigger: 'change' },
      ]
      devicesTypeRules[`properties.devicesTypeList[${i}].properties.lwm2mUrl`] = [
        { required: true, message: t('applicationMgt.validation.lwm2mUrl'), whitespace: true, trigger: 'blur' },
        { type: 'url', message: t('applicationMgt.validation.urlFormat'), trigger: 'blur' },
      ]
      devicesTypeRules[`properties.devicesTypeList[${i}].properties.lwm2mCert`] = [
        { required: true, message: t('applicationMgt.validation.lwm2mCert'), whitespace: true, trigger: 'blur' },
      ]
      devicesTypeRules[`properties.devicesTypeList[${i}].properties.IdLwm2mRootCa`] = [
        { required: true, message: t('applicationMgt.validation.IdLwm2mRootCa'), trigger: 'change' },
      ]
    }
    rules = Object.assign({}, rules, devicesTypeRules)

    return rules
  } else {
    return []
  }
})

/**
 * Tab device type
 */
const currentTabIndex = ref(0)

const handleTabsEdit = (tabIndex: number, action: 'remove' | 'add') => {
  if (action === 'add') {
    applicationMgtForm.properties.devicesTypeList?.push(JSON.parse(JSON.stringify(defaultDeviceType)))

    // Set current tab index to the added one
    currentTabIndex.value = applicationMgtForm.properties.devicesTypeList && applicationMgtForm.properties.devicesTypeList?.length > 0
      ? applicationMgtForm.properties.devicesTypeList?.length - 1
      : 0
  } else if (action === 'remove') {
    applicationMgtForm.properties.devicesTypeList = applicationMgtForm.properties.devicesTypeList?.filter((_: any, i: any) => i !== tabIndex)

    // Set current tab index to previous one if the removed tab is the last one
    if (applicationMgtForm.properties.devicesTypeList
      && applicationMgtForm.properties.devicesTypeList?.length > 0
      && currentTabIndex.value > applicationMgtForm.properties.devicesTypeList?.length - 1
    ) {
      currentTabIndex.value = applicationMgtForm.properties.devicesTypeList?.length - 1
    }
  }
}

/**
 *
 */
const certificates: Ref<Certificate[]> = ref([])
const isLoadingCertificates = ref(false)
const certificateListError: Ref<LxcError|null> = ref(null)
async function fetchCertificates() {
  certificateListError.value = null

  isLoadingCertificates.value = true

  const response = await truststoreService.getTruststoreConfigration()

  if (LxcError.check(response)) {
    certificateListError.value = response
  } else {
    certificates.value = response.data
  }

  isLoadingCertificates.value = false
}

/**
 * Fetch firmwares
 */
const firmwares: Ref<Array<FirmwareI[]|undefined>> = ref([])
const { searchParams, setFilter } = useSearch(FiltersType.PIPE_SEPARATOR)
const isLoadingFirmwares = ref(false)
const firmwareListError: Ref<LxcError|null> = ref(null)

async function fetchFirmwares(tabIndex: number) {
  firmwareListError.value = null
  firmwares.value[tabIndex] = []

  if (applicationMgtForm.properties.devicesTypeList && applicationMgtForm.properties.devicesTypeList[tabIndex].type) {
    isLoadingFirmwares.value = true

    setFilter(Filters.RANGE, applicationMgtForm.properties.devicesTypeList[tabIndex].type) // Filter firmwares on device type
    const response = await FirmwareService.getFirmwares(1, 1000000, searchParams.value) // TODO: set page & pageSize to undefined when it will be accepted

    if (LxcError.check(response)) {
      firmwareListError.value = response
    } else {
      firmwares.value[tabIndex] = response.data
    }

    isLoadingFirmwares.value = false
  }
}

/**
 * Value is true if the form has changes, false otherwise
 */
const applicationMgtFormChanged = computed(() => applicationMgtFormOriginalJSON !== JSON.stringify(applicationMgtForm))

const qrCode = ref<string>()

/**
 * Value is true if the QR Code can be generated (the form has no changes), false otherwise (the form has at least one change)
 */
const canGenerateQrCode = computed(() => {
  return !!applicationMgtForm.id && !applicationMgtFormChanged.value
})

/**
 * Get the QR code
 */
async function generateQrCode() {
  if (applicationMgtForm.id) {
    const response = await applicationMgtService.generateApplicationMgtQrCodeById(applicationMgtForm.id)

    if (LxcError.check(response)) {
      response.notify(NotificationKey.error)
    } else {
      qrCode.value = URL.createObjectURL(new Blob([response], { type: 'image/png' }))
    }
  }
}

/**
 * Cancel
 */
const cancelApplicationMgtCreation = () => router.push(BreadcrumbConfig.APPLICATION_MGT.href)

const validationErrors = ref()

/**
 * Submit form
 */
async function onSubmit() {
  // Do not reset the QR code if the form has no changes
  if (applicationMgtFormChanged.value) {
    qrCode.value = undefined
  }

  const isFormValid = await applicationMgtFormRef.value.validate()
    .then(() => {
      validationErrors.value = null
      return true
    })
    .catch((errors: any) => {
      if (errors) {
        validationErrors.value = errors
      }
      return false
    })

  if (isFormValid) {
    // Update application management
    if (applicationMgtForm.id) {
      const response = await applicationMgtService.updateApplicationMgt(applicationMgtForm)

      if (LxcError.check(response)) {
        response.notify(NotificationKey.saveError)
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess))
        Object.assign(applicationMgtForm, response)
        applicationMgtFormOriginalJSON = JSON.stringify(applicationMgtForm)
      }
    }
    // Create application management
    else {
      const response = await applicationMgtService.createApplicationMgt(applicationMgtForm)

      if (LxcError.check(response)) {
        response.notify(NotificationKey.saveError)
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess))
        Object.assign(applicationMgtForm, response)
        applicationMgtFormOriginalJSON = JSON.stringify(applicationMgtForm)
      }
    }
  }
}

/**
 * Return true if the tab has at least one validation error, false otherwise
 * @param tabIndex
 */
function tabHasValidationError(tabIndex: number): boolean {
  if (validationErrors.value) {
    for (const ruleName in validationErrors.value) {
      if (ruleName.startsWith(`properties.devicesTypeList[${tabIndex}]`)) {
        return true
      }
    }
  }

  return false
}

/**
 * Init on mounted
 */
onMounted(async() => {
  // Load certificates
  fetchCertificates()

  // Load the application management if path parameter id is provided
  if (applicationMgtForm.id) {
    isLoading.value = true

    const response = await applicationMgtService.getApplicationMgtById(applicationMgtForm.id)

    if (LxcError.check(response)) {
      error.value = response
    } else {
      Object.assign(applicationMgtForm, response)
      applicationMgtFormOriginalJSON = JSON.stringify(applicationMgtForm)

      for (const i in applicationMgtForm.properties.devicesTypeList) {
        fetchFirmwares(Number(i))
      }
    }

    isLoading.value = false
  }
})

</script>

<template>
  <el-container
    data-cy="page-application-management-component"
    direction="vertical"
    class="container"
  >
    <lxc-breadcrumb :name="[BreadcrumbConfig.APPLICATION_MGT, applicationMgtForm.id ? applicationMgtForm.name : 'applicationMgt.newApplicationMgt']" />

    <lxc-container
      :is-loading="isLoading"
      :error="error || undefined"
    >
      <lxc-mandatory v-if="canEdit" />

      <div class="container">
        <div class="form">
          <el-form
            ref="applicationMgtFormRef"
            :model="applicationMgtForm"
            :rules="rules"
            label-width="200px"
            @submit.prevent
          >
            <h2 class="section-title">
              {{ $t('applicationMgt.section.informations.title') }}
            </h2>

            <el-form-item
              :label="$t('applicationMgt.form.organization')"
              prop="organization"
            >
              {{ applicationMgtForm.organizationName }}
              <span v-if="applicationMgtForm.organizationTimeZone">&nbsp;({{ applicationMgtForm.organizationTimeZone }})</span>
            </el-form-item>

            <el-form-item
              :label="$t('applicationMgt.form.name')"
              prop="name"
            >
              <el-input
                v-model="applicationMgtForm.name"
                type="text"
                size="large"
                :disabled="!canEdit"
              />
            </el-form-item>

            <el-form-item
              :label="$t('applicationMgt.form.description')"
              prop="description"
            >
              <el-input
                v-model="applicationMgtForm.description"
                type="textarea"
                size="large"
                :disabled="!canEdit"
              />
            </el-form-item>

            <h2 class="section-title">
              {{ $t('applicationMgt.section.properties.title') }}
            </h2>

            <el-form-item
              :label="$t('applicationMgt.form.lxConnectUrl')"
              prop="properties.lxConnectUrl"
            >
              <el-input
                v-model="applicationMgtForm.properties.lxConnectUrl"
                type="text"
                size="large"
                :disabled="!canEdit"
              />
            </el-form-item>

            <el-form-item
              :label="$t('applicationMgt.form.lxConnectCert')"
              prop="properties.lxConnectCert"
            >
              <!--
              <el-select
                v-model="applicationMgtForm.properties.IdLxConnectRootCA"
                filterable
                size="large"
                :loading="isLoadingCertificates"
                :no-data-text="certificateListError ? $t('applicationMgt.form.IdLxConnectRootCAError') : ''"
                :disabled="!canEdit"
              >
                <el-option
                  v-for="certificate in certificates"
                  :key="certificate.id"
                  :label="certificate.commonName"
                  :value="certificate.id"
                />
              </el-select>
              -->
              <el-input
                v-model="applicationMgtForm.properties.lxConnectCert"
                type="textarea"
                size="large"
                :disabled="!canEdit"
              />
            </el-form-item>

            <el-form-item
              :label="$t('applicationMgt.form.oidcCallbackUrl')"
              prop="properties.oidcCallbackUrl"
            >
              <el-input
                v-model="applicationMgtForm.properties.oidcCallbackUrl"
                type="text"
                size="large"
                :disabled="!canEdit"
              />
            </el-form-item>

            <h2 class="section-title">
              {{ $t('applicationMgt.section.deviceTypeList.title') }}
            </h2>

            <!-- closable is allowed only if there is more than one device type because it is required -->
            <el-tabs
              v-model="currentTabIndex"
              type="card"
              addable
              :closable="applicationMgtForm.properties.devicesTypeList && applicationMgtForm.properties.devicesTypeList.length > 1"
              @edit="handleTabsEdit"
            >
              <el-tab-pane
                v-for="deviceType, i in applicationMgtForm.properties.devicesTypeList"
                :key="i"
                :name="i"
              >
                <template #label>
                  <span class="requiredStar">*</span>&nbsp;
                  <span :class="{ requiredStar: tabHasValidationError(i)}">
                    {{ deviceType.type || deviceType.name
                      ? (deviceType.type || '') + (deviceType.type && deviceType.name ? ' - ' : '') + (deviceType.name || '')
                      : '...' }}
                  </span>
                </template>

                <el-form-item
                  :label="$t('applicationMgt.form.deviceType.type')"
                  :prop="`properties.devicesTypeList[${i}].type`"
                >
                  <el-select
                    v-model="deviceType.type"
                    size="large"
                    :disabled="!canEdit"
                    @change="fetchFirmwares(i)"
                  >
                    <el-option
                      v-for="item in typeOptions.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item
                  :label="$t('applicationMgt.form.deviceType.name')"
                  :prop="`properties.devicesTypeList[${i}].name`"
                >
                  <el-input
                    v-model="deviceType.name"
                    type="text"
                    size="large"
                    :disabled="!canEdit"
                  />
                </el-form-item>

                <el-form-item
                  :label="$t('applicationMgt.form.deviceType.properties.estUrl')"
                  :prop="`properties.devicesTypeList[${i}].properties.estUrl`"
                >
                  <el-input
                    v-model="deviceType.properties.estUrl"
                    type="text"
                    size="large"
                    :disabled="!canEdit"
                  />
                </el-form-item>

                <el-form-item
                  :label="$t('applicationMgt.form.deviceType.properties.estCert')"
                  :prop="`properties.devicesTypeList[${i}].properties.estCert`"
                >
                  <!--
                  <el-select
                    v-model="deviceType.properties.IdEstRootCa"
                    filterable
                    size="large"
                    :loading="isLoadingCertificates"
                    :no-data-text="certificateListError ? $t('applicationMgt.form.deviceType.properties.IdEstRootCaError') : ''"
                    :disabled="!canEdit"
                  >
                    <el-option
                      v-for="certificate in certificates"
                      :key="certificate.id"
                      :label="certificate.commonName"
                      :value="certificate.id"
                    />
                  </el-select>
                  -->
                  <el-input
                    v-model="deviceType.properties.estCert"
                    type="textarea"
                    size="large"
                    :disabled="!canEdit"
                  />
                </el-form-item>

                <el-form-item
                  v-if="deviceType.type === 'LPP4'"
                  :label="$t('applicationMgt.form.deviceType.properties.lwm2mUrl')"
                  :prop="`properties.devicesTypeList[${i}].properties.lwm2mUrl`"
                >
                  <el-input
                    v-model="deviceType.properties.lwm2mUrl"
                    type="text"
                    size="large"
                    :disabled="!canEdit"
                  />
                </el-form-item>

                <el-form-item
                  v-if="deviceType.type === 'LPP4'"
                  :label="$t('applicationMgt.form.deviceType.properties.lwm2mCert')"
                  :prop="`properties.devicesTypeList[${i}].properties.lwm2mCert`"
                >
                  <!--
                  <el-select
                    v-model="deviceType.properties.IdLwm2mRootCa"
                    filterable
                    size="large"
                    :loading="isLoadingCertificates"
                    :no-data-text="certificateListError ? $t('applicationMgt.form.deviceType.properties.IdLwm2mRootCa') : ''"
                    :disabled="!canEdit"
                  >
                    <el-option
                      v-for="certificate in certificates"
                      :key="certificate.id"
                      :label="certificate.commonName"
                      :value="certificate.id"
                    />
                  </el-select>
                  -->
                  <el-input
                    v-model="deviceType.properties.lwm2mCert"
                    type="textarea"
                    size="large"
                    :disabled="!canEdit"
                  />
                </el-form-item>

                <el-form-item
                  v-if="deviceType.type"
                  :label="$t('applicationMgt.form.deviceType.properties.firmwareList')"
                  :prop="`properties.devicesTypeList[${i}].properties.firmwareList`"
                >
                  <el-select
                    v-model="deviceType.properties.firmwareList"
                    filterable
                    multiple
                    size="large"
                    :loading="isLoadingFirmwares"
                    :no-data-text="firmwareListError ? $t('applicationMgt.form.deviceType.properties.firmwareListError') : ''"
                    :disabled="!canEdit"
                  >
                    <el-option
                      v-for="firmware in firmwares[i]"
                      :key="firmware.uuid"
                      :label="firmware.name"
                      :value="firmware.uuid"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item
                  :label="$t('applicationMgt.form.deviceType.properties.featureList')"
                  :prop="`properties.devicesTypeList[${i}].properties.featureList`"
                >
                  <el-select
                    v-model="deviceType.properties.featureList"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    :reserve-keyword="false"
                    size="large"
                    :disabled="!canEdit"
                  />
                </el-form-item>

                <!--
                <el-form-item
                  :label="$t('applicationMgt.form.deviceType.properties.IdFactoryRootCA')"
                  :prop="`properties.devicesTypeList[${i}].properties.IdFactoryRootCA`"
                >
                  <el-select
                    v-model="deviceType.properties.IdFactoryRootCA"
                    filterable
                    size="large"
                    :loading="isLoadingCertificates"
                    :no-data-text="certificateListError ? $t('applicationMgt.form.deviceType.properties.IdFactoryRootCAError') : ''"
                    :disabled="!canEdit"
                  >
                    <el-option
                      v-for="certificate in certificates"
                      :key="certificate.id"
                      :label="certificate.commonName"
                      :value="certificate.id"
                    />
                  </el-select>
                </el-form-item>
                -->

                <el-form-item
                  :label="$t('applicationMgt.form.deviceType.description')"
                  :prop="`properties.devicesTypeList[${i}].description`"
                >
                  <el-input
                    v-model="deviceType.description"
                    type="textarea"
                    size="large"
                    :disabled="!canEdit"
                  />
                </el-form-item>
              </el-tab-pane>
            </el-tabs>

            <lxc-cancel-or-submit-buttons
              v-if="canEdit"
              @cancel="cancelApplicationMgtCreation"
              @submit="onSubmit"
            />
          </el-form>
        </div>

        <div class="right-container">
          <div
            v-if="applicationMgtForm.id"
            class="qr-code-container"
          >
            <el-button
              class="bt-generate"
              type="primary"
              :disabled="!canGenerateQrCode"
              @click="generateQrCode"
            >
              {{ canGenerateQrCode && qrCode
                ? $t('applicationMgt.form.actions.regenerateQrCode')
                : $t('applicationMgt.form.actions.generateQrCode')
              }}
            </el-button>
            &nbsp;
            <lxc-information-row
              v-if="!canGenerateQrCode"
              :title="$t('applicationMgt.form.actions.infoGenerateQrCode')"
            />
            <div
              v-if="canGenerateQrCode && qrCode"
              class="qr-code"
            >
              <img
                :src="qrCode"
                alt="qr-code"
              >
            </div>
          </div>
        </div>
      </div>
    </lxc-container>
  </el-container>
</template>

<style scope lang="scss">
.container {
  display: flex;

  .form {
    width: 50%;
  }

  .right-container {

    .qr-code-container {
      margin-left: 20px;
      position: sticky;
      top: 20px;

      .bt-generate {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
