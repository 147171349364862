<script setup lang="ts">
import type { ApplicationI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { filterEmptyValues } from '@lxc/app-device-common'
import filtersUtils from '~/utils/filters.utils'
const props = defineProps<{
  modelValue: ApplicationI[]
  label: string
}>()
const emit = defineEmits(['update:modelValue'])
const applicationName = ref('')
const applicationTypes: Ref<string[]> = ref([])
const applicationDeclinations: Ref<string[]> = ref([])

function initFilterMap(paramApplications?: ApplicationI[]): Record<string, string | undefined> {
  const vFilterMap: Record<string, string | undefined> = {}

  if (paramApplications && paramApplications.length !== 0) {
    for (const application of paramApplications) {
      const tag = filtersUtils.getApplicationTagText(application, props.label)
      if (tag) {
        vFilterMap[tag] = application.id
      }
    }
  }

  return vFilterMap
}

function deleleTagFromList(id: string, list?: ApplicationI[]) {
  if (list) {
    const index = list.findIndex(application => application.id === id)

    if (index != null && index >= 0) {
      list.splice(index, 1)
    }
  }
}

const selectedApplications = computed({
  get(): ApplicationI[] {
    return props.modelValue ?? []
  },
  set(selected?: Array<ApplicationI>) {
    emit('update:modelValue', selected)
  },
})

const isAnyAppliedFilter = computed(() => {
  return props.modelValue.length !== 0
})

const filterTags = computed((): string[] => {
  return filterEmptyValues<string>(props.modelValue.map(application => filtersUtils.getApplicationTagText(application, props.label)))
})

const filterMap = computed((): Record<string, string | undefined> => {
  return initFilterMap(props.modelValue)
})

const onTagDeleteClick = (tag: string) => {
  const applicationId = filterMap.value[tag]

  if (applicationId) {
    deleleTagFromList(applicationId, selectedApplications.value)
  }
}
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="$t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>

  <div class="rounded-lg bg-white text-gray-900">
    <div class="flex p-6 gap-6 flex-nowrap">
      <div class="flex-1 items-start">
        <lxc-log-application-type-filter v-model="applicationTypes" />
      </div>
      <div class="flex-1 items-start">
        <lxc-log-application-declination-filter
          v-model="applicationDeclinations"
          :types="applicationTypes"
        />
      </div>
    </div>
    <lxc-log-application-name-filter v-model="applicationName" />
    <lxc-log-application-list-filter
      v-model="selectedApplications"
      :declinations="applicationDeclinations"
      :name="applicationName"
      :types="applicationTypes"
    />
  </div>
</template>
