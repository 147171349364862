<script setup lang="ts">
import type { CampaignI } from '@lxc/app-device-types'
import { CampaignState, LicenseStatus } from '@lxc/app-device-types'
import type { DebuggerEventExtraInfo, Ref } from 'vue'
import dayjs from 'dayjs'
import { ElMessageBox } from 'element-plus'
import LxcError from '~/utils/LxcError'
import CampaignService from '~/services/campaign.service'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import { useLicenseStatusStore } from '~/stores/useLicenseStatusStore'
import ILxcClose from '~icons/lxc-light/x-close'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const licenseStatusStore = useLicenseStatusStore()

// redirect to index page when license status is not activated or if feature advanced fleet management is not enabled
licenseStatusStore.$subscribe((mutation, state) => {
  // the mutation concerns the licenseStatus state and the license status is not activated
  const isLicenseStatusNotActivated = (mutation.events as DebuggerEventExtraInfo).key === licenseStatusStore.licenseStateKeys.LICENSE_STATUS && (!state.licenseStatus || state.licenseStatus.statusOfLicense === LicenseStatus.NOT_ACTIVATED)

  // the mutation concerns the license feature advanced fleet management state flag and its value is false
  const isLicenseFeatureAdvancedFleetManagementNotPresent = (mutation.events as DebuggerEventExtraInfo).key === licenseStatusStore.licenseStateKeys.IS_LICENSE_FEATURE_ADVANCED_FLEET_MANAGEMENT_PRESENT && !state.isLicenseFeatureAdvancedFleetManagementPresent

  if (isLicenseStatusNotActivated || isLicenseFeatureAdvancedFleetManagementNotPresent) {
    router.replace('/')
  }
})

const id = route.params.id.toString()

const campaign: Ref<CampaignI | null> = ref(null)
const error: Ref<LxcError | null> = ref(null)
const isLoading = ref(true)

const formatObjectDate: any = (date: string, format: string) => date ? dayjs(date).format(format) : ''

async function cancelCampaign() {
  if (campaign.value?.id) {
    const campaignId = campaign.value.id

    ElMessageBox.confirm(
      t('campaign.details.buttons.cancel.confirm.message'),
      t('campaign.details.buttons.cancel.confirm.title'),
      {
        confirmButtonText: t('campaign.details.buttons.cancel.confirm.yes'),
        cancelButtonText: t('campaign.details.buttons.cancel.confirm.no'),
        type: 'warning',
      },
    )
      .then(async() => {
        isLoading.value = true

        const response = await CampaignService.cancelCampaign(campaignId.toString())

        if (LxcError.check(response)) {
          response.notify(NotificationKey.saveError)
        } else {
          showNotificationSuccess(t(NotificationKey.saveSuccess))
          fetchCampaign()
        }

        isLoading.value = false
      })
  }
}

async function fetchCampaign() {
  const response = await CampaignService.getCampaign(id)

  if (LxcError.check(response)) {
    campaign.value = null
    error.value = response
  } else {
    campaign.value = response
  }

  isLoading.value = false
}

// License Check
const isBeyondLicenseDateValidity: Ref<boolean|undefined> = ref(true)
const isBeyondLicenseLimitOfAllDeviceTypes: Ref<boolean|undefined> = ref(true)
const checkLicenseValidityDate = (arg: boolean|undefined) => isBeyondLicenseDateValidity.value = arg
const checkLimitOfAllDeviceTypes = (arg: boolean|undefined) => isBeyondLicenseLimitOfAllDeviceTypes.value = arg
const disableUpdateOrCancelCampaign = computed(() => {
  const { isLicenseFeatureAdvancedFleetManagementActive } = useLicenseStatusStore()
  return !isLicenseFeatureAdvancedFleetManagementActive || isBeyondLicenseDateValidity.value || isBeyondLicenseLimitOfAllDeviceTypes.value
})

onMounted(fetchCampaign)

const canUpdateOrCancelCampaign = computed(() => {
  return campaign.value
    && campaign?.value.state
    && [CampaignState.SCHEDULED, CampaignState.RUNNING, CampaignState.PAUSED].includes(campaign?.value.state)
})
</script>

<template>
  <el-container direction="vertical">
    <lxc-license-warning-messages
      @is-beyond-expiration-date="checkLicenseValidityDate($event)"
      @is-beyond-limit-of-all-device-type="checkLimitOfAllDeviceTypes($event)"
    />

    <div class="flex justify-between mr-14">
      <lxc-breadcrumb :name="campaign?.config?.name" />
      <div class="flex gap-4 self-center">
        <lxc-campaign-funnel
          v-if="canUpdateOrCancelCampaign"
          :disabled="disableUpdateOrCancelCampaign || isLoading"
          can-manage-campaign
          :campaign-id="id"
        />
        <lxc-button
          v-if="canUpdateOrCancelCampaign"
          type="secondary"
          :title="$t('campaign.details.buttons.cancel.label')"
          :disabled="disableUpdateOrCancelCampaign || isLoading"
          :icon="ILxcClose"
          @click="cancelCampaign"
        >
          {{ $t('campaign.details.buttons.cancel.label') }}
        </lxc-button>
      </div>
    </div>

    <lxc-container
      :is-loading="isLoading"
      :error="error"
    >
      <lxc-campaign-overview-dashboard
        :campaign="campaign"
      />

      <h2 class="section-title">
        {{ $t('campaign.details.equipments.title') }}
      </h2>

      <lxc-campaign-operation-list
        :campaign-planned-start-at="campaign?.config?.plannedStartAt"
        :campaign-id="campaign?.id"
        :current-campaign-type="campaign?.type"
      />
    </lxc-container>
  </el-container>
</template>
