import type { ApiListResult, AsyncLogI } from '@lxc/app-device-types'
import RestService from './rest.service'
import type { FilterSelectionValue, FiltersSelection } from '~/types'

export class LogsService extends RestService {
  protected BASE_URL = '/logs'

  getLogs(page?: number, pageSize?: number, searchParams?: string | FiltersSelection, _sort?: string): Promise<ApiListResult<AsyncLogI>> {
    const iterator = (searchParams as FiltersSelection).entries()
    const searchObject: Record<string, FilterSelectionValue> = {}

    for (const entry of iterator) {
      const filterDefinition = entry[1]
      searchObject[filterDefinition.key] = filterDefinition.value
    }

    const params = { page, pageSize, ...searchObject }

    return this.$api.get(`${this.BASE_URL}`, {
      headers: { Accept: 'application/json' },
      params,
      timeout: Infinity,
    })
  }
}

export default new LogsService()
