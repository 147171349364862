<script setup lang="ts">
import { ref } from 'vue'
import { FILTER_SEARCH_TXT_MIN_LENGTH } from '~/constants/constants'

const props = defineProps<{
  modelValue: string
}>()
const applicationName = ref(props.modelValue ?? '')

const emit = defineEmits(['update:modelValue'])

watch(() => applicationName.value, (text: string) => {
  if (text.length >= FILTER_SEARCH_TXT_MIN_LENGTH) {
    emit('update:modelValue', text)
  } else {
    emit('update:modelValue', '')
  }
})
</script>
<template>
  <div class="px-6 pb-6">
    <lxc-search-input
      v-model="applicationName"
      :placeholder="$t('logs.filters.applications.placeholder')"
    />
  </div>
</template>
