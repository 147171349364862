<script setup lang="ts">
import type { CampaignType, DeviceI, SectorI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { storeToRefs } from 'pinia'
import type { ElTable } from 'element-plus'
import {
  deviceModelOptions as defaultDeviceModelOptions,
  stateOptions as defaultStateOptions,
  typeOptions as defaultTypeOptions,
} from './deviceFilters.config'
import { useDevices } from '~/composables/useDevices'
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from '~/constants/constants'
import { PATHS } from '~/constants/paths'
import { useSectorStore } from '~/stores/useSectorStore'
import SectorsService from '~/services/sectors.service'
import type { FilterOptions } from '~/types'
import { Filters } from '~/types'
import type { SearchMode } from '~/composables/useSearch'
import { router } from '~/plugins/router'

const props = defineProps<{
  noAction?: boolean
  selectedDevices?: Array<DeviceI>
  defaultFilters?: Map<Filters, any>
  campaignType?: CampaignType
  searchMode?: SearchMode
  typeOptions?: FilterOptions
  modelOptions?: FilterOptions
  stateOptions?: FilterOptions
}>()

const emit = defineEmits(['update:selectedDevices'])

const tableRef = ref<InstanceType<typeof ElTable>>()

const {
  isLoading,
  results: devices,
  filters,
  error,
  fetchData,
  setFilter,
  getHasActionStatusLabel,
  search,
  onSearch,
} = useDevices(props.searchMode)

const { selectedSectors } = storeToRefs(useSectorStore())

const currentPageSectors = ref<SectorI[]>([])

/**
 * Reload data if global sector filter has been applied
 */
watch(selectedSectors, () => loadData())

const rowSelection: Ref<Array<DeviceI>> = ref([])

/**
 * Select devices
 */
const setSelection = () => {
  if (props.selectedDevices !== undefined && tableRef.value) {
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(row, props.selectedDevices.some(selectedDevice => selectedDevice.id === row.id))
    }

    rowSelection.value = props.selectedDevices || []
  }
}

watch(() => props.selectedDevices, setSelection)

/**
 * Retrieve devices and sectors
 * @param params
 */
async function loadData(page: number = DEFAULT_FIRST_PAGE, pageSize: number = DEFAULT_PAGE_SIZE) {
  setFilter(Filters.SECTORS, selectedSectors.value.map(sector => sector.code))
  const params = new Map()
  params.set('campaignType', props.campaignType)
  await fetchData(page, pageSize, undefined, params)
  await fetchDevicesSectors()

  setSelection()
}

/**
 * Retrieve all sectors matching devices
 */
async function fetchDevicesSectors() {
  const sectorCodes: string[] = (devices.value?.data ?? []).map((device: DeviceI) => device.sectorCode!)

  if (sectorCodes.length) {
    currentPageSectors.value = await SectorsService.getSectorsByCodes(sectorCodes)
  }
}

/**
 * Calcul and emit the selected devices
 */
const handleSelect = (handledSelected: DeviceI[]) => {
  // Keep the selected devices which were selected on another page and not present in handled selected
  emit(
    'update:selectedDevices',
    props.selectedDevices?.filter((selectedDevice: DeviceI) => !tableRef.value || !tableRef.value.data.find(row => row.id === selectedDevice.id))
      .filter((selectedDevice: DeviceI) => !handledSelected.find(row => row.id === selectedDevice.id))
      .concat(handledSelected),
  )
}

// Apply provided default filters
if (props.defaultFilters) {
  for (const [filter, value] of props.defaultFilters) {
    setFilter(filter, value || '')
  }
}

const onSelectDevice = (app: DeviceI) => {
  router.push(`${PATHS.DEVICES_DVTM_ESOFT}/${app.id}`)
}

const searchQuery = ref<string>((filters.get(Filters.NAME) ?? '') as string)
const onSearchInputChanged = (newValue: string) => {
  searchQuery.value = newValue
  setFilter(Filters.NAME, searchQuery.value)
}

function reloadWithContext() {
  loadData(devices.value?.context?.page, devices.value?.context?.pageSize)
}

onSearch(loadData)
</script>

<template>
  <lxc-search-bar
    :search-query="searchQuery"
    :search-placeholder="$t('filters.searchByName')"
    class="grow shrink basis-0"
    @clear="_ => {
      setFilter(Filters.NAME, '')
      search()
    }"
    @input-change="onSearchInputChanged"
    @search="search"
  />
  <lxc-device-filters
    :state-value="filters.get(Filters.STATE)"
    :model-declination-value="filters.get(Filters.MODEL_DECLINATION)"
    :model-type-value="filters.get(Filters.MODEL_TYPE)"
    :connectivity-value="filters.get(Filters.CONNECTIVITY)"
    :certificate-expire-after="filters.get(Filters.CERTIFICATE_EXPIRE_AFTER)"
    :certificate-expire-before="filters.get(Filters.CERTIFICATE_EXPIRE_BEFORE)"
    :type-options="typeOptions ?? defaultTypeOptions"
    :model-options="modelOptions ?? defaultDeviceModelOptions"
    :state-options="stateOptions ?? defaultStateOptions"
    @change="setFilter"
    @enter="search"
  />
  <lxc-table
    ref="tableRef"
    :is-loading="isLoading"
    :data="devices?.data"
    :empty-text="$t('device.empty')"
    :context="devices?.context"
    data-cy="device-table"
    :error="error"
    :clickable="true"
    @change-page-and-page-size="loadData"
    @row-click="onSelectDevice"
    @select="handleSelect"
    @select-all="handleSelect"
  >
    <lxc-table-column
      v-if="selectedDevices !== undefined"
      type="selection"
      width="55"
    />
    <lxc-table-column
      class-name="no-no-wrap"
      prop="name"
      :label="$t('device.name')"
    />
    <lxc-table-column
      prop="model.type"
      :label="$t('device.type')"
    />
    <lxc-table-column
      prop="model.declination"
      :label="$t('device.model')"
    />
    <lxc-table-column
      prop="serialNumber"
      :label="$t('device.serialNumber')"
    />
    <lxc-table-column
      prop="connectivity.state"
      :label="$t('device.connectivity')"
    >
      <template
        #default="scope"
      >
        <lxc-connectivity-status :connectivity-state="scope.row.connectivity.state" />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="$t('device.certificate')"
      prop="certificateExpireBefore"
      sortable="custom"
    >
      <template #default="scope">
        <lxc-certificate-status :certificate="scope.row.certificate" />
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="firmwareVersion"
      :label="$t('device.firmwareVersion')"
    />
    <lxc-table-column :label="$t('device.state')">
      <template #default="scope">
        {{ scope.row.state ? $t(`device.states.${scope.row.state}`) : '' }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="campaignType"
      :label="$t('device.actions.scheduled.title')"
    >
      <template #default="scope">
        <span>{{ getHasActionStatusLabel(scope.row, campaignType) }}</span>
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="!noAction"
      class="w-16"
    >
      <template #default="scoped">
        <lxc-device-actions
          :key="scoped.row.id"
          :is-activated="scoped.row.state"
          :device="scoped.row"
          @change="reloadWithContext"
        />
      </template>
    </lxc-table-column>
  </lxc-table>

  <div class="mt-4">
    <el-select
      v-if="rowSelection?.length !== 0"
      v-model="rowSelection"
      class="footer-selection"
      value-key="id"
      multiple
      collapse-tags
      collapse-tags-tooltip
      reserve-keyword
      :teleported="false"
      @change="$emit('update:selectedDevices', rowSelection)"
    >
      <el-option
        v-for="device in rowSelection"
        :key="device.id"
        :label="device.name"
        :value="device"
      />
    </el-select>
  </div>
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
