<script lang='ts' setup>
import type { TabItem } from '~/types'

const { t } = useI18n()
const props = defineProps<{
  activePageIndex: string
  tabsItems: TabItem[]
}>()
</script>

<template>
  <el-menu
    :default-active="activePageIndex"
    mode="horizontal"
    class="tab-menu"
    router
  >
    <el-menu-item
      v-for="(tabItem, index) in tabsItems"
      :key="index"
      v-can="tabItem.can"
      :index="index.toString()"
      :route="tabItem.to"
      class="tab-item"
    >
      {{ $t(tabItem.label).toUpperCase() }}
    </el-menu-item>
  </el-menu>
</template>
