import { createI18n } from 'vue-i18n'
import messages from '~/assets/locales'

// Initial default value
export const locale = 'en'
export const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
})
