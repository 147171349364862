<script lang="ts" setup>
import type LxcError from '~/utils/LxcError'

const props = defineProps<{
  error?: LxcError | null
  isLoading?: boolean
  loaderSize?: number
  px?: number
  py?: number
}>()

const lxcContainerClass = computed(() => [
  `px-${props.px !== undefined ? props.px : 14}`,
  `py-${props.py !== undefined ? props.py : 5}`,
  'flex',
  'flex-col',
  'grow',
])
</script>

<template>
  <div :class="lxcContainerClass">
    <div
      v-if="isLoading"
      class="mt-20 flex justify-center"
    >
      <lxc-loader
        :size="loaderSize || 28"
      />
    </div>
    <lxc-error
      v-else-if="error"
      :error="error"
    />
    <slot v-else />
  </div>
</template>
