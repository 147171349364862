<script setup lang="ts">
import type { Ref } from 'vue'
import type { ComponentI } from '@lxc/app-device-types'
import { storeToRefs } from 'pinia'
import LxcError from '~/utils/LxcError'
import platformService from '~/services/platform.service'
import { useConfigStore } from '~/stores/useConfigStore'

const isLoading = ref(false)
const error: Ref<LxcError|null> = ref(null)
const { version } = storeToRefs(useConfigStore())

const componentsVersion: Ref<ComponentI[]> = ref<ComponentI[]>([])
const activeName = ref('')

async function getComponentsVersion() {
  isLoading.value = true
  const response = await platformService.getComponentsVersion()

  if (LxcError.check(response)) {
    error.value = response
  } else {
    componentsVersion.value = response
    activeName.value = componentsVersion.value[0].name
  }
  isLoading.value = false
}

onMounted(async() => {
  await getComponentsVersion()
})
</script>
<template>
  <h2 class="title">
    LX CONNECT {{ $t('platform.technicalVersion') }}: {{ (version ?? $t('parameters.technicalVersion.unknown')) }}
  </h2>
  <h2 class="title">
    {{ $t('platform.title') }}
  </h2>
  <el-tabs
    v-model="activeName"
    tab-position="left"
  >
    <lxc-container
      :is-loading="isLoading"
      :error="error"
    >
      <el-tab-pane
        v-for="(component, componentIndex) in componentsVersion"
        :key="componentIndex"
        :label="$t(`platform.componentName.${component.name}`)"
        :name="component.name"
      >
        <lxc-component-version
          :component="component"
        />
      </el-tab-pane>
    </lxc-container>
  </el-tabs>
</template>
<style scoped lang="scss">
.title{
  margin-bottom: 1em;
}
</style>
