<script setup lang="ts">
import type { SectorOrg } from '@lxc/app-device-types'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationSuccess } from '~/utils/notifications-tools'
import sectorsService from '~/services/sectors.service'
import { useSectorStore } from '~/stores/useSectorStore'

const props = defineProps<{
  isVisible: boolean
  sector: SectorOrg|null
  isCreate: boolean
}>()
const emit = defineEmits(['change', 'close'])
const { t } = useI18n()
const { resetSectorsSelection } = useSectorStore()

const sectorFormRef = ref()
const sectorForm = reactive({
  label: '',
  description: '',
})
const rules = reactive({
  label: [
    {
      required: true,
      message: t('sectors.validation.required'),
      whitespace: true,
      trigger: 'blur',
    },
    {
      max: 255,
      message: t('sectors.validation.maxLength', { maxLength: 255 }),
      whitespace: true,
      trigger: 'blur',
    },
  ],
  description: [
    {
      max: 500,
      message: t('sectors.validation.maxLength', { maxLength: 500 }),
      whitespace: true,
      trigger: 'blur',
    },
  ],
})

onMounted(() => {
  if (!props.isCreate && props.sector) {
    const { label, description } = props.sector
    Object.assign(sectorForm, { label, description })
  }
})

/**
 * Create a sector/sub sector
 */
async function onCreateSector() {
  const isFormValid = await sectorFormRef.value.validate().catch(() => false)
  if (isFormValid) {
    const newSector: SectorOrg = {
      parentSectorId: props.sector?.id,
      ...sectorForm,
    }

    if (props.sector?.id) {
      newSector.parentSectorId = props.sector.id
    }

    displayNotification(await sectorsService.createSector(newSector))
    await resetSectorsSelection()
  }
}

/**
 * Create a sector/sub sector
 */
async function onUpdateSector() {
  const isFormValid = await sectorFormRef.value.validate().catch(() => false)

  if (isFormValid && props.sector?.id) {
    displayNotification(await sectorsService.updateSector(props.sector.id, sectorForm))
    await resetSectorsSelection()
  }
}

function displayNotification(response: LxcError | any): void {
  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError)
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess))
    close(true)
  }
}

/**
 * Reset fields and close modal with indication to refresh if needed
 * @param refresh
 */
function close(refresh = false) {
  sectorFormRef.value.resetFields()
  emit('close', refresh)
}
</script>

<template>
  <lxc-modal
    :dialog-visible="isVisible"
    :title="$t(`sectors.modals.${isCreate ? 'create' : 'update'}.title`)"
    @confirm="isCreate ? onCreateSector() : onUpdateSector()"
    @cancel="close"
  >
    <lxc-mandatory size="small" />
    <el-form
      ref="sectorFormRef"
      label-position="top"
      label-width="auto"
      :model="sectorForm"
      :rules="rules"
      @submit.prevent
    >
      <el-form-item
        :label="$t('sectors.modals.create.sector.label')"
        prop="label"
      >
        <el-input
          v-model="sectorForm.label"
          type="text"
        />
      </el-form-item>

      <el-form-item
        :label="$t('sectors.modals.create.sector.description')"
        prop="description"
      >
        <el-input
          v-model="sectorForm.description"
          type="textarea"
        />
      </el-form-item>
    </el-form>
  </lxc-modal>
</template>
