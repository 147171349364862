<script setup lang="ts">
import { useUserSession } from '~/stores/useUserSession'

defineProps<{
  dtwin?: any
}>()

const { userSession } = useUserSession()

const proxyUrl = `${import.meta.env.LXC_AS_DEVICE_URL}proxy`

const customElementError = async(error: any) => {
  if (error.detail[0].response.status === 401) {
    const { logout } = await import('~/utils/auth.utils')
    await logout()
  }
}
</script>

<template>
  <v-front-page
    :device-id="dtwin.uid"
    :proxy-url="proxyUrl"
    :lang="userSession?.language"
    @error="customElementError"
  />
</template>
