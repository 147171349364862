import type { RoleI } from '@lxc/app-device-types'
import RolesService from '~/services/roles.service'
import LxcError from '~/utils/LxcError'

export function useRoles() {
  const roles = ref<RoleI[] | null>(null)
  const error = ref<LxcError | null>(null)
  const isLoading = ref(true)

  async function fetchRoles() {
    isLoading.value = true
    const response = await RolesService.getAllGrantableRoles()

    if (LxcError.check(response)) {
      roles.value = null
      error.value = response
    } else {
      roles.value = response
    }

    isLoading.value = false
  }

  return {
    roles,
    error,
    fetchRoles,
    isRolesLoading: isLoading,
  }
}
