<script setup lang="ts">
import type { PeriodI } from '@lxc/app-device-common'
import dayjs from 'dayjs'
import { formatIsoDateToUtc } from '~/utils/date-tools'
import { type FilterSelectionDefinition, Filters, Visibility } from '~/types'
const { locale, t } = useI18n()
const emit = defineEmits(['change', 'update:creationEndDate', 'update:creationStartDate'])

const props = defineProps<{
  creationStartDate?: FilterSelectionDefinition | null
  creationEndDate?: FilterSelectionDefinition | null
  // this filter current visiblity state
  visibiliy?: Visibility
  // the global filters visiblity
  filterPanelVisibiliy?: Visibility
}>()

const creationDateFilterWrapper = ref<HTMLElement | undefined | null>()
const pickerLayer = ref<HTMLElement | undefined | null>()
const periodSeparator = ref(` ${t('firmware.filters.creationDate.periodSeparator')} `)

function periodToStartDate(period: PeriodI): FilterSelectionDefinition {
  return {
    key: Filters.DATE_ADDED_AFTER,
    operator: '>=',
    value: period.startDate ? formatIsoDateToUtc(period.startDate) : '',
  }
}

function periodToEndDate(period: PeriodI): FilterSelectionDefinition {
  return {
    key: Filters.DATE_ADDED_BEFORE,
    operator: '<=',
    value: period.endDate ? formatIsoDateToUtc(period.endDate) : '',
  }
}

const formPeriod = computed({
  get(): PeriodI {
    let period: PeriodI = {
      startDate: null,
      endDate: null,
    }

    if (props.creationStartDate?.value
      && props.creationEndDate?.value) {
      period = {
        startDate: dayjs(props.creationStartDate.value as string).utc(false).toDate(),
        endDate: dayjs(props.creationEndDate.value as string).utc(false).toDate(),
      }
    }

    return period
  },
  set(newPeriod: PeriodI) {
    const startDate = periodToStartDate(newPeriod)
    const endDate = periodToEndDate(newPeriod)
    emit('update:creationStartDate', startDate)
    emit('update:creationEndDate', endDate)
    emit('change')
  },
})

const setCurrentComponentPosition = () => {
  if (creationDateFilterWrapper.value != null && pickerLayer.value != null) {
    const creationDateClientRect = creationDateFilterWrapper.value.getBoundingClientRect()
    pickerLayer.value.style.top = `${(creationDateClientRect.top)}px`
    pickerLayer.value.style.left = `${(creationDateClientRect.left)}px`
    pickerLayer.value.style.width = `${creationDateClientRect.width}px`
    pickerLayer.value.style.height = `${creationDateClientRect.height}px`
  }
}

const onVisibilityChange = (visibiliy?: Visibility) => {
  switch (visibiliy) {
    case Visibility.SHOWN:
    case Visibility.HIDDEN:
      setCurrentComponentPosition()
      break
  }
}

const onFilterPanelVisiblityChange = (visibiliy?: Visibility) => {
  switch (visibiliy) {
    case Visibility.SHOWN:
    case Visibility.HIDDEN:
      setCurrentComponentPosition()
      break
  }
}

const layerClass = computed((): string[] => {
  const layerClass = [
    'rounded-lg',
    'bg-white',
    'text-gray-900',
    'p-6',
    'firware-creation-date-picker-layer',
    'absolute',
    'z-[1046]',
  ]

  if ((props.filterPanelVisibiliy === undefined || props.filterPanelVisibiliy === Visibility.SHOWN)
    && (props.visibiliy === undefined
  || props.visibiliy === Visibility.SHOWN)) {
    layerClass.push('block')
  } else {
    layerClass.push('hidden')
  }

  return layerClass
})

window.addEventListener('resize', setCurrentComponentPosition)
watch(() => props.filterPanelVisibiliy, onFilterPanelVisiblityChange)
watch(() => props.visibiliy, onVisibilityChange)
watch(() => pickerLayer.value, setCurrentComponentPosition)
watch(() => creationDateFilterWrapper.value, setCurrentComponentPosition)

</script>

<template>
  <div
    ref="creationDateFilterWrapper"
    class="w-full h-[6.08875rem]"
  />
  <Teleport
    to="body"
  >
    <div
      ref="pickerLayer"
      :class="layerClass"
    >
      <lxc-period-picker
        v-model="formPeriod"
        type="primary"
        button-size
        :formatter="$t('firmware.filters.creationDate.formatter.input')"
        :i18n="locale"
        :placeholder="$t('firmware.filters.creationDate.placeholder')"
        :separator="periodSeparator"
        class="mr-3"
      />
    </div>
  </Teleport>
</template>
