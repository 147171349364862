import type { ApiListResult, CampaignType, OperationI } from '@lxc/app-device-types'
import CampaignService from '~/services/campaign.service'
import LxcError from '~/utils/LxcError'

export function useCampaignObjectOperations(objectId: string, campaignType?: CampaignType) {
  const operations = ref<ApiListResult<OperationI> | null>(null)
  const error = ref<LxcError | null>(null)
  const isLoading = ref<boolean>(false)

  async function fetchData(page?: number, pageSize?: number, sort?: string) {
    isLoading.value = true
    error.value = null

    const response = await CampaignService.getCampaignObjectOperations(objectId, page, pageSize, sort, campaignType)

    if (LxcError.check(response)) {
      operations.value = null
      error.value = response
    } else {
      operations.value = response
    }

    isLoading.value = false
  }

  return {
    fetchData,
    isLoading,
    operations,
    error,
  }
}
