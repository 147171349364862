<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useSectorStore } from '~/stores/useSectorStore'
import { useUserSession } from '~/stores/useUserSession'

const { selectedSectors } = storeToRefs(useSectorStore())
const { userSession } = useUserSession()

defineProps({
  isLoggedIn: {
    type: Boolean,
    required: true,
  },
})

const numberOfSelectedSector = computed(() => selectedSectors.value.length ?? 0)

const userInitials = `${userSession?.firstName?.slice(0, 1)}${userSession?.lastName?.slice(0, 1)}`
</script>

<template>
  <el-header class="header-container">
    <el-row
      class="row-wrapper"
      justify="space-between"
    >
      <router-link
        to="/"
        exact
        class="logo"
      >
        <img
          src="/LX-CONNECT_logo.svg"
          alt="LX CONNECT logo"
        >
      </router-link>

      <el-row v-if="isLoggedIn">
        <el-dropdown
          trigger="click"
          popper-class="dropdown-overflow"
        >
          <el-button
            type="primary"
            class="btn-menu"
          >
            {{ userInitials }}
            <el-icon
              :size="10"
              class="el-icon--right"
            >
              <i-lx-angle-down />
            </el-icon>
          </el-button>

          <template #dropdown>
            <lx-header-user-menu />
          </template>
        </el-dropdown>
      </el-row>
    </el-row>
  </el-header>
</template>

<style lang="scss" scoped>
.header-container {
  background-color: $primary-color;
  padding:0;
  box-shadow: $shadow;
  color: $white;

  .row-wrapper {
    height: 100%;
    align-items: center;

    .logo {
      padding: 0 0 0 15px;
    }

    .btn-menu {
      margin-right: 12px;
    }

    .el-icon--right {
      margin-left: 10px;
    }
  }
}
.circle-sectors {
  height: 22px;
  width: 22px;
  background-color: $white;
  border-radius:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:12px;
  font-weight: bold;
  color: $primary-color;
  margin-right: 8px;
}
</style>
