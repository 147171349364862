<script lang='ts' setup>
import { type ActionToUpdateI, type ApplicationI, CampaignType, type DeviceI, type OperationI, type OperationToUpdateI } from '@lxc/app-device-types'
import dayjs from 'dayjs'
import type { Ref } from 'vue'
import LxcInformationRow from '~/components/shared/LxcInformationRow.vue'
import LxcError from '~/utils/LxcError'
import { NotificationKey, showNotificationError, showNotificationSuccess } from '~/utils/notifications-tools'
import applicationService from '~/services/application.service'
import deviceService from '~/services/device.service'
import { ObjectType } from '~/types/object'
import { hasActionScheduled } from '~/composables/useDevices'

const { t } = useI18n()
const props = defineProps<{
  isDialogVisible: boolean
  object: DeviceI | ApplicationI
  objectType: ObjectType
  operation?: OperationI
}>()

const emit = defineEmits([
  'update:toggleDialog',
  'change',
])

const formRef = ref()

interface Form {
  startTime: string
}

const form = reactive<Form>({
  startTime: props.operation?.process?.startedAt || '',
})

async function onConfirmUpdate() {
  const isFormValid = await formRef.value.validate().catch(() => false)
  if (isFormValid) {
    const formattedDate = dayjs(form.startTime).utc(false).toISOString()
    const operationsToUpdate: Ref<Array<OperationToUpdateI>> = ref([])
    const actionsToUpdate: Ref<Array<ActionToUpdateI>> = ref([])

    switch (props.objectType) {
      case ObjectType.DEVICE: {
        const createOrUpdateOperationResponse: Ref<OperationI|any> = ref()
        if (props.operation && props.operation?.definition.id) {
          props.operation.definition.actions.forEach((action) => {
            actionsToUpdate.value.push(
              {
                id: action.id,
                startTime: `${formattedDate.split('.')[0]}Z`, // Remove milliseconds
              })
          })

          operationsToUpdate.value.push({
            id: props.operation.definition.id,
            actions: actionsToUpdate.value,
          })

          createOrUpdateOperationResponse.value = await deviceService.updateOperations(props.object as DeviceI, operationsToUpdate.value)
        } else {
          createOrUpdateOperationResponse.value = await deviceService.createOperationRenewCertificate(props.object as DeviceI, formattedDate)
        }

        if (LxcError.check(createOrUpdateOperationResponse.value)) {
          showNotificationError(t(NotificationKey.error))
        } else {
          showNotificationSuccess(t(NotificationKey.success))
          emit('update:toggleDialog')
          emit('change')
          form.startTime = ''
        }
        break
      }
      case ObjectType.APPLICATION: {
        const createOperationResponse = await applicationService.createOperationRenewCertificate(props.object as ApplicationI, formattedDate)

        if (LxcError.check(createOperationResponse)) {
          showNotificationError(t(NotificationKey.error))
        } else {
          showNotificationSuccess(t(NotificationKey.success))
          emit('update:toggleDialog')
          form.startTime = ''
        }
        break
      }
    }
  }
}

const description = computed(() => props.objectType === ObjectType.DEVICE
  ? t('operation.certificate.update.description', { name: t('device.label') })
  : t('operation.certificate.update.description', { name: t('application.label') }),
)

const notaBene = computed(() => props.objectType === ObjectType.DEVICE
  ? t('operation.certificate.update.notaBene', { name: t('device.label') })
  : t('operation.certificate.update.notaBene', { name: t('application.label') }),
)

// Check if the date has been validated
const isDateValidated = ref(false)
const isDateValid = (rule: any, value: any, callback: any) => {
  // To consider checkDate and now time without seconds
  const checkDate = new Date(value)
  checkDate.setSeconds(0)
  checkDate.setMilliseconds(0)
  const now = new Date()
  now.setSeconds(0)
  now.setMilliseconds(0)

  // Make the check if user don't use "Now" button. If now button is used, the check will be true
  if (checkDate.getTime() !== now.getTime() && !isDateValidated.value) {
    if (checkDate < now) {
      isDateValidated.value = false
      callback(new Error(t('campaign.dateInferiorToToday')))
    } else {
      isDateValidated.value = true
      callback()
    }
  } else {
    isDateValidated.value = true
    callback()
  }
}

const isDateAfterExpirationDate = (rule: any, value: any, callback: any) => {
  if (value > dayjs(props.object.certificate?.notValidAfter).format('YYYY-MM-DDTHH:mm:ss[Z]')) {
    callback(new Error(t('operation.certificate.update.dateNotValidAfterRenewal')))
  }
  else {
    callback()
  }
}

const rules = reactive({
  startTime: [
    { required: true, message: t('operation.certificate.update.dateTimeError'), trigger: 'change' },
    { validator: isDateValid, trigger: 'change' },
    { validator: isDateValid, trigger: 'blur' }, // To check date validity also on blur event if the blur happens too late
    { validator: isDateAfterExpirationDate, trigger: 'change' },
  ],
})

function disabledDate(time: Date) {
  return time.getTime() < dayjs().subtract(1, 'day').valueOf()
}

const isScheduledOrRunningOperations = ref(false)
async function computeScheduledOrRunningOperations() {
  isScheduledOrRunningOperations.value = false
  const deviceId = props.object.id
  let campaignType: CampaignType = CampaignType.CRTCLT_RENEWAL_DVC

  if (props.objectType === ObjectType.APPLICATION) {
    campaignType = CampaignType.CRTCLT_RENEWAL_APP
  }

  if (deviceId) {
    const response = await deviceService.getStatsOperation(deviceId.toString(), campaignType)
    isScheduledOrRunningOperations.value = hasActionScheduled(response, campaignType) || false
  }
}
</script>

<template>
  <lxc-modal
    :dialog-visible="isDialogVisible"
    :title="$t('operation.certificate.update.label')"
    @confirm="onConfirmUpdate"
    @cancel="$emit('update:toggleDialog', false)"
    @update:dialog-visible="$emit('update:toggleDialog', $event)"
    @open="computeScheduledOrRunningOperations"
  >
    <lxc-alert
      v-if="isScheduledOrRunningOperations"
      type="warning"
    >
      <span
        class="break-normal"
      >
        {{ $t('device.warningMessage') }}
      </span>
    </lxc-alert>

    <div>
      <p class="update-description break-normal">
        {{ description }}
      </p>

      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        label-position="top"
        @submit.prevent
      >
        <el-form-item
          :label="$t('operation.certificate.update.plannedStartAt')"
          prop="startTime"
        >
          <!-- checking also the date on blur event if user blur field after a minute -->
          <el-date-picker
            v-model="form.startTime"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            type="datetime"
            size="large"
            :format="$t('device.dateFormat')"
            :disabled-date="disabledDate"
            placeholder="YYYY/MM/DD HH:mm"
            data-cy="datepicker-input"
            @change="() => isDateValidated = false"
            @blur="() => isDateValidated = false"
          />
        </el-form-item>
      </el-form>

      <lxc-information-row
        :title="notaBene"
      />
    </div>
  </lxc-modal>
</template>

<style lang='scss' scoped>
.update-description {
  font-size: 16px;
}

//Overridden nota-bene-container to set custom margin
.nota-bene-container {
  margin: 10px;
}

</style>
