<script setup lang="ts">
import type { ApplicationMgtI } from '@lxc/app-device-types'
import { useAcl } from 'vue-simple-acl'
import { BreadcrumbConfig } from '~/components/shared/breadcrumb/breadcrumb.config'
import { useApplicationMgt } from '~/composables/useApplicationMgt'
import { useElTable } from '~/composables/useElTable'
import { PATHS } from '~/constants/paths'
import { ACL_ROLES, Filters } from '~/types'
import { getDateToLocaleString } from '~/utils/date-tools'
import ILxPlus from '~icons/lx/plus'

const {
  isLoading,
  results,
  filters,
  error,
  pagination,
  fetchData,
  setFilter,
  onSortChange,
  search,
  onSearch,
} = useApplicationMgt()

const { getCellStyle } = useElTable()
const acl = useAcl()

const canManageApplicationMgts = computed(() => acl.can(ACL_ROLES.MANAGE_APPLICATION_MGTS))

function reloadWithContext() {
  fetchData(results.value?.context?.page, results.value?.context?.pageSize)
}

onSearch(fetchData)
</script>

<template>
  <el-container direction="vertical">
    <lxc-breadcrumb
      :name="BreadcrumbConfig.APPLICATION_MGT.title"
      readonly
    />

    <lx-toolbar>
      <lx-search-bar
        :is-loading="isLoading"
        :search-query="filters.get(Filters.APPLICATION_NAME)"
        :search-placeholder="$t('filters.searchByName')"
        @clear="_ => {
          setFilter(Filters.APPLICATION_NAME, '')
          search()
        }"
        @input-change="setFilter(Filters.APPLICATION_NAME, $event)"
        @search="search"
      >
        <lxc-application-mgt-filters
          :device-type="filters.get(Filters.APPMGT_DEVICE_TYPES)"
          :date-added-before="filters.get(Filters.APPMGT_DATE_ADDED_BEFORE)"
          :date-added-after="filters.get(Filters.APPMGT_DATE_ADDED_AFTER)"
          :date-updated-before="filters.get(Filters.APPMGT_DATE_UPDATED_BEFORE)"
          :date-updated-after="filters.get(Filters.APPMGT_DATE_UPDATED_AFTER)"
          @change="setFilter"
          @enter="search"
        />
      </lx-search-bar>

      <el-button
        v-if="canManageApplicationMgts && !isLoading"
        :icon="ILxPlus"
        type="primary"
        size="large"
        @click="$router.push(PATHS.PARAMETERS_APPLICATIONS_CREATE)"
      >
        {{ $t('applicationMgt.newApplicationMgt') }}
      </el-button>
    </lx-toolbar>

    <lxc-container
      :is-loading="isLoading && !results"
      :error="error"
    >
      <el-table
        v-loading="isLoading"
        :data="results?.data"
        :border="false"
        stripe
        :empty-text="$t('applicationMgt.noApplicationMgt')"
        :cell-style="getCellStyle"
        row-class-name="clickable"
        @row-click="$router.push(`${PATHS.PARAMETERS_APPLICATIONS}/${$event.id}`)"
        @sort-change="onSortChange"
      >
        <el-table-column
          prop="organizationName"
          :label="$t('applicationMgt.column.organizationName')"
        />
        <el-table-column
          prop="label"
          :formatter="(item: ApplicationMgtI) => item.name"
          :label="$t('applicationMgt.column.applicationName')"
          sortable="custom"
        />
        <el-table-column
          prop="properties.devicesTypeList"
          :label="$t('applicationMgt.column.deviceTypeList')"
          class-name="no-no-wrap"
        >
          <template #default="scope">
            <ul class="device-type-list">
              <li
                v-for="(deviceTypeName, index) in scope.row.properties.devicesTypeList"
                :key="index"
              >
                {{ deviceTypeName.type }} - {{ deviceTypeName.name }}
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdAt"
          :formatter="(item: ApplicationMgtI) => getDateToLocaleString(item.creationDate || '')"
          :label="$t('applicationMgt.column.creationDate')"
          sortable="custom"
        />
        <el-table-column
          prop="updatedAt"
          :formatter="(item: ApplicationMgtI) => getDateToLocaleString(item.lastUpdated || '')"
          :label="$t('applicationMgt.column.lastUpdated')"
          sortable="custom"
        />
        <el-table-column
          prop="organizationTimeZone"
          :label="$t('applicationMgt.column.timeZone')"
        />
        <el-table-column width="50">
          <template #default="scoped">
            <lxc-application-mgt-actions
              :key="scoped.row.id"
              :application-mgt="scoped.row"
              @change="reloadWithContext"
            />
          </template>
        </el-table-column>
      </el-table>

      <lxc-pagination
        :type-of-element="$t('pagination.applicationMgt', {count:pagination.totalCount})"
        :context="pagination"
        @update:current-page="fetchData"
      />
    </lxc-container>
  </el-container>
</template>

<style lang="scss" scoped>
  .device-type-list{
    margin: 0;
    padding-left: 1em;
  }
</style>
