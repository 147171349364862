<script lang='ts' setup>
import type { UserProfileI } from '@lxc/app-device-types'
import type { Ref } from 'vue'
import { useProfiles } from '~/composables/useProfiles'
import ILxcPlus from '~icons/lx/plus'
import LxcBreadcrumb from '~/components/shared/breadcrumb/LxcBreadcrumb.vue'
import { userTabsItems } from '~/components/userManagement/tab-user-management.config'
import { PATHS } from '~/constants/paths'
import type { PaginationContext } from '~/types/PaginationContext'
import { useElTable } from '~/composables/useElTable'

const router = useRouter()
const { getCellStyle } = useElTable()

const {
  isLoading,
  profiles,
  error,
  fetchUserProfiles,
} = useProfiles()

onMounted(async() => await fetchUserProfiles())

function openProfileDetails(row: UserProfileI) {
  router.push(`${PATHS.USER_MANAGEMENT_PROFILES}/${row.code}`)
}

function goToCreateNewProfile() {
  router.push(PATHS.USER_MANAGEMENT_PROFILES_CREATE)
}

const pagination: Ref<PaginationContext> = computed(() => ({
  pageCount: profiles.value?.context.count ?? 0,
  totalCount: profiles.value?.context?.totalCount ?? 0,
  currentPage: profiles.value?.context?.page ?? 0,
  pageSize: profiles.value?.context?.pageSize ?? 0,
}))

function reloadWithContext() {
  fetchUserProfiles(profiles.value?.context?.page)
}

const tabItems = userTabsItems
</script>

<template>
  <el-container direction="vertical">
    <lxc-breadcrumb />

    <lxc-tab-bar
      :tabs-items="tabItems"
      active-page-index="2"
    />
    <lx-toolbar :is-right="true">
      <el-button
        type="primary"
        :icon="ILxcPlus"
        size="large"
        @click="goToCreateNewProfile"
      >
        {{ $t('profile.button.create') }}
      </el-button>
    </lx-toolbar>

    <lxc-container
      :is-loading="isLoading && !profiles"
      :error="error"
    >
      <el-table
        v-loading="isLoading"
        :data="profiles?.data"
        :fit="true"
        row-class-name="clickable"
        data-cy="profiles-table"
        :cell-style="getCellStyle"
        @row-click="openProfileDetails"
      >
        <el-table-column
          prop="label"
          :label="$t('profile.profile')"
        />
        <el-table-column
          prop="nbUsers"
          :label="$t('profile.nbOfUsers')"
        />
        <el-table-column width="50">
          <template #default="scope">
            <lxc-user-profile-actions
              :user-profile="scope.row"
              @change="reloadWithContext"
            />
          </template>
        </el-table-column>
      </el-table>

      <lxc-pagination
        :type-of-element="$t('pagination.profile', pagination.totalCount)"
        :context="pagination"
        @update:current-page="fetchUserProfiles"
      />
    </lxc-container>
  </el-container>
</template>
