<script setup lang="ts">
import type { CampaignI } from '@lxc/app-device-types'

const props = defineProps<{
  campaign?: CampaignI | null
}>()

const { t } = useI18n()

const inclusionPeriods = computed(() => {
  const inclusionPeriods: Map<string, string> = new Map([['MONDAY', ''], ['TUESDAY', ''], ['WEDNESDAY', ''], ['THURSDAY', ''], ['FRIDAY', ''], ['SATURDAY', ''], ['SUNDAY', '']])
  const numberInclusionPeriod = props.campaign?.config?.inclusionPeriods?.length

  if (props.campaign?.config?.inclusionPeriods && numberInclusionPeriod) {
    for (const inclusionPeriod of props.campaign?.config?.inclusionPeriods) {
      const valueOfDay = inclusionPeriods.get(inclusionPeriod.day)
      const value = (valueOfDay) ? `${valueOfDay} | ${inclusionPeriod.startTimeSlot} - ${inclusionPeriod.endTimeSlot}` : `${inclusionPeriod.startTimeSlot} - ${inclusionPeriod.endTimeSlot}`
      inclusionPeriods.set(inclusionPeriod.day, value)
    }
  }
  else {
    for (const key of inclusionPeriods.keys()) {
      inclusionPeriods.set(key, '00:00 - 00:00')
    }
  }
  return inclusionPeriods
})

</script>

<template>
  <p class="underline underline-offset-auto">
    {{ $t('campaign.details.characteristics.inclusionPeriods.title') }}
  </p>
  <div
    v-for="[key, value] in inclusionPeriods"
    :key="key"
  >
    <p v-if="value.length">
      {{ t(`campaign.details.characteristics.inclusionPeriods.${key.toLocaleLowerCase()}`, { value: `[ ${value} ]` }) }}
    </p>
    <p
      v-else
      class="text-gray-500"
    >
      {{ t(`campaign.details.characteristics.inclusionPeriods.${key.toLocaleLowerCase()}`, { value: t(`campaign.details.characteristics.inclusionPeriods.inactive`) }) }}
    </p>
  </div>
</template>
